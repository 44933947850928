import EventEmitter from 'events';
import { TweenLite, TweenMax, Quint } from "gsap";
import { HELPERS } from './helpers';
import CityService from './City';

export default class CityResults extends EventEmitter {
    constructor( isMobile ) {
        super();
        this.isMobile = isMobile;
        this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.$citySearchTitle = document.querySelector( "#overlay-results-title" );
        this.$fallbackMsg = document.querySelector( "#overlay-results-no-results" );
        this.$articleResults = document.querySelector( "#overlay-results" );
        this.$buttonGroup = document.querySelector( ".overlay-buttons" );
        this.$container = document.querySelector( ".city-overlay-wrapper" );
        this.$parent = document.querySelector( "#city-overlay" );
        this.$dateMentions = Array.from( document.querySelectorAll( ".search-results-date" ) );
        this.$legalLine = document.querySelector( ".overlay-legal" );
        this.isLookingAtCity = false;
    }
    showResult ( articles ) {
        var _this2 = this;
        clearTimeout( this.offsetTimer );
        if ( this.isMobile ) this.$parent.style.pointerEvents = "auto";
        this.$container.style.pointerEvents = "auto";
        this.updateDisplayDate();
        var delay = !this.isLookingAtCity ? 1.3 : 0;
        this.isLookingAtCity = true;
        if ( articles.length > 0 ) {
            var html = "";
            articles.forEach( function ( item ) {
                html += '<a class="overlay-result article" target="_blank" href="' + item.url + '">\n                 <span>' + item.title + "</span></a>"
            } );
            this.$articleResults.innerHTML = html;
            TweenLite.to( this.$citySearchTitle, .25, {
                opacity: 1,
                delay: delay,
                overwrite: "all"
            } );
            TweenLite.to( this.$legalLine, .25, {
                opacity: 1,
                delay: delay + .6,
                overwrite: "all"
            } );
            TweenLite.to( this.$buttonGroup, .25, {
                opacity: 1,
                overwrite: "all"
            } );
            var $articles = document.querySelectorAll( ".overlay-result" );
            TweenMax.staggerFromTo( $articles, .4, {
                opacity: 0,
                y: 10
            }, {
                opacity: 1,
                y: 0,
                ease: Quint.easeOut,
                delay: delay,
                overwrite: "all"
            }, .1 );
            this.$fallbackMsg.style.display = "none";
            this.$buttonGroup.style.position = "relative";
            this.$buttonGroup.style.top = 0;
            this.$buttonGroup.style.left = 0
        } else {
            this.$buttonGroup.style.left = "";
            TweenLite.to( this.$fallbackMsg, .25, {
                opacity: 1,
                delay: delay,
                overwrite: "all"
            } );
            TweenLite.to( this.$citySearchTitle, .25, {
                opacity: 0,
                delay: delay,
                overwrite: "all"
            } );
            TweenLite.to( this.$legalLine, .25, {
                opacity: 0,
                delay: delay,
                overwrite: "all"
            } );
            this.offsetTimer = setTimeout( function () {
                _this2.$buttonGroup.style.position = "absolute";
                _this2.$buttonGroup.style.top = _this2.$citySearchTitle.offsetTop + "px";
                TweenLite.to( _this2.$buttonGroup, .25, {
                    opacity: 1,
                    delay: delay,
                    overwrite: "all"
                } )
            }, 100 );
            this.$fallbackMsg.style.display = "block";
            var _$articles = document.querySelectorAll( ".overlay-result" );
            TweenMax.staggerTo( _$articles, .2, {
                opacity: 0,
                y: -10,
                ease: Quint.easeOut,
                delay: delay,
                overwrite: "all"
            }, .1 )
        }
    }
    updateDisplayDate () {
        var _this3 = this;
        this.$dateMentions.forEach( function ( item ) {
            var currentDate = HELPERS.parseDate( _this3.dateWeAreLookingAt );
            var month = _this3.months[currentDate.getMonth()];
            var date = HELPERS.getTodaysDate() === _this3.dateWeAreLookingAt ? "today" : month + ", " + currentDate.getFullYear();
            item.innerHTML = " " + date
        } )
    }
    loadCity ( city, date ) {
        var _this4 = this;
        this.dateWeAreLookingAt = date;
        this.cityWeAreLookingAt = city;
        ( function ( promisedDate, promisedCity ) {
            var articles = [];
            CityService.getCity( city, date ).then( function ( response ) {
                if ( promisedDate !== _this4.dateWeAreLookingAt && promisedCity !== _this4.cityWeAreLookingAt ) {
                    return
                }
                if ( response && response[0] ) {
                    var data = response[0];
                    if ( Object.keys( data ).length > 0 ) {
                        var key = Object.keys( data )[0];
                        articles = data[key]
                    }
                }
                _this4.showResult( articles )
            } )
        } )( date, city )
    }
}