import { HELPERS } from './helpers';
var cities = [];
var cityToggle = false;

export default {
    getCity ( city, date ) {
        return new Promise( function ( resolve, reject ) {
            var cityStr = city.city + city.region + city.country;
            if ( !cityStr ) {
                reject()
            } else if ( cities[cityStr + date] ) {
                resolve( cities[cityStr + date] )
            } else {
                cityToggle = !cityToggle;
                //console.log( cityStr )
                var path = "/city-1.json";
                if ( date !== HELPERS.getTodaysDate() ) {
                    path += "?date=" + date
                }
                return fetch( path, {
                    credentials: "include"
                } ).then( function ( response ) {
                    return response.text()
                } ).then( function ( text ) {
                    try {
                        if ( text.startsWith( ")]}'," ) ) {
                            text = text.replace( ")]}',", "" );
                            var data = JSON.parse( text );
                            cities[cityStr + date] = data;
                            resolve( data )
                        }
                    } catch ( err ) {
                        reject()
                    }
                } )
            }
        } )
    }
}