(function(root) {
    "use strict";
    var Klang;
    window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;

    function Main(func) {
        Klang = func()
    }

    function Module(func) {
        func(Klang)
    }
    Main(function() {
        var Klang = {};
        Klang.version = Klang.versionNumber = 3;
        Klang.context = null;
        Klang.engineVersion = "N/A";
        Klang.progressCallback = null;
        Klang.readyCallback = null;
        Klang.browser = null;
        Klang.os = null;
        Klang.isMobile = null;
        Klang.isIOS = null;
        Klang.fallback = null;
        Klang.loggingEnabled = false;
        Klang.useMonoBuffers = false;
        Klang.Panner = null;
        Klang.safari = false;
        Klang.Model = {};
        return Klang
    });
    Module(function() {
        window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext
    });
    Module(function(Klang) {
        return Klang.Model.Data = function(data, name) {
            this.data = data.data;
            this._name = name
        }
    });
    Module(function(Klang) {
        return Klang.core = {}
    });
    Module(function(Klang) {
        (function(detector) {
            var supportedAudioFileSuffixes;

            function detectAudioFileSuffixes() {
                var a = window.document.createElement("audio");
                if (!a.canPlayType) {
                    return false
                }
                var supportedFileTypes = [];
                if (a.canPlayType('audio/ogg; codecs="vorbis"').replace(/no/, "")) {
                    supportedFileTypes.push(".ogg")
                }
                if (a.canPlayType("audio/aac").replace(/no/, "")) {
                    supportedFileTypes.push(".m4a")
                }
                if (a.canPlayType("audio/mpeg;").replace(/no/, "")) {
                    supportedFileTypes.push(".mp3")
                }
                return supportedFileTypes
            }

            function canPlayAudioSuffix(suffix) {
                supportedAudioFileSuffixes = supportedAudioFileSuffixes || detectAudioFileSuffixes();
                for (var i = 0; i < supportedAudioFileSuffixes.length; i++) {
                    if (supportedAudioFileSuffixes[i] === suffix) {
                        return true
                    }
                }
                return false
            }
            detector.canPlayAudioSuffix = canPlayAudioSuffix;

            function detectBrowser() {
                var ua = navigator.userAgent;
                var temp;
                var match = ua.match(/(edge(?=\/))\/?\s*(\d+)/i) || [];
                if (match.length == 0) {
                    match = ua.match(/(edge|opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
                }
                if (/trident/i.test(match[1])) {
                    temp = /\brv[ :]+(\d+)/g.exec(ua) || [];
                    return {
                        name: "IE",
                        version: temp[1] || "unknown"
                    }
                }
                if (match[1] === "Chrome") {
                    temp = ua.match(/\bOPR\/(\d+)/);
                    if (temp !== null) {
                        return {
                            name: "Opera",
                            version: temp[1]
                        }
                    }
                }
                match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, "-?"];
                if ((temp = ua.match(/version\/(\d+)/i)) !== null) {
                    match.splice(1, 1, temp[1])
                }
                return {
                    name: match[0],
                    version: parseInt(match[1])
                }
            }
            detector.browser = detectBrowser()
        })(Klang.detector || (Klang.detector = {}));
        var detector = Klang.detector;
        return Klang.core.detector = detector
    });
    Module(function(Klang) {
        (function(network) {
            function isCrossDomain(url) {
                var target = document.createElement("a");
                target.href = url;
                var host = document.createElement("a");
                host.href = location.href;
                var crossdomain = target.hostname != "" && (target.port != host.port || target.protocol != host.protocol || target.hostname != host.hostname);
                return crossdomain
            }
            network.isCrossDomain = isCrossDomain;

            function request(options, onDone, onProgress, onError) {
                var request;
                options.type = options.type || "GET";
                if (Klang.detector.browser["name"] == "MSIE" && Klang.detector.browser["version"] < 10) {
                    request = new window["XDomainRequest"];
                    request.onload = function() {
                        setTimeout(function() {
                            onDone && onDone(request.responseText)
                        }, 10)
                    };
                    request.onprogress = onProgress || function() {};
                    request.onerror = onError || function() {};
                    request.open(options.type, options.url, true)
                } else if (window["XMLHttpRequest"]) {
                    request = new XMLHttpRequest;
                    request.open(options.type, options.url, true);
                    request.onreadystatechange = function() {
                        try {
                            if (request.readyState == 4 && request.status == 200) {
                                if (onDone) {
                                    var response = request.responseText;
                                    onDone(response)
                                }
                            } else if (request.status != 0 && request.status != 200) {
                                if (onError) {
                                    onError({
                                        status: request.status
                                    })
                                }
                            }
                        } catch (e) {
                            throw e;
                            if (onError) {
                                onError({
                                    status: "aborted"
                                })
                            }
                        }
                    }
                } else {
                    throw "Error - browser does not support XDomain/XMLHttp Requests"
                }
                if (request) {
                    request.send(null)
                }
            }
            network.request = request
        })(Klang.network || (Klang.network = {}));
        var network = Klang.network;
        return Klang.core.network = network
    });
    Module(function(Klang) {
        function FileHandler() {
            this.memUsage = 0;
            this._decoding = false;
            this._files = {};
            this._bufferQue = [];
            this._groups = {};
            this._lastSentPercent = -1;
            this.deferDecoding = false;
            this.stremingFileIds = []
        }
        FileHandler.inst = null;
        Object.defineProperty(FileHandler, "instance", {
            get: function() {
                if (FileHandler.inst == null) {
                    FileHandler.inst = new FileHandler
                }
                return FileHandler.inst
            },
            enumerable: true,
            configurable: true
        });
        FileHandler.prototype.sendProgressCallback = function(group) {
            var loadGroup = this._groups[group];
            if (loadGroup.progressCallback && !loadGroup.loadInterrupted) {
                var percent = 0;
                if (loadGroup.progress.readyAudioFiles >= loadGroup.progress.totalAudioFiles) {
                    percent = Math.floor((loadGroup.progress.loadedBytes + loadGroup.progress.bufferedFiles) / (loadGroup.progress.totalBytes + loadGroup.progress.totalFiles) * (100 - (loadGroup.progress.totalFiles - loadGroup.progress.convertedFiles)))
                }
                if (this._lastSentPercent !== percent) {
                    loadGroup.progressCallback(percent)
                }
                this._lastSentPercent = percent
            }
        };
        FileHandler.prototype.updateProgress = function(request, e) {
            var group = request["load_group"];
            if (!request["sizeReceived"]) {
                request["sizeReceived"] = true;
                var totalBytes = 1;
                if (e.lengthComputable) {
                    totalBytes = e.total;
                    request["loadedBytes"] = 0
                }
                request["totalBytes"] = totalBytes;
                this._groups[group].progress.totalBytes += totalBytes;
                this._groups[group].progress.readyAudioFiles++
            }
            if (request["loadedBytes"] !== undefined) {
                var deltaBytes = e.loaded - request["loadedBytes"];
                request["loadedBytes"] = e.loaded;
                this._groups[group].progress.loadedBytes += deltaBytes;
                this.sendProgressCallback(group)
            }
        };
        FileHandler.prototype.decodeBufferQue = function() {
            var _this = this;
            if (this._bufferQue.length) {
                var queItem = this._bufferQue.pop();
                var data = queItem.data;
                var info = queItem.info;
                var callback = queItem.callback;
                this._decoding = true;
                Klang.context.decodeAudioData(data, function(buf) {
                    var group = _this._groups[info.load_group];
                    _this._decoding = false;
                    if (Klang.useMonoBuffers) {
                        var bufferLength = buf.length;
                        var monoBuffer = Klang.context.createBuffer(1, bufferLength, Klang.context.sampleRate);
                        var leftChannelData = buf.getChannelData(0);
                        var monoChannelData = monoBuffer.getChannelData(0);
                        if (typeof monoChannelData.set === "function") {
                            monoChannelData.set(leftChannelData)
                        } else {
                            for (var ix = 0; ix < bufferLength; ix++) {
                                monoChannelData[ix] = leftChannelData[ix]
                            }
                            buf = monoBuffer
                        }
                    }
                    if (Klang.downSample && Klang.downSample !== Klang.context.sampleRate) {
                        buf = Klang.Util.downSample(buf, Klang.downSample)
                    }
                    _this.memUsage += buf.length * buf.numberOfChannels * Float32Array.BYTES_PER_ELEMENT;
                    var memUsageBytes = _this.memUsage;
                    var memUsageMb = memUsageBytes / 1e6;
                    _this.addFile(info, buf);
                    group.progress.convertedFiles++;
                    queItem.data = null;
                    if (_this._bufferQue.length) {
                        _this.decodeBufferQue()
                    } else if (callback) {
                        callback()
                    }
                }, function(ex) {
                    Klang.log("Klang warning: unable to load file '" + (this._baseURL || "") + info.url + "'")
                })
            }
        };
        FileHandler.prototype.decode = FileHandler.prototype.decodeBufferQue;
        FileHandler.prototype.loadAudioBuffer = function(info, callback) {
            var _this = this;
            var request = new XMLHttpRequest;
            var format = ".mp3";
            if (Klang.detector.browser["name"] === "Firefox" || Klang.detector.browser["name"] === "Chrome") {
                format = ".ogg"
            }
            var url = (info.external ? "" : this._baseURL) + info.url + format;
            request.open("GET", url, true);
            request.responseType = "arraybuffer";
            request["sizeReceived"] = false;
            request["load_group"] = info.load_group;
            request.onprogress = function(e) {
                _this.updateProgress(request, e)
            };
            request.onload = function(e) {
                var group = _this._groups[info.load_group];
                var queueItem = {
                    data: request.response,
                    load_group: group,
                    info: info,
                    callback: callback
                };
                _this._bufferQue.push(queueItem);
                if (request["loadedBytes"]) {
                    var deltaBytes = request["totalBytes"] - request["loadedBytes"];
                    group.progress.loadedBytes += deltaBytes
                } else {
                    group.progress.loadedBytes += 1
                }
                _this.updateProgress(request, e);
                if (!_this.deferDecoding) {
                    _this.decodeBufferQue()
                } else {
                    queueItem.callback && queueItem.callback();
                    queueItem.load_group.progress.bufferedFiles++;
                    if (queueItem.load_group.progress.bufferedFiles === queueItem.load_group.progress.totalFiles) {
                        queueItem.load_group.filesLoadedCallback(true, queueItem.load_group._loadedFiles)
                    }
                }
                try {
                    request.response = null
                } catch (e) {}
                request = null
            };
            request.onreadystatechange = function() {
                if (request.readyState == 4 && request.status == 200) {} else if (request.status != 200) {
                    _this._groups[info.load_group].loadInterrupted = true;
                    if (_this._groups[info.load_group].loadFailedCallback) {
                        _this._groups[info.load_group].loadFailedCallback()
                    }
                }
            };
            request.send();
            this._groups[info.load_group].progress.totalAudioFiles++
        };
        FileHandler.prototype.loadMidiFile = function(info, callback) {
            var _this = this;
            loadRemote(this._baseURL + info.url, function(request, e) {
                _this.updateProgress(request, e)
            }, function(data) {
                _this.addFile(info, readMidiFile(data));
                if (callback) {
                    callback()
                }
            })
        };
        FileHandler.prototype.loadMidiString = function(info) {
            var _this = this;
            var request = new XMLHttpRequest;
            request.open("GET", this._baseURL + info.url);
            request.onprogress = function(e) {
                _this.updateProgress(request, e)
            };
            request.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    _this.addFile(info, readMidiString(request.response))
                }
            };
            request.send()
        };
        FileHandler.prototype.loadFiles = function(group, filesLoadedCallback, progressCallback, loadFailedCallback) {
            if (typeof group == "string") {
                group = [group]
            }
            var groupsToLoad = group.length;
            var _filesLoadedCallback = function() {
                groupsToLoad--;
                if (groupsToLoad === 0) {
                    filesLoadedCallback && filesLoadedCallback.apply(this, arguments)
                }
            };
            var loadProgression = {};
            var _progressCallback = function(prog) {
                if (progressCallback) {
                    loadProgression[this.name] = prog;
                    var cnt = 0;
                    var totProg = 0;
                    for (var key in loadProgression) {
                        if (loadProgression.hasOwnProperty(key)) {
                            cnt++;
                            totProg += loadProgression[key]
                        }
                    }
                    totProg /= cnt;
                    progressCallback(totProg)
                }
            };
            var _loadFailedCallback = function() {
                _filesLoadedCallback()
            };
            for (var ix = 0, len = group.length; ix < len; ix++) {
                loadProgression[group[ix]] = 0;
                this._groups[group[ix]] = {};
                this._groups[group[ix]]._loadedFiles = [];
                this._groups[group[ix]].filesLoadedCallback = _filesLoadedCallback;
                this._groups[group[ix]].progressCallback = _progressCallback.bind(this._groups[group[ix]]);
                this._groups[group[ix]].loadFailedCallback = _loadFailedCallback;
                this._groups[group[ix]].loadInterrupted = false;
                this._groups[group[ix]].name = group[ix];
                this._groups[group[ix]].progress = {
                    totalBytes: 0,
                    loadedBytes: 0,
                    totalFiles: 0,
                    totalAudioFiles: 0,
                    readyAudioFiles: 0,
                    bufferedFiles: 0,
                    convertedFiles: 0
                }
            }
            this.checkStreaming();
            for (var ix = 0, len = this._fileInfo.length; ix < len; ix++) {
                var info = this._fileInfo[ix];
                var groupIx = group.indexOf(info.load_group);
                if (groupIx != -1 && !this._files[info.id] && !info.only_audio_tag) {
                    switch (info.file_type) {
                        case "audio":
                            if (this.stremingFileIds.indexOf(info.id) === -1) {
                                this.loadAudioBuffer(info)
                            } else {
                                continue
                            }
                            break;
                        case "midi":
                            this.loadMidiFile(info);
                            break;
                        case "midistring":
                            this.loadMidiString(info);
                            break
                    }
                    this._groups[group[groupIx]].progress.totalFiles++
                }
            }
            for (var ix = 0, len = group.length; ix < len; ix++) {
                if (this._groups[group[ix]].progress.totalFiles == 0) {
                    if (this._groups[group[ix]].filesLoadedCallback && !this._groups[group[ix]]._loadInterrupted) {
                        this._groups[group[ix]].filesLoadedCallback(true, this._groups[group[ix]]._loadedFiles)
                    }
                }
            }
        };
        FileHandler.prototype.checkStreaming = function() {
            var objects = Klang.core.Core.instance._objectTable;
            for (var key in objects) {
                var object = objects[key];
                if (object instanceof Klang.Model.StreamingAudioSource) {
                    var fileId = object._fileId;
                    this.stremingFileIds.push(fileId)
                }
            }
        };
        FileHandler.prototype.prepareFile = function(fileInfo) {
            this._fileInfo.push(fileInfo)
        };
        FileHandler.prototype.prepareFiles = function(fileInfo) {
            var i, len;
            for (i = 0, len = fileInfo.length; i < len; i++) {
                this.prepareFile(fileInfo[i])
            }
        };
        FileHandler.prototype.addFile = function(info, file) {
            this._files[info.id] = file;
            this._groups[info.load_group].progress.bufferedFiles++;
            this._groups[info.load_group]._loadedFiles = this._groups[info.load_group]._loadedFiles || [];
            this._groups[info.load_group]._loadedFiles.push(info);
            this.sendProgressCallback(info.load_group);
            if (this._groups[info.load_group].progress.bufferedFiles == this._groups[info.load_group].progress.totalFiles && !this._groups[info.load_group].loadInterrupted) {
                if (this._groups[info.load_group].filesLoadedCallback) {
                    this._groups[info.load_group].filesLoadedCallback(true, this._groups[info.load_group]._loadedFiles || [])
                }
            }
        };
        FileHandler.prototype.freeSoundFiles = function(group) {
            if (typeof group == "string") {
                group = [group]
            }
            for (var ix = 0, len = this._fileInfo.length; ix < len; ix++) {
                var info = this._fileInfo[ix];
                if (group.indexOf(info.load_group) != -1) {
                    this._files[info.id] = null
                }
            }
        };
        FileHandler.prototype.freeSoundFile = function(id) {
            if (this._files[id]) {
                this._files[id] = null
            }
        };
        FileHandler.prototype.getLoadGroups = function() {
            var i;
            var fileInfoArr = this._fileInfo || [];
            var groupTable = {};
            var listOfGroups = [];
            for (i = 0; i < fileInfoArr.length; i++) {
                var fileInfo = fileInfoArr[i];
                groupTable[fileInfo.load_group] = fileInfo.load_group
            }
            for (i in groupTable) {
                listOfGroups.push(i)
            }
            return listOfGroups
        };
        FileHandler.prototype.getFile = function(id) {
            return this._files[id] || null
        };
        FileHandler.prototype.getFilesForLoadgroup = function(loadGroup) {
            var ret = [];
            for (var ix = 0, len = this._fileInfo.length; ix < len; ix++) {
                if (this._fileInfo[ix].load_group == loadGroup) {
                    ret.push(this._fileInfo[ix])
                }
            }
            return ret
        };
        FileHandler.prototype.getFileInfo = function(fileId) {
            for (var ix = 0, len = this._fileInfo.length; ix < len; ix++) {
                if (this._fileInfo[ix].id == fileId) {
                    return this._fileInfo[ix]
                }
            }
            return undefined
        };
        FileHandler.prototype.preloadFiles = function(loadgroups) {};
        Object.defineProperty(FileHandler.prototype, "progress", {
            get: function() {
                return this._groups
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(FileHandler.prototype, "baseURL", {
            set: function(url) {
                this._baseURL = url
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(FileHandler.prototype, "fileInfo", {
            set: function(fileInfo) {
                this._fileInfo = fileInfo
            },
            enumerable: true,
            configurable: true
        });
        return Klang.core.FileHandler = FileHandler
    });
    Module(function(Klang) {
        function Scheduler() {
            this._updateTime = 100;
            this._lookAHead = this._updateTime * 2;
            this._callbacks = []
        }
        Scheduler.prototype.scheduleClose = function(callback) {
            var timeOffset = callback.targetTime - Klang.context.currentTime;
            setTimeout(function() {
                callback.func.apply(callback.ctx)
            }, timeOffset * 1e3)
        };
        Scheduler.prototype.runScheduler = function() {
            if (this._callbacks.length > 0) {
                var currentTime = Klang.context.currentTime;
                for (var ix = 0; ix < this._callbacks.length; ix++) {
                    var callback = this._callbacks[ix];
                    if (currentTime + this._lookAHead >= callback.targetTime) {
                        this.scheduleClose(callback);
                        this._callbacks.splice(ix, 1);
                        ix--
                    }
                }
                this._lastTime = currentTime;
                var _this = this;
                this._scheduler = setTimeout(function() {
                    _this.runScheduler()
                }, _this._updateTime)
            } else {
                this.stop()
            }
        };
        Scheduler.prototype.start = function() {
            this._started = true;
            this._lastTime = Klang.context.currentTime;
            clearTimeout(this._scheduler);
            this.runScheduler()
        };
        Scheduler.prototype.stop = function() {
            this._started = false;
            clearTimeout(this._scheduler);
            this._scheduler = null
        };
        Scheduler.prototype.delay = function(targetTime, func, ctx) {
            return this.at(Klang.context.currentTime + targetTime, func, ctx)
        };
        Scheduler.prototype.at = function(targetTime, func, ctx) {
            if (targetTime < Klang.context.currentTime) {
                return this
            }
            this._callbacks.push({
                ctx: ctx || this,
                func: func,
                targetTime: targetTime
            });
            if (!this._started) {
                this.start()
            }
            return this
        };
        Scheduler.prototype.cancel = function(func) {
            for (var i = 0; i < this._callbacks.length; i++) {
                if (this._callbacks[i]["func"] && this._callbacks[i]["func"] === func) {
                    this._callbacks.splice(i, 1);
                    break
                }
            }
            return this
        };
        return Klang.core.Scheduler = Scheduler
    });
    Module(function(Klang) {
        function TimeHandler() {
            this._callbacks = []
        }
        TimeHandler.inst = null;
        Object.defineProperty(TimeHandler, "instance", {
            get: function() {
                if (TimeHandler.inst == null) {
                    TimeHandler.inst = new TimeHandler
                }
                return TimeHandler.inst
            },
            enumerable: true,
            configurable: true
        });
        TimeHandler.prototype.startScheduler = function() {
            if (this._callbacks.length > 0) {
                var currentTime = Klang.context.currentTime;
                var deltaTime = currentTime - this._lastTime;
                for (var ix = 0; ix < this._callbacks.length; ix++) {
                    var callback = this._callbacks[ix];
                    callback.timePassed += deltaTime;
                    if (callback.timePassed >= callback.targetTime) {
                        callback.obj[callback.func]();
                        this._callbacks.splice(ix, 1);
                        ix--
                    }
                }
                this._lastTime = currentTime;
                var _this = this;
                this._scheduler = setTimeout(function() {
                    _this.startScheduler()
                }, Klang.core.Core.settings.timehandler_lookahead)
            } else {
                this.stop()
            }
        };
        TimeHandler.prototype.start = function() {
            this._started = true;
            this._lastTime = Klang.context.currentTime;
            clearTimeout(this._scheduler);
            this.startScheduler()
        };
        TimeHandler.prototype.stop = function() {
            this._started = false;
            clearTimeout(this._scheduler);
            this._scheduler = null
        };
        TimeHandler.prototype.registerMethodCallback = function(obj, func, targetTime) {
            this._callbacks.push({
                obj: obj,
                func: func,
                timePassed: 0,
                targetTime: targetTime
            });
            if (!this._started) {
                this.start()
            }
            return this
        };
        TimeHandler.prototype.removeMethodCallback = function(obj, func) {
            for (var ix = 0, len = this._callbacks.length; ix < len; ix++) {
                var callback = this._callbacks[ix];
                if (callback.obj == obj && callback.func == func) {
                    this._callbacks.splice(ix, 1);
                    return
                }
            }
            return this
        };
        return Klang.core.TimeHandler = TimeHandler
    });
    Module(function(Klang) {
        var Util;
        (function(Util) {
            function setParam(param, value, when) {
                param.setValueAtTime(value, when || Klang.context.currentTime)
            }
            Util.setParam = setParam;

            function adjustParam(param, value, when) {
                param.setValueAtTime(param.value + value, when || Klang.context.currentTime)
            }
            Util.adjustParam = adjustParam;

            function curveParamLin(param, value, duration, when, startValue) {
                when = when || Klang.context.currentTime;
                var startAt = param.value;
                if (startValue !== undefined && Klang.detector.browser["name"] == "Firefox") {
                    startAt = startValue
                }
                param.setValueAtTime(startAt, when);
                param.linearRampToValueAtTime(value, Klang.context.currentTime + duration)
            }
            Util.curveParamLin = curveParamLin;

            function curveParamExp(param, value, duration, when, startValue) {
                when = when || Klang.context.currentTime;
                var startAt = param.value;
                if (startValue !== undefined && Klang.detector.browser["name"] == "Firefox") {
                    startAt = startValue
                }
                param.setValueAtTime(startAt == 0 ? Util.EXP_MIN_VALUE : startAt, when);
                param.exponentialRampToValueAtTime(value, Klang.context.currentTime + duration)
            }
            Util.curveParamExp = curveParamExp;

            function curveParam(param, curve, duration, when) {
                when = when || Klang.context.currentTime;
                param.setValueCurveAtTime(Util.CUSTOM_CURVES[curve], when, duration)
            }
            Util.curveParam = curveParam;
            Util.CUSTOM_CURVES = {};

            function createCurves(data) {
                for (var name in data) {
                    var cdata = data[name];
                    if (cdata instanceof Array) {
                        var curve = new Float32Array(cdata.length);
                        for (var ix = 0, len = cdata.length; ix < len; ix++) {
                            curve[ix] = cdata[ix]
                        }
                    } else {
                        if (!cdata.curve_type) {
                            Klang.warn("Modulation: Curve type not specified")
                        }
                        if (!cdata.resolution) {
                            cdata.resolution = 1024
                        }
                        if (!cdata.amplitude) {
                            cdata.amplitude = 1
                        }
                        if (!cdata.amplitude_offset) {
                            cdata.amplitude_offset = 0
                        }
                        if (!cdata.phase_offset) {
                            cdata.phase_offset = 0
                        }
                        if (!cdata.length) {
                            cdata.length = 1
                        }
                        var curve = new Float32Array(cdata.resolution);
                        if (cdata.curve_type == "sine") {
                            var phase_offset = cdata.phase_offset * Math.PI * 2;
                            var length = cdata.length * Math.PI * 2;
                            for (var ix = 0, len = curve.length; ix < len; ix++) {
                                curve[ix] = cdata.amplitude_offset + Math.sin(phase_offset + ix / len * length) * cdata.amplitude
                            }
                        } else if (cdata.curve_type == "saw") {
                            for (var ix = 0, len = curve.length; ix < len; ix++) {
                                curve[ix] = cdata.amplitude_offset + (len - ix) / len * cdata.amplitude
                            }
                        } else if (cdata.curve_type == "inverse-saw") {
                            for (var ix = 0, len = curve.length; ix < len; ix++) {
                                curve[ix] = cdata.amplitude_offset + ix / len * cdata.amplitude
                            }
                        } else {
                            Klang.warn("Modulation: Unrecognized curve type")
                        }
                        Util.CUSTOM_CURVES[name] = curve
                    }
                }
            }
            Util.createCurves = createCurves;
            if (navigator.userAgent.indexOf("MSIE") != -1) {
                var ie = true;
                var ua = navigator.userAgent;
                var re = new RegExp("MSIE ([0-9]{1,}[.0-9]{0,})");
                var ieVersion;
                if (re.exec(ua) != null) {
                    ieVersion = parseInt(RegExp.$1)
                }
                if (ieVersion < 9) {
                    Object.defineProperty = Object["oldDefineProperty"];
                    delete Object["oldDefineProperty"]
                }
            }
            Util.ROOT12 = 1.059463094359295;
            Util.NYQUIST_FREQUENCY = 22050;
            Util.PITCH_SHIFT_FFT = 2048;
            Util.EXP_MIN_VALUE = 1e-4;
            Util.OSC_START_DELAY = .005;
            Util.LOG_TIME_COLOR = "#999999";
            Util.LOG_EVENT_COLOR = "#54CBDD";
            Util.LOG_UNIMPLEMENTED_EVENT_COLOR = "#E075A9";
            Util.LOG_LOAD_COLOR = "#333333";
            Util.LOG_WARN_COLOR = "DarkOrange";
            Util.LOG_ERROR_COLOR = "Red";

            function applyMixins(derivedCtor, baseCtors) {
                baseCtors.forEach(function(baseCtor) {
                    Object.getOwnPropertyNames(baseCtor.prototype).forEach(function(name) {
                        derivedCtor.prototype[name] = baseCtor.prototype[name]
                    })
                })
            }
            Util.lastEvent = undefined;
            Util.clamp = function(value, min, max) {
                return Math.max(min, Math.min(max, value))
            };
            Util.clamp01 = function(value) {
                return Util.clamp(value, 0, 1)
            };
            Util.vars = {};

            function random(max, min) {
                min !== undefined ? min : 1;
                return Math.floor(min + (1 + max - min) * Math.random())
            }
            Util.random = random;

            function randomFloat(max, min) {
                min !== undefined ? min : 1;
                return min + (max - min) * Math.random()
            }
            Util.randomFloat = randomFloat;

            function loadScriptFile(url, success, fail) {
                var script = document.createElement("script");
                script.async = true;
                script.onload = function() {
                    success && success();
                    script.onload = null
                };
                script.onerror = function(e) {
                    fail && fail(e);
                    script.onerror = null
                };
                script.src = url;
                document.getElementsByTagName("head")[0].appendChild(script)
            }
            Util.loadScriptFile = loadScriptFile;

            function ease(current, delta, ease) {
                if (typeof ease === "undefined") {
                    ease = 3
                }
                return current - (current - delta) / ease
            }
            Util.ease = ease;

            function now() {
                return Klang.engineVersion == "webaudio" ? Klang.context.currentTime : 0
            }
            Util.now = now;

            function downSample(buffer, sampleRate) {
                var ratio = sampleRate / Klang.context.sampleRate;
                var stepLen = Math.floor(1 / ratio);
                var newLen = Math.floor(buffer.length * ratio);
                var newSampleRate = Math.floor(Klang.context.sampleRate * ratio);
                var downSampleBuff = Klang.context.createBuffer(buffer.numberOfChannels, newLen, newSampleRate);
                for (var c = 0; c < buffer.numberOfChannels; c++) {
                    var buffDataIn = buffer.getChannelData(c);
                    var buffDataOut = downSampleBuff.getChannelData(c);
                    var j = 0;
                    for (var i = 0; i < buffDataIn.length; i += stepLen) {
                        var acc = 0;
                        for (var j = 0; j < stepLen; j++) {
                            acc += buffDataIn[i + j]
                        }
                        buffDataOut[i / stepLen] = acc / stepLen
                    }
                }
                return downSampleBuff
            }
            Util.downSample = downSample;

            function semitonesToPlaybackRate(semitones) {
                var semitoneRatio = Math.pow(2, 1 / 12);
                var playbackRate = Math.pow(semitoneRatio, semitones);
                return playbackRate
            }
            Util.semitonesToPlaybackRate = semitonesToPlaybackRate;

            function midiNoteToFrequency(note) {
                return 440 * Math.pow(2, (note - 69) / 12)
            }
            Util.midiNoteToFrequency = midiNoteToFrequency;

            function frequencyToMidiNote(freq) {
                return 69 + 12 * Math.log(freq / 440) / Math.log(2)
            }
            Util.frequencyToMidiNote = frequencyToMidiNote;

            function safeFilterType(filterType) {
                if (filterType === undefined) {
                    return "lowpass"
                }
                return filterType
            }
            Util.safeFilterType = safeFilterType;

            function checkMobile() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            }
            Util.checkMobile = checkMobile;

            function checkIOS() {
                return /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
            }
            Util.checkIOS = checkIOS;

            function setBlurFadeOut(state) {
                Klang.core.Core.instance.blurFadeOut = state
            }
            Util.setBlurFadeOut = setBlurFadeOut;

            function getParameterByName(name) {
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                    results = regex.exec(location.search);
                return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "))
            }
            Util.getParameterByName = getParameterByName;

            function transition(from, to, bpm, sync, fadeOutTime, fadeInTime, useOffset, fadeOutImmediately) {
                var i = 0;
                var bpm = bpm || 120;
                var fadeOutTime = fadeOutTime || 2;
                var toStop = [];
                if (!to) {
                    return Util.now()
                }
                if (from && !Array.isArray(from)) {
                    from = [from]
                }
                if (from) {
                    var playingLoop;
                    for (i = 0; i < from.length; i++) {
                        if (from[i].playing || from[i].scheduledToPlay()) {
                            if (playingLoop && playingLoop.scheduledToPlay() && from[i].playing) {
                                playingLoop = from[i]
                            } else {
                                playingLoop = playingLoop || from[i]
                            }
                            if (!from[i]._stopping && from[i] !== to) {
                                toStop.push(from[i])
                            }
                        }
                    }
                    from = playingLoop
                }
                if (from === to && !from._stopping) {
                    return Util.now()
                }
                if (!from) {
                    to.play(Util.now(), 0, false);
                    return Util.now()
                }
                var bps = 60 / bpm;
                var spb = bpm / 60;
                var p1 = from ? from.position : 0;
                p1 = p1 || 0;
                var beat1 = p1 * spb;
                var sync = sync || 4;
                var toNextBar = sync - beat1 % sync;
                if (toNextBar < .5) {
                    toNextBar += sync
                }
                while (fadeOutImmediately && toNextBar < fadeOutTime * spb) {
                    toNextBar += sync
                }
                var toNextBarSec = toNextBar * bps;
                if (!from.playing) {
                    toNextBarSec = 0
                }
                var scheduleTime = Util.now() + toNextBarSec;
                var offset = 0;
                if (useOffset) {
                    offset = from.position + toNextBarSec
                }
                if (fadeInTime) {
                    to.fadeInAndPlay(fadeInTime, scheduleTime, offset)
                } else {
                    to.play(scheduleTime, offset, false)
                }
                var fadeOutStartTime = fadeOutImmediately ? scheduleTime - fadeOutTime : scheduleTime;
                if (fadeOutImmediately && fadeOutStartTime < Klang.context.currentTime) {
                    fadeOutTime = scheduleTime - Klang.context.currentTime
                }
                for (i = 0; i < toStop.length; i++) {
                    toStop[i].fadeOutAndStop(fadeOutTime, fadeOutStartTime)
                }
                return scheduleTime
            }
            Util.transition = transition;

            function getTimeToBeat(from, bpm, sync, offset) {
                var bpm = bpm || 120;
                var from = from;
                var offset = offset || 0;
                if (Array.isArray(from)) {
                    var playingLoop;
                    for (var i = 0; i < from.length; i++) {
                        if (from[i].playing) {
                            playingLoop = from[i];
                            break
                        }
                    }
                    from = playingLoop
                }
                if (!from) {
                    return
                }
                var bps = 60 / bpm;
                var spb = bpm / 60;
                var p1 = from.position;
                p1 = p1 || 0;
                if (from._loopStart > 0) {
                    if (p1 < from._loopStart) {
                        p1 = 0
                    } else {
                        p1 -= from._loopStart
                    }
                }
                var beat1 = p1 * spb;
                var sync = sync || 4;
                var toNextBar = sync - beat1 % sync;
                toNextBar += offset;
                var toNextBarSec = toNextBar * bps;
                if (!from.playing) {
                    toNextBarSec = 0
                }
                return toNextBarSec
            }
            Util.getTimeToBeat = getTimeToBeat;

            function stopPlayingExcept() {
                var exceptions = [];
                for (var _i = 0; _i < arguments.length - 0; _i++) {
                    exceptions[_i] = arguments[_i + 0]
                }
                var sequencerArgs = [{
                    beat: 0,
                    fadeOut: 2
                }];
                for (var ix = exceptions.length - 1; ix >= 0; ix--) {
                    var instance = Klang.core.Core.instance.findInstance(exceptions[ix]);
                    if (instance.type == "Pattern") {
                        sequencerArgs.push(exceptions[ix])
                    }
                }
                var objects = Klang.core.Core.instance._objectTable;
                for (var o in Klang.core.Core.instance._objectTable) {
                    var obj = objects[o];
                    if (obj._type == "AudioSource" && exceptions.indexOf(o) == -1) {
                        if (obj.loop && obj.playing) {
                            obj.fadeOutAndStop(1)
                        }
                    } else if (obj._type == "Sequencer") {
                        obj.stopAll.apply(obj, sequencerArgs)
                    } else if (obj._type == "AdvancedProcess") {
                        if (obj.started && exceptions.indexOf(o) == -1) {
                            obj.stop()
                        }
                    }
                }
            }
            Util.stopPlayingExcept = stopPlayingExcept;

            function shuffle(array) {
                var counter = array.length,
                    temp, index;
                while (counter--) {
                    index = Math.random() * counter | 0;
                    temp = array[counter];
                    array[counter] = array[index];
                    array[index] = temp
                }
                return array
            }
            Util.shuffle = shuffle;

            function cloneObject(obj) {
                if (obj === null || typeof obj !== "object") {
                    return obj
                }
                var temp = obj.constructor();
                for (var key in obj) {
                    temp[key] = cloneObject(obj[key])
                }
                return temp
            }
            Util.cloneObject = cloneObject;

            function logFreq(value) {
                if (value == 0) {
                    return 0
                }
                var min = 20;
                var max = 2e4;
                if (min == 0) {
                    min = .01
                }
                var position = value;
                var minp = min;
                var maxp = max;
                var minv = Math.log(minp);
                var maxv = Math.log(maxp);
                var scale = (maxv - minv) / (maxp - minp);
                return Math.exp(minv + scale * (position - minp))
            }
            Util.logFreq = logFreq;

            function generateIdString(len) {
                var seed = "";
                while (seed.length < len) {
                    seed += "0"
                }
                return (seed + (Math.random() * Math.pow(36, len) << 0).toString(36)).slice(-len)
            }
            Util.generateIdString = generateIdString;
            Util.MidiHandler = {
                midiAccess: null,
                midiIn: null,
                midiOut: null,
                inputs: [],
                outputs: [],
                init: function(done) {
                    this.MIDIUtils.initUtils();
                    var _this = this;
                    window["navigator"].requestMIDIAccess().then(function(e) {
                        _this.onMIDIStarted(e);
                        done && done()
                    }, this.onMIDISystemError)
                },
                onMIDISystemError: function(msg) {
                    console.log("Error encountered:", msg)
                },
                onMIDIStarted: function(midi) {
                    Util.MidiHandler.midiAccess = midi;
                    var inputs = midi.inputs;
                    inputs.forEach(function(port) {
                        Util.MidiHandler.inputs.push(port)
                    });
                    var outputs = midi.outputs;
                    outputs.forEach(function(port) {
                        Util.MidiHandler.outputs.push(port)
                    });
                    if (Util.MidiHandler.inputs.length) {
                        Util.MidiHandler.midiIn = Util.MidiHandler.inputs[0];
                        Util.MidiHandler.midiIn.onmidimessage = Util.MidiHandler.midiMessageReceived;
                        Util.MidiHandler.midiOut = Util.MidiHandler.outputs[0]
                    } else {
                        console.error("No midi inputs found");
                        return
                    }
                },
                midiMessageReceived: function(ev) {
                    var a = ev.data[0];
                    var cmd = ev.data[0] >> 4;
                    var channel = ev.data[0] & 15;
                    var noteNumber = ev.data[1];
                    var velocity = ev.data[2];
                    Util.MidiHandler.handleMidiData(a, cmd, channel, noteNumber, velocity)
                },
                handleMidiData: function(a, cmd, channel, noteNumber, velocity) {},
                changeMidi: function(index) {
                    this.midiOut = this.outputs[index];
                    this.midiIn = this.inputs[index]
                },
                MIDIUtils: {
                    noteMap: {},
                    noteNumberMap: [],
                    notes: ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"],
                    initUtils: function(argument) {
                        for (var i = 0; i < 127; i++) {
                            var index = i,
                                key = this.notes[index % 12],
                                octave = (index / 12 | 0) - 1;
                            if (key.length === 1) {
                                key = key + "-"
                            }
                            key += octave;
                            this.noteMap[key] = i;
                            this.noteNumberMap[i] = key
                        }
                    },
                    getBaseLog: function(value, base) {
                        return Math.log(value) / Math.log(base)
                    },
                    noteNameToNoteNumber: function(name) {
                        return this.noteMap[name]
                    },
                    noteNumberToFrequency: function(note) {
                        return 440 * Math.pow(2, (note - 69) / 12)
                    },
                    noteNumberToName: function(note) {
                        return this.noteNumberMap[note]
                    },
                    frequencyToNoteNumber: function(f) {
                        return Math.round(12 * this.getBaseLog(f / 440, 2) + 69)
                    }
                },
                play: function(note, noteLength, velocity) {
                    if (typeof velocity === "undefined") {
                        velocity = 100
                    }
                    this.midiOut.send([144, note, velocity]);
                    console.log("On");
                    this.midiOut.send([128, note, 0], window.performance.now() + noteLength)
                },
                panic: function() {
                    for (var i = 1; i < 128; ++i) {
                        this.midiOut.send([128, i, 0])
                    }
                }
            }
        })(Util || (Util = {}));
        Util.__extends = function(d, b) {
            function __() {
                this.constructor = d
            }
            __.prototype = b.prototype;
            d.prototype = new __
        };
        return Klang.Util = Util
    });
    Module(function(Klang) {
        function EventEmitter() {}
        EventEmitter.prototype.on = function(name, callback, context) {
            this._events = this._events || {};
            var events = this._events[name] || (this._events[name] = []);
            events.push({
                callback: callback,
                ctxArg: context,
                context: context || this
            });
            return this
        };
        EventEmitter.prototype.off = function(name, callback, context) {
            var i, len, listener, retain;
            if (!this._events || !this._events[name]) {
                return this
            }
            if (!name && !callback && !context) {
                this._events = {}
            }
            var eventListeners = this._events[name];
            if (eventListeners) {
                retain = [];
                if (callback && context) {
                    for (i = 0, len = eventListeners.length; i < len; i++) {
                        listener = eventListeners[i];
                        if (callback !== listener.callback && context !== listener.ctxArg) {
                            retain.push(eventListeners[i])
                        }
                    }
                } else if (callback) {
                    for (i = 0, len = eventListeners.length; i < len; i++) {
                        listener = eventListeners[i];
                        if (callback !== listener.callback) {
                            retain.push(eventListeners[i])
                        }
                    }
                } else if (context) {
                    for (i = 0, len = eventListeners.length; i < len; i++) {
                        listener = eventListeners[i];
                        if (context !== listener.ctxArg) {
                            retain.push(eventListeners[i])
                        }
                    }
                }
                this._events[name] = retain
            }
            if (!this._events[name].length) {
                delete this._events[name]
            }
            return this
        };
        EventEmitter.prototype.trigger = function(name) {
            var args = [];
            for (var _i = 0; _i < arguments.length - 1; _i++) {
                args[_i] = arguments[_i + 1]
            }
            if (!this._events || !this._events[name]) {
                return this
            }
            var i, binding, listeners;
            listeners = this._events[name];
            args = [].splice.call(arguments, 1);
            for (i = listeners.length - 1; i >= 0; i--) {
                binding = listeners[i];
                binding.callback.apply(binding.context, args)
            }
            return this
        };
        Klang.core.internalEventBus = new EventEmitter;
        return Klang.core.EventEmitter = EventEmitter
    });
    Module(function(Klang) {
        function Core() {
            this._initComplete = false;
            this._blurFadeOut = false;
            this._masterBusId = null;
            this._preLoadInitStack = [];
            this._postLoadInitStack = [];
            this._connectStack = [];
            this._superMasterOutput = Klang.context ? Klang.context.createGain() : null;
            this.scheduler = new Klang.core.Scheduler;
            if (Klang.Util.getParameterByName("klang_log")) {
                Klang.loggingEnabled = true
            }
        }
        Core.debugSettings = {};
        Core.inst = null;
        Core.isInited = function isInited() {
            if (Core.inst == null) {
                return false
            }
            return Core.inst._initComplete
        };
        Object.defineProperty(Core.prototype, "initComplete", {
            get: function() {
                return this._initComplete
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Core, "instance", {
            get: function() {
                if (Core.inst == null) {
                    Core.inst = new Core
                }
                return Core.inst
            },
            enumerable: true,
            configurable: true
        });
        Core.prototype.setCallbacks = function(callbacks) {
            this._callbacks = callbacks
        };
        Object.defineProperty(Core, "callbacks", {
            get: function() {
                return Core.instance._callbacks
            },
            enumerable: true,
            configurable: true
        });
        Core.deinit = function deinit() {
            Core.inst = null
        };
        Core.prototype.stopAll = function() {
            if (window["KlangVisual"]) {
                KlangVisual.stop()
            }
            for (var p in this._objectTable) {
                if (this._objectTable[p].stop) {
                    try {
                        this._objectTable[p].stop()
                    } catch (ex) {}
                }
            }
        };
        Core.prototype.getPlaying = function() {
            var playing = [];
            for (var p in this._objectTable) {
                var obj = this._objectTable[p];
                if (obj instanceof Klang.Model.AudioSource && obj.playing) {
                    playing.push(obj)
                } else if (obj instanceof Klang.Model.AudioGroup && obj.playing) {
                    if (obj.latestPlayed) {
                        playing.push(obj.latestPlayed)
                    }
                }
            }
            return playing
        };
        Core.prototype.loadJSON = function(options, readyCallback, progressCallback, url) {
            this._readyCallback = readyCallback;
            this._progressCallback = progressCallback || function() {};
            if (typeof options === "object") {
                Klang.log("Loading config (editor)");
                var data = this.createConfigNode(options);
                Core.settings = data.settings;
                Core.instance.initContent(data, null, url);
                if (window["KlangVisual"]) {
                    KlangVisual.init(options)
                }
            } else if (typeof options === "string") {
                Klang.log("Loading config (client)");
                var request = new XMLHttpRequest;
                request.open("GET", options, true);
                var _this = this;
                request.onreadystatechange = function() {
                    if (request.readyState == 4 && request.status == 200) {
                        var configText = request.responseText;
                        var data = _this.parseConfigJSON(configText);
                        Core.settings = data.settings;
                        Core.instance.initContent(data, null, options);
                        if (window["KlangVisual"]) {
                            KlangVisual.init(JSON.parse(configText))
                        }
                    } else if (request.status == 404) {
                        throw "Klang exception: config file not found: '" + options + "'"
                    } else if (request.status != 200) {
                        throw "Klang exception: unable to load config file: '" + options + "'"
                    }
                };
                request.send(null)
            } else {
                throw "Klang exception: unrecognized options: '" + options + "'"
            }
        };
        Core.prototype.parseConfigJSON = function(jsonString) {
            if (typeof jsonString === "string") {
                return JSON.parse(jsonString, function(key, value) {
                    if (value && typeof value === "object" && typeof value.type === "string") {
                        if (!Klang.Model[value.type]) {
                            Klang.warn("Core: Type not found: " + value.type);
                            return null
                        }
                        return new Klang.Model[value.type](value, key)
                    }
                    return value
                })
            } else {
                for (var key in Object.keys(jsonString)) {
                    var value = jsonString[key];
                    if (!Klang.Model[value.type]) {
                        Klang.warn("Core: Type not found: " + value.type);
                        return null
                    }
                    return new Klang.Model[value.type](value, key)
                }
            }
        };
        Core.prototype.createConfigNode = function(node) {
            if (typeof node === "object") {
                for (var key in node) {
                    var prop = node[key];
                    if (typeof prop === "object" && typeof prop.type === "string") {
                        if (!Klang.Model[prop.type]) {
                            Klang.warn("Core: Type not found: " + prop.type)
                        }
                        if (prop.type == "channel") continue;
                        node[key] = this.createConfigNode(prop);
                        node[key] = new Klang.Model[prop.type](prop, key)
                    } else {
                        node[key] = this.createConfigNode(prop)
                    }
                }
            }
            return node
        };
        Core.prototype.createObject = function(name, data, options) {
            if (!options) {
                options = {}
            }
            if (!Klang.Model[data.type]) {
                Klang.warn("Core: Type not found: " + data.type);
                return
            }
            if (!options.excludeFromTable && this._objectTable[name]) {
                Klang.warn("Core: Duplicate object: " + name)
            }
            var obj = new Klang.Model[data.type](data, name);
            if (!options.excludeFromTable) {
                this._objectTable[name] = obj
            }
            if (!options.noInit && obj.init) {
                obj.init()
            }
            if (!options.noConnect && obj.destinationName && obj.connect) {
                if (obj.destinationName == "$OUT") {
                    obj.connect(this._superMasterOutput)
                } else {
                    var destination = this.findInstance(obj.destinationName);
                    if (!destination) {
                        Klang.warn("Core: Destination not found: " + obj.destinationName)
                    }
                    if (destination._type != "Bus") {
                        Klang.warn("Core: Destination is not a bus: " + obj.destinationName)
                    }
                    obj.connect(destination.input)
                }
            }
            return obj
        };
        Core.prototype.updateObject = function(object, data) {
            var obj = typeof object == "string" ? this._objectTable[object] : object;
            if (obj._type == "SimpleProcess" && data.type == "AdvancedProcess") {
                var advancedProcess = newAdvancedProcess(data, object);
                advancedProcess.init();
                this._objectTable[object] = advancedProcess
            } else if (obj._type == "AdvancedProcess" && data.type == "SimpleProcess") {
                var simpleProcess = newSimpleProcess(data, object);
                simpleProcess.init();
                this._objectTable[object] = simpleProcess
            } else if (obj.setData) {
                obj.setData(data)
            }
        };
        Core.prototype.createEvent = function(name, target) {
            if (this._eventTable[name]) {
                Klang.warn("Core: Duplicate event: " + name)
            }
            this._eventTable[name] = target
        };
        Core.prototype.visChange = function(fadeTime) {
            if (this.isHidden()) {
                if (this._blurFadeOut) {
                    Klang.Util.curveParamLin(this._superMasterOutput.gain, 0, fadeTime)
                }
            } else {
                Klang.Util.curveParamLin(this._superMasterOutput.gain, 1, fadeTime)
            }
        };
        Core.prototype.initContent = function(data, files, url) {
            var relativePath = data.settings.relative_path;
            var baseURL;
            var filePath = data.settings.file_path || "";
            if (relativePath) {
                if (url.lastIndexOf("/") != -1) {
                    baseURL = url.substring(0, url.lastIndexOf("/"));
                    if (baseURL.charAt(baseURL.length - 1) !== "/") {
                        baseURL += "/"
                    }
                    baseURL += filePath
                } else {
                    baseURL = filePath
                }
            } else {
                baseURL = filePath
            }
            Klang.log("Initializing core");
            var startTimeStamp = Klang.context.currentTime;
            if (data.settings.blur_fade_time != -1) {
                this._blurFadeOut = true;
                var fadeTime = data.settings.blur_fade_time || .5;
                if (fadeTime < 0 && fadeTime != -1) {
                    Klang.warn("Core: Invalid blur_fade_time value. Must be -1 or >= 0.")
                }
                var _this = this;
                var visProp = this.getHiddenProp();
                if (visProp) {
                    var evtname = "visibilitychange";
                    document.addEventListener(evtname, function() {
                        _this.visChange(fadeTime)
                    })
                }
            }
            this.maxSimultaneousSounds = data.settings.max_simultaneous_sounds;
            Klang.core.FileHandler.instance.fileInfo = (files !== undefined && files !== null ? files : data.files) || [];
            this._eventTable = data.events || {};
            this._objectTable = {};
            for (var p in data.audio) {
                this._objectTable[p] = data.audio[p]
            }
            for (var p in data.busses) {
                this._objectTable[p] = data.busses[p]
            }
            for (var p in data.sequencers) {
                this._objectTable[p] = data.sequencers[p]
            }
            for (var p in data.processes) {
                this._objectTable[p] = data.processes[p]
            }
            for (var p in data.synths) {
                this._objectTable[p] = data.synths[p]
            }
            for (var p in data.lfos) {
                this._objectTable[p] = data.lfos[p]
            }
            for (var p in data.automations) {
                this._objectTable[p] = data.automations[p]
            }
            for (var p in data.data) {
                this._objectTable[p] = data.data[p]
            }
            this.setVars(data.vars);
            this._masterBusId = data.masterBus;
            this._exportedSymbols = data.exportedSymbols || {};
            this._logIgnore = data.debug.log_ignore || data.log_ignore || {};
            Klang.Util.createCurves(data.curves);
            this._loadStartTimestamp = (new Date).getTime();
            if (data.debug) {
                Klang.debugData.ignoredEvents = data.debug.ignored_events || Klang.debugData.ignoredEvents;
                Klang.debugData.logToConsole = data.debug.log_to_console || Klang.debugData.logToConsole
            }
            Klang.log("Pre load initialization started");
            for (var ix = 0, len = this._preLoadInitStack.length; ix < len; ix++) {
                var element = this._preLoadInitStack[ix];
                if (element.init) {
                    element.init()
                }
            }
            Klang.log("Pre load initialization finished");
            Klang.log("Connecting nodes");
            this._superMasterOutput.connect(Klang.context.destination);
            for (var ix = 0, len = this._connectStack.length; ix < len; ix++) {
                var element = this._connectStack[ix];
                switch (element.destinationName) {
                    case "$OUT":
                        element.connect(this._superMasterOutput);
                        break;
                    case "$PARENT":
                        break;
                    default: {
                        var destination = this.findInstance(element.destinationName);
                        if (!destination) {
                            Klang.warn("Core: Destination not found: " + element.destinationName)
                        }
                        if (destination._type != "Bus") {
                            Klang.warn("Core: Destination is not a bus: " + element.destinationName)
                        }
                        element.connect(destination.input);
                        break
                    }
                }
            }
            Klang.log("Nodes connected");
            this._preLoadInitStack = null;
            this._connectStack = null;
            this._timeHandler = new Klang.core.TimeHandler;
            this._initComplete = true;
            Klang.log("Core initialized");
            Klang.core.FileHandler.instance.baseURL = baseURL;
            if (!Klang.initOptions || Klang.initOptions && !Klang.initOptions.noAutoLoad) {
                Klang.core.FileHandler.instance.loadFiles("auto", Core.soundsLoaded, this._progressCallback)
            } else {
                setTimeout(Core.soundsLoaded, 4)
            }
        };
        Core.prototype.isHidden = function() {
            var prop = this.getHiddenProp();
            if (!prop) {
                return false
            }
            return document[prop]
        };
        Core.prototype.getHiddenProp = function() {
            var prefixes = ["webkit", "moz", "ms", "o"];
            if ("hidden" in document) {
                return "hidden"
            }
            for (var i = 0; i < prefixes.length; i++) {
                if (prefixes[i] + "Hidden" in document) {
                    return prefixes[i] + "Hidden"
                }
            }
            return null
        };
        Core.prototype.setVars = function(vars) {
            if (vars) {
                for (var key in vars) {
                    if (typeof vars[key] == "string" && vars[key].indexOf("me.") > -1) {
                        vars[key] = this.findInstance(vars[key].split("me.")[1])
                    } else if (typeof vars[key] == "object") {
                        var obj = vars[key];
                        for (var prop in obj) {
                            if (obj.hasOwnProperty(prop)) {
                                if (typeof obj[prop] == "string" && obj[prop].indexOf("me.") > -1) {
                                    obj[prop] = this.findInstance(obj[prop].split("me.")[1])
                                }
                            }
                        }
                    }
                }
                Klang.Util.vars = vars
            }
        };
        Core.prototype.loadSoundFiles = function(name, callback, progressCallback, loadFailedCallback) {
            var start = (new Date).getTime();
            if (progressCallback) {
                this._progressCallback = progressCallback
            }
            var _this = this;
            Klang.core.FileHandler.instance.loadFiles(name, function(success, loadedFiles) {
                for (var i = 0; i < loadedFiles.length; i++) {
                    var fileId = loadedFiles[i].id;
                    for (var j in _this._objectTable) {
                        if (_this._objectTable.hasOwnProperty(j)) {
                            var obj = _this._objectTable[j];
                            if (obj._type === "AudioSource" && obj._fileId === fileId) {
                                obj.init()
                            }
                        }
                    }
                }
                var end = (new Date).getTime();
                var time = end - start;
                Klang.log("Execution time for loadgroup: " + time);
                callback && callback(true)
            }, this._progressCallback, loadFailedCallback)
        };
        Core.prototype.freeSoundFiles = function(name) {
            var loadGroups = Klang.core.FileHandler.instance.getLoadGroups();
            if (typeof name === "string") {
                name = [name]
            }
            for (var i = 0; i < name.length; i++) {
                var isLoadGroup = false;
                if (loadGroups.includes(name[i])) {
                    Klang.core.FileHandler.instance.freeSoundFiles(name[i]);
                    isLoadGroup = true;
                    Klang.log("freeing loadgroup!", name[i])
                }
                for (var p in this._objectTable) {
                    var obj = this._objectTable[p];
                    if (obj._type == "AudioSource") {
                        var fileInfo = Klang.core.FileHandler.instance.getFileInfo(obj._fileId);
                        if (isLoadGroup) {
                            if (fileInfo && fileInfo.load_group == name[i]) {
                                obj.freeBuffer()
                            }
                        } else if (obj.editorName === name[i]) {
                            Klang.core.FileHandler.instance.freeSoundFile(obj._fileId);
                            obj.freeBuffer();
                            Klang.log("freeing buffer!", name[i])
                        }
                    }
                }
            }
        };
        Core.soundsLoaded = function soundsLoaded() {
            Klang.log("Post load initialization started");
            var _this = Core.instance;
            for (var i = 0, len = _this._postLoadInitStack.length; i < len; i++) {
                _this._postLoadInitStack[i].init()
            }
            Klang.log("Post load initialization finished");
            _this._postLoadInitStack = null;
            if (_this._readyCallback) {
                _this._readyCallback(true)
            }
        };
        Core.prototype.pushToPreLoadInitStack = function(instance) {
            if (this._preLoadInitStack) {
                this._preLoadInitStack.push(instance);
                return true
            }
            return false
        };
        Core.prototype.pushToPostLoadInitStack = function(instance) {
            if (this._postLoadInitStack) {
                this._postLoadInitStack.push(instance);
                return true
            }
            return false
        };
        Core.prototype.pushToConnectStack = function(instance) {
            if (this._connectStack) {
                this._connectStack.push(instance);
                return true
            }
            return false
        };
        Core.prototype.findInstance = function(name) {
            var instance = this._objectTable[name];
            if (!instance) {
                Klang.warn("Core: Unknown reference: '" + name + "'")
            }
            return instance
        };
        Core.prototype.triggerEvent = function(id) {
            var eventArgs = [];
            for (var _i = 0; _i < arguments.length - 1; _i++) {
                eventArgs[_i] = arguments[_i + 1]
            }
            Klang.Util.lastEvent = id;
            if (Klang.debugData.ignoredEvents[id]) {
                return
            }
            if (!this._eventTable) {
                Klang.logc("Klang Core: eventTable is undefined");
                return
            }
            if (!this._eventTable[id]) {
                if (Klang.debugData.logToConsole && !this._logIgnore[id]) {
                    Klang.logc("Klang Core: Incoming sound event: '" + id + "'" + ", " + eventArgs, Klang.Util.LOG_UNIMPLEMENTED_EVENT_COLOR)
                }
            } else {
                if (Klang.debugData.logToConsole && !this._logIgnore[id]) {
                    Klang.logc("Klang Core: Incoming sound event: '" + id + "'" + ", " + eventArgs, Klang.Util.LOG_EVENT_COLOR)
                }
            }
            var process = this._eventTable[id];
            if (typeof process == "string") {
                if (!this._objectTable[process]) {
                    Klang.warn("Core: Unknown process: '" + process + "'")
                }
                if (this._objectTable[process]._type != "SimpleProcess" && this._objectTable[process]._type != "AdvancedProcess") {
                    Klang.warn("Core: Object is not a process: '" + process + "'")
                }
                this._objectTable[process].start(eventArgs[0])
            } else if (process instanceof Array) {
                for (var ix = 0, len = process.length; ix < len; ix++) {
                    if (!this._objectTable[process[ix]]) {
                        Klang.warn("Core: Unknown process: '" + process[ix] + "'")
                    }
                    if (this._objectTable[process[ix]]._type != "SimpleProcess" && this._objectTable[process[ix]]._type != "AdvancedProcess") {
                        Klang.warn("Core: Object is not a process: '" + process + "'")
                    }
                    this._objectTable[process[ix]].start(eventArgs[0])
                }
            }
        };
        Core.prototype.getSymbolId = function(symbol) {
            return this._exportedSymbols[symbol]
        };
        Core.prototype.initIOS = function() {
            var src = Klang.context.createBufferSource();
            src.start(0);
            Klang.core.internalEventBus.trigger("INIT_IOS")
        };
        Object.defineProperty(Core.prototype, "timeHandler", {
            get: function() {
                return this._timeHandler
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Core.prototype, "output", {
            get: function() {
                return this._superMasterOutput
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Core.prototype, "blurFadeOut", {
            get: function() {
                return this._blurFadeOut
            },
            set: function(state) {
                this._blurFadeOut = state
            },
            enumerable: true,
            configurable: true
        });
        return Klang.core.Core = Core
    });
    Module(function(Klang) {
        Klang.READY_STATE_NOT_INITIATED = 0;
        Klang.READY_STATE_INITIATED = 1;
        Klang.READY_STATE_LOADED = 2;
        Klang.klangInited = false;
        Klang.readyState = Klang.READY_STATE_NOT_INITIATED;
        var _eventQue;

        function pushToEventQue(name) {
            _eventQue = _eventQue || {};
            _eventQue[name] = arguments
        }
        Klang.pushToEventQue = pushToEventQue;

        function triggerEvent(name) {
            var args = [];
            for (var _i = 0; _i < arguments.length - 1; _i++) {
                args[_i] = arguments[_i + 1]
            }
            if (!Klang.core.Core.isInited) {
                return
            }
            if (Klang.engineVersion === "webaudio") {
                if (!Klang.context) {
                    return
                }
                Klang.core.Core.instance.triggerEvent(name, args)
            } else if (Klang.engineVersion === "audiotag") {
                if (Klang.audioTagHandler) {
                    Klang.audioTagHandler.triggerEvent(name, args)
                }
            }
        }
        Klang.triggerEvent = Klang.trigger = triggerEvent;

        function getDestinationForEvent(eventName) {
            var process = Klang.core.Core.instance.findInstance(Klang.getEvents()[eventName]);
            if (process) {
                return process.destination()
            }
            return null
        }
        Klang.getDestinationForEvent = getDestinationForEvent;

        function processEventQue() {
            if (_eventQue) {
                for (var i in _eventQue) {
                    if (_eventQue.hasOwnProperty(i)) {
                        Klang.triggerEvent.apply(Klang, _eventQue[i])
                    }
                }
                _eventQue = null
            }
        }

        function init(json, readyCallback, progressCallback, loadFailedCallback, options) {
            var start = (new Date).getTime();
            Klang.initOptions = options = options || {};
            Klang.isMobile = Klang.Util.checkMobile();
            Klang.isIOS = Klang.Util.checkIOS();
            if (options.useMonoBuffers) {
                Klang.useMonoBuffers = options.useMonoBuffers
            }
            if (typeof json == "object" && json.settings && json.settings.force_logging) {
                Klang.loggingEnabled = true
            }
            if (Klang.klangInited) {
                Klang.warn("Klang already initialized");
                return
            }
            Klang.klangInited = true;
            Klang.readyState = Klang.READY_STATE_NOT_INITIATED;
            var doWebaudio = options.forceMode === "webaudio" || !options.forceMode && window.AudioContext !== undefined;
            var doAudioTag = options.forceMode === "audiotag" || !options.forceMode && !window.AudioContext;
            if (doWebaudio) {
                if (!Klang.context) {
                    Klang.context = Klang.engines.webAudio.Util.createAudioContext()
                }
            } else {
                Klang.engineVersion = "audiotag";
                if (typeof json === "string" && json.indexOf(".json") === -1 && json.indexOf(".js") > 1) {
                    var oldKLANG_CONFIG = window["KLANGCONFIG"];
                    Klang.Util.loadScriptFile(json, function() {
                        var config = window["KLANG_CONFIG"];
                        window["KLANG_CONFIG"] = oldKLANG_CONFIG;
                        Klang.audioTagHandler = new Klang.AudioTagHandler(config, function(success) {
                            Klang.readyState = Klang.READY_STATE_LOADED;
                            readyCallback && readyCallback(success);
                            processEventQue()
                        }, progressCallback, json)
                    }, function() {})
                } else {
                    Klang.audioTagHandler = new Klang.AudioTagHandler(json, function(success) {
                        Klang.readyState = Klang.READY_STATE_LOADED;
                        readyCallback && readyCallback(success);
                        var end = (new Date).getTime();
                        var time = end - start;
                        Klang.log("Execution time: " + time);
                        processEventQue()
                    }, progressCallback, json)
                }
                return true
            }
            Klang.engineVersion = "webaudio";
            if (Klang.core.Core.isInited()) {
                Klang.warn("Klang already initialized")
            }
            if (typeof json === "string" && json.indexOf(".json") === -1 && json.indexOf(".js") > 1) {
                var oldKLANG_CONFIG = window["KLANG_CONFIG"];
                Klang.Util.loadScriptFile(json, function() {
                    var config = window["KLANG_CONFIG"];
                    window["KLANG_CONFIG"] = oldKLANG_CONFIG;
                    Klang.core.Core.instance.loadJSON(config, function(success) {
                        Klang.readyState = Klang.READY_STATE_LOADED;
                        readyCallback && readyCallback(success);
                        processEventQue()
                    }, progressCallback, json)
                }, function() {})
            } else {
                Klang.core.Core.instance.loadJSON(json, function(success) {
                    Klang.readyState = Klang.READY_STATE_LOADED;
                    readyCallback && readyCallback(success);
                    processEventQue();
                    var end = (new Date).getTime();
                    var time = end - start;
                    Klang.log("Execution time: " + time)
                }, progressCallback, json)
            }
            return true
        }
        Klang.init = init;

        function initIOS(gui) {
            if (Klang.engineVersion == "webaudio") {
                Klang.core.Core.instance.initIOS()
            } else if (Klang.engineVersion == "audiotag" && Klang.isMobile) {
                Klang.audioTagHandler.initIOS()
            }
        }
        Klang.initIOS = function(debugButton) {
            initIOS()
        };

        function getLoadGroups() {
            var listOfGroups = [];
            var fileHandler;
            if (Klang.engineVersion === "webaudio") {
                fileHandler = Klang.core.FileHandler.instance
            } else if (Klang.engineVersion === "audiotag") {
                fileHandler = Klang.audioTagHandler
            } else {
                return []
            }
            listOfGroups = fileHandler.getLoadGroups();
            var autoIndex = listOfGroups.indexOf("auto");
            if (autoIndex !== -1) {
                listOfGroups.splice(autoIndex, 1)
            }
            return listOfGroups
        }
        Klang.getLoadGroups = getLoadGroups;
        Klang.getCoreInstance = function() {
            return Klang.core.Core.instance
        };
        Klang.getFileHandlerInstance = function() {
            return Klang.core.FileHandler.instance
        };

        function getUtil() {
            return Klang.Util
        }
        Klang.getUtil = getUtil;

        function getModel() {
            return Klang.Model
        }
        Klang.getModel = getModel;

        function schedule(time, fn) {
            if (Klang.engineVersion !== "webaudio") {
                Klang.err("Schedule only availible in WebAudio version");
                return this
            }
            if (typeof time === "number" && typeof fn === "function") {
                Klang.core.Core.instance.scheduler.at(time, fn)
            } else {
                Klang.err(".schedule requires arg0 - time in seconds and arg1 - a callback function")
            }
            return this
        }
        Klang.schedule = schedule;

        function createObject(name, options) {
            if (!Model[name]) {
                throw new Error("No such object")
            }
            return new Model[name](options)
        }
        Klang.createObject = createObject;

        function setDebugFlag(flagName, value) {
            Klang.core.Core.debugSettings[flagName] = value
        }
        Klang.setDebugFlag = setDebugFlag;

        function load(name, readyCallback, progressCallback, loadFailedCallback) {
            Klang.logc("Klang: Loading: '" + name + "'", Klang.Util.LOG_LOAD_COLOR);
            if (Klang.engineVersion == "webaudio") {
                Klang.core.Core.instance.loadSoundFiles(name, readyCallback, progressCallback, loadFailedCallback)
            } else if (Klang.engineVersion == "audiotag") {
                Klang.audioTagHandler.loadSoundFiles(name, readyCallback, progressCallback, loadFailedCallback)
            } else {
                if (progressCallback) {
                    progressCallback(1)
                }
                if (readyCallback) {
                    readyCallback(false)
                }
            }
        }
        Klang.load = load;

        function free(name) {
            Klang.logc("Klang: Freeing: '" + name + "'", Klang.Util.LOG_LOAD_COLOR);
            if (Klang.engineVersion == "webaudio") {
                Klang.core.Core.instance.freeSoundFiles(name)
            } else if (Klang.engineVersion == "audiotag") {}
        }
        Klang.free = free;

        function getLoadProgress() {
            return FileHandler.instance.progress
        }
        Klang.getLoadProgress = getLoadProgress;

        function stopAll() {
            if (Klang.engineVersion == "webaudio") {
                if (Klang.core.Core.isInited()) {
                    Klang.core.Core.instance.stopAll()
                }
            } else if (Klang.engineVersion == "audiotag") {
                Klang.audioTagHandler.stopAll()
            }
        }
        Klang.stopAll = stopAll;

        function $(symbol, args) {
            if (Klang.engineVersion == "webaudio") {
                if (Klang.core.Core.isInited()) {
                    if (symbol.indexOf(".") === 0) {
                        var type = symbol.substring(1);
                        var ret = [];
                        var entities = Klang.getCoreInstance()._objectTable;
                        for (var i in entities) {
                            if (entities.hasOwnProperty(i)) {
                                var entity = entities[i];
                                if (entity._type === type) {
                                    ret.push(entity)
                                }
                            }
                        }
                        return ret
                    } else {
                        var id = Klang.core.Core.instance.getSymbolId(symbol);
                        return Klang.core.Core.instance.findInstance(id)
                    }
                }
            } else if (Klang.engineVersion == "audiotag") {
                if (symbol.indexOf(".") === 0) {
                    var ret = [];
                    var type = symbol.substring(1);
                    for (var k in Klang.audioTagHandler._audio) {
                        var obj = Klang.audioTagHandler._audio[k];
                        if (obj.constructor.name.indexOf(type) !== -1) {
                            ret.push(obj)
                        }
                    }
                    return ret
                } else {
                    for (var k in Klang.audioTagHandler._audio) {
                        var obj = Klang.audioTagHandler._audio[k];
                        if (obj._data.editorName === symbol) {
                            return obj
                        }
                    }
                }
            }
            return null
        }
        Klang.$ = $
    });
    Module(function(Klang) {
        function log() {
            var args = [];
            for (var _i = 0; _i < arguments.length - 0; _i++) {
                args[_i] = arguments[_i + 0]
            }
            if (Klang.loggingEnabled) {
                if (Klang.browser == "Chrome") {
                    console.log("%c[" + getTimeString() + "] " + args.join(), "color:" + Klang.Util.LOG_TIME_COLOR)
                } else {
                    console.log.apply(console, args)
                }
            }
        }
        Klang.log = log;

        function logc(message, color) {
            if (Klang.loggingEnabled) {
                if (Klang.browser == "Chrome") {
                    if (!color) {
                        color = "gray"
                    }
                    console.log("%c[" + getTimeString() + "] " + message, "color:" + color)
                } else {
                    console.log(message)
                }
            }
        }
        Klang.logc = logc;

        function warn() {
            var args = [];
            for (var _i = 0; _i < arguments.length - 0; _i++) {
                args[_i] = arguments[_i + 0]
            }
            if (Klang.loggingEnabled) {
                if (Klang.browser == "Chrome") {
                    console.warn("%c[" + Klang.getTimeString() + "] " + args.join(), "color:" + Klang.Util.LOG_WARN_COLOR)
                } else {
                    console.warn.apply(console, args)
                }
            }
        }
        Klang.warn = warn;

        function err() {
            var args = [];
            for (var _i = 0; _i < arguments.length - 0; _i++) {
                args[_i] = arguments[_i + 0]
            }
            if (Klang.loggingEnabled) {
                if (Klang.browser == "Chrome") {
                    console.warn("%c[" + Klang.getTimeString() + "] " + args.join(), "color:" + Klang.Util.LOG_ERROR_COLOR)
                } else {
                    console.warn.apply(console, args)
                }
            }
        }
        Klang.err = err;

        function zeropad(num, digits) {
            var str = num.toString();
            while (str.length < digits) {
                str = "0" + str
            }
            return str
        }
        Klang.zeropad = zeropad;

        function getTimeStamp(time) {
            return zeropad(time.getUTCMinutes(), 2) + ":" + zeropad(time.getUTCSeconds(), 2) + "." + zeropad(time.getUTCMilliseconds(), 3)
        }
        Klang.getTimeStamp = getTimeStamp;

        function getTimeString(t) {
            if (t === undefined) {
                t = Klang.context.currentTime
            }
            var ms = Math.round(t * 1e3);
            var s = Math.floor(ms / 1e3 % 60);
            var m = Math.floor(ms / (1e3 * 60) % 60);
            var h = Math.floor(ms / (1e3 * 60 * 60) % 24);
            return zeropad(h, 2) + ":" + zeropad(m, 2) + ":" + zeropad(s, 2) + "." + zeropad(ms % 1e3, 3)
        }
        Klang.getTimeString = getTimeString;

        function getEvents() {
            if (Klang.engineVersion === "flash") {
                return null
            } else if (Klang.engineVersion == "audiotag") {
                return Klang["audioTagHandler"]._events
            }
            return Klang.core.Core.instance._eventTable
        }
        Klang.getEvents = getEvents;
        Klang.debugData = {
            ignoredEvents: {},
            logToConsole: true
        };
        Klang.visualWindow;

        function setCallbacks(callbacks) {
            Klang.core.Core.instance.setCallbacks(callbacks)
        }
        Klang.setCallbacks = setCallbacks;

        function schedulePredefinedEvents(events) {
            var nextEventIx = 0;
            var eventInterval = setInterval(function() {
                var now = Klang.context.currentTime;
                var e = events[nextEventIx];
                while (e.time < now) {
                    triggerEvent(e.name, e.args);
                    nextEventIx++;
                    if (nextEventIx == events.length) {
                        clearInterval(eventInterval);
                        break
                    } else {
                        e = events[nextEventIx]
                    }
                }
            }, 10)
        }
        Klang.schedulePredefinedEvents = schedulePredefinedEvents;

        function deinit(url, readyCallback) {
            Klang.klangInited = false;
            if (Klang.engineVersion == "webaudio") {
                if (Klang.core.Core.isInited()) {
                    Klang.core.Core.instance.stopAll();
                    Klang.core.Core.deinit()
                }
            } else if (Klang.engineVersion == "audiotag") {
                Klang.audioTagHandler.stopAll()
            }
            Klang.engineVersion = "n/a"
        }
        Klang.deinit = deinit
    });
    Module(function(Klang) {
        function SyncCountdown(targetStep, process, args) {
            this._currentStep = 0;
            this._targetStep = targetStep;
            this._process = process;
            this._args = args
        }
        SyncCountdown.prototype.advance = function(step) {
            this._currentStep += step
        };
        SyncCountdown.prototype.performAction = function() {
            if (typeof this._process == "string") {
                new Function("Core", "Model", "Util", "args", this._process)(Klang.core.Core, Klang.Model, Klang.Util, this._args)
            } else {
                this._process.start(this._args)
            }
        };
        Object.defineProperty(SyncCountdown.prototype, "finished", {
            get: function() {
                return this._currentStep >= this._targetStep
            },
            enumerable: true,
            configurable: true
        });
        return Klang.core.SyncCountdown = SyncCountdown
    });
    Module(function(Klang) {
        function SyncHandler() {
            this._timers = []
        }
        SyncHandler.prototype.addSyncCountdown = function(countdown) {
            this._timers.push(countdown)
        };
        SyncHandler.prototype.update = function(step) {
            for (var ix = 0; ix < this._timers.length; ix++) {
                var countdown = this._timers[ix];
                countdown.advance(step);
                if (countdown.finished) {
                    countdown.performAction();
                    this._timers.splice(ix, 1);
                    ix--
                }
            }
        };
        return Klang.core.SyncHandler = SyncHandler
    });
    Module(function() {});
    Module(function(Klang) {
        function Audio(data, name) {
            this.data = data;
            this._name = name;
            this._type = data.type;
            this._output = Klang.context.createGain();
            this._volume = data.volume !== undefined ? data.volume : 1;
            this._output.gain.setValueAtTime(this._volume, Klang.context.currentTime);
            if (data.destination_name) {
                this.destinationName = data.destination_name;
                if (!Klang.core.Core.instance.initComplete) {
                    Klang.core.Core.instance.pushToConnectStack(this)
                }
            }
        }
        Audio.prototype.connect = function(destination) {
            Klang.warn("Audio: Invocation of abstract method: Audio.connect in", this);
            return this
        };
        Audio.prototype.disconnect = function() {
            Klang.warn("Audio: Invocation of abstract method: Audio.disconnect in", this);
            return this
        };
        Audio.prototype.play = function(when, offset) {
            Klang.warn("Audio: Invocation of abstract method: Audio.play in", this);
            return this
        };
        Audio.prototype.stop = function(when) {
            Klang.warn("Audio: Invocation of abstract method: Audio.stop in", this);
            return this
        };
        Audio.prototype.pause = function() {
            Klang.warn("Audio: Invocation of abstract method: Audio.pause in", this);
            return this
        };
        Audio.prototype.unpause = function() {
            Klang.warn("Audio: Invocation of abstract method: Audio.unpause in", this);
            return this
        };
        Audio.prototype.curvePlaybackRate = function(value, duration) {
            Klang.warn("Audio: Invocation of abstract method: Audio.curvePlaybackRate in", this);
            return this
        };
        Audio.prototype.fadeInAndPlay = function(duration, when) {
            console.warn("Audio: Invocation of abstract method: Audio.fadeInAndPlay in", this);
            return this
        };
        Audio.prototype.fadeOutAndStop = function(duration, when) {
            console.warn("Audio: Invocation of abstract method: Audio.fadeOutAndStop in", this);
            return this
        };
        Audio.prototype.deschedule = function() {
            console.warn("Audio: Invocation of abstract method: Audio.deschedule in", this);
            return this
        };
        Object.defineProperty(Audio.prototype, "playbackRate", {
            set: function(value) {
                Klang.warn("Audio: Invocation of abstract property: Audio.playbackRate in", this);
                return this
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Audio.prototype, "playing", {
            get: function() {
                Klang.warn("Audio: Invocation of abstract property: Audio.playing in", this);
                return false
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Audio.prototype, "duration", {
            get: function() {
                Klang.warn("Audio: Invocation of abstract property: Audio.duration in", this);
                return 0
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Audio.prototype, "output", {
            get: function() {
                return this._output
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Audio.prototype, "playbackState", {
            get: function() {
                Klang.warn("Audio: Invocation of abstract property: Audio.playbackState in", this);
                return 0
            },
            enumerable: true,
            configurable: true
        });
        Audio.prototype.setData = function(data) {
            this._volume = data.volume === undefined ? 1 : data.volume;
            this._output.gain.value = this._volume;
            if (this.destinationName != data.destination_name) {
                this.destinationName = data.destination_name;
                this.disconnect();
                this.connect(Klang.core.Core.instance.findInstance(this.destinationName).input)
            }
        };
        Audio.prototype.clone = function() {
            var clone = new this["constructor"](this.data, this._name);
            if (this.data.panner) {
                this.data.panner = new Klang.Model.Panner(this.data.panner.data)
            }
            clone.connect(Klang.core.Core.instance.findInstance(this.destinationName).input);
            return clone
        };
        return Klang.Model.Audio = Audio
    });
    Module(function(Klang) {
        Klang.engines = Klang.engines || {};
        Klang.engines.webAudio = {}
    });
    Module(function(Klang) {
        Klang.audioUtils = Klang.engines.webAudio.audioUtils = {
            crossfade: function(buf, loopStart, loopEnd, length, type) {
                if (typeof type === "undefined") {
                    type = "equalpower"
                }
                var funA;
                var funB;
                if (type == "linear") {
                    funA = function(x) {
                        return 1 - x
                    };
                    funB = function(x) {
                        return x
                    }
                } else if (type == "equalpower") {
                    funA = function(x) {
                        return Math.pow(1 - x, .5)
                    };
                    funB = function(x) {
                        return Math.pow(x, .5)
                    }
                } else {
                    return
                }
                loopEnd = Math.min(loopEnd, buf.length);
                length = Math.min(length, loopStart);
                for (var c = 0; c < buf.numberOfChannels; c++) {
                    var data = buf.getChannelData(c);
                    var a = loopEnd - 1;
                    var b = loopStart - 1;
                    for (var i = length - 1; i >= 0; i--) {
                        var ratio = (i + 1) / (length + 1);
                        data[a] = data[a] * funA(ratio) + data[b] * funB(ratio);
                        a--;
                        b--
                    }
                    var le = loopEnd;
                    var ls = loopStart;
                    while (le < buf.length) {
                        data[le++] = data[ls++]
                    }
                }
            }
        }
    });
    Module(function(Klang) {
        var AudioSource = function(_super) {
            Klang.Util.__extends(AudioSource, _super);

            function AudioSource(data, name) {
                if (data.streaming && Klang.Model.StreamingAudioSource) {
                    return new Klang.Model.StreamingAudioSource(data, name)
                }
                _super.call(this, data, name);
                this._sources = [];
                this._startTime = 0;
                this._loopStartTime = 0;
                this._scheduleAhead = .2;
                this._stopping = false;
                this._fading = false;
                this._paused = false;
                this._pauseTime = -1;
                this._pauseStartTime = -1;
                this.editorName = data.editorName;
                this._fileId = data.file_id;
                this._playbackRate = data.playback_rate || 1;
                this._endTime = 0;
                this._loop = data.loop !== undefined ? data.loop : false;
                this._loopStart = data.loop_start;
                this._loopEnd = data.loop_end;
                this._offset = data.offset || 0;
                this._duration = data.duration || 0;
                this._reverse = data.reverse;
                this._retrig = data.retrig !== undefined ? data.retrig : true;
                this._volumeStartRange = data.volume_start_range;
                this._volumeEndRange = data.volume_end_range;
                this._pitchStartRange = data.pitch_start_range;
                this._pitchEndRange = data.pitch_end_range;
                this._maxSources = data.max_sources || -1;
                this.priority;
                if (data.panner) {
                    this._panner = data.panner
                }
                if (!Klang.core.Core.instance.pushToPostLoadInitStack(this)) {
                    this.init()
                }
            }
            AudioSource.prototype.init = function() {
                if (this._fileId) {
                    if (typeof this._fileId == "string") {
                        this._buffer = Klang.core.FileHandler.instance.getFile(this._fileId)
                    } else if (this._fileId.sampleRate) {
                        this._buffer = this._fileId
                    }
                }
                if (!this._buffer) {
                    return
                }
                if (!this._duration) {
                    this._duration = this._buffer.duration
                }
                if (Klang.detector.browser["name"] == "Edge" && this._loop) {
                    this._loopStart = (this._loopStart || 0) + .07;
                    this._loopEnd = (this._loopEnd || this.duration) + .07;
                    this.data.xfade = this.data.xfade || .1
                }
                if (this._reverse) {
                    var reverseBuffer = Klang.context.createBuffer(this._buffer.numberOfChannels, this._buffer.length, Klang.context.sampleRate);
                    for (var c = 0; c < this._buffer.numberOfChannels; c++) {
                        var channelBuffer = this._buffer.getChannelData(c);
                        var reverseChannelBuffer = reverseBuffer.getChannelData(c);
                        for (var len = channelBuffer.length, ix = len - 1; ix >= 0; ix--) {
                            reverseChannelBuffer[len - ix] = channelBuffer[ix]
                        }
                    }
                    this._buffer = reverseBuffer
                }
                if (this.data.xfade) {
                    var newBuffer = Klang.context.createBuffer(this._buffer.numberOfChannels, this._buffer.length, Klang.context.sampleRate);
                    for (var c = 0; c < this._buffer.numberOfChannels; c++) {
                        var channelBuffer = this._buffer.getChannelData(c);
                        var newChannelBuffer = newBuffer.getChannelData(c);
                        for (var len = channelBuffer.length, ix = len - 1; ix >= 0; ix--) {
                            newChannelBuffer[ix] = channelBuffer[ix]
                        }
                    }
                    this._buffer = newBuffer;
                    var sampleRate = Klang.context.sampleRate;
                    var fadeLength = this.data.xfade === true ? 11025 : this.data.xfade * sampleRate;
                    var loopStart = this._loopStart === undefined ? fadeLength : Math.round(this._loopStart * sampleRate);
                    var loopEnd = this._loopEnd === undefined ? this._buffer.length : Math.round(this._loopEnd * sampleRate);
                    Klang.engines.webAudio.audioUtils.crossfade(this._buffer, loopStart, loopEnd, fadeLength)
                }
            };
            AudioSource.prototype.setLoopRegion = function(loopStart, loopEnd) {
                this._loopStart = loopStart || this._loopStart;
                this._loopEnd = loopEnd || this._loopEnd;
                for (var ix = 0, len = this._sources.length; ix < len; ix++) {
                    var source = this._sources[ix];
                    source.loopStart = this._loopStart;
                    source.loopEnd = this._loopEnd
                }
                return this
            };
            AudioSource.prototype.connect = function(destination, forceConnect) {
                if (!this._destination || forceConnect) {
                    this._destination = destination;
                    if (this._panner) {
                        this._output.connect(this._panner.input);
                        this._panner.output.connect(destination)
                    } else {
                        this._output.connect(destination)
                    }
                }
                return this
            };
            AudioSource.prototype.disconnect = function() {
                this._output.disconnect();
                this._destination = null;
                if (this._panner) {
                    this._panner.output.disconnect()
                }
                return this
            };
            AudioSource.prototype.play = function(when, offset, duration, resume) {
                when = when || 0;
                offset = offset || 0;
                resume = !!resume;
                this.removeUnusedSources();
                if (this.priority !== undefined && Klang.getCoreInstance().maxSimultaneousSounds) {
                    var canPlay = this.checkPriority();
                    if (!canPlay) {
                        Klang.warn("AudioSource: Skipped", this.editorName, "due to priority. Priority:", this.priority, "Max sources:", Klang.getCoreInstance().maxSimultaneousSounds);
                        return
                    }
                }
                if (this._maxSources > -1 && this._sources.length > this._maxSources) {
                    Klang.warn("AudioSource: Max sources reached", this.editorName);
                    return
                }
                if (!duration) {
                    if (this._loop) {
                        duration = 9999999999
                    } else {
                        duration = this._duration
                    }
                }
                if (!this._buffer) {
                    this.init();
                    if (!this._buffer) {
                        Klang.warn("AudioSource: Buffer not found!", this.editorName);
                        return
                    }
                }
                when = when || 0;
                if (when !== 0 && when + .01 <= Klang.context.currentTime) {
                    Klang.warn("AudioSource: Returned, playTime < currentTime", this.editorName);
                    return this
                }
                if (when === 0) {
                    when = Klang.context.currentTime
                }
                this.output.gain.cancelScheduledValues(when);
                if (this._volumeStartRange !== undefined) {
                    this.output.gain.setValueAtTime(this._volume * (Math.random() * (this._volumeEndRange - this._volumeStartRange) + this._volumeStartRange), when)
                } else {
                    this.output.gain.setValueAtTime(this._volume, when)
                }
                if (!this.paused) {
                    this._pauseStartTime = when
                }
                if (!resume) {
                    this._pauseTime = 0
                }
                this._startTime = when;
                this._loopStartTime = when + this.duration;
                this._paused = false;
                if (this._stopping && !this._retrig) {
                    this.output.gain.cancelScheduledValues(when);
                    this.output.gain.setValueAtTime(this.output.gain.value, when);
                    this.output.gain.linearRampToValueAtTime(this._volume, when + .25);
                    clearTimeout(this._stoppingId);
                    this._stopping = false;
                    return
                } else if (!this._fading) {}
                this._fading = false;
                if (!this._retrig && !this.loop) {
                    if (when < this._endTime) {
                        return
                    }
                } else if (this.loop && !this._retrig) {
                    if (this._endTime == -1 || when < this._endTime) {
                        return
                    }
                } else if (this.loop && this._retrig && this.playing && !this._stopping) {
                    return
                } else if (this._stopping) {
                    this._stopping = false
                } else if (Math.round(this._endTime * 1e3) / 1e3 == Math.round((when + this._buffer.duration) * 1e3) / 1e3) {
                    Klang.warn("AudioSource: Returned, Doubletrig", this._name);
                    return this
                }
                this._endTime = this.loop ? -1 : when + this._buffer.duration;
                var source = this.createBufferSource();
                source.buffer = this._buffer;
                if (this._loop) {
                    source.loop = true;
                    source.loopStart = this._loopStart ? this._loopStart : 0;
                    source.loopEnd = this._loopEnd ? this._loopEnd : this._buffer.duration
                }
                if (!this._destination) {
                    Klang.warn("AudioSource: no destination node")
                }
                if (typeof this._destination != "object") {
                    Klang.warn("AudioSource: destination is not an object", this.editorName)
                }
                source.connect(this._output);
                if (offset > this._duration) {
                    offset = offset % this._duration
                }
                this._startOffset = this._offset + offset;
                if (this._pitchStartRange !== undefined) {
                    var _randomplaybackrateValue = this._playbackRate * (Math.random() * (this._pitchEndRange - this._pitchStartRange) + this._pitchStartRange);
                    source.playbackRate.setValueAtTime(_randomplaybackrateValue, Klang.context.currentTime)
                }
                source["startTime"] = when;
                if (this._loop) {
                    source.start(when, this._startOffset)
                } else {
                    source.start(when, this._startOffset, duration || source.buffer.duration)
                }
                if (Klang.core.Core.callbacks && Klang.core.Core.callbacks.scheduleAudioSource) {
                    Klang.core.Core.callbacks.scheduleAudioSource({
                        audio: this,
                        startTime: when
                    })
                }
                return this
            };
            AudioSource.prototype.getNumberOfSamples = function() {
                return this._buffer.length
            };
            AudioSource.prototype.stop = function(when) {
                if (typeof when === "undefined") {
                    when = 0
                }
                if (this._stopping) {
                    this._stopping = false;
                    clearTimeout(this._stoppingId)
                }
                var numSources = this._sources.length;
                if (numSources > 0) {
                    when = when || Klang.Util.now();
                    if (this._loop) {
                        this._loopPlaying = false
                    }
                    this._endTime = when;
                    if (this._retrig) {
                        this._sources[this._sources.length - 1].stop(when);
                        this._sources.splice(this._sources.length - 1, 1)
                    } else {
                        for (var ix = 0; ix < numSources; ix++) {
                            var source = this._sources[ix];
                            source.stop(when);
                            this._endTime = Klang.Util.now()
                        }
                        this._sources = []
                    }
                } else {
                    this._loopPlaying = false
                }
                this._startTime = -1;
                return this
            };
            AudioSource.prototype.deschedule = function() {
                for (var ix = 0; ix < this._sources.length; ix++) {
                    var source = this._sources[ix];
                    if (source["startTime"] > Klang.context.currentTime) {
                        source.stop(0);
                        this._sources[ix].disconnect();
                        source.disconnect();
                        this._sources.splice(ix, 1);
                        ix--
                    }
                }
                return this
            };
            AudioSource.prototype.pause = function() {
                if (this._endTime > Klang.Util.now()) {
                    this._paused = true;
                    var pauseDelta = Klang.Util.now() - this._startTime;
                    this._pauseTime += pauseDelta;
                    this.stop()
                }
                return this
            };
            AudioSource.prototype.unpause = function() {
                if (this.paused) {
                    var realOffset = this._offset;
                    this._offset += this._pauseTime;
                    this.play(0, 0, null, true);
                    this._offset = realOffset;
                    this._paused = false
                }
                return this
            };
            AudioSource.prototype.createBufferSource = function() {
                var source = Klang.context.createBufferSource();
                source.playbackRate.setValueAtTime(this._playbackRate, Klang.context.currentTime);
                this._sources.push(source);
                return source
            };
            AudioSource.prototype.fadeInAndPlay = function(fadeDuration, when, offset, duration) {
                if (typeof offset === "undefined") {
                    offset = 0
                }
                if (typeof duration === "undefined") {
                    duration = this._duration
                }
                var now = Klang.context.currentTime;
                if (!when) {
                    when = now
                }
                if (this.loop && (!this._retrig && (this._endTime == -1 || when < this._endTime)) && !this._stopping) {
                    return
                } else if (this.loop && this._retrig && this.playing && !this._stopping) {
                    return
                }
                this.output.gain.cancelScheduledValues(when);
                if (this._stopping) {
                    clearTimeout(this._stoppingId);
                    this.output.gain.setValueAtTime(this.output.gain.value, when)
                } else {
                    this._fading = true;
                    this.play(when == now ? 0 : when, offset, duration);
                    this.output.gain.setValueAtTime(0, when)
                }
                this._stopping = false;
                this.output.gain.linearRampToValueAtTime(this._volume, when + fadeDuration);
                return this
            };
            AudioSource.prototype.fadeOutAndStop = function(duration, when) {
                if (!this.playing && !this.scheduledToPlay()) {
                    return
                }
                if (when === undefined) {
                    when = Klang.context.currentTime
                }
                if (when <= this._startTime) {
                    return this.stop()
                }
                if (this._stopping) {
                    clearTimeout(this._stoppingId)
                }
                this.output.gain.cancelScheduledValues(when);
                this.output.gain.setValueAtTime(this.output.gain.value || this._volume, when);
                this.output.gain.linearRampToValueAtTime(0, when + duration);
                var _this = this;
                this._stoppingId = setTimeout(function() {
                    if (!_this._stopping) {
                        return
                    }
                    _this._stopping = false;
                    if (_this.loop) {
                        _this._loopPlaying = false
                    }
                    _this.stop(when + duration)
                }, (duration + (when - Klang.Util.now()) - _this._scheduleAhead) / .001);
                this._stopping = true;
                return this
            };
            AudioSource.prototype.checkPriority = function() {
                var core = Klang.getCoreInstance();
                var playing = core.getPlaying();
                var canPlay = true;
                var maxSounds = core.maxSimultaneousSounds;
                if (playing.length > core.maxSimultaneousSounds) {
                    playing.sort(function(a, b) {
                        return parseFloat(a.priority) - parseFloat(b.priority)
                    });
                    if (playing.length) {
                        if (playing[0].priority < this.priority) {
                            playing[0].fadeOutAndStop(.1);
                            Klang.warn("AudioSource: Stopped", playing[0].editorName, "due to priority. Priority:", playing[0].priority, "Max sources:", Klang.getCoreInstance().maxSimultaneousSounds)
                        } else {
                            canPlay = false
                        }
                    }
                }
                return canPlay
            };
            AudioSource.prototype.removeUnusedSources = function() {
                for (var ix = 0; ix < this._sources.length; ix++) {
                    var source = this._sources[ix];
                    if (!source.buffer || !this.loop && source["startTime"] + source.buffer.duration < Klang.context.currentTime) {
                        this._sources[ix].disconnect();
                        this._sources.splice(ix, 1);
                        ix--
                    }
                }
            };
            AudioSource.prototype.curvePlaybackRate = function(value, duration, when) {
                var startTime = when ? when : Klang.Util.now();
                var node = this.playbackRateNode;
                if (node) {
                    node.cancelScheduledValues(startTime);
                    node.setValueAtTime(node.value == 0 ? Klang.Util.EXP_MIN_VALUE : node.value, startTime);
                    node.exponentialRampToValueAtTime(value, startTime + duration)
                }
                this._playbackRate = value;
                return this
            };
            Object.defineProperty(AudioSource.prototype, "lastSource", {
                get: function() {
                    var numSources = this._sources.length;
                    if (numSources == 0) {
                        return null
                    }
                    return this._sources[numSources - 1]
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "loop", {
                get: function() {
                    return this._loop
                },
                set: function(value) {
                    this._loop = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "offset", {
                get: function() {
                    return this._offset
                },
                set: function(value) {
                    if (typeof value === "string" && value.indexOf("%") !== -1) {
                        value = this._duration * parseFloat(value)
                    }
                    this._offset = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "position", {
                get: function() {
                    if (!this.playing || !this._duration) {
                        return 0
                    }
                    var duration = this._duration;
                    if (this._loopStart || this._loopEnd) {
                        duration = (this._loopEnd || duration) - (this._loopStart || 0)
                    }
                    var timePlayed = Klang.Util.now() - this._startTime;
                    var loopTimePlayed = Klang.Util.now() + this._startOffset - this._loopStartTime;
                    if (this._startOffset + timePlayed > this._duration) {
                        return (this._loopStart || 0) + loopTimePlayed % duration
                    } else {
                        return this._startOffset + timePlayed
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "duration", {
                get: function() {
                    return this._duration
                },
                set: function(value) {
                    this._duration = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "paused", {
                get: function() {
                    return this._paused
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "playbackRate", {
                get: function() {
                    return this._playbackRate
                },
                set: function(value) {
                    var node = this.playbackRateNode;
                    if (node) {
                        node.cancelScheduledValues(Klang.Util.now())
                    }
                    this._playbackRate = value;
                    for (var ix = 0, len = this._sources.length; ix < len; ix++) {
                        this._sources[ix].playbackRate.value = this._playbackRate
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "nextPlaybackRate", {
                set: function(value) {
                    this._playbackRate = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "playbackRateNode", {
                get: function() {
                    var source = this.lastSource;
                    return source && source.playbackRate
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "buffer", {
                get: function() {
                    if (!this._buffer) {
                        this._buffer = Klang.core.FileHandler.instance.getFile(this._fileId)
                    }
                    return this._buffer
                },
                set: function(buffer) {
                    this._buffer = buffer
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "playing", {
                get: function() {
                    return (this._endTime == -1 || this._endTime > Klang.Util.now()) && this._startTime <= Klang.Util.now()
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "playbackState", {
                get: function() {
                    var source = this.lastSource;
                    if (source) {
                        return source.playbackState
                    }
                    return 0
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "output", {
                get: function() {
                    if (this._panner) {
                        return this._panner.output
                    } else {
                        return this._output
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(AudioSource.prototype, "panner", {
                get: function() {
                    return this._panner
                },
                enumerable: true,
                configurable: true
            });
            AudioSource.prototype.scheduledToPlay = function() {
                return this._startTime > Klang.Util.now()
            };
            AudioSource.prototype.freeBuffer = function() {
                this._buffer = null;
                for (var ix = 0, len = this._sources.length; ix < len; ix++) {
                    try {
                        this._sources[ix].stop(0)
                    } catch (ex) {}
                    this._sources[ix].disconnect();
                    this._sources[ix] = null
                }
                this._sources = []
            };
            AudioSource.prototype.setData = function(data) {
                _super.prototype.setData.call(this, data);
                var reinit = false;
                this._volumeStartRange = data.volume_start_range;
                this._volumeEndRange = data.volume_end_range;
                this._pitchEndRange = data.pitch_end_range;
                this._pitchStartRange = data.pitch_start_range;
                if (data.file_id !== undefined && this._fileId != data.file_id) {
                    this._fileId = data.file_id;
                    reinit = true
                }
                this._playbackRate = data.playback_rate === undefined ? 1 : data.playback_rate;
                if (this.playbackRateNode) {
                    this.playbackRateNode.value = this._playbackRate
                }
                this._loop = data.loop === undefined ? false : data.loop;
                if (this.lastSource) {
                    this.lastSource.loop = this._loop
                }
                if (!this._loop) {
                    this._loopPlaying = false
                }
                this._loopStart = data.loop_start === undefined ? 0 : data.loop_start;
                if (this.lastSource) {
                    this.lastSource.loopStart = this._loopStart
                }
                this._loopEnd = data.loop_end === undefined ? 0 : data.loop_end;
                if (this.lastSource) {
                    this.lastSource.loopEnd = this._loopEnd
                }
                var offset = data.offset === undefined ? 0 : data.offset;
                if (this._offset != offset) {
                    this._offset = offset;
                    reinit = true
                }
                var duration = data.duration === undefined ? 0 : data.duration;
                if (this._duration != duration) {
                    this._duration = duration;
                    reinit = true
                }
                this._retrig = data.retrig === undefined ? true : data.retrig;
                if (data.reverse === undefined) {
                    data.reverse = false
                }
                if (this._reverse != data.reverse) {
                    this._reverse = data.reverse;
                    reinit = true
                }
                if (data.xfade === undefined) {
                    data.xfade = false
                }
                if (this.data.xfade != data.xfade) {
                    reinit = true
                }
                this.data = data;
                if (reinit) {
                    this.init()
                }
                if (data.panner) {
                    if (!this._panner) {
                        var d = this._destination;
                        this.disconnect();
                        this._panner = newPanner(data.panner);
                        this.connect(d)
                    } else {
                        this._panner.setData(data.panner)
                    }
                } else if (!data.panner) {
                    if (this._panner) {
                        var d = this._destination;
                        this.disconnect();
                        this._panner = null;
                        this.connect(d)
                    }
                }
            };
            return AudioSource
        }(Klang.Model.Audio);
        return Klang.Model.AudioSource = AudioSource
    });
    Module(function(Klang) {
        var GroupType = {
            CONCURRENT: 0,
            STEP: 1,
            RANDOM: 2,
            SHUFFLE: 3,
            BACKWARDS: 4
        };
        var QueueType = {
            NONE: 0,
            ONE: 1,
            INFINITE: 2
        };

        function AudioGroup(data, name) {
            this._adder = 0;
            this._currentId = 0;
            this._paused = false;
            this.data = data;
            this.editorName = data.editorName;
            this._name = name;
            this._type = data.type;
            this._groupType = data.group_type !== undefined ? data.group_type : GroupType.STEP;
            this._retrig = data.retrig !== undefined ? data.retrig : true;
            this._queue = data.queue !== undefined ? data.queue : QueueType.NONE;
            this._content = data.content || [];
            Klang.core.Core.instance.pushToPreLoadInitStack(this)
        }
        AudioGroup.prototype.init = function() {
            var newContent = [];
            for (var ix = 0, len = this._content.length; ix < len; ix++) {
                newContent.push(Klang.core.Core.instance.findInstance(this._content[ix]))
            }
            this._content = newContent
        };
        AudioGroup.prototype.play = function(when, audioSource, forcePlay) {
            if (!this._content.length) {
                return
            }
            var latestPlaying = this.latestPlayed ? this.latestPlayed.playing : false;
            if (!forcePlay && !this._retrig && latestPlaying) {
                if (this._queue != QueueType.NONE) {
                    if (this._queue == QueueType.ONE && this._latestStartTime > Klang.context.currentTime) {
                        this.latestPlayed.stop();
                        this.play(this._latestStartTime, audioSource, true)
                    } else {
                        this.play(this._latestStartTime + this.latestPlayed.duration, audioSource, true)
                    }
                }
                return this
            }
            this._paused = false;
            if (audioSource !== undefined) {
                var asId;
                if (typeof audioSource == "number") {
                    asId = audioSource
                } else if (typeof audioSource == "string") {
                    asId = this.getIdFromString(audioSource)
                } else if (audioSource._name) {
                    asId = this.getIdFromString(audioSource._name)
                }
                this._content[asId].play(when);
                this._latestPlayed = this._content[asId]
            } else {
                if (this._groupType == GroupType.CONCURRENT) {
                    for (var ix = 0, len = this._content.length; ix < len; ix++) {
                        this._content[ix].play(when)
                    }
                } else {
                    this._currentId = this.getIdToPlay();
                    this._content[this._currentId].play(when)
                }
                if (this._groupType === GroupType.CONCURRENT) {
                    this._latestPlayed = this._content[0]
                } else {
                    this._latestPlayed = this._content[this._currentId]
                }
            }
            this._latestStartTime = when || Klang.context.currentTime;
            return this
        };
        AudioGroup.prototype.getIdToPlay = function() {
            var _id;
            if (this._groupType == GroupType.STEP) {
                if (this._adder < 0) {
                    _id = this._content.length - 1 + this._adder % this._content.length
                } else {
                    _id = this._adder % this._content.length
                }
                this._adder++
            } else if (this._groupType == GroupType.RANDOM) {
                var random = Math.floor(Math.random() * (this._content.length - 1));
                if (this._content.length > 1 && random == this._adder) {
                    random = (random + 1) % this._content.length
                }
                _id = this._adder = random
            } else if (this._groupType == GroupType.SHUFFLE) {
                if (this._adder % this._content.length == 0) {
                    Klang.Util.shuffle(this._content)
                }
                _id = this._adder % this._content.length;
                this._adder++
            } else if (this._groupType == GroupType.BACKWARDS) {
                if (this._adder < 0) {
                    _id = this._content.length - 1 + this._adder % this._content.length
                } else {
                    _id = this._adder % this._content.length
                }
                this._adder--
            }
            return _id
        };
        AudioGroup.prototype.stop = function(when) {
            this._content[this._currentId].stop(when);
            return this
        };
        AudioGroup.prototype.stopAll = function(when) {
            for (var ix = 0, len = this._content.length; ix < len; ix++) {
                this._content[ix].stop(when)
            }
            return this
        };
        AudioGroup.prototype.pause = function() {
            this._paused = true;
            if (this._latestPlayed) {
                this._latestPlayed.pause()
            }
            return this
        };
        AudioGroup.prototype.unpause = function() {
            this._paused = false;
            if (this._latestPlayed) {
                this._latestPlayed.unpause()
            }
            return this
        };
        AudioGroup.prototype.fadeInAndPlay = function(duration, when) {
            var latestPlaying = this.latestPlayed ? this.latestPlayed.playing : false;
            if (!this._retrig && latestPlaying) {
                return
            }
            this._currentId = this.getIdToPlay();
            this._latestPlayed = this._content[this._currentId];
            this._content[this._currentId].fadeInAndPlay(duration, when);
            return this
        };
        AudioGroup.prototype.fadeOutAndStop = function(duration, when) {
            if (when === undefined) {
                when = Klang.context.currentTime
            }
            if (this._latestPlayed) {
                this._latestPlayed.fadeOutAndStop(duration, when)
            }
            return this
        };
        AudioGroup.prototype.curvePlaybackRate = function(value, duration, when) {
            var startTime = when ? when : Klang.Util.now();
            for (var ix = 0, len = this._content.length; ix < len; ix++) {
                this._content[ix].curvePlaybackRate(value, duration, when)
            }
            return this
        };
        AudioGroup.prototype.deschedule = function() {
            for (var ix = 0, len = this._content.length; ix < len; ix++) {
                this._content[ix].deschedule()
            }
            return this
        };
        AudioGroup.prototype.getIdFromString = function(str) {
            for (var ix = 0, len = this._content.length; ix < len; ix++) {
                if (this._content[ix]._name == str) {
                    return ix
                }
            }
        };
        Object.defineProperty(AudioGroup.prototype, "playbackRate", {
            set: function(value) {
                for (var ix = 0, len = this._content.length; ix < len; ix++) {
                    this._content[ix].playbackRate = value
                }
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AudioGroup.prototype, "groupType", {
            get: function() {
                return this._groupType
            },
            set: function(value) {
                this._groupType = value
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AudioGroup.prototype, "content", {
            get: function() {
                return this._content
            },
            set: function(value) {
                this._content = value;
                this.init()
            },
            enumerable: true,
            configurable: true
        });
        AudioGroup.prototype.addContent = function(audio) {
            this._content.push(audio)
        };
        AudioGroup.prototype.removeContent = function(name) {
            for (var i = 0; i < this._content.length; i++) {
                if (this._content[i]._name === name) {
                    this._content.splice(i, 1)
                }
            }
        };
        Object.defineProperty(AudioGroup.prototype, "playing", {
            get: function() {
                return this._latestPlayed ? this._latestPlayed.playing : false
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AudioGroup.prototype, "duration", {
            get: function() {
                return this._latestPlayed ? this._latestPlayed.duration : this._content[0].duration
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AudioGroup.prototype, "playbackState", {
            get: function() {
                return this._content[this._currentId].playbackState
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(AudioGroup.prototype, "latestPlayed", {
            get: function() {
                return this._latestPlayed
            },
            enumerable: true,
            configurable: true
        });
        AudioGroup.prototype.setData = function(data) {
            this._groupType = data.group_type === undefined ? GroupType.STEP : data.group_type;
            this._retrig = data.retrig === undefined ? true : data.retrig;
            this._queue = data.queue === undefined ? QueueType.NONE : data.queue;
            if (data.content) {
                this._content = data.content;
                this.init()
            }
        };
        return Klang.Model.AudioGroup = AudioGroup
    });
    Module(function(Klang) {
        function Automation(data) {
            this._startValue = data.start_value || 0;
            this._points = data.points || []
        }
        Automation.prototype.automate = function(param, when) {
            when = when || Klang.context.currentTime;
            param.cancelScheduledValues(when);
            param.setValueAtTime(this._startValue, when);
            var lastEndTime = 0;
            for (var ix = 0, len = this._points.length; ix < len; ix++) {
                var p = this._points[ix];
                switch (p.curve) {
                    case "lin":
                        param.linearRampToValueAtTime(p.value, when + p.time);
                        break;
                    case "exp":
                        param.exponentialRampToValueAtTime(p.value, when + p.time);
                        break;
                    default:
                        if (Klang.Util.CUSTOM_CURVES[p.curve]) {
                            Klang.warn("Automation: Invalid curve type: " + p.curve);
                            break
                        }
                        param.setValueCurveAtTime(Klang.Util.CUSTOM_CURVES[p.curve], when + lastEndTime, p.time - lastEndTime);
                        break
                }
                lastEndTime = p.time
            }
        };
        return Klang.Model.Automation = Automation
    });
    Module(function(Klang) {
        function Bus(data, name) {
            this._name = name;
            this._type = data.type;
            this._input = Klang.context.createGain();
            this._output = Klang.context.createGain();
            this._effects = data.effects || [];
            this._data = data;
            for (var i = 0, len = this._effects.length; i < len; i++) {
                if (data.effects[i].active === false) {
                    this._effects[i].setActive(false)
                }
            }
            var inputVol = data.input_vol !== undefined ? data.input_vol : 1;
            var outputVol = data.output_vol !== undefined ? data.output_vol : 1;
            this._input.gain.setValueAtTime(inputVol, Klang.context.currentTime);
            this._output.gain.setValueAtTime(outputVol, Klang.context.currentTime);
            if (data.destination_name) {
                this.destinationName = data.destination_name;
                Klang.core.Core.instance.pushToConnectStack(this)
            }
            Klang.core.Core.instance.pushToPreLoadInitStack(this)
        }
        Bus.prototype.init = function() {
            var lastNode = this._input;
            for (var i = 0, len = this._effects.length; i < len; i++) {
                lastNode.disconnect();
                lastNode.connect(this._effects[i].input);
                lastNode = this._effects[i]
            }
            lastNode.connect(this._output)
        };
        Bus.prototype.connect = function(destination) {
            this._output.connect(destination);
            this._destination = destination;
            return this
        };
        Bus.prototype.disconnect = function() {
            this._output.disconnect();
            return this
        };
        Bus.prototype.refreshAudioNodes = function() {
            for (var i = 0; i < this.effects.length; i++) {
                this.effects[i].disconnect();
                if (this.effects[i].refreshAudioNodes) {
                    this.effects[i].refreshAudioNodes()
                }
            }
            var outVol = this._output.gain.value;
            this._output.gain.cancelScheduledValues(Klang.context.currentTime);
            this._output.disconnect();
            this._output = Klang.context.createGain();
            this._output.gain.setValueAtTime(outVol, Klang.context.currentTime);
            this.init();
            if (this._destination) {
                this._output.connect(this._destination)
            }
        };
        Bus.prototype.insertEffect = function(effectData, index) {
            var effect = Klang.core.Core.instance.createObject(undefined, effectData, {
                excludeFromTable: true
            });
            if (index === undefined) {
                this._effects.push(effect)
            } else {
                this._effects.splice(index, 0, effect)
            }
            this.init();
            return this
        };
        Bus.prototype.moveEffect = function(fromIndex, toIndex) {
            for (var i = 0, len = this._effects.length; i < len; i++) {
                this._effects[i].disconnect()
            }
            var effect = this._effects[fromIndex];
            this._effects.splice(fromIndex, 1);
            this._effects.splice(toIndex, 0, effect);
            this.init();
            return this
        };
        Object.defineProperty(Bus.prototype, "input", {
            get: function() {
                return this._input
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Bus.prototype, "output", {
            get: function() {
                return this._output
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Bus.prototype, "effects", {
            get: function() {
                return this._effects
            },
            enumerable: true,
            configurable: true
        });
        Bus.prototype.setData = function(data) {
            this._input.gain.value = data.input_vol === undefined ? 1 : data.input_vol;
            this._output.gain.value = data.output_vol === undefined ? 1 : data.output_vol;
            if (data.effects.length < this.effects.length) {
                this.input.disconnect();
                var found = false;
                for (var ix = 0; ix < this._effects.length; ix++) {
                    this._effects[ix].disconnect();
                    if (!found) {
                        if (data.effects[ix] === undefined) {
                            this._effects.splice(ix, 1);
                            found = true
                        } else if (this._effects[ix]._type != data.effects[ix].type) {
                            this._effects.splice(ix, 1);
                            ix--;
                            found = true
                        }
                    }
                }
                this.init()
            } else if (data.effects.length > this.effects.length) {
                this.insertEffect(data.effects[data.effects.length - 1])
            } else {
                for (var ix = 0, len = this._effects.length; ix < len; ix++) {
                    this._effects[ix].setData(data.effects[ix])
                }
            }
            if (this.destinationName != data.destination_name) {
                this.destinationName = data.destination_name;
                this.disconnect();
                if (this.destinationName == "$OUT") {
                    this.connect(Klang.core.Core.instance._superMasterOutput)
                } else {
                    this.connect(Klang.core.Core.instance.findInstance(this.destinationName).input)
                }
            }
        };
        return Klang.Model.Bus = Bus
    });
    Module(function(Klang) {
        Klang.audioUtil = Klang.engines.webAudio.Util = {}
    });
    Module(function(Klang) {
        var scales = {
            diatonic: [0, -1, 0, -1, 0, 0, -1, 0, -1, 0, -1, 0],
            dorian: [0, 1, 0, 0, -1, 0, 1, 0, 1, 0, 0, -1],
            phrygian: [0, 0, -1, 0, -1, 0, 1, 0, 0, -1, 0, -1],
            lydian: [0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0],
            mixolydian: [0, 1, 0, 1, 0, 0, -1, 0, -1, 0, 0, -1],
            aeolian: [0, -1, 0, 0, -1, 0, -1, 0, 0, -1, 0, -1],
            locrian: [0, 0, -1, 0, -1, 0, 0, -1, 0, -1, 0, -1],
            harmonicMinor: [0, 1, 0, 0, -1, 0, 1, 0, 0, -1, 1, 0],
            melodicMinor: [0, 1, 0, 0, -1, 0, 1, 0, -1, 0, 1, 0],
            majorPentatonic: [0, 1, 0, 1, 0, -1, 1, 0, 1, 0, -1, 1],
            minorPentatonic: [0, -1, 1, 0, -1, 0, 1, 0, -1, 1, 0, -1],
            doubleHarmonic: [0, 0, -1, 1, 0, 0, 1, 0, 0, -1, 1, 0],
            halfDim: [0, 1, 0, 0, -1, 0, 0, -1, 0, -1, 0, -1],
            chromatic: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            custom: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        };
        var getTransposeFromScale = function(midiNoteNumber, scale, root) {
            var scaleStep = midiNoteNumber % 12 - root;
            if (scaleStep < 0) {
                scaleStep += 12
            }
            var transpose = this.scales[scale][scaleStep];
            return transpose
        };
        var getNoteInScale = function(midiNoteNumber, scale, root) {
            var transpose;
            if (scale) {
                var orgNote = midiNoteNumber;
                var scaleStep = orgNote % 12 - root;
                if (scaleStep < 0) {
                    scaleStep += 12
                }
                transpose = this.scales[scale][scaleStep]
            }
            return midiNoteNumber + transpose
        };
        Klang.engines.webAudio.Util.getNoteInScale = getNoteInScale;
        Klang.engines.webAudio.Util.getTransposeFromScale = getTransposeFromScale;
        Klang.engines.webAudio.Util.scales = scales
    });
    Module(function(Klang) {
        (function(PatternState) {
            PatternState._map = [];
            PatternState._map[0] = "PrePlaying";
            PatternState.PrePlaying = 0;
            PatternState._map[1] = "Playing";
            PatternState.Playing = 1;
            PatternState._map[2] = "PreStopping";
            PatternState.PreStopping = 2;
            PatternState._map[3] = "PostStop";
            PatternState.PostStop = 3;
            PatternState._map[4] = "Stopped";
            PatternState.Stopped = 4
        })(Klang.Model.PatternState || (Klang.Model.PatternState = {}));
        var PatternState = Klang.Model.PatternState;

        function getPatternStateString(state) {
            switch (state) {
                case PatternState.PrePlaying:
                    return "PrePlaying";
                case PatternState.Playing:
                    return "Playing";
                case PatternState.PreStopping:
                    return "PreStopping";
                case PatternState.PostStop:
                    return "PostStop";
                case PatternState.Stopped:
                    return "Stopped"
            }
        }
        Klang.Model.getPatternStateString = getPatternStateString;
        var Pattern = function(_super) {
            Klang.Util.__extends(Pattern, _super);

            function Pattern(data, name) {
                _super.call(this, data, name);
                this._startStep = 0;
                this._totalStep = 0;
                this._currentStep = 0;
                this._syncStep = 0;
                this._stepCount = 0;
                this._fadeTime = 0;
                this._length = 2;
                this._loop = true;
                this._tail = false;
                this._forceFade = false;
                this._activeUpbeat = -1;
                this._startOffset = 0;
                this._state = PatternState.Stopped;
                this._beatSubscription = data.beat_subscription || .25;
                this._length = data.length || 0;
                this._startStep = data.start_step || 0;
                this._loop = data.loop !== undefined ? data.loop : true;
                this._tail = data.tail !== undefined ? data.tail : false;
                this._clips = [];
                this._upbeats = [];
                this._sequencerName = data.sequencer;
                this._initData = {
                    dummyClips: data.content,
                    dummyUpbeats: data.upbeats
                };
                Klang.core.Core.instance.pushToPreLoadInitStack(this)
            }
            Pattern.prototype.init = function() {
                if (this._initData.dummyClips) {
                    for (var ix = 0, len = this._initData.dummyClips.length; ix < len; ix++) {
                        var dummy = this._initData.dummyClips[ix];
                        if (dummy.audio) {
                            this._clips.push({
                                audio: Klang.core.Core.instance.findInstance(dummy.audio),
                                process: null,
                                args: null,
                                step: dummy.step
                            });
                            this._clips[this._clips.length - 1].audio._parentType = this._type
                        } else {
                            this._clips.push({
                                audio: null,
                                process: Klang.core.Core.instance.findInstance(dummy.process),
                                args: dummy.args,
                                step: dummy.step
                            })
                        }
                    }
                }
                if (this._initData.dummyUpbeats) {
                    for (var ix = 0, ilen = this._initData.dummyUpbeats.length; ix < ilen; ix++) {
                        var dummyUpbeat = this._initData.dummyUpbeats[ix];
                        var upbeatClips = [];
                        for (var jx = 0, jlen = dummyUpbeat.content.length; jx < jlen; jx++) {
                            var dummyClip = dummyUpbeat.content[jx];
                            if (dummyClip.audio) {
                                upbeatClips.push({
                                    audio: Klang.core.Core.instance.findInstance(dummyClip.audio),
                                    process: null,
                                    args: null,
                                    step: dummyClip.step
                                });
                                this._clips[this._clips.length - 1].audio._parentType = this._type
                            } else {
                                upbeatClips.push({
                                    audio: null,
                                    process: Klang.core.Core.instance.findInstance(dummyClip.process),
                                    args: dummyClip.args,
                                    step: dummyClip.step
                                })
                            }
                        }
                        dummyUpbeat.clips = upbeatClips;
                        this._upbeats.push({
                            length: dummyUpbeat.length,
                            clips: upbeatClips
                        })
                    }
                    this._upbeats.sort(function(a, b) {
                        return b.length - a.length
                    })
                }
                this._sequencer = Klang.core.Core.instance.findInstance(this._sequencerName);
                this._sequencer.registerPattern(this);
                this._initData = null
            };
            Pattern.prototype.connect = function(destination) {
                for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                    var a = this._clips[ix].audio;
                    if (a && (!a.destinationName || Klang.core.Core.instance.findInstance(a.destinationName).destinationName == "$OUT")) {
                        a.disconnect();
                        a.connect(this._output)
                    }
                }
                this._output.connect(destination);
                return this
            };
            Pattern.prototype.disconnect = function() {
                this._output.disconnect();
                return this
            };
            Pattern.prototype.changeState = function(state) {
                if (state == this._state) {
                    return
                }
                if (Klang.core.Core.callbacks && Klang.core.Core.callbacks.changePatternState) {
                    Klang.core.Core.callbacks.changePatternState({
                        pattern: this,
                        lastState: this._state,
                        newState: state,
                        step: this._sequencer.currentStep
                    })
                }
                this._state = state
            };
            Pattern.prototype.prePlaySchedule = function(steps, syncStep, restart, fadeIn, duration, offset) {
                restart = restart || false;
                var t = Klang.context.currentTime;
                if (this._state == PatternState.PreStopping || this._state == PatternState.PostStop) {
                    this._output.gain.cancelScheduledValues(t);
                    this._output.gain.setValueAtTime(this._output.gain.value, t);
                    this._output.gain.linearRampToValueAtTime(this._volume, t + .5);
                    this.changeState(PatternState.Playing);
                    clearTimeout(this._stoppingId);
                    return this
                } else if (this._output.gain.value != this._volume || PatternState.Stopped) {
                    var v;
                    if (this._state === PatternState.Stopped && fadeIn) {
                        v = 0
                    } else {
                        v = this._output.gain.value
                    }
                    this._output.gain.cancelScheduledValues(t);
                    this._output.gain.setValueAtTime(v, t);
                    this._output.gain.linearRampToValueAtTime(this._volume, t + duration)
                } else if (fadeIn) {
                    var playTime = this._sequencer.getBeatTime(steps);
                    this._output.gain.cancelScheduledValues(playTime);
                    this._output.gain.setValueAtTime(0, playTime);
                    this._output.gain.linearRampToValueAtTime(this._volume, playTime + duration)
                }
                if (this._state == PatternState.Playing || this._state == PatternState.PrePlaying) {
                    if (restart) {
                        this._syncStep = syncStep;
                        this.stop(steps, true, 0)
                    } else {
                        return this
                    }
                }
                if (offset !== undefined) {
                    this._startOffset = offset
                }
                this._syncStep = syncStep % this._length + this._startStep;
                if (steps > 0 || restart) {
                    this._stepCount = steps;
                    this._currentStep = this._startStep;
                    this._totalStep = 0;
                    this._activeUpbeat = -1;
                    for (var ix = 0, len = this._upbeats.length; ix < len; ix++) {
                        var upbeat = this._upbeats[ix];
                        if (upbeat.length <= steps) {
                            if (this._activeUpbeat == -1 || this._upbeats[this._activeUpbeat].length < upbeat.length) {
                                this._activeUpbeat = ix
                            }
                        }
                    }
                    this.changeState(PatternState.PrePlaying)
                } else {
                    this.changeState(PatternState.Playing)
                }
                return this
            };
            Pattern.prototype.play = function(when) {
                if (this._state == PatternState.Playing || this._state == PatternState.PrePlaying) {
                    return this
                } else if (this._state == PatternState.PreStopping || this._state == PatternState.PostStop) {
                    clearTimeout(this._stoppingId)
                }
                this._currentStep = this._sequencer.currentStep % this._length + this._startStep;
                this.changeState(PatternState.Playing);
                if (!this._sequencer.started) {
                    this._sequencer.start()
                }
                return this
            };
            Pattern.prototype.stop = function(when, beat, fadeTime, wait) {
                if (this._state == PatternState.Stopped) {
                    return this
                } else if (this._state === PatternState.PrePlaying) {
                    this.changeState(PatternState.Stopped);
                    return
                }
                if (when === undefined) {
                    this.changeState(PatternState.Stopped);
                    this._currentStep = 0;
                    return this
                }
                if (beat === undefined) {
                    beat = true
                }
                if (beat) {
                    this._stepCount = this._sequencer.getStepsToNext(this._sequencer.beatLength * when) || 0;
                    this._fadeTime = fadeTime;
                    this.changeState(PatternState.PreStopping);
                    if (wait > 0) {
                        this._stepCount += wait
                    }
                } else {
                    if (fadeTime) {
                        var fadeBeats = fadeTime / this._sequencer.getNoteTime(1);
                        this._stepCount = Math.ceil(fadeBeats);
                        this.changeState(PatternState.Stopped);
                        var t = Klang.context.currentTime;
                        for (var i = 0; i < this._clips.length; i++) {
                            if (this._clips[i].audio) {
                                this._clips[i].audio.fadeOutAndStop(fadeTime, when)
                            }
                        }
                    } else {
                        this.changeState(PatternState.Stopped);
                        this._currentStep = 0;
                        for (var i = 0; i < this._clips.length; i++) {
                            if (this._clips[i].audio) {
                                this._clips[i].audio.stop(when + this._sequencer.getNoteTime(this._sequencer.resolution))
                            }
                        }
                    }
                }
                return this
            };
            Pattern.prototype.pause = function() {
                for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                    if (this._clips[ix].audio) {
                        this._clips[ix].audio.pause()
                    }
                }
                return this
            };
            Pattern.prototype.unpause = function() {
                for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                    if (this._clips[ix].audio) {
                        this._clips[ix].audio.unpause()
                    }
                }
                return this
            };
            Pattern.prototype.playStep = function(currentStep, scheduleTime) {
                if (this._currentStep >= this._length + this._startStep) {
                    if (this._loop) {
                        this._currentStep = this._startStep
                    } else if (!this._loop) {
                        this.changeState(PatternState.Stopped)
                    }
                }
                for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                    if (this._clips[ix].step == this._currentStep) {
                        var clip = this._clips[ix];
                        if (clip.audio) {
                            clip.audio.play(scheduleTime, this._startOffset)
                        } else {
                            clip.process.start(clip.args)
                        }
                    }
                }
                this._totalStep += this._beatSubscription;
                this._currentStep += this._beatSubscription
            };
            Pattern.prototype.update = function(currentStep, scheduleTime) {
                if (this._state != PatternState.Stopped && currentStep % this._beatSubscription == 0) {
                    switch (this._state) {
                        case PatternState.PrePlaying: {
                            if (this._activeUpbeat != -1) {
                                var upbeat = this._upbeats[this._activeUpbeat];
                                for (var ix = 0, len = upbeat.clips.length; ix < len; ix++) {
                                    var clip = upbeat.clips[ix];
                                    if (clip.step == upbeat.length - this._stepCount) {
                                        if (clip.audio) {
                                            clip.audio.play(scheduleTime)
                                        } else {
                                            clip.process.start(clip.args)
                                        }
                                    }
                                }
                            }
                            this._stepCount -= this._beatSubscription;
                            if (this._stepCount <= 0) {
                                this._currentStep = this._startStep + this._syncStep % this._length;
                                this._syncStep = 0;
                                this.changeState(PatternState.Playing)
                            }
                            break
                        }
                        case PatternState.Playing: {
                            this.playStep(currentStep, scheduleTime);
                            break
                        }
                        case PatternState.PreStopping: {
                            this._stepCount -= this._beatSubscription;
                            if (this._stepCount <= 0) {
                                if (!this._tail || this._forceFade) {
                                    this.stop(scheduleTime, false, this._fadeTime)
                                } else {
                                    this.changeState(PatternState.Stopped);
                                    this._currentStep = 0
                                }
                            } else {
                                this.playStep(currentStep, scheduleTime)
                            }
                            break
                        }
                        case PatternState.PostStop: {
                            this.playStep(currentStep, scheduleTime);
                            this._stepCount -= this._beatSubscription;
                            if (this._stepCount <= 0) {
                                this._forceFade = false;
                                this.changeState(PatternState.Stopped);
                                this._currentStep = 0
                            }
                            break
                        }
                    }
                }
                return this
            };
            Pattern.prototype.deschedule = function(steps) {
                if (steps === undefined) {
                    steps = this._length
                }
                if (this._state != PatternState.Stopped) {
                    steps = steps % this._length;
                    for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                        var clip = this._clips[ix];
                        if (clip.audio) {
                            clip.audio.deschedule()
                        }
                    }
                    clearTimeout(this._stoppingId);
                    this._output.gain.cancelScheduledValues(Klang.Util.now());
                    this._currentStep = this._currentStep - steps;
                    if (this._currentStep < this._startStep) {
                        var stepDelta = this._startStep - this._currentStep;
                        this._currentStep = this._startStep + this._length - stepDelta
                    }
                }
                return this
            };
            Pattern.prototype.fadeInAndPlay = function(duration, when) {
                return this
            };
            Pattern.prototype.fadeOutAndStop = function(duration, when) {
                when = when || Klang.Util.now();
                this.stop(when, false, duration);
                return this
            };
            Pattern.prototype.curvePlaybackRate = function(value, duration) {
                for (var i = 0, l = this._clips.length; i < l; i++) {
                    this._clips[i].audio.curvePlaybackRate(value, duration)
                }
                return this
            };
            Pattern.prototype.getNextBar = function(x) {
                var nextBar = Math.ceil(this._currentStep / x);
                if (this._currentStep > this._length - x) {
                    nextBar = 0
                }
                return nextBar
            };
            Object.defineProperty(Pattern.prototype, "forceFade", {
                set: function(value) {
                    this._forceFade = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Pattern.prototype, "playbackRate", {
                set: function(value) {
                    for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                        this._clips[ix].audio.playbackRate = value
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Pattern.prototype, "length", {
                get: function() {
                    return this._length
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Pattern.prototype, "loop", {
                get: function() {
                    return this._loop
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Pattern.prototype, "state", {
                get: function() {
                    return this._state
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Pattern.prototype, "playing", {
                get: function() {
                    var _playing = false;
                    if (this._state === 1 || this._state === 1) {
                        _playing = true
                    }
                    return _playing
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Pattern.prototype, "duration", {
                get: function() {
                    return this._length * this._sequencer.getNoteTime(1)
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Pattern.prototype, "playbackState", {
                get: function() {
                    return 0
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Pattern.prototype, "currentStep", {
                get: function() {
                    return this._currentStep
                },
                enumerable: true,
                configurable: true
            });
            Pattern.prototype.setData = function(data) {
                _super.prototype.setData.call(this, data);
                var reinit = false;
                this._beatSubscription = data.beat_subscription !== undefined ? data.beat_subscription : .25;
                this._length = data.length !== undefined ? data.length : 0;
                this._startStep = data.start_step !== undefined ? data.start_step : 0;
                this._loop = data.loop === undefined ? true : data.loop;
                this._tail = data.tail === undefined ? false : data.tail;
                if (data.sequencer !== undefined && this._sequencerName != data.sequencer) {
                    this._sequencerName = data.sequencer;
                    reinit = true
                }
                this._initData = {
                    dummyClips: null,
                    dummyUpbeats: null
                };
                if (data.content) {
                    this._initData.dummyClips = data.content;
                    this._clips = [];
                    reinit = true
                }
                if (data.upbeats) {
                    this._initData.dummyUpbeats = data.upbeats;
                    this._upbeats = [];
                    reinit = true
                }
                if (reinit) {
                    this._sequencer.unregisterPattern(this);
                    this.init()
                }
            };
            return Pattern
        }(Klang.Model.Audio);
        return Klang.Model.Pattern = Pattern
    });
    Module(function(Klang) {
        var PatternState = Klang.Model.PatternState;
        var audioUtil = Klang.engines.webAudio.Util;
        var MidiPattern = function(_super) {
            Klang.Util.__extends(MidiPattern, _super);

            function MidiPattern(data, name) {
                _super.call(this, data, name);
                this._startStep = 0;
                this._totalStep = 0;
                this._currentStep = 0;
                this._syncStep = 0;
                this._stepCount = 0;
                this._fadeTime = 0;
                this._transpose = 0;
                this._updatedClips = [];
                this._state = PatternState.Stopped;
                this._beatSubscription = data.beat_subscription || .25;
                this._midiFileId = data.file_id;
                this._midiTrackIx = data.midi_track || 0;
                this._sequencerName = data.sequencer;
                this._synthName = data.synth;
                this._loop = data.loop !== undefined ? data.loop : true;
                this._length = data.length || 0;
                this._nextClip = 0;
                this._startStep = data.start_step || 0;
                this._root = data.root || 0;
                this._transpose = this._orgTranspose = data.transpose || 0;
                this._scale = this._orgScale = data.scale;
                this._rootNote = data.root_note || 36;
                this._activeUpbeat = -1;
                this._dataClips = data.clips || undefined;
                if (data.upbeats) {
                    this._upbeats = [];
                    this._upbeatLoopOffset = 0;
                    for (var ix = 0, len = data.upbeats.length; ix < len; ix++) {
                        this._upbeats.push({
                            length: data.upbeats[ix].length,
                            step: data.upbeats[ix].step,
                            targetStep: data.upbeats[ix].target_step,
                            playInLoop: data.upbeats[ix].play_in_loop
                        })
                    }
                }
                Klang.core.Core.instance.pushToPostLoadInitStack(this)
            }
            MidiPattern.prototype.init = function() {
                this._sequencer = Klang.core.Core.instance.findInstance(this._sequencerName);
                this._sequencer.registerPattern(this);
                if (this._synthName === "progression") {
                    this._synth = "progression";
                    this._progression = true;
                    this._currentChord = []
                } else {
                    this._synth = Klang.core.Core.instance.findInstance(this._synthName)
                }
                this._midiFile = Klang.core.FileHandler.instance.getFile(this._midiFileId);
                if (this._midiFile) {
                    this.setupFile()
                } else if (this._dataClips) {
                    this._clips = this._dataClips
                } else {
                    this._clips = []
                }
            };
            MidiPattern.prototype.setupFile = function() {
                this._midiTrack = this._midiFile.tracks[this._midiTrackIx];
                if (this._midiTrack === undefined) {
                    Klang.warn("MidiPattern: midi track out of bounds: " + this._midiTrackIx)
                }
                this.recalculateBPM(this._sequencer.bpm);
                var ticksPerBeat = this._midiFile.header.ticksPerBeat;
                var step = 0;
                var ticks = 0;
                this._clips = [];
                for (var ix = 0, len = this._midiTrack.length; ix < len; ix++) {
                    var ev = this._midiTrack[ix];
                    ticks += ev.deltaTime;
                    var of = ticks / ticksPerBeat % this._sequencer.resolution;
                    var st = ticks / ticksPerBeat - of ;
                    this._clips.push({
                        event: ev,
                        step: st,
                        offset: ticks % (ticksPerBeat * this._sequencer.resolution)
                    })
                }
                return this
            };
            MidiPattern.prototype.connect = function(destination) {
                this._output.connect(destination);
                return this
            };
            MidiPattern.prototype.disconnect = function() {
                this._output.disconnect();
                return this
            };
            MidiPattern.prototype.changeState = function(state) {
                if (state == this._state) {
                    return
                }
                if (Klang.core.Core.callbacks && Klang.core.Core.callbacks.changePatternState) {
                    Klang.core.Core.callbacks.changePatternState({
                        pattern: this,
                        lastState: this._state,
                        newState: state,
                        step: this._sequencer.currentStep
                    })
                }
                this._state = state
            };
            MidiPattern.prototype.prePlaySchedule = function(steps, syncStep, restart) {
                if (!this._midiFile) {
                    this._midiFile = Klang.core.FileHandler.instance.getFile(this._midiFileId);
                    if (!this._midiFile) {
                        Klang.log("MidiPattern: midifile not found: " + this._name + ". Playing without midifile.")
                    } else {
                        this.setupFile()
                    }
                }
                restart = restart || false;
                if (this._state == PatternState.Playing) {
                    if (restart) {
                        this._syncStep = syncStep;
                        this.stop(steps, true)
                    } else {
                        return this
                    }
                }
                var startTime = this._sequencer._scheduleTime + steps * this._sequencer.getNoteTime(.25);
                this.trigger("start", startTime);
                this._syncStep = syncStep % this._length;
                this._currentStep = this._startStep;
                this.findNextClip(this._currentStep);
                if (steps > 0) {
                    this._stepCount = steps;
                    this._currentStep += this._syncStep;
                    this._syncStep = 0;
                    this._totalStep = 0;
                    this.changeState(PatternState.PrePlaying);
                    if (this._upbeats) {
                        this._activeUpbeat = -1;
                        for (var ix = 0, len = this._upbeats.length; ix < len; ix++) {
                            var upbeat = this._upbeats[ix];
                            if (upbeat.length <= steps) {
                                if (this._activeUpbeat == -1 || this._upbeats[this._activeUpbeat].length < upbeat.length) {
                                    this._activeUpbeat = ix
                                }
                            }
                        }
                        if (this._activeUpbeat != -1 && this._upbeats[this._activeUpbeat].playInLoop) {
                            this._upbeatLoopOffset = this._upbeats[this._activeUpbeat].length
                        }
                    }
                    this.findNextClip(this._activeUpbeat == -1 ? this._currentStep : this._upbeats[this._activeUpbeat].step)
                } else {
                    this.changeState(PatternState.Playing)
                }
                this._patternStartTime = Klang.Util.now();
                return this
            };
            MidiPattern.prototype.play = function(when) {
                if (!this._midiFile) {
                    this._midiFile = Klang.core.FileHandler.instance.getFile(this._midiFileId);
                    if (!this._midiFile) {
                        Klang.log("MidiPattern: midifile not found: " + this._name + ". Playing without midifile.")
                    } else {
                        this.setupFile()
                    }
                }
                if (this._state == PatternState.Playing) {
                    return this
                }
                if (when && when != 0) {
                    var targetVol = this._output.gain.value;
                    this._output.gain.setValueAtTime(0, 0);
                    this._output.gain.setValueAtTime(targetVol, when)
                }
                this._currentStep = this._sequencer.currentStep % this._length + this._startStep;
                this.changeState(PatternState.Playing);
                this.findNextClip(this._currentStep);
                if (!this._sequencer.started) {
                    this._sequencer.start()
                }
                return this
            };
            MidiPattern.prototype.restart = function() {
                this._currentStep = this._startStep;
                this._nextClip = 0;
                return this
            };
            MidiPattern.prototype.stop = function(when, beat) {
                this.trigger("stop", when);
                if (this._synth.deschedule && this._sequencer._scheduleAheadTime > .5) {
                    this._synth.deschedule()
                }
                if (when === undefined || this._state == PatternState.Stopped) {
                    this.changeState(PatternState.Stopped);
                    return this
                }
                if (beat === undefined) {
                    beat = true
                }
                if (beat) {
                    this._stepCount = this._sequencer.getStepsToNext(this._sequencer.beatLength * when);
                    this.changeState(PatternState.PreStopping)
                } else {
                    this.changeState(PatternState.Stopped);
                    if (this._synth !== "progression" && this._synth._loopedSamples) {
                        this._synth.stop(when)
                    }
                }
                return this
            };
            MidiPattern.prototype.pause = function() {
                return this
            };
            MidiPattern.prototype.unpause = function() {
                return this
            };
            MidiPattern.prototype.sendMidiEvents = function(step, scheduleTime, bypassNoteOn) {
                if (!this._clips.length) {
                    return
                }
                var startClip = this._nextClip;
                while (this._clips[this._nextClip].step == step) {
                    var nextClip = this._clips[this._nextClip];
                    if (!this._progression) {
                        var transpose = 0;
                        if (nextClip.event.noteNumber) {
                            if (this._scale) {
                                transpose = audioUtil.getTransposeFromScale(nextClip.event.noteNumber, this._scale, this._root)
                            }
                            if (this._transpose != 0) {
                                transpose += this._transpose
                            }
                        }
                        if (!(bypassNoteOn && nextClip.event.subtype === "noteOn")) {
                            var offset = this._midiFile ? nextClip.offset * this._secPerTick : nextClip.offset;
                            this._synth.handleMidiEvent(nextClip.event, scheduleTime + offset, transpose);
                            if (nextClip.duration != "undefined") {
                                var newEvent = Klang.Util.cloneObject(nextClip.event);
                                newEvent.subtype = "noteOff";
                                var noteOffTime = nextClip.duration * (this._sequencer.getNoteTime(.25) * 4);
                                this._synth.handleMidiEvent(newEvent, scheduleTime + noteOffTime + offset, transpose)
                            }
                        }
                    } else {
                        if (nextClip.event.subtype === "noteOn") {
                            this._currentChord.push(nextClip.event.noteNumber)
                        } else if (nextClip.event.subtype === "noteOff") {
                            var id = this._currentChord.indexOf(nextClip.event.noteNumber);
                            if (id > -1) {
                                this._currentChord.splice(id, 1)
                            }
                        }
                    }
                    this._nextClip++;
                    if (this._nextClip == this._clips.length) {
                        this._nextClip = 0
                    }
                    if (this._nextClip === startClip) {
                        Klang.log("MidiPattern", this._name, "got stuck, check if you're playing the correct midi track.");
                        break
                    }
                }
                if (this._progression && this._currentChord.length) {
                    this._currentChord.sort(function(a, b) {
                        return a - b
                    });
                    var chordRootMidiNote = this._currentChord[0];
                    var root = chordRootMidiNote % 12;
                    var transpose = 0;
                    if (root != this._root) {
                        transpose = chordRootMidiNote - this._rootNote
                    }
                    var scale = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    var chordNormalized = [];
                    for (var j = 0; j < this._currentChord.length; j++) {
                        var n = this._currentChord[j] % 12 - root;
                        if (n < 0) {
                            n += 12
                        }
                        chordNormalized.push(n)
                    }
                    chordNormalized.sort(function(a, b) {
                        return a - b
                    });
                    for (var i = 0; i < scale.length; i++) {
                        var closest = this.getClosestValues(chordNormalized, i);
                        if (closest !== undefined) {
                            scale[i] = closest - i
                        }
                    }
                    this._sequencer.customScale = scale;
                    this._sequencer.transpose = transpose;
                    this._rootNote = chordRootMidiNote
                }
            };
            MidiPattern.prototype.getClosestValues = function(a, x) {
                var lo = -1,
                    hi = a.length;
                while (hi - lo > 1) {
                    var mid = Math.round((lo + hi) / 2);
                    if (a[mid] <= x) {
                        lo = mid
                    } else {
                        hi = mid
                    }
                }
                var closest;
                if (a[lo] == x) {
                    closest = hi = lo
                }
                if (Math.abs(x - hi) > Math.abs(x - lo)) {
                    closest = lo
                } else if (Math.abs(x - hi) < Math.abs(x - lo)) {
                    closest = hi
                } else {
                    closest = lo
                }
                return a[closest]
            };
            MidiPattern.prototype.findNextClip = function(step) {
                for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                    if (this._clips[ix].step >= step) {
                        this._nextClip = ix;
                        return ix;
                        break
                    }
                }
            };
            MidiPattern.prototype.playStep = function(currentStep, scheduleTime) {
                var playThisStep = true;
                if (this._currentStep >= this._length + this._startStep) {
                    this.sendMidiEvents(this._length, scheduleTime, true);
                    this._currentStep = this._startStep;
                    this.findNextClip(this._currentStep);
                    if (!this._loop) {
                        this.changeState(PatternState.Stopped);
                        playThisStep = false
                    }
                    if (this._updatedClips.length) {
                        var newArray = this._clips.concat(this._updatedClips);
                        newArray.sort(function(a, b) {
                            return a.step - b.step
                        });
                        this._clips = newArray;
                        this._updatedClips = []
                    }
                }
                if (playThisStep) {
                    this.sendMidiEvents(this._currentStep, scheduleTime, false)
                }
                this._totalStep += this._beatSubscription;
                this._currentStep += this._beatSubscription
            };
            MidiPattern.prototype.update = function(currentStep, scheduleTime) {
                if (this._state != PatternState.Stopped && currentStep % this._beatSubscription == 0) {
                    if (this._upbeats && this._activeUpbeat != -1 && this._upbeats[this._activeUpbeat].playInLoop && this._state == PatternState.Playing) {
                        if (this._currentStep >= this._length + this._startStep - this._upbeatLoopOffset) {
                            if (this._upbeatLoopOffset > 0) {
                                this._stepCount = this._upbeatLoopOffset;
                                this.changeState(PatternState.PrePlaying)
                            }
                            this.sendMidiEvents(this._currentStep, scheduleTime, true);
                            this._currentStep = this._startStep;
                            this.findNextClip(this._upbeats[this._activeUpbeat].step)
                        }
                    }
                    switch (this._state) {
                        case PatternState.PrePlaying: {
                            if (this._activeUpbeat != -1) {
                                var upbeat = this._upbeats[this._activeUpbeat];
                                var currentUpbeatStep = upbeat.length - this._stepCount;
                                if (currentUpbeatStep >= 0) {
                                    this.sendMidiEvents(upbeat.step + currentUpbeatStep, scheduleTime, false)
                                }
                            }
                            this._stepCount -= this._beatSubscription;
                            if (this._stepCount <= 0) {
                                if (this._activeUpbeat != -1 && upbeat.targetStep) {
                                    this._currentStep = upbeat.targetStep
                                }
                                this.findNextClip(this._currentStep);
                                this.changeState(PatternState.Playing)
                            }
                            break
                        }
                        case PatternState.Playing: {
                            this.playStep(currentStep, scheduleTime);
                            break
                        }
                        case PatternState.PreStopping: {
                            this._stepCount -= this._beatSubscription;
                            if (this._stepCount <= 0) {
                                this.stop(scheduleTime, false)
                            } else {
                                this.playStep(currentStep, scheduleTime)
                            }
                            break
                        }
                        case PatternState.PostStop: {
                            break
                        }
                    }
                }
                return this
            };
            MidiPattern.prototype.recalculateBPM = function(bpm) {
                var ticksPerBeat = this._midiFile.header.ticksPerBeat;
                var microsecPerQuarterNote = 6e7 / bpm;
                var secPerQuarterNote = microsecPerQuarterNote / 1e6;
                this._secPerTick = secPerQuarterNote / ticksPerBeat
            };
            MidiPattern.prototype.getNextBar = function(x) {
                var nextBar = Math.ceil(this._currentStep / x);
                if (this._currentStep > this._length - x) {
                    nextBar = 0
                }
                return nextBar
            };
            MidiPattern.prototype.fadeInAndPlay = function(duration, when) {
                this.play(when);
                this.output.gain.value = 0;
                Klang.Util.curveParamLin(this.output.gain, 1, duration, when);
                return this
            };
            MidiPattern.prototype.fadeOutAndStop = function(duration, when) {
                if (when === undefined) {
                    when = Klang.context.currentTime
                }
                this.output.gain.cancelScheduledValues(when);
                Klang.Util.curveParamLin(this.output.gain, 0, duration, when);
                Klang.Util.setParam(this.output.gain, this._volume, when + duration);
                this.stop(when + duration);
                return this
            };
            MidiPattern.prototype.deschedule = function(steps) {
                if (steps === undefined) {
                    steps = this._length
                }
                if (this._synth.deschedule) {
                    this._synth.deschedule()
                }
                if (this._state != PatternState.Stopped) {
                    steps = steps % this._length;
                    this._currentStep = this._currentStep - steps;
                    if (this._currentStep < this._startStep) {
                        var stepDelta = this._startStep - this._currentStep;
                        this._currentStep = this._startStep + this._length - stepDelta
                    }
                    for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                        if (this._clips[ix].step >= this._currentStep) {
                            this._nextClip = ix;
                            break
                        }
                    }
                }
                return this
            };
            MidiPattern.prototype.resetTranspose = function() {
                this._transpose = this._orgTranspose
            };
            Object.defineProperty(MidiPattern.prototype, "length", {
                get: function() {
                    return this._length
                },
                set: function(length) {
                    this._length = length
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MidiPattern.prototype, "startStep", {
                set: function(step) {
                    this._startStep = step;
                    this._currentStep = this._sequencer.currentStep % this._length + this._startStep;
                    for (var ix = 0, len = this._clips.length; ix < len; ix++) {
                        if (this._clips[ix].step >= this._currentStep) {
                            this._nextClip = ix;
                            break
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MidiPattern.prototype, "scale", {
                set: function(scale) {
                    if (!this._progression) {
                        if (scale === "reset") {
                            this._scale = this._orgScale
                        } else {
                            this._scale = scale
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MidiPattern.prototype, "customScale", {
                set: function(obj) {
                    if (!this._progression) {
                        Klang.audioUtil.scales["custom"] = obj;
                        this._scale = "custom"
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MidiPattern.prototype, "transpose", {
                get: function() {
                    return this._transpose
                },
                set: function(transpose) {
                    this._transpose = transpose
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MidiPattern.prototype, "loop", {
                get: function() {
                    return this._loop
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MidiPattern.prototype, "state", {
                get: function() {
                    return this._state
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MidiPattern.prototype, "playing", {
                get: function() {
                    var _playing = false;
                    if (this._state === 1 || this._state === 1) {
                        _playing = true
                    }
                    return _playing
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MidiPattern.prototype, "duration", {
                get: function() {
                    return this._length * this._sequencer.getNoteTime(1)
                },
                enumerable: true,
                configurable: true
            });
            MidiPattern.prototype.getNoteInScale = function(midiNumber) {
                return Klang.audioUtil.getNoteInScale(midiNumber, this._scale, this._root)
            };
            MidiPattern.prototype.getPositionInPattern = function() {
                var patternLength = this.length;
                var lengthSeconds = this._sequencer.getNoteTime(patternLength);
                var positionSeconds = (Klang.Util.now() - this._patternStartTime) % lengthSeconds;
                var sixteenNote = this._sequencer.getNoteTime(.25);
                var positionStepsRaw = positionSeconds / sixteenNote;
                var positionSteps = Math.floor(positionStepsRaw);
                var delta = positionStepsRaw - positionSteps;
                var offset = sixteenNote * delta;
                var step = positionSteps / 4 - .25;
                return {
                    step: step,
                    offset: offset
                }
            };
            MidiPattern.prototype.addNote = function(noteNumber, velocity, step, duration, offset) {
                this.addSingleEvent("noteOn", noteNumber, velocity, step, offset);
                var noteOffStep = step + duration;
                if (noteOffStep > this.length) {
                    noteOffStep = noteOffStep - this.length
                }
                this.addSingleEvent("noteOff", noteNumber, velocity, noteOffStep, offset)
            };
            MidiPattern.prototype.addSingleEvent = function(type, noteNumber, velocity, step, offset) {
                this._updatedClips.push({
                    event: {
                        type: "channel",
                        subtype: type,
                        noteNumber: noteNumber,
                        velocity: velocity
                    },
                    step: step,
                    offset: offset || 0
                });
                this._updatedClips.sort(function(a, b) {
                    return a.step - b.step
                })
            };
            MidiPattern.prototype.setData = function(data) {
                _super.prototype.setData.call(this, data);
                var reinit = false;
                this._beatSubscription = data.beat_subscription === undefined ? .25 : data.beat_subscription;
                if (this._midiFile != data.file_id) {
                    this._midiFile = data.file_id
                }
                if (this._midiTrackIx != data.midi_track) {
                    this._midiTrackIx = data.midi_track
                }
                if (data.sequener !== undefined && this._sequencerName != data.sequencer) {
                    this._sequencerName = data.sequencer;
                    reinit = true
                }
                if (data.synth !== undefined && this._synthName != data.synth) {
                    this._synthName = data.synth;
                    reinit = true
                }
                if (data.clips) {
                    this._clips = data.clips
                }
                this._loop = data.loop === undefined ? false : data.loop;
                this._length = data.length === undefined ? 0 : data.length;
                this._root = data.root === undefined ? 0 : data.root;
                this._orgTranspose = data.transpose === undefined ? 0 : data.transpose;
                this._transpose = this._orgTranspose;
                this._orgScale = data.scale === undefined ? 0 : data.scale;
                this._scale = this._orgScale;
                this._rootNote = data.root_note === undefined ? 36 : data.root_note;
                this._activeUpbeat = -1;
                if (data.upbeats) {
                    this._upbeats = [];
                    this._upbeatLoopOffset = 0;
                    for (var ix = 0, len = data.upbeats.length; ix < len; ix++) {
                        this._upbeats.push({
                            length: data.upbeats[ix].length,
                            step: data.upbeats[ix].step,
                            targetStep: data.upbeats[ix].target_step,
                            playInLoop: data.upbeats[ix].play_in_loop
                        })
                    }
                    reinit = true
                }
                if (reinit) {
                    this._sequencer.unregisterPattern(this);
                    this.init()
                }
            };
            return MidiPattern
        }(Klang.Model.Audio);
        return Klang.Model.MidiPattern = MidiPattern
    });
    Module(function(Klang) {
        function Effect(data) {
            this.active = true;
            this._type = data.type;
            this._output && this.disconnect();
            this._input = Klang.context.createGain();
            this._output = Klang.context.createGain();
            if (data.active === false) {
                this.active = false
            }
        }
        Effect.prototype.connect = function(destination) {
            this._output.connect(destination);
            return this
        };
        Effect.prototype.disconnect = function() {
            this._output.disconnect();
            return this
        };
        Effect.prototype.setActive = function(state) {
            Klang.warn("Effect: Invocation of abstract method: Effect.setActive in", this);
            return this
        };
        Object.defineProperty(Effect.prototype, "input", {
            get: function() {
                return this._input
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Effect.prototype, "output", {
            get: function() {
                return this._output
            },
            enumerable: true,
            configurable: true
        });
        return Klang.Model.Effect = Effect
    });
    Module(function(Klang) {
        var EffectSend = function(_super) {
            Klang.Util.__extends(EffectSend, _super);

            function EffectSend(data) {
                _super.call(this, data);
                this._wet = Klang.context.createGain();
                this._wet.gain.setValueAtTime(data.wet, Klang.context.currentTime);
                this._input.connect(this._wet);
                this._input.connect(this._output);
                this.destinationName = data.destination_name;
                Klang.core.Core.instance.pushToPreLoadInitStack(this)
            }
            EffectSend.prototype.init = function() {
                var destination = Klang.core.Core.instance.findInstance(this.destinationName);
                if (destination) {
                    this._wet.connect(destination.input)
                }
            };
            EffectSend.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._wet);
                    this._input.connect(this._output)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Object.defineProperty(EffectSend.prototype, "wet", {
                get: function() {
                    return this._wet.gain
                },
                enumerable: true,
                configurable: true
            });
            EffectSend.prototype.setData = function(data) {
                if (data.wet !== undefined) {
                    this.wet.value = data.wet
                }
                if (data.destination_name != this.destinationName) {
                    this.destinationName = data.destination_name;
                    this._wet.disconnect();
                    this.init()
                }
            };
            return EffectSend
        }(Klang.Model.Effect);
        return Klang.Model.EffectSend = EffectSend
    });
    Module(function(Klang) {
        var Equalizer = function(_super) {
            Klang.Util.__extends(Equalizer, _super);

            function Equalizer(data) {
                _super.call(this, data);
                this._filters = [];
                if (Klang.detector.browser["name"] == "Firefox") {
                    this._input.connect(this._output);
                    return
                }
                if (data.bands.length == 0) {
                    Klang.warn("Equalizer: No bands specified");
                    this._input.connect(this.output)
                } else {
                    for (var ix = 0, len = data.bands.length; ix < len; ix++) {
                        var band = data.bands[ix];
                        var filter = Klang.context.createBiquadFilter();
                        if (band.filter_type) {
                            filter.type = Klang.Util.safeFilterType(band.filter_type)
                        }
                        if (band.frequency) {
                            filter.frequency.value = band.frequency
                        }
                        if (band.gain) {
                            filter.gain.value = band.gain
                        }
                        if (band.Q) {
                            filter.Q.value = band.Q
                        }
                        if (ix == 0) {
                            this._input.connect(filter)
                        } else {
                            this._filters[ix - 1].connect(filter)
                        }
                        this._filters.push(filter)
                    }
                    this._filters[this._filters.length - 1].connect(this._output)
                }
            }
            Equalizer.prototype.addFilter = function(type, frequency, q, gain) {
                var filter = Klang.context.createBiquadFilter();
                filter.type = type;
                filter.frequency.value = frequency;
                filter.gain.value = gain;
                filter.Q.value = q;
                if (this._filters.length == 0) {
                    this._input.disconnect();
                    this._input.connect(filter)
                } else {
                    this._filters[this._filters.length - 1].disconnect();
                    this._filters[this._filters.length - 1].connect(filter)
                }
                filter.connect(this.output);
                this._filters.push(filter)
            };
            Equalizer.prototype.removeFilter = function(index) {
                this._filters[index].disconnect();
                if (index == 0 && this._filters.length > 1) {
                    this._input.disconnect();
                    this._input.connect(this._filters[1])
                } else if (index == 0) {
                    this._input.disconnect();
                    this._input.connect(this._output)
                } else if (index == this._filters.length - 1) {
                    this._filters[index - 1].disconnect();
                    this._filters[index - 1].connect(this._output)
                } else {
                    this._filters[index - 1].disconnect();
                    this._filters[index - 1].connect(this._filters[index + 1])
                }
                this._filters.splice(index, 1)
            };
            Equalizer.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    if (this._filters.length == 0) {
                        this._input.connect(this._output)
                    } else {
                        this._input.connect(this._filters[0])
                    }
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Object.defineProperty(Equalizer.prototype, "filters", {
                get: function() {
                    return this._filters
                },
                enumerable: true,
                configurable: true
            });
            Equalizer.prototype.setData = function(data) {
                for (var ix = 0, len = this._filters.length; ix < len; ix++) {
                    var filterData = data.bands[ix];
                    var filter = this._filters[ix];
                    if (filter && filterData) {
                        filter.frequency.value = filterData.frequency;
                        filter.Q.value = filterData.Q;
                        filter.gain.value = filterData.gain;
                        var newType = Klang.Util.safeFilterType(filterData.filter_type);
                        if (filter.type != newType) {
                            filter.type = newType
                        }
                    }
                }
            };
            return Equalizer
        }(Klang.Model.Effect);
        return Klang.Model.Equalizer = Equalizer
    });
    Module(function(Klang) {
        var BiquadFilter = function(_super) {
            Klang.Util.__extends(BiquadFilter, _super);

            function BiquadFilter(data) {
                _super.call(this, data);
                this.init(data)
            }
            BiquadFilter.prototype.init = function(data) {
                this._data = data;
                this._filter = Klang.context.createBiquadFilter();
                this._filter.type = Klang.Util.safeFilterType(data.filter_type);
                this._input.connect(this._filter);
                this._filter.connect(this._output);
                var _filterStartFreq = data.frequency !== undefined ? data.frequency : 1e3;
                this._filter.frequency.setValueAtTime(_filterStartFreq, Klang.context.currentTime);
                var _filterStartQ = data.Q !== undefined ? data.Q : 1;
                this._filter.Q.setValueAtTime(_filterStartQ, Klang.context.currentTime);
                var _filterStartGain = data.gain !== undefined ? data.gain : 0;
                this._filter.gain.setValueAtTime(_filterStartGain, Klang.context.currentTime)
            };
            BiquadFilter.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._filter)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Object.defineProperty(BiquadFilter.prototype, "frequency", {
                get: function() {
                    return this._filter.frequency
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(BiquadFilter.prototype, "Q", {
                get: function() {
                    return this._filter.Q
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(BiquadFilter.prototype, "gain", {
                get: function() {
                    return this._filter.gain
                },
                enumerable: true,
                configurable: true
            });
            BiquadFilter.prototype.refreshAudioNodes = function() {
                var fValue = this._filter.frequency.value;
                var qValue = this._filter.Q.value;
                var gValue = this._filter.gain.value;
                this._filter.frequency.cancelScheduledValues(Klang.context.currentTime);
                this._filter.Q.cancelScheduledValues(Klang.context.currentTime);
                this._filter.gain.cancelScheduledValues(Klang.context.currentTime);
                this._filter.disconnect();
                this.init(this._data);
                this._filter.frequency.value = fValue;
                this._filter.Q.value = qValue;
                this._filter.gain.value = gValue
            };
            BiquadFilter.prototype.setData = function(data) {
                if (data.filter_type === undefined) {
                    data.filter_type = "lowpass"
                }
                if (this._filter.type != data.filter_type) {
                    this._filter.type = Klang.Util.safeFilterType(data.filter_type)
                }
                this._filter.frequency.value = data.frequency === undefined ? 1e3 : data.frequency;
                this._filter.Q.value = data.Q === undefined ? 1 : data.Q;
                this._filter.gain.value = data.gain === undefined ? 0 : data.gain
            };
            return BiquadFilter
        }(Klang.Model.Effect);
        return Klang.Model.BiquadFilter = BiquadFilter
    });
    Module(function(Klang) {
        var Bitcrusher = function(_super) {
            Klang.Util.__extends(Bitcrusher, _super);

            function Bitcrusher(data) {
                _super.call(this, data);
                this._pro = Klang.context.createScriptProcessor(data.buffer_size || 4096, 2, 2);
                var _this = this;
                this._pro.onaudioprocess = function(e) {
                    var inp = e.inputBuffer,
                        out = e.outputBuffer,
                        iL = inp.getChannelData(0),
                        iR = inp.getChannelData(1),
                        oL = out.getChannelData(0),
                        oR = out.getChannelData(1),
                        step = Math.pow(.5, _this._bits),
                        len = inp.length,
                        sample = 0,
                        lastL = 0,
                        lastR = 0,
                        i = 0;
                    for (; i < len; ++i) {
                        if ((sample += _this._reduction) >= 1) {
                            sample--;
                            lastL = step * Math.floor(iL[i] / step);
                            lastR = step * Math.floor(iR[i] / step)
                        }
                        oL[i] = lastL;
                        oR[i] = lastR
                    }
                };
                this._bits = data.bits || 4;
                this._reduction = data.reduction || .2;
                this._input.connect(this._pro);
                this._pro.connect(this._output)
            }
            Bitcrusher.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._pro);
                    this._input.connect(this._output)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Object.defineProperty(Bitcrusher.prototype, "bits", {
                get: function() {
                    return this._bits
                },
                set: function(value) {
                    this._bits = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Bitcrusher.prototype, "reduction", {
                get: function() {
                    return this._reduction
                },
                set: function(value) {
                    this._reduction = value
                },
                enumerable: true,
                configurable: true
            });
            Bitcrusher.prototype.setData = function(data) {
                this._bits = data.bits !== undefined ? data.bits : 4;
                this._reduction = data.reduction !== undefined ? data.reduction : .2
            };
            return Bitcrusher
        }(Klang.Model.Effect);
        return Klang.Model.Bitcrusher = Bitcrusher
    });
    Module(function(Klang) {
        var Compressor = function(_super) {
            Klang.Util.__extends(Compressor, _super);

            function Compressor(data) {
                _super.call(this, data);
                this._bypass = data.bypass;
                if (Klang.isMobile) {
                    this._input.connect(this._output);
                    return
                }
                this._dynamicsCompressor = Klang.context.createDynamicsCompressor();
                this._makeUpGain = Klang.context.createGain();
                this._input.connect(this._dynamicsCompressor);
                this._dynamicsCompressor.connect(this._makeUpGain);
                this._makeUpGain.connect(this._output);
                if (this._bypass) {
                    this._input.connect(this._output);
                    this._makeUpGain.gain.value = 0
                }
                this._dynamicsCompressor.threshold.value = data.threshold || this._dynamicsCompressor.threshold.value;
                this._dynamicsCompressor.knee.value = data.knee || this._dynamicsCompressor.knee.value;
                this._dynamicsCompressor.ratio.value = data.ratio || this._dynamicsCompressor.ratio.value;
                this._dynamicsCompressor.attack.value = data.attack || this._dynamicsCompressor.attack.value;
                this._dynamicsCompressor.release.value = data.release || this._dynamicsCompressor.release.value;
                this._makeUpGain.gain.value = data.make_up_gain || this._makeUpGain.gain.value
            }
            Compressor.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._dynamicsCompressor);
                    if (this._bypass) {
                        this._input.connect(this._output)
                    }
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Object.defineProperty(Compressor.prototype, "threshold", {
                get: function() {
                    return this._dynamicsCompressor.threshold
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Compressor.prototype, "knee", {
                get: function() {
                    return this._dynamicsCompressor.knee
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Compressor.prototype, "ratio", {
                get: function() {
                    return this._dynamicsCompressor.ratio
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Compressor.prototype, "attack", {
                get: function() {
                    return this._dynamicsCompressor.attack
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Compressor.prototype, "release", {
                get: function() {
                    return this._dynamicsCompressor.release
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Compressor.prototype, "reduction", {
                get: function() {
                    return this._dynamicsCompressor.reduction
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Compressor.prototype, "makeUpGain", {
                get: function() {
                    return this._makeUpGain.gain
                },
                enumerable: true,
                configurable: true
            });
            Compressor.prototype.setData = function(data) {
                if (data.threshold !== undefined) {
                    this.threshold.value = data.threshold
                }
                if (data.knee !== undefined) {
                    this.knee.value = data.knee
                }
                if (data.ratio !== undefined) {
                    this.ratio.value = data.ratio
                }
                if (data.attack !== undefined) {
                    this.attack.value = data.attack
                }
                if (data.release !== undefined) {
                    this.release.value = data.release
                }
                if (data.make_up_gain !== undefined) {
                    this.makeUpGain.value = data.make_up_gain
                }
            };
            return Compressor
        }(Klang.Model.Effect);
        return Klang.Model.Compressor = Compressor
    });
    Module(function(Klang) {
        var Convolver = function(_super) {
            Klang.Util.__extends(Convolver, _super);

            function Convolver(data) {
                _super.call(this, data);
                this._soundName = data.sound;
                this._convolver = Klang.context.createConvolver();
                this._wetGain = Klang.context.createGain();
                this._dryGain = Klang.context.createGain();
                this._wetGain.gain.value = 1;
                this._dryGain.gain.value = 0;
                this._wetGain.connect(this._convolver);
                this._dryGain.connect(this._output);
                this._input.connect(this._wetGain);
                this._input.connect(this._dryGain);
                this._convolver.connect(this._output);
                Klang.core.Core.instance.pushToPostLoadInitStack(this)
            }
            Convolver.prototype.dryWet = function(mix) {
                mix = Math.max(0, Math.min(1, mix));
                this._wetGain.gain.value = mix;
                this._dryGain.gain.value = 1 - mix
            };
            Convolver.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._convolver)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Convolver.prototype.init = function() {
                var soundInstance = Klang.core.Core.instance.findInstance(this._soundName);
                this._convolver.buffer = soundInstance.buffer
            };
            Convolver.prototype.setData = function(data) {
                if (data.sound && data.sound != this._soundName) {
                    this._soundName = data.sound;
                    this.init()
                }
            };
            return Convolver
        }(Klang.Model.Effect);
        return Klang.Model.Convolver = Convolver
    });
    Module(function(Klang) {
        var DelayBase = function(_super) {
            Klang.Util.__extends(DelayBase, _super);

            function DelayBase(data) {
                _super.call(this, data);
                this._sync = data.sync
            }
            DelayBase.prototype.init = function() {
                if (this._sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    this.updateSync(seq.bpm);
                    seq.registerBPMSync(this)
                }
            };
            DelayBase.prototype.setSync = function(sequencer, rate) {
                if (sequencer) {
                    this._sync = sequencer;
                    this._syncResolution = rate || 1;
                    this.init()
                } else {
                    this._sync = null;
                    this._syncResolution = null
                }
                return this
            };
            DelayBase.prototype.setSyncRate = function(rate) {
                if (this._sync) {
                    this._syncResolution = rate;
                    this.updateSync(Klang.core.Core.instance.findInstance(this._sync).bpm)
                }
                return this
            };
            DelayBase.prototype.updateSync = function(bpm) {
                Klang.warn("DelayBase: Invocation of abstract method: DelayBase.updateSync in", this);
                return this
            };
            Object.defineProperty(DelayBase.prototype, "sync", {
                get: function() {
                    return this._sync
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(DelayBase.prototype, "syncResolution", {
                get: function() {
                    return this._syncResolution
                },
                set: function(value) {
                    this._syncResolution = value
                },
                enumerable: true,
                configurable: true
            });
            return DelayBase
        }(Klang.Model.Effect);
        return Klang.Model.DelayBase = DelayBase
    });
    Module(function(Klang) {
        var Delay = function(_super) {
            Klang.Util.__extends(Delay, _super);

            function Delay(data) {
                _super.call(this, data);
                this._feedback = Klang.context.createGain();
                this._delay = Klang.context.createDelay();
                if (data.filter) {
                    this._filter = Klang.context.createBiquadFilter();
                    this._input.connect(this._filter);
                    this._filter.connect(this._delay);
                    this._filter.type = Klang.Util.safeFilterType(data.filter.filter_type);
                    this._filter.frequency.value = data.filter.frequency || 1e3;
                    this._filter.Q.value = data.filter.Q || 4;
                    this._filter.gain.value = data.filter.gain || 1
                } else {
                    this._input.connect(this._delay)
                }
                this._delay.connect(this._feedback);
                this._delay.connect(this._output);
                this._feedback.connect(this._delay);
                if (this.sync) {
                    Klang.core.Core.instance.pushToPreLoadInitStack(this);
                    this.syncResolution = data.delay_time || 1
                } else {
                    this._delay.delayTime.value = data.delay_time || .125
                }
                this._feedback.gain.value = data.feedback || .3;
                this._output.gain.value = data.output_vol || data.wet || 1
            }
            Delay.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._delay)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Delay.prototype.updateSync = function(bpm) {
                this._delay.delayTime.value = 60 / bpm * this.syncResolution;
                return this
            };
            Object.defineProperty(Delay.prototype, "delayTime", {
                get: function() {
                    return this._delay.delayTime
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Delay.prototype, "feedback", {
                get: function() {
                    return this._feedback.gain
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Delay.prototype, "filter", {
                get: function() {
                    return this._filter
                },
                enumerable: true,
                configurable: true
            });
            Delay.prototype.setData = function(data) {
                if (data.feedback) {
                    this._feedback.gain.value = data.feedback
                }
                if (data.sync) {
                    this.setSync(data.sync, data.delay_time)
                } else {
                    if (data.delay_time) {
                        this._delay.delayTime.value = data.delay_time
                    }
                }
                if (data.filter) {
                    if (!this._filter) {
                        this.input.disconnect();
                        this._filter = Klang.context.createBiquadFilter();
                        this.input.connect(this._filter);
                        this._filter.connect(this._delay)
                    }
                    if (data.filter.filter_type !== undefined) {
                        this._filter.type = Klang.Util.safeFilterType(data.filter.filter_type)
                    }
                    if (data.filter.frequency !== undefined) {
                        this._filter.frequency.value = data.filter.frequency
                    }
                    if (data.filter.Q !== undefined) {
                        this._filter.Q.value = data.filter.Q
                    }
                    if (data.filter.gain !== undefined) {
                        this._filter.gain.value = data.filter.gain
                    }
                } else {
                    if (this._filter) {
                        this.input.disconnect();
                        this._filter.disconnect();
                        this.input.connect(this._delay);
                        this._filter = null
                    }
                }
            };
            return Delay
        }(Klang.Model.DelayBase);
        return Klang.Model.Delay = Delay
    });
    Module(function(Klang) {
        var StereoDelay = function(_super) {
            Klang.Util.__extends(StereoDelay, _super);

            function StereoDelay(data) {
                _super.call(this, data);
                if (this.sync) {
                    data.left.sync = this.sync;
                    data.right.sync = this.sync
                }
                this._splitter = Klang.context.createChannelSplitter(2);
                this._merger = Klang.context.createChannelMerger(2);
                this._leftDelay = new Delay(data.left || {});
                this._rightDelay = new Delay(data.right || {});
                this._input.connect(this._splitter);
                this._splitter.connect(this._leftDelay.input, 0, 0);
                this._splitter.connect(this._rightDelay.input, 0, 0);
                this._splitter.connect(this._rightDelay.input, 1, 0);
                this._leftDelay.output.connect(this._merger, 0, 0);
                this._rightDelay.output.connect(this._merger, 0, 1);
                this._merger.connect(this._output)
            }
            StereoDelay.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._splitter)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            StereoDelay.prototype.updateSync = function(bpm) {
                this._leftDelay.updateSync(bpm);
                this._rightDelay.updateSync(bpm);
                return this
            };
            Object.defineProperty(StereoDelay.prototype, "leftDelay", {
                get: function() {
                    return this._leftDelay
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StereoDelay.prototype, "rightDelay", {
                get: function() {
                    return this._rightDelay
                },
                enumerable: true,
                configurable: true
            });
            StereoDelay.prototype.setData = function(data) {
                this._leftDelay.setData(data.left);
                this._rightDelay.setData(data.right)
            };
            return StereoDelay
        }(Klang.Model.DelayBase);
        return Klang.Model.StereoDelay = StereoDelay
    });
    Module(function(Klang) {
        var PingPongDelay = function(_super) {
            Klang.Util.__extends(PingPongDelay, _super);

            function PingPongDelay(data) {
                _super.call(this, data);
                this._splitter = Klang.context.createChannelSplitter(2);
                this._merger = Klang.context.createChannelMerger(2);
                this._mono = Klang.context.createGain();
                this._leftDelay = Klang.context.createDelay();
                this._rightDelay = Klang.context.createDelay();
                this._feedback = Klang.context.createGain();
                if (data.filter) {
                    this._filter = Klang.context.createBiquadFilter();
                    this._mono.connect(this._filter);
                    this._filter.connect(this._leftDelay);
                    this._feedback.connect(this._filter);
                    this._filter.type = Klang.Util.safeFilterType(data.filter.filter_type);
                    this._filter.frequency.value = data.filter.frequency || 1e3;
                    this._filter.Q.value = data.filter.Q || 4;
                    this._filter.gain.value = data.filter.gain || 1
                } else {
                    this._mono.connect(this._leftDelay);
                    this._feedback.connect(this._leftDelay)
                }
                this._input.connect(this._splitter);
                this._splitter.connect(this._mono, 0, 0);
                this._splitter.connect(this._mono, 1, 0);
                this._leftDelay.connect(this._rightDelay);
                this._rightDelay.connect(this._feedback);
                this._leftDelay.connect(this._merger, 0, 0);
                this._rightDelay.connect(this._merger, 0, 1);
                this._merger.connect(this._output);
                if (this.sync) {
                    Klang.core.Core.instance.pushToPreLoadInitStack(this);
                    this.syncResolution = data.delay_time || 1
                } else {
                    var _delayStartTime = data.delay_time || .125;
                    this._leftDelay.delayTime.setValueAtTime(_delayStartTime, Klang.context.currentTime);
                    this._rightDelay.delayTime.setValueAtTime(_delayStartTime, Klang.context.currentTime)
                }
                var _startFeedback = data.feedback || .3;
                this._feedback.gain.setValueAtTime(_startFeedback, Klang.context.currentTime);
                var _startOutput = data.output_vol || data.wet || 1;
                this._output.gain.setValueAtTime(_startOutput, Klang.context.currentTime)
            }
            PingPongDelay.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._splitter)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            PingPongDelay.prototype.updateSync = function(bpm) {
                this._leftDelay.delayTime.value = 60 / bpm * this.syncResolution;
                this._rightDelay.delayTime.value = this._leftDelay.delayTime.value;
                return this
            };
            Object.defineProperty(PingPongDelay.prototype, "delay_time", {
                set: function(val) {
                    this._leftDelay.delayTime.value = val;
                    this._rightDelay.delayTime.value = val
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(PingPongDelay.prototype, "feedback", {
                get: function() {
                    return this._feedback.gain
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(PingPongDelay.prototype, "filter", {
                get: function() {
                    return this._filter
                },
                enumerable: true,
                configurable: true
            });
            PingPongDelay.prototype.setData = function(data) {
                if (data.feedback) {
                    this._feedback.gain.value = data.feedback
                }
                if (data.sync) {
                    this.setSync(data.sync, data.delay_time)
                } else {
                    if (data.delay_time) {
                        this._leftDelay.delayTime.value = data.delay_time;
                        this._rightDelay.delayTime.value = data.delay_time
                    }
                }
                if (data.filter) {
                    if (!this._filter) {
                        this._mono.disconnect();
                        this._feedback.disconnect();
                        this._filter = Klang.context.createBiquadFilter();
                        this._mono.connect(this._filter);
                        this._filter.connect(this._leftDelay);
                        this._feedback.connect(this._filter)
                    }
                    if (data.filter.filter_type !== undefined) {
                        this._filter.type = Klang.Util.safeFilterType(data.filter.filter_type)
                    }
                    if (data.filter.frequency !== undefined) {
                        this._filter.frequency.value = data.filter.frequency
                    }
                    if (data.filter.Q !== undefined) {
                        this._filter.Q.value = data.filter.Q
                    }
                    if (data.filter.gain !== undefined) {
                        this._filter.gain.value = data.filter.gain
                    }
                } else {
                    if (this._filter) {
                        this._mono.disconnect();
                        this._feedback.disconnect();
                        this._filter.disconnect();
                        this._mono.connect(this._leftDelay);
                        this._feedback.connect(this._leftDelay);
                        this._filter = null
                    }
                }
            };
            return PingPongDelay
        }(Klang.Model.DelayBase);
        return Klang.Model.PingPongDelay = PingPongDelay
    });
    Module(function(Klang) {
        var Limiter = function(_super) {
            Klang.Util.__extends(Limiter, _super);

            function Limiter(data) {
                _super.call(this, data);
                this._compressor = Klang.context.createDynamicsCompressor();
                this._preGain = Klang.context.createGain();
                this._postGain = Klang.context.createGain();
                this._input.connect(this._preGain);
                this._preGain.connect(this._compressor);
                this._compressor.connect(this._postGain);
                this._postGain.connect(this._output);
                this._compressor.threshold.value = data.threshold || 0;
                this._compressor.knee.value = 0;
                this._compressor.ratio.value = 100;
                this._compressor.attack.value = 0;
                this._compressor.release.value = 0;
                this._preGain.gain.value = data.pre_gain === undefined ? 1 : data.pre_gain;
                this._postGain.gain.value = data.post_gain === undefined ? 1 : data.post_gain
            }
            Limiter.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._preGain)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Object.defineProperty(Limiter.prototype, "threshold", {
                get: function() {
                    return this._compressor.threshold
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Limiter.prototype, "preGain", {
                get: function() {
                    return this._preGain.gain
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Limiter.prototype, "postGain", {
                get: function() {
                    return this._postGain.gain
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Limiter.prototype, "reduction", {
                get: function() {
                    return this._compressor.reduction
                },
                enumerable: true,
                configurable: true
            });
            Limiter.prototype.setData = function(data) {
                if (data.threshold !== undefined) {
                    this._compressor.threshold.value = data.threshold
                }
                if (data.pre_gain !== undefined) {
                    this._preGain.gain.value = data.pre_gain
                }
                if (data.post_gain !== undefined) {
                    this._postGain.gain.value = data.post_gain
                }
            };
            return Limiter
        }(Klang.Model.Effect);
        return Klang.Model.Limiter = Limiter
    });
    Module(function(Klang) {
        var Panner = function(_super) {
            Klang.Util.__extends(Panner, _super);

            function Panner(data) {
                _super.call(this, data);
                this.data = data;
                this._name = data.name;
                this._panner = Klang.context.createPanner();
                this._input.connect(this._panner);
                this._panner.connect(this._output);
                if (data.panning_model !== undefined) {
                    this._panner.panningModel = data.panning_model
                }
                if (data.distance_model !== undefined) {
                    this._panner.distanceModel = data.distance_model
                }
                if (data.ref_distance !== undefined) {
                    this._panner.refDistance = data.ref_distance
                }
                if (data.max_distance !== undefined) {
                    this._panner.maxDistance = data.max_distance
                }
                if (data.rolloff_factor !== undefined) {
                    this._panner.rolloffFactor = data.rolloff_factor
                }
                if (data.cone_inner_angle !== undefined) {
                    this._panner.coneInnerAngle = data.cone_inner_angle
                }
                if (data.cone_outer_angle !== undefined) {
                    this._panner.coneOuterAngle = data.cone_outer_angle
                }
                if (data.cone_outer_gain !== undefined) {
                    this._panner.coneOuterGain = data.cone_outer_gain
                }
                if (data.position !== undefined) {
                    this._panner.setPosition(data.position[0], data.position[1], data.position[2])
                }
                if (data.orientation !== undefined) {
                    this._panner.setOrientation(data.position[0], data.position[1], data.position[2])
                }
                Panner.panners[this._name] = this
            }
            Panner.panners = {};
            Panner._scale = 1;
            Panner.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._panner)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Panner.prototype.setPosition = function(x, y, z) {
                this._panner.setPosition(x * Panner.scale, y * Panner.scale, z * Panner.scale)
            };
            Panner.prototype.setOrientation = function(x, y, z) {
                this._panner.setOrientation(x, y, z)
            };
            Panner.prototype.setVelocity = function(x, y, z) {
                Klang.warn("setVelocity is removed from Klang and the WebAudio API")
            };
            Panner.prototype.setData = function(data) {
                this._panner.setPosition(data.position[0], data.position[1], data.position[2]);
                this._panner.setOrientation(data.position[0], data.position[1], data.position[2]);
                if (data.panning_model !== undefined) {
                    this._panner.panningModel = data.panning_model
                }
                if (data.distance_model !== undefined) {
                    this._panner.distanceModel = data.distance_model
                }
                if (data.ref_distance !== undefined) {
                    this._panner.refDistance = data.ref_distance
                }
                if (data.max_distance !== undefined) {
                    this._panner.maxDistance = data.max_distance
                }
                if (data.rolloff_factor !== undefined) {
                    this._panner.rolloffFactor = data.rolloff_factor
                }
                if (data.cone_inner_angle !== undefined) {
                    this._panner.coneInnerAngle = data.cone_inner_angle
                }
                if (data.cone_outer_angle !== undefined) {
                    this._panner.coneOuterAngle = data.cone_outer_angle
                }
                if (data.cone_outer_gain !== undefined) {
                    this._panner.coneOuterGain = data.cone_outer_gain
                }
            };
            Object.defineProperty(Panner, "listener", {
                get: function() {
                    return Klang.context.listener
                },
                enumerable: true,
                configurable: true
            });
            Panner.setListenerPosition = function setListenerPosition(x, y, z) {
                Klang.context.listener.setPosition(x * Panner.scale, y * Panner.scale, z * Panner.scale)
            };
            Panner.setListenerOrientation = function setListenerOrientation(x, y, z, xUp, yUp, zUp) {
                Klang.context.listener.setOrientation(x, y, z, xUp, yUp, zUp)
            };
            Panner.setListenerVelocity = function setListenerVelocity(x, y, z) {
                Klang.warn("setListenerVelocity is removed from Klang and the WebAudio API")
            };
            Panner.setDopplerFactor = function setDopplerFactor(factor) {
                Klang.warn("setDopplerFactor is removed from Klang and the WebAudio API")
            };
            Panner.setSpeedOfSound = function setSpeedOfSound(speed) {
                Klang.context.listener.speed = speed
            };
            Panner.setListenerData = function setListenerData(data) {
                if (!data) {
                    return
                }
                Panner.scale = data.scale;
                Panner.setListenerPosition(data.position[0], data.position[1], data.position[2]);
                Panner.setListenerOrientation(data.orientation[0], data.orientation[1], data.orientation[2], data.orientation_up[0], data.orientation_up[1], data.orientation_up[2]);
                Panner.setSpeedOfSound(data.speed_of_sound)
            };
            Panner.get = function get(name) {
                return Panner.panners[name]
            };
            Object.defineProperty(Panner, "scale", {
                get: function() {
                    return Panner._scale
                },
                set: function(scale) {
                    Panner._scale = scale
                },
                enumerable: true,
                configurable: true
            });
            return Panner
        }(Klang.Model.Effect);
        return Klang.Model.Panner = Panner
    });
    Module(function(Klang) {
        var Sidechain = function(_super) {
            Klang.Util.__extends(Sidechain, _super);

            function Sidechain(data) {
                _super.call(this, data);
                this._source = data.source;
                this._gain = Klang.context.createGain();
                this._processor = Klang.context.createScriptProcessor(data.buffer_size || 0);
                var _this = this;
                var reductionIsAudioParameter = typeof this._source.reduction !== "number";
                this._processor.onaudioprocess = function() {
                    var reduction = reductionIsAudioParameter ? _this._source.reduction.value : _this._source.reduction;
                    _this._gain.gain.value = reduction === 0 ? 1 : Math.pow(10, reduction / 20)
                };
                this._input.connect(this._gain);
                this._input.connect(this._processor);
                this._processor.connect(Klang.context.destination);
                this._gain.connect(this._output);
                Klang.core.Core.instance.pushToPreLoadInitStack(this)
            }
            Sidechain.prototype.init = function() {
                if (!this._source || !this._source.bus || this._source.index === undefined) {
                    Klang.warn("Sidechain: No source specified")
                }
                var bus = Klang.core.Core.instance.findInstance(this._source.bus);
                this._source = bus._effects[this._source.index]
            };
            Sidechain.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._gain);
                    this._input.connect(this._processor)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            return Sidechain
        }(Klang.Model.Effect);
        return Klang.Model.Sidechain = Sidechain
    });
    Module(function(Klang) {
        var StereoPannerPolyfill = function(_super) {
            Klang.Util.__extends(StereoPannerPolyfill, _super);

            function StereoPannerPolyfill(data) {
                _super.call(this, data);
                this._splitter = Klang.context.createChannelSplitter(2);
                this._merger = Klang.context.createChannelMerger(2);
                this._left = Klang.context.createGain();
                this._right = Klang.context.createGain();
                this._input.connect(this._splitter);
                this._splitter.connect(this._left, 0, 0);
                this._splitter.connect(this._right, 1, 0);
                this._left.connect(this._merger, 0, 0);
                this._right.connect(this._merger, 0, 1);
                this._merger.connect(this._output);
                this.pan = data.pan
            }
            StereoPannerPolyfill.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._splitter)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            StereoPannerPolyfill.prototype.getGainValue = function(value) {
                return (value + 1) / 2
            };
            StereoPannerPolyfill.prototype.setPanTo = function(value, when) {
                var gainValue = this.getGainValue(value);
                this._left.gain.setValueAtTime(1 - gainValue, when || 0);
                this._right.gain.setValueAtTime(gainValue, when || 0);
                return this
            };
            StereoPannerPolyfill.prototype.linPanTo = function(value, duration, when) {
                when = when || Klang.context.currentTime;
                var gainValue = this.getGainValue(value);
                this._left.gain.setValueAtTime(this._left.gain.value, when);
                this._left.gain.linearRampToValueAtTime(1 - gainValue, when + duration);
                this._right.gain.setValueAtTime(this._right.gain.value, when);
                this._right.gain.linearRampToValueAtTime(gainValue, when + duration);
                return this
            };
            StereoPannerPolyfill.prototype.expPanTo = function(value, duration, when) {
                when = when || Klang.context.currentTime;
                var gainValue = this.getGainValue(value);
                this._left.gain.setValueAtTime(this._left.gain.value == 0 ? Klang.Util.EXP_MIN_VALUE : this._left.gain.value, when);
                this._left.gain.exponentialRampToValueAtTime(1 - gainValue, when + duration);
                this._right.gain.setValueAtTime(this._right.gain.value == 0 ? Klang.Util.EXP_MIN_VALUE : this._right.gain.value, when);
                this._right.gain.exponentialRampToValueAtTime(gainValue, when + duration);
                return this
            };
            Object.defineProperty(StereoPannerPolyfill.prototype, "pan", {
                get: function() {
                    return this._right.gain.value
                },
                set: function(value) {
                    var gainValue = this.getGainValue(value);
                    this._left.gain.value = 1 - gainValue;
                    this._right.gain.value = gainValue
                },
                enumerable: true,
                configurable: true
            });
            StereoPannerPolyfill.prototype.setData = function(data) {
                if (data.pan !== undefined) {
                    this.pan = data.pan
                }
            };
            return StereoPannerPolyfill
        }(Klang.Model.Effect);
        var StereoPanner = function(_super) {
            if (window.AudioContext) {
                if (typeof window.AudioContext.prototype.createStereoPanner === "undefined") {
                    return StereoPannerPolyfill
                }
            }
            Klang.Util.__extends(StereoPanner, _super);

            function StereoPanner(data) {
                _super.call(this, data);
                this._panner = Klang.context.createStereoPanner();
                this._panner.pan.setValueAtTime(data.pan, Klang.context.currentTime);
                this._input.connect(this._panner);
                this._panner.connect(this._output)
            }
            StereoPanner.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._splitter)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            StereoPanner.prototype.getGainValue = function(value) {
                return value
            };
            StereoPanner.prototype.setPanTo = function(value, when) {
                when = Math.max(when || 0, Klang.context.currentTime);
                this._panner.pan.cancelScheduledValues(when);
                this._panner.pan.setValueAtTime(value, when);
                return this
            };
            StereoPanner.prototype.linPanTo = function(value, duration, when) {
                when = Math.max(when || 0, Klang.context.currentTime);
                this._panner.pan.cancelScheduledValues(when);
                this._panner.pan.setValueAtTime(this._panner.pan.value, when);
                this._panner.pan.linearRampToValueAtTime(value, when + duration);
                return this
            };
            StereoPanner.prototype.expPanTo = function(value, duration, when) {
                when = when || Klang.context.currentTime;
                value = Klang.Util.clamp(value, -.99, .99);
                this._panner.pan.setValueAtTime(this._panner.pan.value == 0 ? Klang.Util.EXP_MIN_VALUE : this._panner.pan.value, when);
                this._panner.pan.exponentialRampToValueAtTime(value, when + duration);
                return this
            };
            StereoPanner.prototype.refreshAudioNodes = function() {
                var panValue = this._panner.pan.value;
                this._panner.pan.cancelScheduledValues(Klang.context.currentTime);
                this._panner.disconnect();
                this._panner = Klang.context.createStereoPanner();
                this._panner.pan.value = panValue;
                this._input.connect(this._panner);
                this._panner.connect(this._output)
            };
            Object.defineProperty(StereoPanner.prototype, "pan", {
                get: function() {
                    return this._panner.pan.value
                },
                set: function(value) {
                    this._panner.pan.value = value
                },
                enumerable: true,
                configurable: true
            });
            StereoPanner.prototype.setData = function(data) {
                if (data.pan !== undefined) {
                    this.pan = data.pan
                }
            };
            return StereoPanner
        }(Klang.Model.Effect);
        return Klang.Model.StereoPanner = StereoPanner
    });
    Module(function(Klang) {
        var Tremolo = function(_super) {
            Klang.Util.__extends(Tremolo, _super);

            function Tremolo(data, startTime) {
                _super.call(this, data);
                if (data.sync) {
                    this._sync = data.sync;
                    this._rate = data.rate || .25
                }
                this._oscillator = Klang.context.createOscillator();
                this._amplitude = Klang.context.createGain();
                this._input.connect(this._output);
                this._oscillator.connect(this._amplitude);
                this._amplitude.connect(this._output.gain);
                this._oscillator.frequency.value = data.frequency || 10;
                this._oscillator.type = data.wave || 0;
                this._amplitude.gain.value = data.amplitude || 1;
                this._oscillator.start(startTime);
                Klang.core.Core.instance.pushToPreLoadInitStack(this)
            }
            Tremolo.prototype.init = function() {
                if (this._sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    this.updateSync(seq.bpm);
                    seq.registerBPMSync(this)
                }
            };
            Tremolo.prototype.setActive = function(state) {
                if (state) {
                    this._amplitude.connect(this._output.gain)
                } else {
                    this._amplitude.disconnect()
                }
                return this
            };
            Tremolo.prototype.updateSync = function(bpm) {
                this._oscillator.frequency.value = bpm / 60 / this._rate;
                return this
            };
            Object.defineProperty(Tremolo.prototype, "frequency", {
                get: function() {
                    return this._oscillator.frequency
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Tremolo.prototype, "amplitude", {
                get: function() {
                    return this._amplitude.gain
                },
                enumerable: true,
                configurable: true
            });
            Tremolo.prototype.setData = function(data) {
                if (data.amplitude !== undefined) {
                    this.amplitude.value = data.amplitude
                }
                if (data.wave !== undefined) {
                    this._oscillator.type = data.wave
                }
                if (data.sync) {
                    this._sync = data.sync;
                    this._rate = data.rate || .25;
                    this.init()
                } else {
                    if (data.frequency !== undefined) {
                        this.frequency.value = data.frequency
                    }
                }
            };
            return Tremolo
        }(Klang.Model.Effect);
        return Klang.Model.Tremolo = Tremolo
    });
    Module(function(Klang) {
        var Distortion = function(_super) {
            Klang.Util.__extends(Distortion, _super);

            function Distortion(data) {
                _super.call(this, data);
                this._samples = 8192;
                this._distortionType = data.distortion_type || 0;
                this._amount = data.amount || .7;
                this._samples = 8192;
                this._waveshaper = Klang.context.createWaveShaper();
                this._inputDrive = Klang.context.createGain();
                this._outputDrive = Klang.context.createGain();
                this._input.connect(this._inputDrive);
                this._inputDrive.connect(this._waveshaper);
                this._waveshaper.connect(this._outputDrive);
                this._outputDrive.connect(this._output);
                this._ws_table = new Float32Array(this._samples);
                this.createWSCurve(this._distortionType, this._amount);
                this._inputDrive.gain.value = data.drive || .5;
                this._outputDrive.gain.value = data.outputGain || .5
            }
            Distortion.prototype.createWSCurve = function(type, amount) {
                switch (type) {
                    case 0:
                        var amount = Math.min(amount, .9999);
                        var k = 2 * amount / (1 - amount),
                            i, x;
                        for (i = 0; i < this._samples; i++) {
                            x = i * 2 / this._samples - 1;
                            this._ws_table[i] = (1 + k) * x / (1 + k * Math.abs(x))
                        }
                        break;
                    case 1:
                        var i, x, y;
                        for (i = 0; i < this._samples; i++) {
                            x = i * 2 / this._samples - 1;
                            y = (.5 * Math.pow(x + 1.4, 2) - 1) * y >= 0 ? 5.8 : 1.2;
                            this._ws_table[i] = this.tanh(y)
                        }
                        break;
                    case 2:
                        var i, x, y, a = 1 - amount;
                        for (i = 0; i < this._samples; i++) {
                            x = i * 2 / this._samples - 1;
                            y = x < 0 ? -Math.pow(Math.abs(x), a + .04) : Math.pow(x, a);
                            this._ws_table[i] = this.tanh(y * 2)
                        }
                        break;
                    case 3:
                        var i, x, y, abx, a = 1 - amount > .99 ? .99 : 1 - amount;
                        for (i = 0; i < this._samples; i++) {
                            x = i * 2 / this._samples - 1;
                            abx = Math.abs(x);
                            if (abx < a) {
                                y = abx
                            } else if (abx > a) {
                                y = a + (abx - a) / (1 + Math.pow((abx - a) / (1 - a), 2))
                            } else if (abx > 1) {
                                y = abx
                            }
                            this._ws_table[i] = this.sign(x) * y * (1 / ((a + 1) / 2))
                        }
                        break;
                    case 4:
                        var i, x;
                        for (i = 0; i < this._samples; i++) {
                            x = i * 2 / this._samples - 1;
                            if (x < -.08905) {
                                this._ws_table[i] = -3 / 4 * (1 - Math.pow(1 - (Math.abs(x) - .032857), 12) + 1 / 3 * (Math.abs(x) - .032847)) + .01
                            } else if (x >= -.08905 && x < .320018) {
                                this._ws_table[i] = -6.153 * (x * x) + 3.9375 * x
                            } else {
                                this._ws_table[i] = .630035
                            }
                        }
                        break;
                    case 5:
                        var a = 2 + Math.round(amount * 14),
                            bits = Math.round(Math.pow(2, a - 1)),
                            i, x;
                        for (i = 0; i < this._samples; i++) {
                            x = i * 2 / this._samples - 1;
                            this._ws_table[i] = Math.round(x * bits) / bits
                        }
                        break
                }
                this._waveshaper.curve = this._ws_table
            };
            Distortion.prototype.tanh = function(n) {
                return (Math.exp(n) - Math.exp(-n)) / (Math.exp(n) + Math.exp(-n))
            };
            Distortion.prototype.sign = function(x) {
                if (x === 0) {
                    return 1
                } else {
                    return Math.abs(x) / x
                }
            };
            Distortion.prototype.setActive = function(state) {
                this._input.disconnect();
                if (state) {
                    this._input.connect(this._inputDrive)
                } else {
                    this._input.connect(this._output)
                }
                return this
            };
            Object.defineProperty(Distortion.prototype, "amount", {
                get: function() {
                    return this._amount
                },
                set: function(val) {
                    this._amount = val;
                    this.createWSCurve(this._distortionType, this._amount)
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Distortion.prototype, "distortionType", {
                get: function() {
                    return this._distortionType
                },
                set: function(val) {
                    this._distortionType = val;
                    this.createWSCurve(this._distortionType, this._amount)
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Distortion.prototype, "drive", {
                get: function() {
                    return this._inputDrive.gain
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Distortion.prototype, "outputGain", {
                get: function() {
                    return this._outputDrive.gain
                },
                enumerable: true,
                configurable: true
            });
            Distortion.prototype.setData = function(data) {
                if (data.amount !== undefined) {
                    this.amount = data.amount
                }
                if (data.distortion_type !== undefined) {
                    this.distortionType = data.distortion_type
                }
                if (data.drive !== undefined) {
                    this._inputDrive.gain.value = data.drive
                }
                if (data.outputGain !== undefined) {
                    this._outputDrive.gain.value = data.outputGain
                }
            };
            return Distortion
        }(Klang.Model.Effect);
        return Klang.Model.Distortion = Distortion
    });
    Module(function(Klang) {
        function Synth(data, name) {
            this._arpCounter = 0;
            this._arpNoteLength = .5;
            this._arpPattern = [];
            this._arpPatternStep = 0;
            this._name = name;
            this._type = data.type;
            this._output = Klang.context.createGain();
            this._output.gain.value = data.volume || 1;
            if (data.destination_name) {
                this.destinationName = data.destination_name;
                if (!Klang.core.Core.instance.initComplete) {
                    Klang.core.Core.instance.pushToConnectStack(this)
                }
            }
            if (data.arp) {
                this._arpMode = data.arp.arp_mode || "off";
                this._octaves = data.arp.octaves || 1;
                this._sync = data.arp.sync;
                this._arpPattern = data.arp.arp_pattern || []
            } else {
                this._arpMode = "off"
            }
            this._activeVoices = [];
            this._arpVoices = [];
            this._beatSubscription = data.beat_subscription || .25;
            this.data = data
        }
        Synth.prototype.connect = function(destination) {
            this._output.connect(destination);
            return this
        };
        Synth.prototype.disconnect = function() {
            this._output.disconnect();
            return this
        };
        Synth.prototype.handleMidiEvent = function(event, when, transpose, bypassArp) {
            Klang.warn("Synth: Invocation of abstract method: Synth.handleMidiEvent in", this);
            return this
        };
        Synth.prototype.stop = function() {
            Klang.warn("Synth: Invocation of abstract method: Synth.stop in", this)
        };
        Synth.prototype.handleArpModes = function(midiEvent) {
            this._arpVoices = [];
            if (this._octaves > 1) {
                var octaves = [];
                for (var j = 0; j < this._octaves - 1; j++) {
                    for (var i = 0; i < this._activeVoices.length; i++) {
                        var e = this._activeVoices[i].midiEvent;
                        var note = e.noteNumber;
                        var ev = {
                            type: "channel",
                            subtype: e.subtype,
                            noteNumber: note += 12 * (j + 1),
                            velocity: e.velocity,
                            deltaTime: e.deltaTime
                        };
                        octaves.push({
                            midiEvent: ev,
                            transpose: this._activeVoices[i].transpose
                        })
                    }
                }
                this._arpVoices = this._activeVoices.concat(octaves)
            } else {
                this._arpVoices = this._activeVoices
            }
            if (this._arpMode === "up") {
                this._arpVoices = this._arpVoices.sort(this.sortVoices)
            } else if (this._arpMode === "down") {
                this._arpVoices = this._arpVoices.sort(this.sortVoices);
                this._arpVoices.reverse()
            } else if (this._arpMode === "up-down") {
                var up = this._arpVoices.slice(0);
                up.sort(this.sortVoices);
                var down = this._arpVoices.slice(0);
                down.sort(this.sortVoices);
                down.reverse();
                this._arpVoices = up.concat(down);
                if (this._arpVoices.length > 1) {
                    this._arpVoices.splice(this._arpVoices.length / 2, 1);
                    this._arpVoices.pop()
                }
            } else if (this._arpMode === "random") {
                this._arpVoices = Klang.Util.shuffle(this._arpVoices)
            }
        };
        Synth.prototype.sortVoices = function(a, b) {
            if (a.midiEvent.noteNumber < b.midiEvent.noteNumber) {
                return -1
            }
            if (a.midiEvent.noteNumber > b.midiEvent.noteNumber) {
                return 1
            }
            return 0
        };
        Synth.prototype.arpActive = function(active) {
            if (active) {
                if (this._sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    seq.registerSynth(this);
                    if (!seq._started) {
                        seq.start()
                    }
                }
            } else {
                this._arpMode = "off";
                if (this._sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    seq.unregisterSynth(this)
                }
            }
        };
        Synth.prototype.update = function(currentStep, scheduleTime) {
            if (currentStep % this._beatSubscription == 0) {
                this._arpPatternStep = currentStep * 4 % this._arpPattern.length;
                if (this._arpVoices.length === 0) {
                    return
                }
                if (this._arpPattern.length) {
                    if (!this._arpPattern[currentStep * 4 % this._arpPattern.length]) {
                        return
                    }
                }
                this._arpCounter++;
                this._arpCounter = this._arpCounter % this._arpVoices.length;
                if (this._arpCounter < this._arpVoices.length) {
                    var vel = this._fixedVelocities ? this._fixedVelocities[this._arpCounter] : this._arpVoices[this._arpCounter].midiEvent.velocity;
                    this._arpVoices[this._arpCounter].midiEvent.velocity = vel;
                    this.handleMidiEvent(this._arpVoices[this._arpCounter].midiEvent, scheduleTime, this._arpVoices[this._arpCounter].transpose, true);
                    var noteOff = {
                        type: "channel",
                        subtype: "noteOff",
                        noteNumber: this._arpVoices[this._arpCounter].midiEvent.noteNumber,
                        velocity: this._arpVoices[this._arpCounter].midiEvent.velocity,
                        deltaTime: this._arpVoices[this._arpCounter].midiEvent.deltaTime
                    };
                    this.handleMidiEvent(noteOff, scheduleTime + this._arpNoteLength, this._arpVoices[this._arpCounter].transpose, true)
                }
            }
        };
        Synth.prototype.deschedule = function() {
            Klang.warn("Synth: Invocation of abstract method: Synth.deschedule in", this);
            return this
        };
        Object.defineProperty(Synth.prototype, "arpCounter", {
            get: function() {
                return this._arpCounter
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Synth.prototype, "arpLength", {
            get: function() {
                return this._arpVoices.length
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Synth.prototype, "output", {
            get: function() {
                return this._output
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Synth.prototype, "arpPattern", {
            get: function() {
                return this._arpPattern
            },
            set: function(pattern) {
                this._arpPattern = pattern
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Synth.prototype, "arpPatternStep", {
            get: function() {
                return this._arpPatternStep
            },
            enumerable: true,
            configurable: true
        });
        Synth.prototype.setData = function(data) {
            if (data.volume !== undefined) {
                this.output.gain.value = data.volume
            }
            if (this.destinationName != data.destination_name) {
                this.destinationName = data.destination_name;
                this.disconnect();
                this.connect(Klang.core.Core.instance.findInstance(this.destinationName).input)
            }
            if (data.arp) {
                this._sync = data.arp.sync;
                this._octaves = data.arp.octaves;
                this._arpPattern = data.arp.arp_pattern;
                if (this._arpMode == "off") {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    seq.registerSynth(this);
                    if (!seq._started) {
                        seq.start()
                    }
                }
                this._arpMode = data.arp.arp_mode
            } else {
                this._arpMode = "off";
                if (this._sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    seq.unregisterSynth(this)
                }
            }
            this.data = data
        };
        return Klang.Model.Synth = Synth
    });
    Module(function(Klang) {
        function LFO(data, startTime) {
            startTime = startTime || 0;
            this._targets = data.targets;
            this._sync = data.sync;
            this._rate = data.rate || 1;
            this._phaseVal = data.phase || 0;
            this._oscillator = Klang.context.createOscillator();
            this._oscillator.type = data.wave || "sine";
            this._oscillator.frequency.value = this._rate;
            this._amplitude = Klang.context.createGain();
            this._amplitude.gain.value = data.amplitude || 1;
            this._phase = Klang.context.createDelay();
            this._phase.delayTime.value = this._phaseVal * (1 / this._oscillator.frequency.value);
            this._oscillator.connect(this._phase);
            this._phase.connect(this._amplitude);
            this._oscillator.start(startTime);
            Klang.core.Core.instance.pushToPreLoadInitStack(this)
        }
        LFO.prototype.init = function() {
            if (this._sync) {
                var seq = Klang.core.Core.instance.findInstance(this._sync);
                this.updateSync(seq.bpm);
                seq.registerBPMSync(this)
            }
            for (var ix = 0, len = this._targets.length; ix < len; ix++) {
                var t = this._targets[ix];
                var bus = Klang.core.Core.instance.findInstance(t.bus);
                var effect = bus.effects[t.effect];
                if (!effect) {
                    Klang.warn("LFO: Effect index out of bounds: " + t.effect)
                }
                if (!effect[t.param]) {
                    Klang.warn("LFO: Parameter not recognized: " + t.param)
                }
                this._amplitude.connect(effect[t.param])
            }
        };
        LFO.prototype.updateSync = function(bpm) {
            this._oscillator.frequency.value = bpm / 60 / this._rate;
            this._phase.delayTime.value = this._phaseVal * (1 / this._oscillator.frequency.value);
            return this
        };
        LFO.prototype.setData = function(data) {
            this._oscillator.type = data.wave;
            this._oscillator.frequency.value = data.rate;
            this._phase.delayTime.value = data.phase * (1 / this._oscillator.frequency.value);
            this._amplitude.gain.value = data.amplitude || 1;
            if (data.targets) {
                this._targets = data.targets;
                this.init()
            }
            if (this._sync != data.sync) {
                this._sync = data.sync;
                this.init()
            }
        };
        return Klang.Model.LFO = LFO
    });
    Module(function(Klang) {
        var generateNoiseBuffer = function(frames, alg) {
            if (alg < 0 || alg > 3) {
                Klang.warn("Synth: Invalid noise algorithm: " + alg)
            }
            var sampleFrames = frames || 65536;
            var buffer = Klang.context.createBuffer(1, sampleFrames, Klang.context.sampleRate);
            var bufferData = buffer.getChannelData(0);
            if (!alg) {
                alg = 0
            }
            for (var i = 0; i < sampleFrames; i++) {
                switch (alg) {
                    case 0:
                        bufferData[i] = Math.random() * 2 - 1;
                        break;
                    case 1:
                        bufferData[i] = Math.random();
                        break;
                    case 2:
                        bufferData[i] = Math.random() - 1;
                        break;
                    case 3:
                        bufferData[i] = i / sampleFrames;
                        break;
                    default:
                        break
                }
            }
            return buffer
        };
        Klang.engines.webAudio.Util.generateNoiseBuffer = generateNoiseBuffer
    });
    Module(function(Klang) {
        var audioUtil = Klang.engines.webAudio.Util;
        var SympleVoice = function() {
            function SympleVoice(data, voiceType, filterData, startTime, noiseBuffer) {
                this.filterStartFreq = -1;
                this._filterEnabled = true;
                this.voiceType = voiceType;
                this.active = false;
                this.activatedNote = -1;
                this._enabled = true;
                this._detune = data.detune || 0;
                this._wave = data.wave || "sine";
                this._frames = data.frames;
                this._algorithm = data.algorithm;
                if (noiseBuffer) {
                    this._noiseBuffer = noiseBuffer
                }
                this.gain = Klang.context.createGain();
                if (!filterData) {
                    filterData = {
                        frequency: 22050,
                        Q: 1,
                        filter_type: "lowpass"
                    };
                    this.filterEnabled = false
                }
                this._filterData = filterData
            }
            SympleVoice.prototype.noteOn = function(noteNumber, velocity, when, gainEG, filterEG, pitchEG, transpose) {
                if (!this.enabled) {
                    return
                }
                if (this._wave !== "noise") {
                    this.source = Klang.context.createOscillator();
                    this.source.type = this._wave;
                    this.source.detune.value = this._detune
                } else if (this._wave == "noise") {
                    this.source = Klang.context.createBufferSource();
                    this.source.buffer = this._noiseBuffer;
                    this.source.loop = true
                }
                this._envelope = Klang.context.createGain();
                if (this._filterData) {
                    this.filter = Klang.context.createBiquadFilter();
                    this.filter.type = Klang.Util.safeFilterType(this._filterData.filter_type);
                    this.filter.frequency.value = this._filterData.frequency === undefined ? Klang.Util.NYQUIST_FREQUENCY : this._filterData.frequency;
                    if (this.filter.detune) {
                        this.filter.detune.value = this._filterData.detune || 0
                    }
                    this.filter.Q.value = this._filterData.Q || this.filter.Q.value;
                    this.filter.gain.value = this._filterData.gain || this.filter.gain.value;
                    this.filterTargetFreq = this.filter.frequency.value;
                    this.source.connect(this.filter);
                    this.filter.connect(this._envelope);
                    this._envelope.connect(this.gain)
                } else {
                    this.source.connect(this._envelope);
                    this._envelope.connect(this.gain)
                }
                if (this.voiceType == 1) {
                    this.filterAmplitudeGainNode.connect(this.filter.frequency)
                }
                if (when < Klang.Util.now()) {
                    when = Klang.Util.now()
                }
                this.active = true;
                this.activatedNote = noteNumber;
                if (this._wave !== "noise") {
                    var pitchTargetFreq = Klang.Util.midiNoteToFrequency(noteNumber + transpose);
                    if (pitchEG) {
                        var pitchStartFreq = -1;
                        if (pitchEG.contour > 0) {
                            pitchStartFreq = pitchTargetFreq * (1 - pitchEG.contour)
                        } else if (pitchEG.contour < 0) {
                            pitchStartFreq = (Klang.Util.NYQUIST_FREQUENCY - pitchTargetFreq) * -pitchEG.contour + pitchTargetFreq
                        }
                        this.source.frequency.cancelScheduledValues(when);
                        if (pitchStartFreq != -1) {
                            this.source.frequency.setValueAtTime(pitchStartFreq, when);
                            if (Klang.safari) {
                                this.source.frequency.setTargetValueAtTime(pitchTargetFreq, when, pitchEG.decay)
                            } else {
                                this.source.frequency.setTargetAtTime(pitchTargetFreq, when, pitchEG.decay)
                            }
                        } else {
                            this.source.frequency.setValueAtTime(pitchTargetFreq, when)
                        }
                    } else {
                        this.source.frequency.setValueAtTime(pitchTargetFreq, when)
                    }
                }
                if (filterEG) {
                    this.filterStartFreq = -1;
                    if (filterEG.contour < 0) {
                        this.filterStartFreq = this.filterTargetFreq * (1 + filterEG.contour) + 1
                    } else if (filterEG.contour > 0) {
                        this.filterStartFreq = (Klang.Util.NYQUIST_FREQUENCY - this.filterTargetFreq) * filterEG.contour + this.filterTargetFreq
                    }
                    if (this.filterStartFreq != -1) {
                        this.filter.frequency.cancelScheduledValues(when);
                        this.filter.frequency.setValueAtTime(this.filterStartFreq, when + 1e-4);
                        this.filter.frequency.exponentialRampToValueAtTime(this.filterTargetFreq, when + filterEG.attack);
                        if (Klang.safari) {
                            this.filter.frequency.setTargetValueAtTime(this.filterTargetFreq * filterEG.sustain, when + filterEG.attack, filterEG.decay)
                        } else {
                            this.filter.frequency.setTargetAtTime(this.filterTargetFreq * filterEG.sustain, when + filterEG.attack + 1e-4, filterEG.decay)
                        }
                    }
                }
                var vol;
                if (gainEG.volumeCurve === "linear") {
                    vol = velocity / 128
                } else if (gainEG.volumeCurve === "exponential") {
                    vol = Math.abs(1 - Math.exp(velocity / 128))
                } else {
                    vol = 1
                }
                if (gainEG) {
                    this.gain.gain.cancelScheduledValues(when);
                    this._envelope.gain.setValueAtTime(0, when);
                    this._envelope.gain.linearRampToValueAtTime(vol, when + gainEG.attack);
                    if (Klang.safari) {
                        this._envelope.gain.setTargetValueAtTime(vol * gainEG.sustain, when + gainEG.attack, gainEG.decay)
                    } else {
                        this._envelope.gain.setTargetAtTime(vol * gainEG.sustain, when + gainEG.attack + 1e-4, gainEG.decay)
                    }
                } else {
                    this._envelope.gain.setValueAtTime(vol, when)
                }
                this.source["startTime"] = when;
                Klang.safari ? this.source.noteOn(when) : this.source.start(when)
            };
            SympleVoice.prototype.noteOff = function(noteNumber, when, gainEG, filterEG) {
                if (!this.enabled) {
                    return
                }
                if (when < Klang.Util.now()) {
                    when = Klang.Util.now()
                }
                this.active = false;
                if (filterEG) {
                    if (this.filterStartFreq != -1) {
                        this.filter.frequency.cancelScheduledValues(when);
                        if (when != Klang.Util.now()) {
                            this.filter.frequency.setTargetAtTime(this.filterTargetFreq * filterEG.sustain, when, .1)
                        } else {
                            this.filter.frequency.setValueAtTime(this.filter.frequency.value, when)
                        }
                        if (Klang.safari) {
                            this.filter.frequency.setTargetValueAtTime(this.filterStartFreq, when, filterEG.release)
                        } else {
                            this.filter.frequency.setTargetAtTime(this.filterStartFreq, when, filterEG.release)
                        }
                    }
                }
                if (gainEG) {
                    this._envelope.gain.cancelScheduledValues(when);
                    if (when != Klang.Util.now()) {
                        this._envelope.gain.setTargetAtTime(gainEG.sustain, when, .1)
                    } else {
                        this._envelope.gain.setValueAtTime(this._envelope.gain.value, when)
                    }
                    if (Klang.safari) {
                        this._envelope.gain.setTargetValueAtTime(0, when, gainEG.release)
                    } else {
                        this._envelope.gain.setTargetAtTime(0, when, gainEG.release)
                    }
                } else {
                    this._envelope.gain.setValueAtTime(0, when)
                }
                this.source["offTime"] = when;
                Klang.safari ? this.source.noteOff(when + gainEG.release * 5) : this.source.stop(when + gainEG.release * 5)
            };
            SympleVoice.prototype.stop = function() {
                this.filter.frequency.cancelScheduledValues(0);
                this._envelope.gain.cancelScheduledValues(0);
                this._envelope.gain.setValueAtTime(0, 0);
                Klang.safari ? this.source.noteOff(0) : this.source.stop(0);
                this.source.disconnect();
                if (this.filter) {
                    this.filter.disconnect()
                }
                if (this._envelope) {
                    this._envelope.disconnect()
                }
            };
            SympleVoice.prototype.stopSoft = function(when, gainEG, filterEG) {
                this.active = false;
                this._envelope.gain.cancelScheduledValues(when);
                if (Klang.safari) {
                    this._envelope.gain.setTargetValueAtTime(0, when, gainEG.release)
                } else {
                    this._envelope.gain.setTargetAtTime(0, when, gainEG.release)
                }
                var offTime = when + gainEG.release * 5;
                Klang.safari ? this.source.noteOff(offTime) : this.source.stop(offTime);
                var _this = this;
                setTimeout(function() {
                    _this.source.disconnect();
                    if (_this.filter) {
                        _this.filter.disconnect()
                    }
                    if (_this._envelope) {
                        _this._envelope.disconnect()
                    }
                }, (offTime - Klang.Util.now()) * 1e3)
            };
            Object.defineProperty(SympleVoice.prototype, "enabled", {
                get: function() {
                    return this._enabled
                },
                set: function(state) {
                    this._enabled = state
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(SympleVoice.prototype, "filterEnabled", {
                get: function() {
                    return this._filterEnabled
                },
                set: function(state) {
                    this._filterEnabled = state;
                    if (!this.source) {
                        return
                    }
                    if (state) {
                        this.source.disconnect();
                        this.source.connect(this.filter);
                        this.filter.connect(this._envelope)
                    } else {
                        this.source.disconnect();
                        this.filter.disconnect();
                        this.source.connect(this._envelope)
                    }
                },
                enumerable: true,
                configurable: true
            });
            return SympleVoice
        }();
        var SympleOsc = function() {
            function SympleOsc(data, poly, filterData, startTime) {
                this._enabled = true;
                this.nextVoice = 0;
                this.octave = data.octave || 0;
                this.output = Klang.context.createGain();
                this.output.gain.value = data.volume === undefined ? 1 : data.volume;
                this._data = data;
                this._poly = poly;
                this._filterData = filterData;
                this.voices = [];
                this._noiseBuffer = audioUtil.generateNoiseBuffer(this._data._frames, this._data._algorithm)
            }
            SympleOsc.prototype.noteOn = function(noteNumber, velocity, when, gainEG, filterEG, pitchEG, transpose) {
                if (!this.enabled) {
                    return
                }
                if (this.voices.length == this._poly) {
                    this.voices[0].noteOff(noteNumber, when, gainEG, filterEG);
                    this.voices.splice(0, 1)
                }
                noteNumber += this.octave * 12;
                var v;
                if (this._data.wave == "noise") {
                    v = new SympleVoice(this._data, 0, this._filterData, when, this._noiseBuffer)
                } else {
                    v = new SympleVoice(this._data, 0, this._filterData, when, null)
                }
                v.gain.connect(this.output);
                v.noteOn(noteNumber, velocity, when, gainEG, filterEG, pitchEG, transpose);
                if (this.lfoPitchGainNode && this._data.wave != "noise") {
                    this.lfoPitchGainNode.connect(v.source.frequency)
                }
                if (this.filterAmplitude && v.filter) {
                    this.filterAmplitude.connect(v.filter.frequency)
                }
                this.voices.push(v)
            };
            SympleOsc.prototype.noteOff = function(noteNumber, when, gainEG, filterEG) {
                if (!this.enabled) {
                    return
                }
                noteNumber += this.octave * 12;
                for (var ix = 0; ix < this.voices.length; ix++) {
                    if (this.voices[ix].active && this.voices[ix].activatedNote == noteNumber) {
                        this.voices[ix].noteOff(noteNumber, when, gainEG, filterEG);
                        this.voices.splice(ix, 1);
                        break
                    }
                }
            };
            SympleOsc.prototype.stopSoft = function(when, gainEG, filterEG) {
                for (var ix = 0; ix < this.voices.length; ix++) {
                    this.voices[ix].stopSoft(when, gainEG, filterEG)
                }
                this.voices = []
            };
            SympleOsc.prototype.stop = function() {
                for (var ix = 0, len = this.voices.length; ix < len; ix++) {
                    this.voices[ix].stop()
                }
                this.voices = []
            };
            SympleOsc.prototype.deschedule = function() {
                for (var ix = 0, len = this.voices.length; ix < len; ix++) {
                    if (this.voices[ix]) {
                        var source = this.voices[ix].source;
                        if (source.playbackState == 1 || source["startTime"] > Klang.context.currentTime) {
                            this.voices[ix].stop();
                            this.voices.splice(ix, 1);
                            ix--
                        }
                    }
                }
            };
            SympleOsc.prototype.setDetune = function(detune, when) {
                if (this._data) {
                    this._data.detune = detune;
                    for (var ix = 0, len = this.voices.length; ix < len; ix++) {
                        this.voices[ix].source.detune.setValueAtTime(detune, when)
                    }
                }
            };
            Object.defineProperty(SympleOsc.prototype, "enabled", {
                get: function() {
                    return this._enabled
                },
                set: function(state) {
                    this._enabled = state
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(SympleOsc.prototype, "detune", {
                get: function() {
                    return this._detune
                },
                enumerable: true,
                configurable: true
            });
            return SympleOsc
        }();
        var SympleLFO = function() {
            function SympleLFO(data, startTime) {
                this.osc = Klang.context.createOscillator();
                this.phaseDelay = Klang.context.createDelay();
                this.osc.type = data.wave || "sine";
                this.osc.frequency.value = data.rate || 1;
                this.phase = data.phase || 0;
                this.phaseDelay.delayTime.value = this.phase * (1 / this.osc.frequency.value);
                this.sync = data.sync;
                this.syncResolution = data.rate;
                this.osc.connect(this.phaseDelay);
                this.oscVolumeAmplitude = Klang.context.createGain();
                this.oscVolumeAmplitude.gain.value = data.osc_volume_amount;
                this.phaseDelay.connect(this.oscVolumeAmplitude);
                this.pitchAmplitude = Klang.context.createGain();
                this.pitchAmplitude.gain.value = data.pitch_amount;
                this.phaseDelay.connect(this.pitchAmplitude);
                this.filterAmplitude = Klang.context.createGain();
                this.filterAmplitude.gain.value = data.filter_amount;
                this.phaseDelay.connect(this.filterAmplitude);
                Klang.safari ? this.osc.noteOn(startTime) : this.osc.start(startTime)
            }
            SympleLFO.prototype.updateSync = function(bpm) {
                this.osc.frequency.value = bpm / 60 / this.syncResolution;
                this.phaseDelay.delayTime.value = this.phase * (1 / this.osc.frequency.value);
                return this
            };
            return SympleLFO
        }();
        var Symple = function(_super) {
            Klang.Util.__extends(Symple, _super);

            function Symple(data, name) {
                _super.call(this, data, name);
                this.bendRange = 400;
                var startTime = Klang.context.currentTime + Klang.Util.OSC_START_DELAY;
                this._gainEG = data.gain_eg;
                this._filterEG = data.filter_eg;
                this._pitchEG = data.pitch_eg;
                var disabledOscs = 0;
                if (!data.oscillators[0]) {
                    data.oscillators.push({
                        wave: "sine",
                        detune: 0,
                        volume: 1,
                        octave: 0
                    });
                    disabledOscs++
                }
                if (!data.oscillators[1]) {
                    data.oscillators.push({
                        wave: "sine",
                        detune: 0,
                        volume: 1,
                        octave: 0
                    });
                    disabledOscs++
                }
                this._oscs = [];
                this._poly = data.poly;
                for (var ix = 0, len = data.oscillators.length; ix < len; ix++) {
                    var o = new SympleOsc(data.oscillators[ix], data.poly, data.filter, startTime);
                    o.output.connect(this.output);
                    this._oscs.push(o)
                }
                if (disabledOscs == 1) {
                    this._oscs[1].enabled = false
                } else if (disabledOscs == 2) {
                    this._oscs[0].enabled = false;
                    this._oscs[1].enabled = false
                }
                if (data.LFO) {
                    this._LFO = new SympleLFO(data.LFO, startTime);
                    for (var ix = 0, len = this._oscs.length; ix < len; ix++) {
                        var osc = this._oscs[ix];
                        if (this._LFO.oscVolumeAmplitude) {
                            this._LFO.oscVolumeAmplitude.connect(osc.output.gain)
                        }
                        if (this._LFO.pitchAmplitude) {
                            osc.lfoPitchGainNode = this._LFO.pitchAmplitude
                        }
                        if (this._LFO.filterAmplitude) {
                            osc.filterAmplitude = this._LFO.filterAmplitude
                        }
                    }
                    if (this._LFO.sync) {
                        Klang.core.Core.instance.pushToPreLoadInitStack(this)
                    }
                }
                if (this._sync) {
                    Klang.core.Core.instance.pushToPreLoadInitStack(this)
                }
            }
            Symple.prototype.init = function() {
                if (this._LFO && this._LFO.sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._LFO.sync);
                    this._LFO.updateSync(seq.bpm);
                    seq.registerBPMSync(this._LFO)
                }
                if (this._sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    seq.registerSynth(this);
                    if (!seq.started) {
                        seq.start()
                    }
                }
            };
            Symple.prototype.handleMidiEvent = function(midiEvent, when, transpose, bypassArp) {
                when = when || Klang.context.currentTime;
                bypassArp = bypassArp || false;
                transpose = transpose || 0;
                if (midiEvent.type == "channel") {
                    if (midiEvent.subtype == "noteOn") {
                        if (this._arpMode != "off" && !bypassArp) {
                            this._activeVoices.push({
                                midiEvent: midiEvent,
                                transpose: transpose
                            });
                            this.handleArpModes(midiEvent);
                            return
                        }
                        for (var ix = 0, len = this._oscs.length; ix < len; ix++) {
                            this._oscs[ix].noteOn(midiEvent.noteNumber, midiEvent.velocity, when, this._gainEG, this._filterEG, this._pitchEG, transpose)
                        }
                    } else if (midiEvent.subtype == "noteOff") {
                        if (this._arpMode != "off" && !bypassArp) {
                            for (var i = 0; i < this._activeVoices.length; i++) {
                                if (midiEvent.noteNumber === this._activeVoices[i].midiEvent.noteNumber) {
                                    this._activeVoices.splice(i, 1)
                                }
                            }
                            this.handleArpModes(midiEvent);
                            return
                        }
                        for (var ix = 0, len = this._oscs.length; ix < len; ix++) {
                            this._oscs[ix].noteOff(midiEvent.noteNumber, when, this._gainEG, this._filterEG)
                        }
                    } else if (midiEvent.subtype == "pitchBend") {
                        var bend;
                        if (midiEvent.value !== undefined) {
                            bend = midiEvent.value
                        } else if (midiEvent.velocity !== undefined) {
                            bend = midiEvent.velocity
                        }
                        var currentPitch = (bend - 8192) / 16384 * this.bendRange;
                        for (var i = 0; i < this._oscs.length; i++) {
                            this._oscs[i].setDetune(currentPitch, when)
                        }
                    }
                }
                return this
            };
            Symple.prototype.glideTo = function(midiNotes, when, duration, transpose) {
                var now = Klang.Util.now();
                when = when || now;
                if (duration === undefined) {
                    duration = .5
                }
                transpose = transpose || 0;
                for (var o = 0, len = this._oscs.length; o < len; o++) {
                    var osc = this._oscs[o];
                    var voicesToUpdate = Math.min(midiNotes.length, osc.voices.length);
                    for (var v = 0; v < voicesToUpdate; v++) {
                        var voice = osc.voices[v];
                        var toFrequency = Klang.Util.midiNoteToFrequency(midiNotes[v] + transpose + osc.octave * 12);
                        if (voice.source.frequency) {
                            voice.source.frequency.linearRampToValueAtTime(toFrequency, when + duration)
                        }
                    }
                }
                return this
            };
            Symple.prototype.stop = function(when) {
                when = when || Klang.Util.now();
                for (var ix = 0, len = this._oscs.length; ix < len; ix++) {
                    this._oscs[ix].stopSoft(when, this._gainEG, this._filterEG)
                }
                if (this._activeVoices.length) {
                    this._activeVoices = []
                }
                this._arpVoices = []
            };
            Symple.prototype.deschedule = function() {
                for (var o = 0, len = this._oscs.length; o < len; o++) {
                    this._oscs[o].deschedule()
                }
                return this
            };
            Object.defineProperty(Symple.prototype, "filterFrequency", {
                set: function(val) {
                    for (var o = 0, len = this._oscs.length; o < len; o++) {
                        var osc = this._oscs[o];
                        osc._filterData.frequency = val;
                        for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                            var voice = osc.voices[v];
                            voice.filterTargetFreq = val;
                            if (!this._filterEG || this._filterEG.contour == 0) {
                                voice.filter.frequency.value = val
                            }
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "filterQ", {
                set: function(val) {
                    for (var o = 0, len = this._oscs.length; o < len; o++) {
                        var osc = this._oscs[o];
                        osc._filterData.Q = val;
                        for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                            var voice = osc.voices[v];
                            voice.filterTargetFreq = val;
                            if (!this._filterEG || this._filterEG.contour == 0) {
                                voice.filter.Q.value = val
                            }
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "filterType", {
                set: function(val) {
                    for (var o = 0, len = this._oscs.length; o < len; o++) {
                        var osc = this._oscs[o];
                        osc._filterData.filter_type = val;
                        for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                            var voice = osc.voices[v];
                            voice.filter.type = val
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "osc1Wave", {
                set: function(val) {
                    var osc = this._oscs[0];
                    osc._data.wave = val;
                    for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                        var voice = osc.voices[v];
                        if (voice.source.type && val !== "4") {
                            voice.source.type = val
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "osc1Vol", {
                set: function(val) {
                    var osc = this._oscs[0];
                    osc.output.gain.value = val
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "osc1Detune", {
                set: function(val) {
                    var osc = this._oscs[0];
                    osc._detune = val;
                    for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                        var voice = osc.voices[v];
                        if (voice.source.detune) {
                            voice.source.detune.value = val
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "osc1Octave", {
                set: function(val) {
                    var osc = this._oscs[0];
                    osc.octave = val
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "osc2Wave", {
                set: function(val) {
                    if (this._oscs.length < 2) {
                        return
                    }
                    var osc = this._oscs[1];
                    osc._data.wave = val;
                    for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                        var voice = osc.voices[v];
                        if (voice.source.type && val !== "4") {
                            voice.source.type = val
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "osc2Vol", {
                set: function(val) {
                    if (this._oscs.length < 2) {
                        return
                    }
                    var osc = this._oscs[1];
                    osc.output.gain.value = val
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "osc2Detune", {
                set: function(val) {
                    if (this._oscs.length < 2) {
                        return
                    }
                    var osc = this._oscs[1];
                    osc._detune = val;
                    for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                        var voice = osc.voices[v];
                        if (voice.source.detune) {
                            voice.source.detune.value = val
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "osc2Octave", {
                set: function(val) {
                    if (this._oscs.length < 2) {
                        return
                    }
                    var osc = this._oscs[1];
                    osc.octave = val
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "pitchDecay", {
                set: function(val) {
                    if (this._pitchEG) {
                        this._pitchEG.decay = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "pitchContour", {
                set: function(val) {
                    if (this._pitchEG) {
                        this._pitchEG.contour = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "lfoWave", {
                set: function(val) {
                    if (this._LFO) {
                        this._LFO.osc.type = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "lfoRate", {
                set: function(val) {
                    if (this._LFO) {
                        this._LFO.osc.frequency.value = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "lfoPhase", {
                set: function(val) {
                    if (this._LFO) {
                        this._LFO.phase = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "lfoOscVol", {
                set: function(val) {
                    if (this._LFO) {
                        this._LFO.oscVolumeAmplitude.gain.value = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "lfoPitch", {
                set: function(val) {
                    if (this._LFO) {
                        this._LFO.pitchAmplitude.gain.value = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "lfoFilter", {
                set: function(val) {
                    if (this._LFO) {
                        this._LFO.filterAmplitude.gain.value = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "arpMode", {
                set: function(val) {
                    this._arpMode = val
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Symple.prototype, "arpOctaves", {
                set: function(val) {
                    this._octaves = val
                },
                enumerable: true,
                configurable: true
            });
            Symple.prototype.setData = function(data) {
                _super.prototype.setData.call(this, data);
                this._poly = data.poly;
                this._gainEG = data.gain_eg ? data.gain_eg : undefined;
                this._filterEG = data.filter_eg ? data.filter_eg : undefined;
                this._pitchEG = data.pitch_eg ? data.pitch_eg : undefined;
                this._oscs[0].enabled = data.oscillators[0] ? true : false;
                this._oscs[1].enabled = data.oscillators[1] ? true : false;
                for (var o = 0, len = this._oscs.length; o < len; o++) {
                    var osc = this._oscs[o];
                    osc._data = data.oscillators[o];
                    osc._filterData = data.filter;
                    osc._poly = this._poly;
                    for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                        var voice = osc.voices[v];
                        if (data.filter) {
                            if (!voice.filterEnabled) {
                                voice.filterEnabled = true
                            }
                            if (!this._filterEG || this._filterEG.contour == 0) {
                                voice.filter.frequency.value = data.filter.frequency
                            }
                            voice.filter.Q.value = data.filter.Q;
                            voice.filterTargetFreq = data.filter.frequency;
                            voice.filter.type = data.filter.filter_type
                        } else if (voice.filterEnabled) {
                            voice.filterEnabled = false
                        }
                        if (data.oscillators && data.oscillators[o] && voice.voiceType == 0) {
                            if (voice.source.type && data.oscillators[0].wave !== 4) {
                                voice.source.type = data.oscillators[o].wave
                            }
                            if (voice.source.detune) {
                                voice.source.detune.value = data.oscillators[o].detune
                            }
                        }
                    }
                    if (data.oscillators[o]) {
                        osc.output.gain.value = data.oscillators[o].volume;
                        osc.octave = data.oscillators[o].octave
                    }
                }
                if (data.LFO) {
                    this._LFO.osc.type = data.LFO.wave;
                    this._LFO.osc.frequency.value = data.LFO.rate;
                    this._LFO.phase = data.LFO.phase;
                    this._LFO.phaseDelay.delayTime.value = data.LFO.phase * (1 / this._LFO.osc.frequency.value);
                    this._LFO.oscVolumeAmplitude.gain.value = data.LFO.osc_volume_amount;
                    this._LFO.pitchAmplitude.gain.value = data.LFO.pitch_amount;
                    this._LFO.filterAmplitude.gain.value = data.LFO.filter_amount
                } else {
                    this._LFO.oscVolumeAmplitude.gain.value = 0;
                    this._LFO.pitchAmplitude.gain.value = 0;
                    this._LFO.filterAmplitude.gain.value = 0
                }
            };
            return Symple
        }(Klang.Model.Synth);
        return Klang.Model.Symple = Symple
    });
    Module(function(Klang) {
        var audioUtil = Klang.engines.webAudio.Util;
        var MonoSynth = function(_super) {
            Klang.Util.__extends(MonoSynth, _super);

            function MonoSynth(data, name) {
                _super.call(this, data, name);
                var startTime = Klang.context.currentTime + Klang.Util.OSC_START_DELAY;
                this._gainEG = data.gain_eg;
                this._pitchEG = data.pitch_eg;
                this._osc = null;
                if (this._sync) {
                    Klang.core.Core.instance.pushToPreLoadInitStack(this)
                }
            }
            MonoSynth.prototype.init = function() {
                if (this._sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    seq.registerSynth(this);
                    if (!seq.started) {
                        seq.start()
                    }
                }
            };
            MonoSynth.prototype.trigger = function(note) {
                var midiEvent = {
                    noteNumber: note,
                    when: Klang.context.currentTime
                };
                this.noteOn(midiEvent.noteNumber, midiEvent.when);
                this.noteOff(midiEvent.noteNumber, midiEvent.when)
            };
            MonoSynth.prototype.noteOn = function(noteNumber, velocity, when, transpose) {
                when = Math.max(when || 0, Klang.context.currentTime);
                transpose = transpose || 0;
                if (!this._osc) {
                    this._osc = Klang.context.createOscillator();
                    this._oscGain = Klang.context.createGain();
                    this._osc.connect(this._oscGain);
                    this._oscGain.connect(this.output);
                    this._osc.start(when);
                    this._oscGain.gain.value = 0;
                    this._osc.type = this.data.oscillators[0].wave
                }
                var f = Klang.Util.midiNoteToFrequency(noteNumber + transpose + this.data.oscillators[0].octave * 12);
                var v = this._oscGain.gain.value;
                var oldF = this._osc.frequency.value;
                var smoothingTime = .02;
                this._oscGain.gain.cancelScheduledValues(when);
                this._osc.frequency.cancelScheduledValues(when);
                this._osc.frequency.setValueAtTime(oldF, when);
                this._oscGain.gain.setValueAtTime(v, when);
                this._oscGain.gain.linearRampToValueAtTime(0, when + smoothingTime);
                this._osc.frequency.linearRampToValueAtTime(f, when + smoothingTime);
                this.smoothingTime = smoothingTime;
                var attackVal = smoothingTime + this._gainEG.attack + .01;
                this._oscGain.gain.linearRampToValueAtTime(1, when + attackVal);
                var decayVal = attackVal + this._gainEG.decay;
                this._oscGain.gain.linearRampToValueAtTime(this._gainEG.sustain, when + decayVal);
                this._adEndTime = when + decayVal;
                return this
            };
            MonoSynth.prototype.noteOff = function(noteNumber, when) {
                when = Math.max(when || 0, this._adEndTime + 1e-4);
                var attackVal = this.smoothingTime + this._gainEG.attack + .01;
                var release = attackVal + this.smoothingTime + this._gainEG.release;
                this._oscGain.gain.linearRampToValueAtTime(0, when + release)
            };
            MonoSynth.prototype.handleMidiEvent = function(midiEvent, when, transpose, bypassArp) {
                when = when || Klang.context.currentTime;
                transpose = transpose || 0;
                if (midiEvent.type == "channel") {
                    if (midiEvent.subtype == "noteOn") {
                        this.noteOn(midiEvent.noteNumber, midiEvent.velocity, when, transpose)
                    } else if (midiEvent.subtype == "noteOff") {
                        this.noteOff(midiEvent.noteNumber, when, this._gainEG)
                    } else if (midiEvent.subtype == "pitchBend") {
                        var bend;
                        if (midiEvent.value !== undefined) {
                            bend = midiEvent.value
                        } else if (midiEvent.velocity !== undefined) {
                            bend = midiEvent.velocity
                        }
                        var currentPitch = (bend - 8192) / 16384 * this.bendRange;
                        for (var i = 0; i < this._oscs.length; i++) {
                            this._oscs[i].setDetune(currentPitch, when)
                        }
                    }
                }
                return this
            };
            MonoSynth.prototype.glideTo = function(midiNotes, when, duration, transpose) {
                var now = Klang.Util.now();
                when = when || now;
                if (duration === undefined) {
                    duration = .5
                }
                transpose = transpose || 0;
                for (var o = 0, len = this._oscs.length; o < len; o++) {
                    var osc = this._oscs[o];
                    var voicesToUpdate = Math.min(midiNotes.length, osc.voices.length);
                    for (var v = 0; v < voicesToUpdate; v++) {
                        var voice = osc.voices[v];
                        var toFrequency = Klang.Util.midiNoteToFrequency(midiNotes[v] + transpose + osc.octave * 12);
                        if (voice.source.frequency) {
                            voice.source.frequency.linearRampToValueAtTime(toFrequency, when + duration)
                        }
                    }
                }
                return this
            };
            MonoSynth.prototype.stop = function(when) {
                when = when || Klang.Util.now();
                this._osc.stop();
                this._osc.disconnect();
                this._oscGain.disconnect();
                this._osc = null
            };
            MonoSynth.prototype.deschedule = function() {};
            Object.defineProperty(MonoSynth.prototype, "osc1Wave", {
                set: function(val) {
                    var osc = this._oscs[0];
                    osc._data.wave = val;
                    for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                        var voice = osc.voices[v];
                        if (voice.source.type && val !== "4") {
                            voice.source.type = val
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MonoSynth.prototype, "osc1Vol", {
                set: function(val) {
                    var osc = this._oscs[0];
                    osc.output.gain.value = val
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MonoSynth.prototype, "osc1Detune", {
                set: function(val) {
                    var osc = this._oscs[0];
                    osc._detune = val;
                    for (var v = 0, vlen = osc.voices.length; v < vlen; v++) {
                        var voice = osc.voices[v];
                        if (voice.source.detune) {
                            voice.source.detune.value = val
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MonoSynth.prototype, "osc1Octave", {
                set: function(val) {
                    var osc = this._oscs[0];
                    osc.octave = val
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MonoSynth.prototype, "pitchDecay", {
                set: function(val) {
                    if (this._pitchEG) {
                        this._pitchEG.decay = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(MonoSynth.prototype, "pitchContour", {
                set: function(val) {
                    if (this._pitchEG) {
                        this._pitchEG.contour = val
                    }
                },
                enumerable: true,
                configurable: true
            });
            MonoSynth.prototype.setData = function(data) {
                _super.prototype.setData.call(this, data);
                this._poly = data.poly;
                this._gainEG = data.gain_eg ? data.gain_eg : undefined;
                this._pitchEG = data.pitch_eg ? data.pitch_eg : undefined;
                if (this._osc) {
                    this._osc.type = data.oscillators[0].wave
                }
            };
            return MonoSynth
        }(Klang.Model.Synth);
        return Klang.Model.MonoSynth = MonoSynth
    });
    Module(function(Klang) {
        var SamplePlayer = function(_super) {
            Klang.Util.__extends(SamplePlayer, _super);

            function SamplePlayer(data, name) {
                _super.call(this, data, name);
                this._content = [];
                this._playingVoices = [];
                this._allVoices = [];
                this._hasNoteOffSamples = false;
                this._hasSustainOnSamples = false;
                this._hasSustainOffSamples = false;
                this._useEnvelope = true;
                this._pitchBendRange = .25;
                this._pedalOnTime = -1;
                this._sustained = [];
                this._maxNotes = 20;
                this._stopFactor = 5;
                this._content = Klang.Util.cloneObject(data.content);
                this._volumeCurve = data.volume_curve || "none";
                this._gainEG = data.eg_gain || {
                    attack: 0,
                    decay: 0,
                    sustain: 1,
                    release: .005
                };
                this._currentPitch = 1;
                Klang.core.Core.instance.pushToPreLoadInitStack(this)
            }
            SamplePlayer.prototype.init = function() {
                this._envelope = Klang.context.createGain();
                for (var ix = 0, len = this._content.length; ix < len; ix++) {
                    if (this._content[ix].value === "noteOff") {
                        this._hasNoteOffSamples = true
                    }
                    if (this._content[ix].value === "sustainOn") {
                        this._hasSustainOnSamples = true
                    }
                    if (this._content[ix].value === "sustainOff") {
                        this._hasSustainOffSamples = true
                    }
                    for (var j = 0; j < this._content[ix].samples.length; j++) {
                        this._content[ix].samples[j].source = Klang.core.Core.instance.findInstance(this._content[ix].samples[j].source);
                        if (!this._content[ix].samples[j].source) {
                            Klang.warn("SamplePlayer: audio source not found")
                        }
                        this._content[ix].samples[j].source._parentType = "SamplePlayer";
                        if (this._content[ix].samples[j].source.loop) {
                            this._loopedSamples = true
                        }
                    }
                }
                if (this._sync) {
                    var seq = Klang.core.Core.instance.findInstance(this._sync);
                    seq.registerSynth(this);
                    if (!seq.started) {}
                }
            };
            SamplePlayer.prototype.handleMidiEvent = function(midiEvent, when, transpose, bypassArp, insertBus) {
                when = when || Klang.Util.now();
                bypassArp = bypassArp || false;
                transpose = transpose || 0;
                if (midiEvent.type == "channel") {
                    if (midiEvent.subtype == "noteOn") {
                        if (this._arpMode != "off" && !bypassArp) {
                            this._activeVoices.push({
                                midiEvent: midiEvent,
                                transpose: transpose
                            });
                            this.handleArpModes(midiEvent);
                            return
                        }
                        this.noteOn(when, midiEvent.noteNumber, transpose, midiEvent.velocity, midiEvent.subtype, false, insertBus);
                        if (this._callback) {
                            this._callback(midiEvent, when)
                        }
                    } else if (midiEvent.subtype == "noteOff") {
                        if (this._arpMode != "off" && !bypassArp) {
                            for (var i = 0; i < this._activeVoices.length; i++) {
                                if (midiEvent.noteNumber === this._activeVoices[i].midiEvent.noteNumber) {
                                    this._activeVoices.splice(i, 1)
                                }
                            }
                            this.handleArpModes(midiEvent);
                            return
                        }
                        this.noteOff(when, midiEvent.noteNumber, midiEvent.velocity, midiEvent.subtype);
                        if (this._callback) {
                            this._callback(midiEvent, when)
                        }
                    } else if (midiEvent.subtype == "pitchBend") {
                        var bend = midiEvent.value;
                        this._currentPitch = 1 + (bend - 64) / 127;
                        for (var i = 0; i < this._playingVoices.length; i++) {
                            this._playingVoices[i].source.playbackRateNode.setValueAtTime(this._currentPitch, when)
                        }
                    } else if (midiEvent.subtype == "controller") {
                        var controllerType = midiEvent.controllerType || midiEvent.noteNumber;
                        var value = midiEvent.value === undefined ? midiEvent.velocity : midiEvent.value;
                        switch (controllerType) {
                            case 1:
                                break;
                            case 64:
                                if (value < 64) {
                                    this.pedalRelease(when);
                                    if (when > this._pedalOnTime) {
                                        this._pedalOnTime = -1
                                    }
                                    if (this._hasSustainOffSamples) {
                                        this.noteOn(when, 0, 0, 127, "sustainOff")
                                    }
                                } else if (value > 64) {
                                    this._pedalOnTime = when;
                                    if (this._hasSustainOnSamples) {
                                        this.noteOn(when, 0, 0, 127, "sustainOn")
                                    }
                                }
                                break;
                            default:
                        }
                    }
                }
                return this
            };
            SamplePlayer.prototype.noteOn = function(when, midiNote, transpose, velocity, value, volume, insertBus) {
                for (var ix = 0; ix < this._allVoices.length; ix++) {
                    var v = this._allVoices[ix];
                    if (v.source._sources.length == 0 || v.source.lastSource.playbackState == 3) {
                        this._allVoices.splice(ix, 1);
                        ix--
                    }
                }
                var note = this.getNote(midiNote + transpose, velocity, value);
                var targetPitch = Klang.Util.midiNoteToFrequency(midiNote + transpose);
                var rootPitch = Klang.Util.midiNoteToFrequency(note.root);
                var rate = targetPitch / rootPitch;
                if (note.root === -1) {
                    rate = 1
                }
                var copy = new Klang.Model.AudioSource(note.source.data, midiNote.toString());
                if (value === "noteOn") {
                    var newVoice = {
                        source: copy,
                        time: when,
                        velocity: velocity,
                        note: midiNote,
                        transpose: transpose
                    };
                    this._playingVoices.push(newVoice);
                    this._allVoices.push(newVoice)
                }
                if (insertBus) {
                    copy.connect(insertBus.input)
                } else if (this.destinationName) {
                    copy.connect(Klang.core.Core.instance.findInstance(this.destinationName).input)
                } else {
                    copy.connect(Klang.core.Core.instance.findInstance(copy.destinationName).input)
                }
                when = when < Klang.Util.now() ? Klang.Util.now() : when;
                var vol = 0;
                if (volume) {
                    vol = volume * velocity / 128
                } else if (this._volumeCurve === "linear") {
                    vol = velocity / 128
                } else if (this._volumeCurve === "exponential") {
                    vol = Math.abs(1 - Math.exp(velocity / 128))
                } else if (this._volumeCurve === "none") {
                    vol = 1
                }
                vol *= copy.output.gain.value;
                copy.output.gain.cancelScheduledValues(when);
                copy.nextPlaybackRate = rate * this._currentPitch;
                copy.play(when);
                if (this._useEnvelope) {
                    var attackWhen = Math.max(when + 1e-4, when + this._gainEG.attack);
                    copy.output.gain.setValueAtTime(0, when);
                    copy.output.gain.linearRampToValueAtTime(vol, attackWhen);
                    if (copy.output.gain.setTargetAtTime) {
                        copy.output.gain.setTargetAtTime(vol * this._gainEG.sustain, attackWhen, this._gainEG.decay || .01)
                    } else if (copy.output.gain.setTargetValueAtTime) {
                        copy.output.gain.setTargetValueAtTime(vol * this._gainEG.sustain, attackWhen, this._gainEG.decay)
                    }
                } else {
                    copy.output.gain.setValueAtTime(vol, when);
                    copy.output.gain.value = vol
                }
            };
            SamplePlayer.prototype.adsr = function(attack, decay, sustain, release) {
                var eg = this._gainEG;
                if (arguments.length === 0) {
                    return {
                        attack: eg.attack,
                        decay: eg.decay,
                        sustain: eg.sustain,
                        release: eg.release
                    }
                } else {
                    eg.attack = arguments[0] === undefined ? eg.attack : arguments[0];
                    eg.decay = arguments[1] === undefined ? eg.decay : arguments[1];
                    eg.sustain = arguments[2] === undefined ? eg.sustain : arguments[2];
                    eg.release = arguments[3] === undefined ? eg.release : arguments[3]
                }
                return this
            };
            SamplePlayer.prototype.noteOff = function(when, midiNote, velocity, value) {
                var note = this.getNote(midiNote, velocity, "noteOn");
                for (var i = 0; i < this._playingVoices.length; i++) {
                    if (!midiNote || midiNote.toString() === this._playingVoices[i].source._name) {
                        if (when > this._pedalOnTime && this._pedalOnTime > 0) {
                            if (this._sustained.length > this._maxNotes) {
                                this._sustained[0].source.stop(when + this._gainEG.release * this._stopFactor);
                                this._sustained.splice(0, 1)
                            }
                            this._sustained.push(this._playingVoices[i]);
                            this._playingVoices.splice(i, 1)
                        } else {
                            if (when < Klang.Util.now()) {
                                when = Klang.Util.now()
                            }
                            if (this._useEnvelope) {
                                var val = this._playingVoices[i].source.output.gain.value;
                                this._playingVoices[i].source.output.gain.cancelScheduledValues(when);
                                if (when != Klang.Util.now() || Klang.detector.browser["name"] == "Firefox") {
                                    this._playingVoices[i].source.output.gain.setValueAtTime(this._gainEG.sustain, when)
                                } else {
                                    this._playingVoices[i].source.output.gain.setValueAtTime(val, when)
                                }
                                this._playingVoices[i].source.stop(when + this._gainEG.release * this._stopFactor);
                                this._playingVoices[i].source.output.gain.setTargetAtTime(0, when, this._gainEG.release)
                            } else {
                                this._playingVoices[i].source.fadeOutAndStop(this._gainEG.release, when)
                            }
                            if (this._hasNoteOffSamples) {
                                var t = Klang.Util.now() - this._playingVoices[i].time;
                                var v = Math.min(Math.exp(-t) / 3, 1);
                                this.noteOn(when, midiNote, this._playingVoices[i].transpose, this._playingVoices[i].velocity, value, v)
                            }
                            this._playingVoices.splice(i, 1)
                        }
                    }
                }
            };
            SamplePlayer.prototype.stop = function(when) {
                var when = when || Klang.Util.now();
                this.pedalRelease(when);
                for (var i = 0; i < this._playingVoices.length; i++) {
                    if (when < Klang.Util.now()) {
                        when = Klang.Util.now()
                    }
                    var val = this._playingVoices[i].source.output.gain.value;
                    this._playingVoices[i].source.output.gain.cancelScheduledValues(when);
                    this._playingVoices[i].source.output.gain.setValueAtTime(val, when);
                    this._playingVoices[i].source.stop(when + this._gainEG.release * this._stopFactor);
                    this._playingVoices[i].source.output.gain.setTargetAtTime(0, when, this._gainEG.release)
                }
                this._playingVoices = [];
                this._arpVoices = [];
                return this
            };
            SamplePlayer.prototype.deschedule = function() {
                for (var i = 0; i < this._allVoices.length; i++) {
                    this._allVoices[i].source.deschedule()
                }
                return this
            };
            SamplePlayer.prototype.pedalRelease = function(when) {
                for (var i = 0; i < this._sustained.length; i++) {
                    if (when < Klang.Util.now()) {
                        when = Klang.Util.now()
                    }
                    if (Klang.detector.browser["name"] == "Firefox") {
                        this._sustained[i].source.output.gain.linearRampToValueAtTime(0, when + .3);
                        this._sustained[i].source.stop(when + this._gainEG.release * this._stopFactor);
                        continue
                    }
                    this._sustained[i].source.output.gain.setTargetAtTime(0, when, this._gainEG.release);
                    if (this._hasNoteOffSamples) {
                        var t = Klang.Util.now() - this._sustained[i].time;
                        var v = Math.min(Math.exp(-t) / 3, 1);
                        this.noteOn(when, this._sustained[i].note, this._sustained[i].transpose, this._sustained[i].velocity, "noteOff", v)
                    }
                }
                this._sustained = []
            };
            SamplePlayer.prototype.getNote = function(note, velocity, value) {
                var i = 0;
                var val = this._content[i].value || "noteOn";
                while (velocity > this._content[i].highVelocity || value !== this._content[i].value) {
                    i++
                }
                var velocityLayer = i;
                var j = 0;
                while (note < this._content[velocityLayer].samples[j].startRange || note > this._content[velocityLayer].samples[j].endRange) {
                    j++
                }
                return this._content[velocityLayer].samples[j]
            };
            Object.defineProperty(SamplePlayer.prototype, "content", {
                get: function() {
                    return this._content
                },
                set: function(value) {
                    this._content = value;
                    this.init()
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(SamplePlayer.prototype, "callbackFunction", {
                set: function(func) {
                    this._callback = func
                },
                enumerable: true,
                configurable: true
            });
            SamplePlayer.prototype.setData = function(data) {
                _super.prototype.setData.call(this, data);
                if (data.eg_gain) {
                    this._gainEG = data.eg_gain
                }
                if (data.content) {
                    this._content = data.content;
                    this.init()
                }
                if (data.volumeCurve) {
                    this._volumeCurve = data.volumeCurve
                }
            };
            return SamplePlayer
        }(Klang.Model.Synth);
        return Klang.Model.SamplePlayer = SamplePlayer
    });
    Module(function(Klang) {
        (function(SyncType) {
            SyncType._map = [];
            SyncType._map[0] = "Restart";
            SyncType.Restart = 0;
            SyncType._map[1] = "Playing";
            SyncType.Playing = 1;
            SyncType._map[2] = "All";
            SyncType.All = 2;
            SyncType._map[3] = "Continue";
            SyncType.Continue = 3
        })(Klang.Model.SyncType || (Klang.Model.SyncType = {}))
    });
    Module(function(Klang) {
        var SyncType = Klang.Model.SyncType;
        var Sequencer = function(_super) {
            Klang.Util.__extends(Sequencer, _super);

            function Sequencer(data, name) {
                _super.call(this);
                this._scheduler = null;
                this._started = false;
                this._bpm = 120;
                this._barLength = 4;
                this._beatLength = 1;
                this._resolution = .25;
                this._currentStep = 0;
                this._paused = false;
                this._maxSwing = .08;
                this._swingFactor = 0;
                this._lastBeat = -1;
                this._name = name;
                this._type = data.type;
                this._bpm = data.bpm || 120;
                this._barLength = data.bar_length || 4;
                this._beatLength = data.beat_length || 1;
                this._registeredPatterns = [];
                this._registeredSynths = [];
                this._syncHandler = new Klang.core.SyncHandler;
                this._syncedObjects = [];
                this._swingFactor = data.swing_factor || 0;
                Klang.core.Core.instance.pushToPreLoadInitStack(this)
            }
            Sequencer.prototype.init = function() {
                this._lookahead = Klang.core.Core.settings.sequencer_lookahead || 50;
                this._scheduleAheadTime = Klang.core.Core.settings.sequencer_schedule_ahead || .2;
                if (Klang.isIOS) {
                    this._scheduleAheadTime = Klang.core.Core.settings.sequencer_schedule_ahead_ios || 5
                }
                this._resolution = Klang.core.Core.settings.sequencer_resolution || .25
            };
            Sequencer.prototype.startScheduler = function() {
                if (!this._paused && Klang.context.currentTime !== 0) {
                    this._lastScheduleLoopTime = Klang.context.currentTime;
                    while (this._scheduleTime < Klang.context.currentTime + this._scheduleAheadTime) {
                        for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                            this._registeredPatterns[ix].update(this._currentStep, this._scheduleTime)
                        }
                        for (var jx = 0, len = this._registeredSynths.length; jx < len; jx++) {
                            this._registeredSynths[jx].update(this._currentStep, this._scheduleTime)
                        }
                        var currentStep = this.currentStep;
                        var currentBeat = Math.floor(currentStep);
                        if (currentBeat !== this._lastBeat) {
                            this.trigger("beforeNextBeat", currentBeat, this.getBeatTime(0) - this._scheduleTime, this._scheduleTime)
                        }
                        this._lastBeat = currentBeat;
                        this.trigger("progress", this._currentStep, this.getBeatTime(0) - this._scheduleTime, this._scheduleTime);
                        this._currentStep += this._resolution;
                        this._syncHandler.update(this._resolution);
                        if (this._swingFactor > 0) {
                            if (this._currentStep * 4 % 2) {
                                this._scheduleTime += (.25 + this._maxSwing * this._swingFactor) * (60 / this._bpm)
                            } else {
                                this._scheduleTime += (.25 - this._maxSwing * this._swingFactor) * (60 / this._bpm)
                            }
                        } else {
                            this._scheduleTime += 60 / this._bpm * this._resolution
                        }
                    }
                }
                var _this = this;
                this._scheduler = setTimeout(function() {
                    _this.startScheduler()
                }, _this._lookahead)
            };
            Sequencer.prototype.start = function() {
                if (!this._started) {
                    this._started = true;
                    this._scheduleTime = Klang.context.currentTime;
                    if (this._scheduleAheadTime <= .2) {
                        this._scheduleTime += .3
                    }
                    clearTimeout(this._scheduler);
                    this.startScheduler();
                    if (Klang.core.Core.callbacks && Klang.core.Core.callbacks.startSequencer) {
                        Klang.core.Core.callbacks.startSequencer({
                            name: this._name
                        })
                    }
                    this.trigger("start")
                }
                return this
            };
            Sequencer.prototype.pause = function() {
                this._paused = true;
                this._pauseOffset = this._scheduleTime - Klang.Util.now();
                for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                    this._registeredPatterns[ix].pause()
                }
                this.trigger("pause");
                return this
            };
            Sequencer.prototype.unpause = function() {
                this._paused = false;
                this._scheduleTime = Klang.Util.now() + this._pauseOffset;
                for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                    this._registeredPatterns[ix].unpause()
                }
                return this
            };
            Sequencer.prototype.reschedule = function() {
                clearTimeout(this._scheduler);
                var scheduled = this._scheduleTime - Klang.context.currentTime;
                var resolutionTime = this.getNoteTime(this._resolution);
                var scheduleOffset = scheduled > this._scheduleAheadTime ? scheduled - this._scheduleAheadTime : scheduled - (this._scheduleAheadTime - resolutionTime);
                var realScheduledSteps = (scheduled - scheduled % resolutionTime) / resolutionTime / (this._beatLength / this._resolution);
                var scheduledSteps = this._scheduleAheadTime / resolutionTime / (this._beatLength / this._resolution);
                this._scheduleTime = Klang.context.currentTime + scheduleOffset;
                if (realScheduledSteps < scheduledSteps) {
                    this._scheduleTime -= resolutionTime
                }
                this._currentStep -= scheduledSteps;
                if (isNaN(this._currentStep) || this._currentStep < 0) {
                    this._currentStep = 0
                }
                for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                    this._registeredPatterns[ix].deschedule(scheduledSteps)
                }
                this.startScheduler();
                return this
            };
            Sequencer.prototype.stop = function() {
                this._started = false;
                clearTimeout(this._scheduler);
                this._scheduler = null;
                this._started = false;
                if (Klang.core.Core.callbacks && Klang.core.Core.callbacks.stopSequencer) {
                    Klang.core.Core.callbacks.stopSequencer({
                        name: this._name
                    })
                }
                return this
            };
            Sequencer.prototype.stopAll = function(params) {
                var exceptions = [];
                for (var _i = 0; _i < arguments.length - 1; _i++) {
                    exceptions[_i] = arguments[_i + 1]
                }
                var beat = params.beat !== undefined ? params.beat : 4;
                var fadeTime = params.fadeTime === undefined ? 0 : params.fadeTime;
                var forceFade = params.forceFade || false;
                var wait = params.wait || 0;
                for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                    if (exceptions.indexOf(this._registeredPatterns[ix]) == -1) {
                        this._registeredPatterns[ix].forceFade = forceFade;
                        this._registeredPatterns[ix].stop(beat, true, fadeTime, wait)
                    }
                }
                return this
            };
            Sequencer.prototype.restart = function() {
                this._currentStep = 0;
                return this
            };
            Sequencer.prototype.registerPattern = function(pattern) {
                this._registeredPatterns.push(pattern);
                return this
            };
            Sequencer.prototype.unregisterPattern = function(pattern) {
                var index = this._registeredPatterns.indexOf(pattern);
                this._registeredPatterns.splice(index, 1);
                return this
            };
            Sequencer.prototype.registerSynth = function(synth) {
                if (this._registeredSynths.indexOf(synth) == -1) {
                    this._registeredSynths.push(synth)
                }
                return this
            };
            Sequencer.prototype.unregisterSynth = function(synth) {
                var index = this._registeredPatterns.indexOf(synth);
                this._registeredSynths.splice(index, 1);
                return this
            };
            Sequencer.prototype.sync = function(process, beatModifier, args) {
                return this.syncInSteps(process, this.getStepsToNext(this.beatLength * beatModifier), args)
            };
            Sequencer.prototype.syncInSteps = function(process, steps, args) {
                if (!this._started) {
                    this.start()
                }
                var scheduleTime = this.getNoteTime(steps) + this._scheduleTime;
                if (!args) {
                    args = [scheduleTime]
                } else if (args.length) {
                    args.push(scheduleTime)
                }
                this._syncHandler.addSyncCountdown(new SyncCountdown(steps, process, args));
                return this
            };
            Sequencer.prototype.quantizeDuration = function(duration, beat) {
                var bpm = this.bpm;
                var spb = 60 / bpm;
                var bps = bpm / 60;
                var beatLen = Math.floor(duration / spb);
                return beatLen * bps
            };
            Sequencer.prototype.syncCallback = function(cb, beatQuant, offset, minOffset) {
                minOffset = minOffset || 0;
                offset = offset || 0;
                beatQuant = beatQuant || 1;
                var _this = this;
                var handler = function(scheduleBeat, _, scheduleTime) {
                    var beatMod = scheduleBeat % beatQuant;
                    if (beatMod === offset && minOffset <= 0) {
                        _this.off("beforeNextBeat", handler);
                        cb(scheduleBeat, scheduleTime)
                    }
                    minOffset--
                };
                this._syncCallbackContext = this._syncCallbackContext || {
                    ctx: this
                };
                this.on("beforeNextBeat", handler, this._syncCallbackContext)
            };
            Sequencer.prototype.cancelCallbacks = function() {
                if (this._syncCallbackContext) {
                    this.off("beforeNextBeat", null, this._syncCallbackContext)
                }
            };
            Sequencer.prototype.syncPattern = function(params) {
                var patterns = [];
                for (var _i = 0; _i < arguments.length - 1; _i++) {
                    patterns[_i] = arguments[_i + 1]
                }
                var beat = params.beat || 0;
                var fadeIn = params.fadeIn || false;
                var duration = params.duration || 1;
                var absolute = params.absolute === undefined ? false : params.absolute;
                var syncType = params.syncType !== undefined ? params.syncType : 3;
                var offset = params.offset;
                var wait = params.wait || 0;
                var steps;
                var first;
                if (!this._started) {
                    this._currentStep = 0;
                    this.start();
                    steps = beat = 0;
                    first = true
                }
                var syncStep;
                var restart = false;
                if (syncType === SyncType.Restart) {
                    syncStep = 0;
                    restart = true
                } else if (syncType === SyncType.Playing) {
                    var longest = 0;
                    var longestId = -1;
                    for (var ix = 0, len = patterns.length; ix < len; ix++) {
                        if (patterns[ix].state === 1) {
                            if (patterns[ix].length > longest) {
                                longest = patterns[ix].length;
                                longestId = ix
                            }
                        }
                    }
                    var nextBar = 0;
                    if (longestId > -1) {
                        nextBar = patterns[longestId].getNextBar(beat)
                    }
                    syncStep = nextBar * beat;
                    if (nextBar > 0 && wait > 0) {
                        syncStep += wait
                    }
                } else if (syncType === SyncType.All) {
                    var longest = 0;
                    var longestId = -1;
                    for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                        if (this._registeredPatterns[ix].state === 1 || this._registeredPatterns[ix].state === 2) {
                            if (this._registeredPatterns[ix].length > longest) {
                                longest = this._registeredPatterns[ix].length;
                                longestId = ix
                            }
                        }
                    }
                    var nextStep = 0;
                    if (longestId > -1) {
                        nextStep = this._currentStep + this.getStepsToNext(beat)
                    }
                    syncStep = nextStep
                } else if (syncType === SyncType.Continue) {
                    syncStep = 0;
                    restart = first ? true : false
                }
                if (absolute != false) {
                    if (typeof absolute == "number") {
                        steps = this.getStepsToNext(this.beatLength * absolute) + this.beatLength * beat
                    } else {
                        steps = this.beatLength * beat
                    }
                } else {
                    if (beat > 0) {
                        steps = this.getStepsToNext(this.beatLength * beat)
                    } else if (beat == 0) {
                        steps = 0
                    }
                }
                if (wait > 0) {
                    steps += wait
                }
                for (var ix = 0, len = patterns.length; ix < len; ix++) {
                    if (offset !== undefined) {
                        offset = this.getNoteTime(offset)
                    }
                    patterns[ix].prePlaySchedule(steps, syncStep, restart, fadeIn, duration, offset)
                }
                if (first) {
                    var scheduled = this._scheduleTime - Klang.context.currentTime;
                    var resolutionTime = this.getNoteTime(this._resolution);
                    var scheduleOffset = scheduled > this._scheduleAheadTime ? scheduled - this._scheduleAheadTime : scheduled - (this._scheduleAheadTime - resolutionTime);
                    this._scheduleTime = Klang.context.currentTime + scheduleOffset;
                    if (restart) {
                        this._currentStep = patterns[0]._currentStep - this._resolution
                    } else {
                        this._currentStep = patterns[0]._currentStep
                    }
                    first = false
                } else if (this._scheduleAheadTime > .5) {
                    this.reschedule()
                }
                return this
            };
            Sequencer.prototype.registerBPMSync = function(obj) {
                if (this._syncedObjects.indexOf(obj) == -1) {
                    this._syncedObjects.push(obj)
                }
                return this
            };
            Sequencer.prototype.unregisterBPMSync = function(obj) {
                var index = this._syncedObjects.indexOf(obj);
                if (index != -1) {
                    this._syncedObjects.splice(index, 1)
                }
                return this
            };
            Sequencer.prototype.getStepsToNext = function(x) {
                if (x == 0) {
                    return 0
                }
                return x - this._currentStep % x
            };
            Sequencer.prototype.getNoteTime = function(note) {
                if (note === undefined) {
                    note = 1
                }
                return 60 / this._bpm * note
            };
            Sequencer.prototype.getBeatTime = function(x) {
                return this.getNoteTime(this.getStepsToNext(x)) + this._scheduleTime
            };
            Object.defineProperty(Sequencer.prototype, "started", {
                get: function() {
                    return this._started
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "paused", {
                get: function() {
                    return this._paused
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "bpm", {
                get: function() {
                    return this._bpm
                },
                set: function(value) {
                    this._bpm = value;
                    for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                        if (this._registeredPatterns[ix]._type == "MidiPattern") {
                            this._registeredPatterns[ix].recalculateBPM(this._bpm)
                        }
                    }
                    for (var ix = 0, len = this._syncedObjects.length; ix < len; ix++) {
                        this._syncedObjects[ix].updateSync(this._bpm)
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "scale", {
                set: function(scale) {
                    for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                        if (this._registeredPatterns[ix]._type == "MidiPattern") {
                            this._registeredPatterns[ix].scale = scale
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "customScale", {
                set: function(obj) {
                    for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                        if (this._registeredPatterns[ix]._type == "MidiPattern") {
                            this._registeredPatterns[ix].customScale = obj
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "transpose", {
                set: function(transpose) {
                    for (var ix = 0, len = this._registeredPatterns.length; ix < len; ix++) {
                        if (this._registeredPatterns[ix]._type == "MidiPattern") {
                            if (transpose === 0) {
                                this._registeredPatterns[ix].resetTranspose()
                            } else {
                                this._registeredPatterns[ix].transpose += transpose
                            }
                        }
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "resolution", {
                get: function() {
                    return this._resolution
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "barLength", {
                get: function() {
                    return this._barLength
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "beatLength", {
                get: function() {
                    return this._beatLength
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "currentStep", {
                get: function() {
                    return this._currentStep
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(Sequencer.prototype, "swingFactor", {
                set: function(val) {
                    this._swingFactor = val
                },
                enumerable: true,
                configurable: true
            });
            Sequencer.prototype.setData = function(data) {
                this._bpm = data.bpm || 120;
                this._barLength = data.measure_length || 4;
                this._beatLength = data.beat_length || 1;
                this._swingFactor = data.swing_factor || 0
            };
            return Sequencer
        }(Klang.core.EventEmitter);
        return Klang.Model.Sequencer = Sequencer
    });
    Module(function(Klang) {
        var Process = function() {
            function Process(data) {
                this._vars = data.vars;
                Klang.core.Core.instance.pushToPreLoadInitStack(this)
            }
            Process.prototype.init = function() {
                for (var ix = 0, len = this._vars.length; ix < len; ix++) {
                    var n = this._vars[ix];
                    this._destination = this._actionData[n] = Klang.core.Core.instance.findInstance(n)
                }
                this._vars = null
            };
            Process.prototype.start = function(args) {
                Klang.warn("Process: Invocation of abstract method")
            };
            Process.prototype.destination = function() {
                return this._destination
            };
            Process.prototype.execute = function(action, args, noCache) {
                if (typeof action === "function") {
                    action(Klang.core.Core, Klang.Model, Klang.Util, this._actionData, args, Klang.Util.vars)
                } else {
                    if (!this._func || noCache) {
                        this._func = new Function("Core", "Model", "Util", "me", "args", "vars", action)
                    }
                    return this._func(Klang.core.Core, Klang.Model, Klang.Util, this._actionData, args, Klang.Util.vars)
                }
            };
            return Process
        }();
        return Klang.core.Process = Process
    });
    Module(function(Klang) {
        var SimpleProcess = function(_super) {
            Klang.Util.__extends(SimpleProcess, _super);

            function SimpleProcess(data, name) {
                _super.call(this, data);
                this._name = name;
                this.data = data;
                this._type = data.type;
                this._action = data.action;
                this._actionData = {}
            }
            SimpleProcess.prototype.start = function(args) {
                this.execute(this._action, args)
            };
            SimpleProcess.prototype.setData = function(data) {
                this._action = data.action;
                this._vars = data.vars;
                this.init();
                this._func = new Function("Core", "Model", "Util", "me", "args", "vars", this._action)
            };
            return SimpleProcess
        }(Klang.core.Process);
        Klang.Model.SimpleProcess = SimpleProcess;
        var AdvancedProcess = function(_super) {
            Klang.Util.__extends(AdvancedProcess, _super);

            function AdvancedProcess(data, name) {
                _super.call(this, data);
                this._nextStartTime = 0;
                this._waitOffset = 0;
                this.SCHEDULE_AHEAD_TIME = .2;
                this._lastTime = 0;
                this._name = name;
                this._type = data.type;
                this._preAction = data.pre_action || null;
                this._actions = data.actions;
                this._currentAction = 0;
                this._started = false;
                this._loop = data.loop !== undefined ? data.loop : false;
                this._loopTime = data.loopTime || -1;
                this._actionData = {
                    process: this
                };
                if (this._loop) {
                    var waitFound = false;
                    for (var ix = 0, len = this._actions.length; ix < len; ix++) {
                        if (this._actions[ix].operation == "wait") {
                            waitFound = true;
                            break
                        }
                    }
                    if (!waitFound) {
                        Klang.warn("Process: Infinite loop found in process '" + this._name + "'")
                    }
                }
            }
            AdvancedProcess.prototype.start = function(args) {
                if (Klang.isIOS) {
                    return
                }
                this._args = args;
                this._currentAction = 0;
                this._execTime = 0;
                this._startTime = Klang.context.currentTime;
                this._nextStartTime = this._startTime;
                if (this._preAction) {
                    if (this._preAction.operation == "exec") {
                        this.execute(this._preAction.script, this._args)
                    } else if (this._preAction.operation == "wait") {
                        this._execTime = this.execute(this._preAction.script, this._args);
                        this._waitOffset += this._execTime;
                        if (this._execTime >= this.SCHEDULE_AHEAD_TIME) {
                            Klang.core.TimeHandler.instance.registerMethodCallback(this, "cont", this._execTime - this.SCHEDULE_AHEAD_TIME / 2)
                        } else {
                            this.cont()
                        }
                        return
                    }
                }
                this._started = true;
                this.cont()
            };
            AdvancedProcess.prototype.cont = function() {
                this._actionData["execTime"] = this._nextStartTime + this._waitOffset;
                for (var len = this._actions.length; this._currentAction < len; this._currentAction++) {
                    if (!this._started) {
                        return
                    }
                    var action = this._actions[this._currentAction];
                    if (action.operation == "exec") {
                        this.execute(action.script, this._args, true);
                        this._execTime = 0
                    } else if (action.operation == "wait") {
                        this._execTime = this.execute(action.script, this._args, true);
                        this._waitOffset += this._execTime;
                        if (this._execTime >= this.SCHEDULE_AHEAD_TIME) {
                            Klang.core.TimeHandler.instance.registerMethodCallback(this, "cont", this._execTime - this.SCHEDULE_AHEAD_TIME / 2)
                        } else {
                            if (this._waitOffset > this.SCHEDULE_AHEAD_TIME) {
                                this.scheduleLoop(this._waitOffset)
                            } else {
                                this._currentAction++;
                                this.cont()
                            }
                        }
                        this._currentAction++;
                        return
                    }
                }
                if (this._loop) {
                    if (this._loopTime > 0) {
                        this.scheduleLoop(this._loopTime)
                    } else {
                        this._waitOffset = 0;
                        this._currentAction = 0;
                        this.cont()
                    }
                }
            };
            AdvancedProcess.prototype.scheduleLoop = function(loopTime) {
                if (!this._started) {
                    return
                }
                this._nextStartTime += loopTime;
                var timeTilNext = this._nextStartTime - Klang.context.currentTime;
                var _this = this;
                var loopTimeoutId = setTimeout(function() {
                    _this._waitOffset = 0;
                    _this._currentAction = 0;
                    _this.cont()
                }, (timeTilNext - this.SCHEDULE_AHEAD_TIME / 2) * 1e3)
            };
            AdvancedProcess.prototype.stop = function() {
                this._started = false;
                Klang.core.TimeHandler.instance.removeMethodCallback(this, "cont")
            };
            Object.defineProperty(AdvancedProcess.prototype, "started", {
                get: function() {
                    return this._started
                },
                enumerable: true,
                configurable: true
            });
            AdvancedProcess.prototype.setData = function(data) {
                this._actions = data.actions;
                this._vars = data.vars;
                this.init()
            };
            return AdvancedProcess
        }(Klang.core.Process);
        return Klang.Model.AdvancedProcess = AdvancedProcess
    });
    Module(function(Klang) {
        Klang.engines.webAudio.Util.createAudioContext = function(desiredSampleRate) {
            var AudioCtor = window.AudioContext || window.webkitAudioContext;
            desiredSampleRate = typeof desiredSampleRate === "number" ? desiredSampleRate : 44100;
            var context = new AudioCtor;
            if (/(iPhone|iPad)/i.test(navigator.userAgent) && context.sampleRate !== desiredSampleRate) {
                var buffer = context.createBuffer(1, 1, desiredSampleRate);
                var dummy = context.createBufferSource();
                dummy.buffer = buffer;
                dummy.connect(context.destination);
                dummy.start(0);
                dummy.disconnect();
                context.close();
                context = new AudioCtor
            }
            return context
        }
    });
    Module(function(Klang) {});
    Module(function(Klang) {
        function touchLoad(e) {
            Klang.audioTagHandler.loadSoundFiles()
        }
        var ATAudioFile = function() {
            function ATAudioFile(url, fileData) {
                this._url = url;
                this.data = fileData;
                this._onCanPlayThrough = this._onCanPlayThrough.bind(this);
                this._waitForReadyState = this._waitForReadyState.bind(this);
                this.state = ATAudioFile.STATE_NOT_LOADED
            }
            ATAudioFile.STATE_NOT_LOADED = 0;
            ATAudioFile.STATE_LOADING = 1;
            ATAudioFile.STATE_LOADED = 2;
            ATAudioFile.prototype._onCanPlayThrough = function() {
                this.audioElement.removeEventListener("canplaythrough", this._onCanPlayThrough, false);
                this._waitForReadyState(function() {
                    this.ready = true;
                    this.state = this.state = ATAudioFile.STATE_LOADED;
                    this._readyCallback && this._readyCallback();
                    this.audioElement.pause()
                }.bind(this))
            };
            ATAudioFile.prototype._waitForReadyState = function(cb) {
                var _this = this;
                (function wait() {
                    if (_this.audioElement.readyState) {
                        cb && cb()
                    } else {
                        setTimeout(wait, 100)
                    }
                })()
            };
            ATAudioFile.prototype.load = function(onDone) {
                if (this.state === ATAudioFile.STATE_NOT_LOADED) {
                    this.state = ATAudioFile.STATE_LOADING;
                    var el = this.audioElement = new Audio;
                    el.src = this._url;
                    el.addEventListener("canplaythrough", this._onCanPlayThrough, false);
                    el.volume = 0;
                    el.play()
                }
                this._readyCallback = onDone
            };
            ATAudioFile.prototype.clone = function() {
                var clone = new ATAudioFile(this._url, this.data);
                clone.state = this.state;
                clone.audioElement = new Audio;
                clone.ready = !!this.ready;
                clone.audioElement.src = this._url;
                clone.audioElement.volume = 0;
                clone.audioElement.play();
                return clone
            };
            return ATAudioFile
        }();
        Klang.ATAudioFile = ATAudioFile;
        var ATAudioSource = function() {
            function ATAudioSource(data, file) {
                this._xLoopTimer = 0;
                this.state = ATAudioSource.STATE_STOPPED;
                this._data = data;
                this._currentFile = file;
                this._retrig = data.retrig === undefined ? true : data.retrig;
                this._loopStart = data.loop_start || 0;
                this._loopEnd = data.loop_end || 0;
                this._destination_name = data.destination_name;
                if (!this._currentFile) {
                    return
                }
                this._priority = this._currentFile.data.audio_tag;
                this._loop = !!this._data.loop;
                this._gain = new ATGainNode(data.volume, this);
                if (this._loop) {
                    this._files = [this._currentFile, this._currentFile.clone()]
                }
                this.beforeEnding = this.beforeEnding.bind(this)
            }
            ATAudioSource.STATE_PLAYING = 3;
            ATAudioSource.STATE_STOPPING = 3;
            ATAudioSource.STATE_STOPPED = 4;
            ATAudioSource.prototype.beforeEnding = function() {
                if (this._playing && this._loop) {
                    var otherFile = this._currentFile;
                    this._currentFile = otherFile === this._files[0] ? this._files[1] : this._files[0];
                    this._currentFile.currentTime = this._loopStart;
                    this._currentFile.audioElement.currentTime = this._loopStart;
                    otherFile.audioElement.pause();
                    this.update();
                    this._currentFile.audioElement.play();
                    clearTimeout(this._xLoopTimer);
                    this._xLoopTimer = setTimeout(this.beforeEnding, (this._currentFile.audioElement.duration - this._loopStart - (this._loopEnd ? this._currentFile.audioElement.duration - this._loopEnd : 0)) * 1e3)
                }
            };
            ATAudioSource.prototype.play = function(when, offset, resume, keepVolume, loopTrigg) {
                when = when || 0;
                if (!this._currentFile.ready) {
                    return this
                }
                if (this._playing && !this._retrig && this.state !== ATAudioSource.STATE_STOPPING) {
                    return this
                }
                if (when > 0) {
                    var _this = this;
                    this._playTimeout = setTimeout(function() {
                        _this.doPlay(offset, resume, keepVolume, loopTrigg)
                    }, when * 1e3)
                } else {
                    this.doPlay(offset, resume, keepVolume, loopTrigg)
                }
            };
            ATAudioSource.prototype.doPlay = function(offset, resume, keepVolume, loopTrigg) {
                offset = offset || 0;
                if (this.state == ATAudioSource.STATE_STOPPING && !this._retrig) {
                    this.getOutput().fadeVolume(this.getOutput().getVolume(), .5);
                    this.state = ATAudioSource.STATE_PLAYING;
                    return
                } else {
                    this.update()
                }
                this._currentFile.audioElement.currentTime = offset;
                this._currentFile.audioElement.play();
                this._playing = true;
                if (this._loop) {
                    clearTimeout(this._xLoopTimer);
                    this._xLoopTimer = setTimeout(this.beforeEnding, (this._currentFile.audioElement.duration - offset - this._loopStart - (this._loopEnd ? this._currentFile.audioElement.duration - this._loopEnd : 0)) * 1e3)
                }
                this.state = ATAudioSource.STATE_PLAYING;
                return this
            };
            ATAudioSource.prototype.fadeInAndPlay = function(targetValue, duration, when, offset) {
                when = when || 0;
                if (when > 0) {
                    var _this = this;
                    this._playTimeout = setTimeout(function() {
                        _this.doFadeInAndPlay(_this.getOutput().getVolume(), duration)
                    }, when * 1e3)
                } else {
                    this.doFadeInAndPlay(this.getOutput().getVolume(), duration)
                }
                return this
            };
            ATAudioSource.prototype.doFadeInAndPlay = function(targetValue, duration) {
                this._gain.setVolume(this.state == ATAudioSource.STATE_PLAYING ? this._gain.getVolume() : 0);
                this.play(0, 0, false, true);
                this._gain.fadeVolume(targetValue, duration);
                return this
            };
            ATAudioSource.prototype.stop = function(when) {
                if (this._playTimeout) {
                    clearTimeout(this._playTimeout)
                }
                this.state = ATAudioSource.STATE_STOPPED;
                this._currentFile.audioElement.pause();
                this._playing = false;
                clearTimeout(this._xLoopTimer);
                return this
            };
            ATAudioSource.prototype.fadeOutAndStop = function(duration, when) {
                if (this.state != ATAudioSource.STATE_PLAYING) {
                    return
                }
                if (this._playTimeout) {
                    clearTimeout(this._playTimeout)
                }
                var _this = this;
                this._gain.fadeVolume(0, duration, function() {
                    if (_this.state == ATAudioSource.STATE_STOPPING) {
                        _this.stop()
                    }
                });
                this.state = ATAudioSource.STATE_STOPPING;
                return this
            };
            ATAudioSource.prototype.setVolume = function(value) {
                value = value === undefined ? this.getOutput().getVolume() : value * this.getOutput().getVolume();
                value = Math.max(0, Math.min(1, value * Klang.audioTagHandler.getGlobalVolume() * Klang.audioTagHandler.getFocusBlurVolume()));
                if (this._currentFile.audioElement && isFinite(value)) {
                    this._currentFile.audioElement.volume = value
                }
                return this
            };
            ATAudioSource.prototype.update = function() {
                this.setVolume(this._destination.calcVolume())
            };
            ATAudioSource.prototype.connect = function(bus) {
                this._destination = bus;
                bus.addConnected(this);
                this.update()
            };
            ATAudioSource.prototype.getOutput = function() {
                return this._gain
            };
            Object.defineProperty(ATAudioSource.prototype, "position", {
                get: function() {
                    return this.playing ? this._currentFile.audioElement.currentTime : 0
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(ATAudioSource.prototype, "playing", {
                get: function() {
                    return this._playing
                },
                enumerable: true,
                configurable: true
            });
            return ATAudioSource
        }();
        Klang.ATAudioSource = ATAudioSource;
        var ATAudioGroup = function() {
            function ATAudioGroup(data, audioTagHandler) {
                this._data = data;
                this._content = [];
                for (var c in this._data.content) {
                    var audio = audioTagHandler.getObject(this._data.content[c]);
                    if (audio) {
                        this._content.push(audio)
                    }
                }
            }
            ATAudioGroup.prototype.play = function(when, audioSource, forcePlay) {
                var index = typeof audioSource === "number" ? audioSource : Klang.Util.random(this._content.length - 1, 0);
                if (this._content[index]) {
                    this._content[index].play(when)
                }
                return this
            };
            ATAudioGroup.prototype.stop = function() {
                for (var c in this._content) {
                    if (this._content[c]) {
                        this._content[c].stop()
                    }
                }
                return this
            };
            Object.defineProperty(ATAudioGroup.prototype, "playing", {
                get: function() {
                    var playing = false;
                    for (var c in this._content) {
                        if (this._content[c]._playing) {
                            playing = true
                        }
                    }
                    return playing
                },
                enumerable: true,
                configurable: true
            });
            ATAudioGroup.prototype.update = function() {};
            ATAudioGroup.prototype.connect = function(bus) {};
            return ATAudioGroup
        }();
        Klang.ATAudioGroup = ATAudioGroup;
        var ATGainNode = function() {
            function ATGainNode(volume, owner) {
                this._currentVolume = this._volume = volume !== undefined ? volume : 1;
                this._currentVolume = Math.max(0, Math.min(this._currentVolume, 1));
                this._owner = owner
            }
            ATGainNode.prototype.getVolume = function() {
                return this._volume
            };
            ATGainNode.prototype.setVolume = function(value) {
                value = Math.max(0, Math.min(1, value));
                this._currentVolume = value;
                if (this._owner && this._owner.setVolume) {
                    this._owner.setVolume(this._currentVolume)
                }
                return this
            };
            ATGainNode.prototype.fadeVolume = function(targetValue, duration, callback) {
                if (this._fadeTimer) {
                    clearInterval(this._fadeTimer)
                }
                var _this = this;
                this._fadeSteps = Math.round(duration * 1e3) / 10;
                this._volumeStep = (this._currentVolume - targetValue) / this._fadeSteps;
                this._fadeTimer = setInterval(function() {
                    _this.setVolume(_this._currentVolume - _this._volumeStep);
                    _this._fadeSteps--;
                    if (_this._fadeSteps <= 0) {
                        clearInterval(_this._fadeTimer);
                        if (callback) {
                            callback()
                        }
                    }
                }, 10);
                return this
            };
            ATGainNode.prototype.resetVolume = function(keepVolume) {
                var volumeToSet = keepVolume ? this._currentVolume : this._volume;
                clearInterval(this._fadeTimer);
                this.setVolume(volumeToSet);
                return this
            };
            return ATGainNode
        }();
        Klang.ATGainNode = ATGainNode;
        var ATBus = function() {
            function ATBus(data, name, isMaster) {
                this._isMaster = false;
                this._connected = [];
                this._isMaster = isMaster;
                this._data = data;
                this._name = name;
                this._output = new ATGainNode(data.output_vol !== undefined ? data.output_vol : 1, this);
                this._volume = data.output_vol !== undefined ? data.output_vol : 1;
                this._destination_name = data.destination_name
            }
            ATBus.prototype.getVolume = function() {
                if (!this._isMaster) {
                    return this._volume
                } else {
                    return this._volume
                }
            };
            ATBus.prototype.calcVolume = function(vol) {
                if (typeof vol === "undefined") {
                    vol = 1
                }
                vol *= this._volume;
                if (this._destination) {
                    return this._destination.calcVolume(vol)
                }
                return vol
            };
            ATBus.prototype.setVolume = function(volume) {
                this._volume = volume;
                for (var i = 0; i < this._connected.length; i++) {
                    this._connected[i].update()
                }
            };
            ATBus.prototype.update = function() {
                for (var i = 0; i < this._connected.length; i++) {
                    this._connected[i].update()
                }
            };
            ATBus.prototype.getOutput = function() {
                return this._output
            };
            ATBus.prototype.addConnected = function(c) {
                this._connected.push(c)
            };
            ATBus.prototype.connect = function(bus) {
                this._destination = bus;
                bus.addConnected(this)
            };
            return ATBus
        }();
        Klang.ATBus = ATBus;
        var ATProcess = function() {
            function ATProcess(data, name, vars) {
                this._data = data;
                this._name = name;
                this._vars = vars;
                if (this._data.at_action === "copy") {
                    this._data.at_action = this._data.action
                }
            }
            ATProcess.prototype.start = function(args) {
                try {
                    if (typeof this._data.at_action === "function") {
                        this._data.at_action(Klang.Util, this._vars, args)
                    } else {
                        new Function("Util", "me", "args", this._data.at_action)(Klang.Util, this._vars, args)
                    }
                } catch (ex) {
                    Klang.err("Klang: error in process '" + this._name + "': " + ex.name + ": " + ex.message)
                }
            };
            return ATProcess
        }();
        Klang.ATProcess = ATProcess;

        function AudioTagHandler(config, readyCallback, progressCallback, configURL) {
            this._loadedFiles = 0;
            Klang.audioTagHandler = this;
            this._audioFiles = {};
            this._limitSounds = Klang.isMobile || Klang.detector.browser["name"] == "Opera";
            if (typeof config == "string") {
                var _this = this;
                Klang.network.request({
                    url: config
                }, function(data) {
                    try {
                        _this.init(JSON.parse(data), readyCallback, progressCallback, configURL)
                    } catch (ex) {
                        Klang.engineVersion = "n/a";
                        if (readyCallback) {
                            readyCallback(false)
                        }
                    }
                }, null, function(error) {
                    Klang.err(error)
                })
            } else if (typeof config == "object") {
                this.init(config, readyCallback, progressCallback, configURL)
            } else {
                Klang.err("Klang exception: unrecognized config type: " + typeof config)
            }
        }
        AudioTagHandler.prototype.init = function(data, readyCallback, progressCallback, configURL) {
            var _this = this;
            this._globalVolume = 1;
            this._focusBlurVolume = 1;
            this._readyCallback = readyCallback;
            this._progressCallback = progressCallback;
            this._events = data.events;
            var relativePath = parseInt(data.settings.relative_path);
            var baseURL;
            var filePath = data.settings.file_path || "";
            if (relativePath) {
                if (configURL.lastIndexOf("/") != -1) {
                    baseURL = configURL.substring(0, configURL.lastIndexOf("/"));
                    if (baseURL.charAt(baseURL.length - 1) !== "/") {
                        baseURL += "/"
                    }
                    baseURL += filePath
                } else {
                    baseURL = filePath
                }
            } else {
                baseURL = filePath
            }
            var format = ".mp3";
            if (Klang.detector.browser["name"] == "Firefox" || Klang.detector.browser["name"] == "Chrome") {
                format = ".ogg"
            }
            for (var p in data.files) {
                var fileData = data.files[p];
                var prio = fileData.audio_tag;
                if (prio && (!this._limitSounds || prio == 1)) {
                    this._audioFiles[fileData.id] = new ATAudioFile(baseURL + fileData.url + format, fileData)
                }
            }
            this._masterBus = data.masterBus;
            this._busses = {};
            for (var b in data.busses) {
                this._busses[b] = new ATBus(data.busses[b], b, b == this._masterBus)
            }
            this._audio = {};
            for (var a in data.audio) {
                if (data.audio.hasOwnProperty(a)) {
                    var audioData = data.audio[a];
                    if (audioData.type == "AudioSource") {
                        if (this._audioFiles[audioData.file_id]) {
                            var sprite = this._audioFiles[audioData.file_id];
                            this._audio[a] = new ATAudioSource(audioData, this._audioFiles[audioData.file_id])
                        }
                    } else if (audioData.type == "AudioGroup") {
                        this._audio[a] = new ATAudioGroup(audioData, this)
                    }
                }
            }
            for (var bus in this._busses) {
                if (bus != this._masterBus) {
                    this._busses[bus].connect(this._busses[this._busses[bus]._destination_name])
                }
            }
            for (var as in this._audio) {
                if (this._audio[as]._data.type == "AudioSource") {
                    this._audio[as].connect(this._busses[this._audio[as]._destination_name])
                }
            }
            this._processes = {};
            for (var p in data.processes) {
                var processData = data.processes[p];
                if (processData.at_action) {
                    var processArgs = {};
                    for (var v in processData.vars) {
                        var processVarName = processData.vars[v];
                        processArgs[processVarName] = this._audio[processVarName] || this._busses[processVarName]
                    }
                    this._processes[p] = new ATProcess(processData, p, processArgs)
                }
            }
            this.loadSoundFiles(["auto", "autotag"], readyCallback, progressCallback);
            if (data.settings.blur_fade_time != -1) {
                this._blurFadeOut = true;
                var fadeTime = data.settings.blur_fade_time || .5;
                var _this = this;
                var visProp = this.getHiddenProp();
                if (visProp) {
                    var evtname = "visibilitychange";
                    document.addEventListener(evtname, this.visChange.bind(this))
                }
            }
        };
        AudioTagHandler.prototype.visChange = function() {
            if (this.isHidden()) {
                if (this._blurFadeOut) {
                    this.setFocusBlurVolume(0)
                }
            } else {
                this.setFocusBlurVolume(1)
            }
        };
        AudioTagHandler.prototype.fadeBusVolume = function(bus, value, duration) {
            var b = this._busses[bus._name];
            b.getOutput().fadeVolume(value, duration)
        };
        AudioTagHandler.prototype.isHidden = function() {
            var prop = this.getHiddenProp();
            if (!prop) {
                return false
            }
            return document[prop]
        };
        AudioTagHandler.prototype.getHiddenProp = function() {
            var prefixes = ["webkit", "moz", "ms", "o"];
            if ("hidden" in document) {
                return "hidden"
            }
            for (var i = 0; i < prefixes.length; i++) {
                if (prefixes[i] + "Hidden" in document) {
                    return prefixes[i] + "Hidden"
                }
            }
            return null
        };
        AudioTagHandler.prototype.initIOS = function() {
            if (Klang.isIOS || Klang.isMobile) {
                for (var p in this._audioFiles) {
                    this._audioFiles[p].load()
                }
            }
        };
        AudioTagHandler.prototype.loadSoundFiles = function(group, readyCallback, progressCallback, loadFailedCallback) {
            if (readyCallback) {
                this._readyCallback = readyCallback
            }
            if (progressCallback) {
                this._progressCallback = progressCallback
            }
            if (typeof group === "string") {
                group = [group]
            }
            this._loadedFiles = 0;
            this._numFiles = 0;
            var _this = this;
            for (var p in this._audioFiles) {
                if (this._audioFiles.hasOwnProperty(p)) {
                    var audioFile = this._audioFiles[p];
                    var loadGroup = audioFile.data.load_group;
                    if (group === undefined || group.indexOf(loadGroup) != -1) {
                        if (audioFile.state === ATAudioFile.STATE_NOT_LOADED) {
                            this._numFiles++;
                            audioFile.load(function() {
                                _this.loadProgress()
                            })
                        }
                    }
                }
            }
            if (this._numFiles == 0 && this._readyCallback) {
                this._readyCallback(true)
            }
        };
        AudioTagHandler.prototype.getLoadGroups = function() {
            var i;
            var fileInfoArr = this._audioFiles || [];
            var groupTable = {};
            var listOfGroups = [];
            for (i in fileInfoArr) {
                var fileInfo = fileInfoArr[i];
                groupTable[fileInfo.data.load_group] = fileInfo.data.load_group
            }
            for (i in groupTable) {
                listOfGroups.push(i)
            }
            return listOfGroups
        };
        AudioTagHandler.prototype.loadProgress = function() {
            this._loadedFiles++;
            if (this._progressCallback) {
                this._progressCallback(this._loadedFiles / this._numFiles * 100)
            }
            if (this._readyCallback && this._loadedFiles == this._numFiles) {
                var _this = this;
                setTimeout(function() {
                    _this._readyCallback(true)
                }, 200)
            }
        };
        AudioTagHandler.prototype.triggerEvent = function(name, args) {
            var str = "";
            for (var i = 0; i < args.length; i++) {
                str += args[i] + ", "
            }
            if (name != "sound_position") {
                var arg = "";
                if (args) {
                    arg = args[0]
                }
            }
            if (!this._events) {
                return
            }
            try {
                var eventTarget = this._events[name];
                if (typeof eventTarget == "string") {
                    var process = this._processes[eventTarget];
                    if (process) {
                        process.start(args)
                    }
                } else if (eventTarget) {
                    for (var ix = 0, len = eventTarget.length; ix < len; ix++) {
                        var processName = eventTarget[ix];
                        var process = this._processes[processName];
                        if (process) {
                            process.start(args)
                        }
                    }
                }
            } catch (ex) {
                Klang.err("Klang: error when triggering event '" + name + "': " + ex.name + ": " + ex.message)
            }
        };
        AudioTagHandler.prototype.getFocusBlurVolume = function() {
            return this._focusBlurVolume
        };
        AudioTagHandler.prototype.setFocusBlurVolume = function(value) {
            value = Math.max(0, Math.min(value, 1));
            this._focusBlurVolume = value;
            for (var a in this._audio) {
                var audio = this._audio[a];
                if (audio && audio.setVolume && audio.getOutput()) {
                    var audioOut = audio.getOutput();
                    if (audioOut) {
                        audio.setVolume(audioOut.getVolume())
                    }
                }
            }
        };
        AudioTagHandler.prototype.getGlobalVolume = function() {
            return this._globalVolume
        };
        AudioTagHandler.prototype.setGlobalVolume = function(value) {
            value = Math.max(0, Math.min(value, 1));
            this._globalVolume = value;
            for (var a in this._audio) {
                var audio = this._audio[a];
                if (audio && audio.setVolume && audio.getOutput) {
                    var audioOut = audio.getOutput();
                    if (audioOut) {
                        audio.setVolume(audioOut.getVolume())
                    }
                }
            }
        };
        AudioTagHandler.prototype.fadeGlobalVolume = function(value, duration) {
            value = Math.max(0, Math.min(value, 1));
            if (this._globalFadeTimer) {
                clearInterval(this._globalFadeTimer)
            }
            var _this = this;
            var fadeSteps = Math.round(duration * 1e3) / 10;
            var volumeStep = (this._globalVolume - value) / fadeSteps;
            this._globalFadeTimer = setInterval(function() {
                _this._globalVolume = _this._globalVolume - volumeStep;
                fadeSteps--;
                for (var a in _this._audio) {
                    var audio = _this._audio[a];
                    if (audio && audio.setVolume && audio.getOutput) {
                        audio.setVolume()
                    }
                }
                if (fadeSteps <= 0) {
                    clearInterval(_this._globalFadeTimer)
                }
            }, 10)
        };
        AudioTagHandler.prototype.getLimitSounds = function() {
            return this._limitSounds
        };
        AudioTagHandler.prototype.stopAll = function(priority) {
            for (var a in this._audio) {
                if (priority === undefined || this._audio[a]._priority == priority) {
                    this._audio[a].stop()
                }
            }
            this.stopPeriodic();
            return this
        };
        AudioTagHandler.prototype.getObject = function(name) {
            return this._audioFiles[name] || this._audio[name]
        };
        AudioTagHandler.prototype.playPeriodic = function(obj, maxSec, minSec) {
            clearTimeout(this._periodicTimer);
            var _this = this;
            this._periodicTimer = setTimeout(function() {
                obj.play();
                _this.playPeriodic(obj, maxSec, minSec)
            }, Klang.Util.random(maxSec * 1e3, minSec * 1e3))
        };
        AudioTagHandler.prototype.stopPeriodic = function() {
            clearTimeout(this._periodicTimer)
        };
        return Klang.AudioTagHandler = AudioTagHandler
    });
    Module(function(Klang) {
        var StreamingAudioSource = function() {
            Klang.Util.__extends(StreamingAudioSource, Klang.Model.Audio);

            function StreamingAudioSource(data, name) {
                Klang.Model.Audio.call(this, data, name);
                this._startTime = 0;
                this._loopStartTime = 0;
                this._scheduleAhead = .2;
                this._stopping = false;
                this._fading = false;
                this._paused = false;
                this._pauseTime = -1;
                this._connected = false;
                this._durationTimeout = 0;
                this._pauseStartTime = -1;
                this.data = data;
                this.editorName = data.editorName;
                this._fileId = data.file_id;
                this._playbackRate = data.playback_rate || 1;
                this._endTime = 0;
                this._loop = data.loop !== undefined ? data.loop : false;
                this._loopStart = data.loop_start;
                this._loopEnd = data.loop_end;
                this._offset = data.offset || 0;
                this._duration = data.duration || 0;
                this._reverse = data.reverse;
                this._retrig = data.retrig !== undefined ? data.retrig : true;
                this._lockPlaybackrate = data.lock_playback_rate !== undefined ? data.lock_playback_rate : false;
                this._volumeStartRange = data.volume_start_range;
                this._volumeEndRange = data.volume_end_range;
                if (data.panner) {
                    this._panner = data.panner
                }
                if (!Klang.core.Core.instance.pushToPostLoadInitStack(this)) {
                    this.init()
                }
                Klang.core.internalEventBus.on("INIT_IOS", this.initIOS.bind(this))
            }
            StreamingAudioSource.prototype.init = function() {
                var self = this;
                var url;
                this._audioElement = new Audio;
                this._mediaElementSource = Klang.context.createMediaElementSource(this._audioElement);
                this._mediaElementSource.connect(this._output);
                this._audioElement.crossOrigin = "anonymous";
                if (this._fileId) {
                    if (typeof this._fileId === "string") {
                        var info = Klang.core.FileHandler.instance.getFileInfo(this._fileId);
                        url = (info.external ? "" : Klang.core.FileHandler.instance._baseURL) + info.url
                    }
                } else {
                    url = this.data.url
                }
                var format = ".mp3";
                if (Klang.detector.browser["name"] === "Firefox" || Klang.detector.browser["name"] === "Chrome") {
                    format = ".ogg"
                }
                this._audioElement.src = url + format;
                if (!this._duration) {
                    this._audioElement.addEventListener("loadedmetadata", function onMetaData(_event) {
                        self._duration = self._audioElement.duration;
                        self._audioElement.removeEventListener("loadedmetadata", onMetaData, false)
                    }, false)
                }
            };
            StreamingAudioSource.prototype.setLoopRegion = function(loopStart, loopEnd) {
                this._loopStart = loopStart || this._loopStart;
                this._loopEnd = loopEnd || this._loopEnd;
                this._audioElement.loopStart = this._loopStart;
                this._audioElement.loopEnd = this._loopEnd;
                return this
            };
            StreamingAudioSource.prototype.connect = function(destination, forceConnect) {
                if (!this._destination || forceConnect) {
                    this._destination = destination;
                    if (this._panner) {
                        this._output.connect(this._panner.input);
                        this._panner.output.connect(destination)
                    } else {
                        this._output.connect(destination)
                    }
                }
                return this
            };
            StreamingAudioSource.prototype.disconnect = function() {
                this._output.disconnect();
                this._destination = null;
                if (this._panner) {
                    this._panner.output.disconnect()
                }
                return this
            };
            StreamingAudioSource.prototype.play = function(when, offset, duration, resume) {
                var now = Klang.context.currentTime;
                clearTimeout(this._stopTimeout);
                Klang.log("StreamingAudioSource.play. Playing: " + this._playing);
                if (this._playing) {
                    this.stop(when)
                }
                when = when || 0;
                offset = offset || 0;
                resume = !false;
                this.removeUnusedSources();
                if (!duration) {
                    if (this._loop) {
                        duration = 9999999999
                    } else {
                        duration = this._duration
                    }
                }
                if (!this._audioElement) {
                    this.init()
                }
                if (when !== 0 && when + .01 <= now) {
                    Klang.warn("StreamingAudioSource: Returned, playTime < currentTime", this._name);
                    return this
                } else if (when == 0) {
                    when = now
                }
                this.output.gain.cancelScheduledValues(when);
                this.output.gain.setValueAtTime(this._volume, when);
                if (!this.paused) {
                    this._pauseStartTime = when
                }
                if (!resume) {
                    this._pauseTime = 0
                }
                this._startTime = when;
                this._loopStartTime = when + this.duration;
                this._paused = false;
                if (this._stopping && !this._retrig) {
                    this.output.gain.cancelScheduledValues(when);
                    this.output.gain.setValueAtTime(this.output.gain.value, when);
                    this.output.gain.linearRampToValueAtTime(this._volume, when + .25);
                    clearTimeout(this._stoppingId);
                    this._stopping = false;
                    return
                }
                this._fading = false;
                if (!this._retrig && !this.loop) {
                    if (when < this._endTime) {
                        return
                    }
                } else if (this.loop && this._retrig && this.playing && !this._stopping) {
                    return
                } else if (this._stopping) {
                    this._stopping = false
                } else if (Math.round(this._endTime * 1e3) / 1e3 == Math.round((when + this._duration) * 1e3) / 1e3) {
                    Klang.warn("StreamingAudioSource: Returned, Doubletrig", this._name);
                    return this
                }
                this._endTime = this.loop ? -1 : when + this._duration;
                if (this._loop) {
                    this._audioElement.loop = true;
                    this._audioElement.loopStart = this._loopStart ? this._loopStart : 0;
                    this._audioElement.loopEnd = this._loopEnd ? this._loopEnd : this._duration
                }
                if (!this._destination) {
                    Klang.warn("StreamingAudioSource: no destination node")
                }
                if (typeof this._destination != "object") {
                    Klang.warn("StreamingAudioSource: destination is not an object", this._name)
                }
                if (offset > this._duration) {
                    offset = offset % this._duration
                }
                this._startOffset = this._offset + offset;
                this._playing = true;
                Klang.log("AudioElement.play");
                this._playTimeout = setTimeout(function() {
                    this._audioElement.play()
                }.bind(this), Math.max(0, when - Klang.Util.now()) * 1e3);
                return this
            };
            StreamingAudioSource.prototype.getNumberOfSamples = function() {
                return this._duration * Klang.context.sampleRate
            };
            StreamingAudioSource.prototype.stop = function(when) {
                if (typeof when === "undefined") {
                    when = 0
                }
                clearTimeout(this._playTimeout);
                clearTimeout(this._durationTimeout);
                if (this._stopping) {
                    this._stopping = false;
                    clearTimeout(this._stoppingId)
                }
                var whenDelta = when - Klang.context.currentTime;
                if (whenDelta > 0) {
                    this._stopTimeout = setTimeout(function() {
                        this._audioElement.pause();
                        this._audioElement.currentTime = 0;
                        this._stopping = false
                    }.bind(this), whenDelta * 1e3);
                    this._stopping = true;
                    this._endTime = when
                } else {
                    this._audioElement.pause();
                    this._audioElement.currentTime = 0;
                    this._endTime = Klang.Util.now()
                }
                this._playing = false;
                return this
            };
            StreamingAudioSource.prototype.deschedule = function() {
                this._audioElement.pause();
                return this
            };
            StreamingAudioSource.prototype.pause = function() {
                if (this._endTime > Klang.Util.now()) {
                    this._paused = true;
                    var pauseDelta = Klang.Util.now() - this._startTime;
                    this._pauseTime += pauseDelta;
                    this.stop()
                }
                return this
            };
            StreamingAudioSource.prototype.unpause = function() {
                if (this.paused) {
                    var realOffset = this._offset;
                    this._offset += this._pauseTime;
                    this.play(0, 0, null, true);
                    this._offset = realOffset;
                    this._paused = false
                }
                return this
            };
            StreamingAudioSource.prototype.createMediaElementSource = function() {
                if (!this._connected) {
                    var source = Klang.context.createMediaElementSource(this._audioElement);
                    this._source = source
                } else {
                    var source = this._source
                }
                return source
            };
            StreamingAudioSource.prototype.initIOS = function() {
                if (!this._audioElement) {
                    this.init()
                }
                this._audioElement.play();
                this._audioElement.pause()
            };
            StreamingAudioSource.prototype.fadeInAndPlay = function(fadeDuration, when, offset, duration) {
                if (typeof offset === "undefined") {
                    offset = 0
                }
                if (typeof duration === "undefined") {
                    duration = this._duration
                }
                var now = Klang.context.currentTime;
                if (!when) {
                    when = now
                }
                if (this.loop && (!this._retrig && (this._endTime == -1 || when < this._endTime)) && !this._stopping) {
                    return
                } else if (this.loop && this._retrig && this.playing && !this._stopping) {
                    return
                }
                this.output.gain.cancelScheduledValues(when);
                if (this._stopping && !this._retrig) {
                    clearTimeout(this._stoppingId);
                    this.output.gain.setValueAtTime(this.output.gain.value, when)
                } else {
                    this._fading = true;
                    this.play(when == now ? 0 : when, offset, duration);
                    this.output.gain.setValueAtTime(0, when)
                }
                this._stopping = false;
                this.output.gain.linearRampToValueAtTime(this._volume, when + fadeDuration);
                return this
            };
            StreamingAudioSource.prototype.fadeOutAndStop = function(duration, when) {
                if (!this.playing) {
                    return
                }
                if (when === undefined) {
                    when = Klang.context.currentTime
                }
                if (this._stopping) {
                    clearTimeout(this._stoppingId)
                }
                this.output.gain.cancelScheduledValues(when);
                this.output.gain.setValueAtTime(this.output.gain.value || this._volume, when);
                this.output.gain.linearRampToValueAtTime(0, when + duration);
                var _this = this;
                this._stoppingId = setTimeout(function() {
                    if (!_this._stopping) {
                        return
                    }
                    _this._stopping = false;
                    if (_this.loop) {
                        _this._loopPlaying = false
                    }
                    _this.stop(when + duration)
                }, (duration + (when - Klang.Util.now()) - _this._scheduleAhead) * 1e3);
                this._stopping = true;
                return this
            };
            StreamingAudioSource.prototype.removeUnusedSources = function() {};
            StreamingAudioSource.prototype.curvePlaybackRate = function(value, duration, when) {
                if (this._lockPlaybackrate) {
                    return
                }
                var startTime = when ? when : Klang.Util.now();
                var node = this.playbackRateNode;
                if (node) {
                    node.cancelScheduledValues(startTime);
                    node.setValueAtTime(node.value == 0 ? Klang.Util.EXP_MIN_VALUE : node.value, startTime);
                    node.exponentialRampToValueAtTime(value, startTime + duration)
                }
                this._playbackRate = value;
                return this
            };
            Object.defineProperty(StreamingAudioSource.prototype, "lastSource", {
                get: function() {
                    return this._audioElement
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "loop", {
                get: function() {
                    return this._loop
                },
                set: function(value) {
                    this._loop = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "offset", {
                get: function() {
                    return this._offset
                },
                set: function(value) {
                    if (typeof value === "string" && value.indexOf("%") !== -1) {
                        value = this._duration * parseFloat(value)
                    }
                    this._offset = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "position", {
                get: function() {
                    if (!this.playing || !this._duration) {
                        return 0
                    }
                    var duration = this._duration;
                    if (this._loopStart || this._loopEnd) {
                        duration = (this._loopEnd || duration) - (this._loopStart || 0)
                    }
                    var timePlayed = Klang.Util.now() - this._startTime;
                    var loopTimePlayed = Klang.Util.now() + this._startOffset - this._loopStartTime;
                    if (this._startOffset + timePlayed > this._duration) {
                        return this._loopStart + loopTimePlayed % duration
                    } else {
                        return this._startOffset + timePlayed
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "duration", {
                get: function() {
                    return this._duration
                },
                set: function(value) {
                    this._duration = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "paused", {
                get: function() {
                    return this._paused
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "playbackRate", {
                get: function() {
                    return this._playbackRate
                },
                set: function(value) {
                    if (this._lockPlaybackrate) {
                        return
                    }
                    var node = this.playbackRateNode;
                    if (node) {
                        node.cancelScheduledValues(Klang.Util.now())
                    }
                    this._playbackRate = value;
                    for (var ix = 0, len = this._sources.length; ix < len; ix++) {
                        this._sources[ix].playbackRate.value = this._playbackRate
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "nextPlaybackRate", {
                set: function(value) {
                    if (this._lockPlaybackrate) {
                        return
                    }
                    this._playbackRate = value
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "playbackRateNode", {
                get: function() {
                    var source = this.lastSource;
                    return source && source.playbackRate
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "buffer", {
                get: function() {
                    return null
                },
                set: function(buffer) {
                    console.error("can't set buffer on a streaming source")
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "playing", {
                get: function() {
                    return this._endTime == -1 || this._endTime > Klang.Util.now()
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "playbackState", {
                get: function() {
                    var source = this.lastSource;
                    if (source) {
                        return source.playbackState
                    }
                    return 0
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "output", {
                get: function() {
                    if (this._panner) {
                        return this._panner.output
                    } else {
                        return this._output
                    }
                },
                enumerable: true,
                configurable: true
            });
            Object.defineProperty(StreamingAudioSource.prototype, "panner", {
                get: function() {
                    return this._panner
                },
                enumerable: true,
                configurable: true
            });
            StreamingAudioSource.prototype.freeBuffer = function() {};
            StreamingAudioSource.prototype.setData = function(data) {
                Klang.Model.Audio.prototype.setData.call(this, data);
                var reinit = false;
                this._volumeStartRange = data.volume_start_range;
                this._volumeEndRange = data.volume_end_range;
                if (data.file_id !== undefined && this._fileId != data.file_id) {
                    this._fileId = data.file_id;
                    reinit = true
                }
                this._playbackRate = data.playback_rate === undefined ? 1 : data.playback_rate;
                if (this.playbackRateNode) {
                    this.playbackRateNode.value = this._playbackRate
                }
                this._loop = data.loop === undefined ? false : data.loop;
                if (this.lastSource) {
                    this.lastSource.loop = this._loop
                }
                if (!this._loop) {
                    this._loopPlaying = false
                }
                this._loopStart = data.loop_start === undefined ? 0 : data.loop_start;
                if (this.lastSource) {
                    this.lastSource.loopStart = this._loopStart
                }
                this._loopEnd = data.loop_end === undefined ? 0 : data.loop_end;
                if (this.lastSource) {
                    this.lastSource.loopEnd = this._loopEnd
                }
                var offset = data.offset === undefined ? 0 : data.offset;
                if (this._offset != offset) {
                    this._offset = offset;
                    reinit = true
                }
                var duration = data.duration === undefined ? 0 : data.duration;
                if (this._duration != duration) {
                    this._duration = duration;
                    reinit = true
                }
                this._retrig = data.retrig === undefined ? true : data.retrig;
                if (data.reverse === undefined) {
                    data.reverse = false
                }
                if (this._reverse != data.reverse) {
                    this._reverse = data.reverse;
                    reinit = true
                }
                if (this.data.xfade != data.xfade) {
                    reinit = true
                }
                this.data = data;
                if (reinit) {
                    this.init()
                }
                if (data.panner) {
                    if (!this._panner) {
                        var d = this._destination;
                        this.disconnect();
                        this._panner = newPanner(data.panner);
                        this.connect(d)
                    } else {
                        this._panner.setData(data.panner)
                    }
                } else if (!data.panner) {
                    if (this._panner) {
                        var d = this._destination;
                        this.disconnect();
                        this._panner = null;
                        this.connect(d)
                    }
                }
            };
            return StreamingAudioSource
        }();
        return Klang.Model.StreamingAudioSource = StreamingAudioSource
    });
    Module(function(Klang) {
        window.Klang = Klang;
        return window.Klang
    });
    window.Klang = Klang;
    if (typeof define === "function" && define.amd) {
        define("Klang", function() {
            return window.Klang
        })
    } else if (typeof module === "object") {
        module.exports = window.Klang
    }
})(this);