import * as THREE from 'three';
import { TweenLite, Quad } from 'gsap';

const VERTEX = "\nvarying vec2 v_uv;\n\nvoid main() {\n  v_uv = uv;\n  vec4 mvPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0);\n  mvPosition.xy += position.xy;\n  gl_Position = projectionMatrix * mvPosition;\n}\n";
const FRAG = "\nuniform float opacity;\nuniform float r;\nvarying vec2 v_uv;\nvarying vec3 colorP;\n\nvoid main() {\n  float r = 0.2;\n  vec2 uv = v_uv * 1.2;\n  vec2 diff = vec2(0.5) - v_uv;\n  float angle = atan(diff.y, diff.x);\n  float s = distance(v_uv, vec2(0.5)) / 0.41667 - 1.0;\n  float inner = smoothstep(-0.01, 0.0, s);\n  float outer = pow(clamp((r + .005 - s) / r, 0., 1.), 1.);\n  s = mix(inner, outer, step(0.0, s));\n\n  gl_FragColor = vec4(0.2 * 0.7, 0.18 * 0.7, 0.19 * 0.7, s * opacity * cos(angle * 0.8 + 0.24));\n  // gl_FragColor = vec4(vec3(diff), 0.5);\n}\n";
const SIZE = 1.2;

export default class EarthGlow {
    constructor() {
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load( "/marker.png" );
        texture.flipY = false;
        this.uniforms = {
            opacity: {
                value: 0,
                type: "f"
            }
        };
        const material = new THREE.ShaderMaterial( {
            uniforms: this.uniforms,
            vertexShader: VERTEX,
            fragmentShader: FRAG,
            blending: THREE.NormalBlending,
            depthTest: false,
            transparent: true
        } );
        const geometry = new THREE.PlaneGeometry( SIZE, SIZE, 1 );
        this.mesh = new THREE.Mesh( geometry, material );
    }

    show ( small ) {
        TweenLite.to( this.uniforms.opacity, .25, {
            value: 1,
            ease: Quad.easeOut
        } );
        TweenLite.to( this.uniforms.r, .25, {
            value: small ? .75 : 1,
            ease: Quad.easeOut
        } )
    }
    hide () {
        TweenLite.to( this.uniforms.opacity, .2, {
            value: 0,
            ease: Quad.easeInOut
        } );
        TweenLite.to( this.uniforms.r, .2, {
            value: 0,
            ease: Quad.easeInOut
        } )
    }
}