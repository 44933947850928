import Globe from './Globe';
import Tooltip from './Tooltip';
import Timeline from './Timeline';
import Cities from './Cities';
import Pages from './Pages';
import Nav from './Nav';
import audioController from './AudioController';
import { TweenLite } from 'gsap';

export default class App {
    constructor() {
        this.isMobile = "ontouchstart" in document.documentElement;
        this.isMobile = /\bCrOS\b/.test( navigator.userAgent ) ? false : this.isMobile;
        this.isEmbed = window.location.pathname === "/embed";
        this.globe = new Globe( this.isMobile );
        this.playIntro = true;
        this.introComplete = false;
        this.date = false;
        this.deepLinkCity = false;
        this.timeline = new Timeline( this.isMobile );
        this.tooltip = new Tooltip( document.querySelector( "#tooltip" ) );
        if ( this.isMobile ) {
            document.body.classList.add( "is-mobile" )
        }
        if ( this.isEmbed ) {
            document.body.classList.add( "is-embed" )
        }
        document.addEventListener( "touchend", function ( e ) {
            if ( e.changedTouches.length && document.querySelector( "#city-overlay" ).scrollTop < 70 ) {
                this.globe.setMouse( e.changedTouches[0].clientX, e.changedTouches[0].clientY )
            }
        } );
        const self = this;
        this.cities = new Cities( {
            isTouch: this.isMobile,
            isEmbed: this.isEmbed
        } );
         
        this.buildUI();
        this.pages.on( "open-city", function ( slug ) {
            self.deepLinkCity = decodeURIComponent( slug )
        } );
        this.pages.on( "close-overlay", function () {
            self.cities.animateOutOverlay()
        } );
        this.timeline.on( "date-update", function ( dateObj, lastDateObj ) {
            if ( self.date !== dateObj.date ) {
                self.date = dateObj.date;
                //console.log( dateObj )
                self.globe.setDate( dateObj.date );
                if ( !!lastDateObj === true ) {
                    self.cities.setDate( dateObj.date, dateObj.date === lastDateObj.date )
                } else {
                    self.cities.setDate( dateObj.date )
                }
            }
        } );
        if ( !this.isMobile ) {
            this.timeline.once( "intro-complete", function () {
                self.addDragListeners();
                /*if ( _this.deepLinkCity ) {
                    gsap.TweenLite.delayedCall( 1, function () {
                        this.globe.gotoCity( this.deepLinkCity )
                    } )
                }*/
            } )
        } else {
            this.addDragListeners();
            /*if ( this.deepLinkCity ) {
                this.globe.gotoCity( this.deepLinkCity )
            }*/
        }
        if ( window.location.pathname !== "/" ) {
            this.playIntro = false
        }
        audioController.init( this.isMobile || this.isEmbed )
        this.globe.load().then( function () {
            TweenLite.delayedCall( .5, function () {

                self.introComplete = true;
                self.pages.introComplete = true;

                self.cities.on( "close-overlay", function () {
                    self.globe.zoomOut()
                } );
                self.cities.on( "look-at-city", function ( city ) {
                    self.lookAtCity( city )
                } );

                self.globe.on( "day-loaded", function ( data ) {
                    self.cities.setCityData( data )
                } );

                self.timeline.getData().then( function () {
                    self.globe.setDate( self.timeline.points[self.timeline.points.length - 1].date );
                    TweenLite.delayedCall( .2, function () {
                        self.cities.animateIn()
                    } )
                } )
            } );
            audioController.startBackground();
        } );
        /*
        Promise.all( [_audioController2.default.init( this.isMobile || this.isEmbed ), this.globe.load( this.playIntro )] ).then( function () {
            _gsap.TweenLite.delayedCall( .5, function () {
                _this.introComplete = true;
                _this.pages.introComplete = true;
                _analytics.Analytics.createEvent( "pageload", "Home", "/home", true );
                _this.cities.on( "close-overlay", function () {
                    _this.globe.zoomOut()
                } );
                _this.cities.on( "look-at-city", function ( city ) {
                    _this.lookAtCity( city )
                } );
                _this.globe.on( "day-loaded", function ( data ) {
                    _this.cities.setCityData( data )
                } );
                _this.timeline.getData().then( function () {
                    if ( !_this.isMobile && !_this.isEmbed ) {
                        _this.globe.setDate( _this.timeline.points[0].date );
                        _gsap.TweenLite.delayedCall( 1, function () {
                            _this.globe.setDate( _this.timeline.points[60].date )
                        } );
                        _gsap.TweenLite.delayedCall( 2, function () {
                            _this.globe.setDate( _this.timeline.points[90].date )
                        } );
                        _gsap.TweenLite.delayedCall( 3, function () {
                            _this.globe.setDate( _this.timeline.points[100].date )
                        } );
                        _gsap.TweenLite.delayedCall( 4, function () {
                            _this.globe.setDate( _this.timeline.points[_this.timeline.points.length - 1].date )
                        } );
                        _gsap.TweenLite.delayedCall( .2, function () {
                            _this.cities.animateIn()
                        } )
                    } else {
                        _this.globe.setDate( _this.timeline.points[_this.timeline.points.length - 1].date );
                        _gsap.TweenLite.delayedCall( .2, function () {
                            _this.cities.animateIn()
                        } )
                    }
                } )
            } );
            if ( !_this.playIntro ) {
                _audioController2.default.startBackground()
            }
        } );*/
        this.globe.on( "look-at-city", function ( city ) {
            self.lookAtCity( city );
            self.tooltip.clear()
        } );
        this.globe.on( "zoom-out", function () {
            self.cities.closeOverlay()
        } );
        this.globe.on( "hover-city", function ( city ) {
            if ( city ) {
                self.tooltip.setTitle( city.city )
            } else {
                self.tooltip.clear()
            }
        } );
        this.globe.on( "hover-off", function () {
            self.tooltip.clear()
        } );
        document.addEventListener( "mousemove", function ( event ) {
            self.tooltip.tick( event.clientX, event.clientY )
        } );
        window.onresize = function () {
            setTimeout( function () {
                if ( self.globe ) {
                    self.globe.resize();
                }
                if ( self.timeline /*&& this.introComplete && !this.isEmbed*/ ) {
                    self.timeline.resize()
                }
                if ( self.cities ) {
                    self.cities.resize()
                }
                if ( self.pages ) {
                    self.pages.resize()
                }
            }, 100 )
        };
        window.addEventListener( "orientationchange", function () {
            var event = document.createEvent( "HTMLEvents" );
            event.initEvent( "resize", true, false );
            window.dispatchEvent( event );
            if ( window.innerWidth <= 991 ) {
                setTimeout( function () {
                    if ( self.pages ) {
                        self.pages.reorient()
                    }
                    if ( self.cities ) {
                        self.cities.reorient()
                    }
                    if ( self.nav ) {
                        self.nav.reorient()
                    }
                }, 100 )
            }
        } );
        requestAnimationFrame( this.animate );
    }
    addDragListeners () {
        const self = this;
        var upEvent = this.isMobile ? "touchend" : "mouseup";
        var moveEvent = this.isMobile ? "touchmove" : "mousemove";
        document.addEventListener( upEvent, function ( e ) {
            //this.timeline.mouseup( e );
            self.globe.mouseup( e )
        } );
        document.addEventListener( moveEvent, function ( e ) {
            /*if ( !self.globe.dragState.isDragging ) {
                self.timeline.mousemove( e )
            }*/
            self.globe.mousemove( e )
        } )
    }
    lookAtCity = ( city ) => {
        this.cities.openOverlay( city );
        this.globe.zoomInOn( city )
    }
    animate = ( time ) => {
        requestAnimationFrame( this.animate );
        /*if ( this.timeline && this.introComplete ) {
            this.timeline.tick( time )
        }*/
        if ( this.globe ) {
            this.globe.tick( time )
        }
    }
    buildUI = () => {
        if ( !this.built ) {
            this.pages = new Pages();
            this.nav = new Nav();
            this.build = true
        }
    }
}