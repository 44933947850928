import { TweenLite, Quad } from 'gsap';
import * as THREE from 'three';
import { COLOR_SHADER } from './helpers';


const VERTEX = "\nvarying vec2 v_uv;\nvarying vec3 colorP;\n\n" + COLOR_SHADER + "\n\nvoid main() {\n  v_uv = uv;\n  vec4 mvPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0);\n  mvPosition.xy += position.xy;\n\n  colorP = positionBasedColor(modelMatrix * vec4(0.0, 0.0, 0.0, 1.0));\n\n  gl_Position = projectionMatrix * mvPosition;\n}\n";
const FRAG = "\nuniform float opacity;\nuniform float r;\nuniform vec2 disc; // disc.x is the radius of hover disc, disc.y is visibilty of the hover disc\nvarying vec2 v_uv;\nvarying vec3 colorP;\n\nvoid main() {\n  // Distance to center\n  float dToC = distance(v_uv, vec2(0.5));\n  // Scale position to the current radius setting\n  float _r = max(1. - dToC / r, 0.0) ; // 0 -> 1 in the center;\n  // Increase fall off\n  float c = pow(_r, 2.5);\n\n  vec3 glow = colorP * c * opacity;\n\n  // Draw disc\n  vec3 discC = vec3(1, 1, 1) * step(0.1, disc.y) * step(dToC, disc.x);\n\n  gl_FragColor = vec4(glow + discC, 1.);\n}\n";
const SIZE = .21;


export default class HoverGlow {
    constructor() {
        let texture = new THREE.TextureLoader()
        texture.load( "/marker.png" );
        texture.flipY = false;
        this.uniforms = {
            opacity: {
                value: 0,
                type: "f"
            },
            r: {
                value: .001,
                type: "f"
            },
            disc: {
                value: new THREE.Vector2( 0, 0 ),
                type: "v2"
            }
        };
        var material = new THREE.ShaderMaterial( {
            uniforms: this.uniforms,
            vertexShader: VERTEX,
            fragmentShader: FRAG,
            blending: THREE.AdditiveBlending,
            depthTest: false,
            transparent: true
        } );
        var geometry = new THREE.PlaneGeometry( SIZE, SIZE, 1 );
        var plane = new THREE.Mesh( geometry, material );
        this.mesh = plane;
    }
    moveTo ( x, y, z ) {
        this.mesh.position.x = x;
        this.mesh.position.y = y;
        this.mesh.position.z = z
    }
    show ( small ) {
        TweenLite.to( this.uniforms.opacity, .25, {
            value: .8,
            ease: Quad.easeOut
        } );
        TweenLite.to( this.uniforms.r, .25, {
            value: .5 * ( small ? .35 : 1 ),
            ease: Quad.easeOut
        } );
        this.uniforms.disc.value.x = .029;
        this.uniforms.disc.value.y = small ? 0 : 1
    }
    hide () {
        TweenLite.to( this.uniforms.opacity, .2, {
            value: 0,
            ease: Quad.easeInOut
        } );
        TweenLite.to( this.uniforms.r, .2, {
            value: .001,
            ease: Quad.easeInOut
        } );
        this.uniforms.disc.value.y = 0
    }
}