import EventEmitter from 'events';

export default class Tooltip extends EventEmitter {
    constructor(element) {
        super();
        this.$tooltip = element;
        this.title = "";
        element.innerHTML = "";
    }
    setTitle(title) {
        this.$tooltip.innerHTML = title;
        this.$tooltip.classList.add("appear")
    }
    clear() {
        this.title = "";
        this.$tooltip.innerHTML = "";
        this.$tooltip.classList.remove("appear")
    }
    tick(mouseX, mouseY) {
        this.$tooltip.style.left = mouseX + "px";
        this.$tooltip.style.top = mouseY + "px"
    }
}