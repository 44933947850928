import * as THREE from 'three';
import { TweenLite, Quint } from "gsap";
import EventEmitter from 'events';
import Glows from './Glows';
import EarthGlow from './EarthGlow';
import ParticleSystem from './ParticleSystem'
import AudioController from './AudioController';
import Markers from './Markers';
import { HELPERS } from './helpers';
import DataService from './DataService';

window.setFromSpherical = function ( obj, r, p, t ) {
    obj.setFromSpherical( new THREE.Spherical( r, THREE.Math.degToRad( p ), THREE.Math.degToRad( t ) ) )
};

export default class Globe extends EventEmitter {
    constructor(isMobile) {
        super();
        // Constants
        this.viewportHeight = this.getViewportHeight();
        this.isMobile = isMobile;
        this.ROTATION_SPEED = 2.5;
        this.WEATHER_ROTATION_SPEED = 1;
        this.RADIUS = .5;
        this.SEGMENTS = 64;
        this.TILT_AMOUNT = 0 * Math.PI / 180;
        this.setDefaultRotation();
        this.DRAG_SPEED = 20;
        this.WEATHER_OPACITY = .2;
        this.touchPointsDiff = -1;
        this.PINCH_ZOOM_SPEED = .2;
        this.CAMERA_FAR = this.isMobile ? 6.5 : 4.5;
        this.CAMERA_INTRO = this.CAMERA_FAR - .4;
        this.CAMERA_ZOOM_IN = 1.45;
        this.ORIGIN = new THREE.Vector3;
        this.DRAG_THRESHOLD = this.isMobile ? 2 : 0;
        this.renderer = new THREE.WebGLRenderer( {
            alpha: false
        } );
        this.$overlayWrapper = document.querySelector(".city-overlay-wrapper");
        this.$topCities = Array.from(document.querySelectorAll(".cities-wrapper .city span"));
        this.$home = document.querySelector( "#home" );
        this.globe = document.querySelector( "#globe" );
        this.globe.appendChild( this.renderer.domElement );

        this.renderer.setClearColor( 0, 1 );
        const ratio = HELPERS.DPR();
        this.renderer.setPixelRatio( ratio );
        this.renderer.setSize( window.innerWidth, this.viewportHeight );

        this.scene = new THREE.Scene;

        this.camera = new THREE.PerspectiveCamera( 15, window.innerWidth / this.viewportHeight, .01, 100 );
        this.camera.position.z = this.CAMERA_INTRO;
        this.cameraSpherical = new THREE.Spherical;
        this.dragCamera = false;
        this.dragCameraSpherical = new THREE.Spherical;
        this.cameraGoal = new THREE.Vector3;

        // Light Group
        this.lightGroup = new THREE.Object3D;
        this.scene.add( this.lightGroup );

        // Light One
        this.light1 = new THREE.DirectionalLight( 16775912, 0 );
        this.light1.position.setFromSpherical( new THREE.Spherical( 1, THREE.Math.degToRad( 100 ), THREE.Math.degToRad( 10 ) ) );
        this.lightGroup.add( this.light1 );

        // Light Two
        this.light2 = new THREE.DirectionalLight( 16775912, 0 );
        this.light2.position.set( -13, 10, -20 );
        this.light2GoalPosition = ( new THREE.Vector3 ).setFromSpherical( new THREE.Spherical( 1, THREE.Math.degToRad( 75 ), THREE.Math.degToRad( -147.5 ) ) );
        this.lightGroup.add( this.light2 );

        this.topGlobGroup = new THREE.Object3D;
        this.globeGroup = new THREE.Object3D;
        this.globeGroup.rotation.y = -35 * Math.PI / 180;
        this.topGlobGroup.add( this.globeGroup );
        this.topGlobGroup.rotation.z = this.TILT_AMOUNT;
        this.scene.add( this.topGlobGroup );

        // Earth Glow
        this.earthglow = new EarthGlow();
        this.topGlobGroup.add( this.earthglow.mesh );

        this.markers = false;
        this.pointer = new THREE.Vector2( -1, 1 );
        this.overMarkerId = false;

        this.dragState = {
            maybe: false,
            isDragging: false,
            start: {
                x: 0,
                y: 0
            },
            prev: {
                x: 0,
                y: 0
            }
        };
        this.time = 0;
        this.isZoomedOut = true;
        this.$soundOn = document.querySelector("#icon-sound");
        this.$soundOff = document.querySelector("#icon-sound-off");
    }
    setDefaultRotation () {
        this.maxXRotate = 15 * Math.PI / 180 + .5 * Math.PI;
        this.minXRotate = -15 * Math.PI / 180 + .5 * Math.PI
    }
    setZoomedInRotation () {
        this.maxXRotate = 60 * Math.PI / 180 + .5 * Math.PI;
        this.minXRotate = -60 * Math.PI / 180 + .5 * Math.PI
    }
    colorTest () {
        this.globeGroup.add( new THREE.Mesh( new THREE.SphereGeometry( this.RADIUS + .025, 12, 12 ), new THREE.ShaderMaterial( {
            vertexShader: "\n        varying vec3 color;\n\n        void main() {\n          vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);\n\n          color = vec3(1.0, 0.2, 0.8);\n\n          // World Position\n          // vec4 wPos = modelMatrix * vec4(position, 1.0 );\n          // float xmix = min(1., max(0., (wPos.x / 0.25) * 0.5 + 0.5));\n          // float ymix = min(1., max(0., (wPos.y / 0.25) * 0.5 + 0.5));\n          // color =\n          //   vec3(.906, .278, .294) * (1. - ymix) * 0.5 +\n          //   vec3(.306, .259, .545) * xmix +\n          //   vec3(.835, .803, .392) * (1. - xmix);\n\n          // Camera Position\n          float xmix = min(1., max(0., (mvPosition.x / 0.25) * 0.5 + 0.5));\n          float ymix = min(1., max(0., (mvPosition.y / 0.25) * 0.5 + 0.5));\n          color =\n            vec3(1., .486, 0.451) * (1. - ymix) +\n            vec3(.62, .6, 1.) * ymix +\n            vec3(1., .957, .502) * xmix +\n            vec3(.6, 1., .6) * (1. - xmix);\n\n          gl_Position = projectionMatrix * mvPosition;\n        }",
            fragmentShader: "\n        varying vec3 color;\n\n        void main() {\n          gl_FragColor = vec4(color, 1.0);\n        }",
            blending: THREE.NormalBlending
        } ) ) )
    }
    loadWeather () {
        const weatherDistance = .001;
        const textureLoader = new THREE.TextureLoader;
        this.weatherImg = textureLoader.load( "/weather.jpeg" );
        this.weatherImg.mapping = THREE.UVMapping;
        const shape = new THREE.SphereGeometry( this.RADIUS + weatherDistance, this.SEGMENTS, this.SEGMENTS );
        const material = new THREE.MeshLambertMaterial();
        material.map = this.weatherImg;
        material.transparent = true;
        material.opacity = this.WEATHER_OPACITY;
        material.blending = THREE.AdditiveBlending;
        this.weatherMesh = new THREE.Mesh( shape, material );
        this.globeGroup.add( this.weatherMesh )
    }
    loadEarthImages ( onLoaded ) {
        let done = 0;
        const textureLoader = new THREE.TextureLoader();
        const imagePath = "/earth.jpeg";
        this.earth_img = textureLoader.load( imagePath, () => {
            done++;
            if ( done === 1 )
                onLoaded()
        } );
        this.earth_img.mapping = THREE.UVMapping
    }
    loadStars () {
        const starDistance = .001;
        const textureLoader = new THREE.TextureLoader();
        this.starImg = textureLoader.load( "/star.jpeg" );
        this.starImg.mapping = THREE.UVMapping;
        this.starImg.wrapS = THREE.RepeatWrapping;
        this.starImg.wrapT = THREE.RepeatWrapping;
        this.starImg.repeat.set( 7, 7 );
        const shape = new THREE.SphereGeometry( this.RADIUS + starDistance + 4, 8, 7 );
        const material = new THREE.MeshLambertMaterial();
        material.map = this.starImg;
        this.starMesh = new THREE.Mesh( shape, material );
        this.starMesh.material.side = THREE.BackSide;
        this.scene.add( this.starMesh )
    }
    playIntro () {
        TweenLite.to( this.earthglow.uniforms.opacity, 4, {
            value: 1,
            ease: Quint.EaseOut,
            delay: .7
        } );
        TweenLite.to( this.light2, 1, {
            intensity: 3,
            ease: Quint.EaseOut,
            delay: .7
        } );
        TweenLite.to( this.light1, 2, {
            intensity: .8,
            ease: Quint.EaseOut,
            delay: 2
        } );
        TweenLite.to( this.camera.position, 6, {
            z: this.CAMERA_FAR,
            ease: Quint.EaseOut,
            delay: .5
        } );
        this.showEntrance();
        //Analytics.createEvent( "click", "Home", "/home", true )
    }
    showEntrance () {
        const self = this;
        const $introBtn = document.querySelector( "#intro-btn" );
        const $introMainSite = document.querySelector( ".intro-main--site" );
        const $intro = document.querySelector( "#intro" );
        $introMainSite.querySelector( ".intro-title" ).classList.add( "visible" );
        TweenLite.delayedCall( .8, function () {
            $introMainSite.querySelector( ".intro-subtitle" ).classList.add( "visible" )
        } );
        TweenLite.delayedCall( 1.6, function () {
            $introBtn.classList.add( "scale" )
        } );
        $introBtn.addEventListener( "click", function () {
            AudioController.enableWebAudio();
            AudioController.startBackground();
            $introMainSite.querySelector( ".intro-caption" ).classList.add( "fade" );
            $introMainSite.querySelector( ".intro-title" ).classList.add( "fade" );
            $introMainSite.querySelector( ".intro-subtitle" ).classList.add( "fade" );
            $introMainSite.querySelector( ".intro-btn" ).classList.add( "scale-in" );
            TweenLite.delayedCall( .5, function () {
                self.emit( "intro-complete" )
            } );
            TweenLite.to( self.light1, 2, {
                intensity: 1,
                ease: Quint.EaseOut
            } );
            TweenLite.to( self.light2.position, 3, {
                x: self.light2GoalPosition.x,
                y: self.light2GoalPosition.y,
                z: self.light2GoalPosition.z,
                ease: Quint.EaseOut
            } );
            TweenLite.to( self.light2, 3, {
                intensity: 5,
                ease: Quint.EaseOut
            } );
            //_analytics.Analytics.createEvent( "click", "clickable_link, landing_page, explore", "Explore Button" );
            TweenLite.delayedCall( 2, function () {
                $intro.style.display = "none";
                self.addEvents()
            } )
        } );
    }
    load () {
        const self = this;
        var shouldPlayIntro = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        this.shouldPlayIntro = shouldPlayIntro;
        this.playIntro();
        return new Promise( function ( resolve ) {
            self.loadWeather();
            self.loadStars();
            self.loadEarthImages( function () {
                var earthMaterial = new THREE.MeshPhongMaterial( {
                    map: self.earth_img,
                    specular: new THREE.Color( 0, 0, 0 ),
                    shininess: .25
                } );
                self.globeGroup.add( new THREE.Mesh( new THREE.SphereGeometry( self.RADIUS, self.SEGMENTS, self.SEGMENTS ), earthMaterial ) );
                self.particles = new ParticleSystem();
                self.globeGroup.add( self.particles.mesh );
                self.glows = new Glows( 300, self.globeGroup );
                self.autoRotating = true;
                self.interactionEnabled = true;
                self.on( "intro-complete", function () {
                    this.$home.style.opacity = "1";
                    resolve()
                } )
            } )
        } )
    }
    overMarker ( markerId ) {
        if ( markerId !== this.overMarkerId ) {
            this.leaveAllMarkers();
            this.overMarkerId = markerId;
            if ( !this.isMobile ) {
                this.onMarkerEnter( markerId )
            }
        }
    }
    leaveAllMarkers () {
        if ( this.overMarkerId ) {
            this.onMarkerLeave( this.overMarkerId );
            this.overMarkerId = false;
            this.emit( "hover-off" )
        }
    }
    onMarkerEnter ( markerId ) {
        var point = this.markers.getPointById( markerId );
        if ( point )
            this.emit( "hover-city", point );
        document.body.style.cursor = "pointer";
        if ( point && !this.currentLookedAtCity )
            this.particles.increaseRate( markerId, point.pos, point.howmany );
        this.markers.select( markerId );
        AudioController.playOneShot()
    }
    onMarkerLeave ( markerId ) {
        document.body.style.cursor = "";
        this.emit( "hover-off" );
        this.markers.deselect( markerId );
        this.particles.decreaseRate( markerId )
    }
    mouseOverTopCities () {
        var topCities = this.$topCities;
        for ( var i = 0; i < topCities.length; i++ ) {
            var overlayCoords = topCities[i].getBoundingClientRect();
            if ( !( this.clientX < overlayCoords.left || this.clientX > overlayCoords.left + overlayCoords.width || this.clientY < overlayCoords.top || this.clientY > overlayCoords.top + overlayCoords.height ) )
                return true
        }
        return false
    }
    mouseOverCityOverlay () {
        var overlayCoords = this.$overlayWrapper.getBoundingClientRect();
        return !( this.clientX < overlayCoords.left || this.clientX > overlayCoords.left + overlayCoords.width || this.clientY < overlayCoords.top || this.clientY > overlayCoords.top + overlayCoords.height )
    }
    tick ( time ) {
        this.time = time;
        if ( this.dragCamera ) {
            var startRotation = this.camera.quaternion.clone();
            var thetaDiff = this.dragCameraSpherical.theta - this.cameraSpherical.theta;
            var phiDiff = this.dragCameraSpherical.phi - this.cameraSpherical.phi;
            this.cameraSpherical.theta = this.cameraSpherical.theta + thetaDiff * .08;
            this.cameraSpherical.phi = this.cameraSpherical.phi + phiDiff * .08;
            this.camera.position.setFromSpherical( this.cameraSpherical );
            this.camera.lookAt( this.ORIGIN );
            var endRotation = this.camera.quaternion.clone();
            var deltaRotation = endRotation.multiply( startRotation.invert() );
            var rotateGlobeBy = new THREE.Quaternion;
            rotateGlobeBy.slerp( deltaRotation, .9 );
            this.starMesh.applyQuaternion( rotateGlobeBy )
        }
        this.lightGroup.rotation.copy( this.camera.rotation );
        if ( this.isMobile && !!this.lookingAt || !!this.lookingAt && this.mouseOverCityOverlay() || !!this.markers === false || this.dragState.isDragging || !this.isMobile && this.mouseOverTopCities() ) {
            this.leaveAllMarkers()
        } else {
            var overId = this.markers.raycast( this.pointer, this.camera );
            if ( overId ) {
                this.overMarker( overId )
            } else {
                this.leaveAllMarkers()
            }
        }
        if ( this.autoRotating ) {
            this.globeGroup.rotation.y += this.ROTATION_SPEED * Math.PI / 18e3;
            this.starMesh.rotation.y += .05 * this.ROTATION_SPEED * Math.PI / 18e3
        }
        if ( this.weatherMesh && this.autoRotating ) {
            this.weatherMesh.rotation.y += this.WEATHER_ROTATION_SPEED * Math.PI / 18e3
        }
        if ( this.markers ) this.markers.tick( time );
        if ( this.particles ) this.particles.tick( time );
        this.renderer.render( this.scene, this.camera )
    }
    addMarkers ( points ) {
        const self = this;
        this.points = points;
        //console.log( 'points', points )
        var clusters = HELPERS.cluster( points, 4e5 );
        this.clusters = clusters.map( function ( c ) {
            if ( c.length === 1 ) {
                return c[0]
            } else {
                var point = c.reduce( function ( a, o ) {
                    a.lat += o.lat;
                    a.lon += o.lon;
                    a._howmany += o.howmany;
                    return a
                }, {
                    lat: 0,
                    lon: 0,
                    _howmany: 0
                } );
                point.lat /= c.length;
                point.lon /= c.length;
                point.pos = HELPERS.xyzFromLatLng( point.lat, point.lon, self.RADIUS );
                point.city = c[0].city + " combined " + c.length;
                point.region = c[0].region;
                point.country = c[0].country;
                point.distance = 0;
                point.howmany = Math.min( 150, 3 * point._howmany / c.length );
                point.combined = true;
                return point
            }
        } );
        this.clearSpawners();
        if ( !this.markers ) {
            this.markers = new Markers( this.isMobile );
            this.globeGroup.add( this.markers.mesh );
            this.globeGroup.add( this.markers.hoverglow.mesh )
        }
        this.markers.updatePoints( this.points );
        if ( !!this.currentLookedAtCity === true ) {
            this.markers.highlight( HELPERS.pointId( this.currentLookedAtCity ) );
            var point = this.points.find( function ( o ) {
                return o.key === self.currentLookedAtCity.key
            } );
            if ( point ) {
                this.particles.addSpawner( HELPERS.pointId( this.currentLookedAtCity ), this.currentLookedAtCity.pos, point ? point.howmany * 10 : 5, 1, new THREE.Vector2( 1 + 2.5 * Math.random(), 1.5 + 2 * Math.random() ) )
            }
        } else {
            this.addSpawners()
        }
    }
    addSpawners () {
        const self = this;
        if ( this.particles ) {
            this.clusters.forEach( function ( p ) {
                var area = void 0;
                if ( p.combined ) {
                    area = new THREE.Vector2( 2.5, 2.5 )
                }
                self.particles.addSpawner( HELPERS.pointId( p ), p.pos, p.howmany, p.distance, area )
            } );
            this.glows.update( this.clusters )
        }
    }
    clearSpawners () {
        var quick = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        if ( this.particles ) {
            this.particles.clearSpawners( quick );
            this.glows.clear()
        }
    }
    setDate ( date ) {
        const self = this;
        if ( !this.loadingDate ) {
            this.loadingDate = true;
            this.nextDate = null;
            const dataService = new DataService();
            dataService.getDate( date ).then( function ( response ) {
                self.loadingDate = false;
                if ( response ) {
                    //console.log( response );
                    var dist = [];
                    var markerData = response.map( function ( point ) {
                        dist.push( point.distance );
                        var min = 20;
                        var max = 3e3;
                        var value = point.value;
                        value = value < min ? min : value;
                        value = value > max ? max : value;
                        var howmany = Math.round( HELPERS.mapRange( value, min, max, 10, 30 ) );
                        var pos = HELPERS.xyzFromLatLng( point.lat, point.lon, self.RADIUS );
                        if ( self.nextDate ) {
                            self.setDate( self.nextDate )
                        }
                        return {
                            pos: pos,
                            howmany: howmany,
                            lat: point.lat,
                            lon: point.lon,
                            key: point.key,
                            value: point.value,
                            city: point.city,
                            region: point.region,
                            country: point.country,
                            distance: 0
                        }
                    } );
                    self.addMarkers( markerData );
                    self.emit( "day-loaded", markerData )
                }
            } )
        } else {
            this.nextDate = date
        }
    }
    lookAtCamera ( lookAt ) {
        var cityPos = this.globeGroup.localToWorld( lookAt.clone() );
        var cameraPos = cityPos.clone();
        cameraPos.normalize().multiplyScalar( this.CAMERA_ZOOM_IN );
        var cameraClone = this.camera.clone();
        cameraClone.position.copy( cameraPos );
        cameraClone.lookAt( cityPos );
        var fov = THREE.Math.degToRad( cameraClone.fov );
        var dist = cameraClone.position.distanceTo( cityPos );
        var height = 2 * Math.tan( fov / 2 ) * dist;
        var width = height * cameraClone.aspect;
        var x = 0
            , y = 0;
        if ( window.innerWidth < 992 ) {
            y = -height * .25
        } else if ( window.innerWidth < 1300 ) {
            x = width * .3
        } else {
            x = width * .25
        }
        var vx = new THREE.Vector3( 1, 0, 0 );
        vx.applyQuaternion( cameraClone.quaternion );
        cityPos.add( vx.multiplyScalar( x ) );
        var vy = new THREE.Vector3( 0, 1, 0 );
        vy.applyQuaternion( cameraClone.quaternion );
        cityPos.add( vy.multiplyScalar( y ) );
        cameraClone.position.copy( cityPos ).normalize().multiplyScalar( this.CAMERA_ZOOM_IN );
        cameraClone.lookAt( cityPos );
        return cameraClone
    }
    zoomInOn ( city ) {
        const self = this;
        this.dragCamera = false;
        this.currentLookedAtCity = city;
        this.lookingAt = new THREE.Vector3( city.pos.x, city.pos.y, city.pos.z );
        this.markers.decreaseHitArea();
        this.autoRotating = false;
        this.dragDisabled = true;
        if ( this.isZoomedOut ) {
            AudioController.zoomIn()
        }
        this.isZoomedOut = false;
        TweenLite.killTweensOf( this.cameraSpherical );
        this.cameraSpherical.setFromVector3( this.camera.position ).makeSafe();
        var goalCamera = this.lookAtCamera( this.lookingAt );
        var goalSpherical = new THREE.Spherical;
        goalSpherical.setFromVector3( goalCamera.position ).makeSafe();
        var thetaDiff = goalSpherical.theta - this.cameraSpherical.theta;
        if ( thetaDiff > Math.PI ) {
            goalSpherical.theta -= Math.PI * 2
        } else if ( thetaDiff < -Math.PI ) {
            goalSpherical.theta += Math.PI * 2
        }
        TweenLite.to( this.cameraSpherical, 2.5, {
            radius: goalSpherical.radius,
            phi: goalSpherical.phi,
            theta: goalSpherical.theta,
            ease: Quint.easeOut,
            onUpdate: function onUpdate () {
                self.camera.position.setFromSpherical( self.cameraSpherical );
                self.camera.lookAt( self.ORIGIN )
            }
        } );
        TweenLite.to( this.weatherMesh.material, 1, {
            opacity: 0
        } );
        this.clearSpawners();
        this.markers.highlight( HELPERS.pointId( city ) );
        this.particles.addSpawner( HELPERS.pointId( city ), city.pos, city.howmany * 10, 1, new THREE.Vector2( 1 + 3 * Math.random(), 1.5 + 2.5 * Math.random() ) )
    }
    zoomOut () {
        const self = this;
        this.setDefaultRotation();
        this.currentLookedAtCity = false;
        this.lookingAt = false;
        this.markers.setDefaultHitArea();
        this.autoRotating = true;
        this.interactionEnabled = true;
        this.dragDisabled = false;
        if ( !this.isZoomedOut ) {
            AudioController.zoomOut()
        }
        this.isZoomedOut = true;
        TweenLite.killTweensOf( this.cameraSpherical );
        this.cameraSpherical.setFromVector3( this.camera.position ).makeSafe();
        var p = new THREE.Vector3;
        p.copy( this.camera.position );
        p.y = 0;
        p.normalize().multiplyScalar( this.CAMERA_FAR );
        var goalSpherical = new THREE.Spherical;
        goalSpherical.setFromVector3( p );
        goalSpherical.makeSafe();
        TweenLite.to( this.cameraSpherical, 2.5, {
            radius: goalSpherical.radius,
            phi: goalSpherical.phi,
            theta: goalSpherical.theta,
            ease: Quint.easeOut,
            onUpdate: function onUpdate () {
                self.camera.position.setFromSpherical( self.cameraSpherical );
                self.camera.lookAt( self.ORIGIN )
            }
        } );
        TweenLite.to( this.weatherMesh.material, 2.5, {
            opacity: this.WEATHER_OPACITY
        } );
        this.clearSpawners();
        this.addSpawners();
        this.markers.unhighlight()
    }
    onDragStart () {
        this.leaveAllMarkers();
        document.body.classList.add( "is-dragging" );
        this.autoRotating = false;
        this.cameraSpherical.setFromVector3( this.camera.position ).makeSafe();
        this.dragCameraSpherical.setFromVector3( this.camera.position ).makeSafe()
    }
    onDrag ( dx, dy ) {
        this.dragCamera = true;
        if ( dx ) {
            this.dragCameraSpherical.theta -= dx * this.DRAG_SPEED * Math.PI / 18e3
        }
        if ( dy ) {
            this.dragCameraSpherical.phi -= dy * this.DRAG_SPEED * Math.PI / 18e3;
            this.dragCameraSpherical.phi = Math.min( Math.max( this.dragCameraSpherical.phi, this.minXRotate ), this.maxXRotate )
        }
        this.dragCameraSpherical.makeSafe()
    }
    onDragStop () {
        document.body.classList.remove( "is-dragging" );
        this.autoRotating = true
    }
    stopDrag () {
        this.dragState.maybe = false;
        if ( this.dragState.isDragging ) {
            this.dragState.isDragging = false;
            this.onDragStop()
        }
    }
    mouseup ( e ) {
        var wasDragging = this.dragState.isDragging;
        this.stopDrag( e );
        if ( !this.interactionEnabled || wasDragging || this.pinching )
            return;
        if ( this.markers && this.overMarkerId !== false && !wasDragging ) {
            this.gotoCity( this.overMarkerId );
            var point = this.markers.getPointById( this.overMarkerId );
            if ( point && this.currentLookedAtCity !== point ) {
                /*if ( _settings.Settings.isEmbedMode )
                    return;*/
                this.currentLookedAtCity = point;
                //_analytics.Analytics.createEvent( "click", "Globe Click - " + point.key.replace( " ", "" ), "clickable_link, home, globe_" + point.key.replace( " ", "" ) );
                this.emit( "look-at-city", point );
                AudioController.onClick()
            }
        }
        return
    }
    gotoCity ( id ) {
        /* if ( _settings.Settings.isEmbedMode ) {
             window.open( "/location#" + id, "_blank" );
             return
         }*/
         var point = this.markers.getPointById( id );
         if ( point && this.currentLookedAtCity !== point ) {
             this.currentLookedAtCity = point;
             //_analytics.Analytics.createEvent( "click", "Globe Click - " + point.key.replace( " ", "" ), "clickable_link, home, globe_" + point.key.replace( " ", "" ) );
             this.emit( "look-at-city", point );
             AudioController.onClick()
         }
    }
    mousemove ( e ) {
        if ( !this.interactionEnabled ) {
            return
        }
        e.preventDefault();
        if ( this.isMobile ) {
            if ( e.targetTouches.length === 2 ) {
                var curDiff = Math.abs( e.targetTouches[0].clientX - e.targetTouches[1].clientX );
                if ( curDiff > this.touchPointsDiff ) {
                    this.cameraSpherical.setFromVector3( this.camera.position ).makeSafe();
                    if ( this.cameraSpherical.radius - this.PINCH_ZOOM_SPEED > this.CAMERA_ZOOM_IN ) {
                        this.setZoomedInRotation();
                        this.cameraSpherical.radius -= this.PINCH_ZOOM_SPEED;
                        this.camera.position.setFromSpherical( this.cameraSpherical )
                    } else {
                        this.setDefaultRotation()
                    }
                }
                if ( curDiff < this.touchPointsDiff ) {
                    this.cameraSpherical.setFromVector3( this.camera.position ).makeSafe();
                    if ( this.cameraSpherical.radius + this.PINCH_ZOOM_SPEED < this.CAMERA_FAR ) {
                        this.setZoomedInRotation();
                        this.cameraSpherical.radius += this.PINCH_ZOOM_SPEED;
                        this.camera.position.setFromSpherical( this.cameraSpherical )
                    } else {
                        this.setDefaultRotation()
                    }
                }
                this.touchPointsDiff = curDiff;
                this.pinching = true;
                return
            }
        }
        this.pinching = false;
        var x = Math.floor( this.isMobile ? e.touches[0].clientX : e.clientX );
        var y = Math.floor( this.isMobile ? e.touches[0].clientY : e.clientY );
        this.clientX = x;
        this.clientY = y;
        this.pointer.x = x / window.innerWidth * 2 - 1;
        this.pointer.y = -( y / this.viewportHeight ) * 2 + 1;
        if ( !this.dragDisabled ) {
            var dx = Math.abs( x - this.dragState.start.x );
            var dy = Math.abs( y - this.dragState.start.y );
            if ( this.dragState.maybe && ( dx > this.DRAG_THRESHOLD || dy > this.DRAG_THRESHOLD ) ) {
                this.dragState.maybe = false;
                this.dragState.isDragging = true;
                this.dragState.prev.x = this.dragState.start.x;
                this.dragState.prev.y = this.dragState.start.y;
                this.onDragStart()
            }
            if ( this.dragState.isDragging ) {
                this.onDrag( x - this.dragState.prev.x, y - this.dragState.prev.y );
                this.dragState.prev.x = x;
                this.dragState.prev.y = y;
                //_analytics.Analytics.createEvent( "interaction", "Moves Globe" )
            }
        }
    }
    addEvents () {
        const self = this;
        this.$soundOn.addEventListener( "click", function () {
            AudioController.soundOff();
            self.$soundOn.classList.remove( "active" );
            self.$soundOff.classList.add( "active" );
            //_analytics.Analytics.createEvent( "click", "Sound Toggle", "clickable_link, nav, sound_toggle" )
        } );
        this.$soundOff.addEventListener( "click", function () {
            AudioController.soundOn();
            self.$soundOff.classList.remove( "active" );
            self.$soundOn.classList.add( "active" );
            //_analytics.Analytics.createEvent( "click", "Sound Toggle", "clickable_link, nav, sound_toggle" )
        } );
        var downEvent = this.isMobile ? "touchstart" : "mousedown";
        this.globe.addEventListener( downEvent, function ( e ) {
            if ( !self.interactionEnabled ) {
                return
            }
            e.preventDefault();
            self.dragCamera = false;
            var x = Math.floor( self.isMobile ? e.touches[0].clientX : e.clientX );
            var y = Math.floor( self.isMobile ? e.touches[0].clientY : e.clientY );
            if ( !self.dragState.isDragging && self.isMobile ) {
                self.pointer.x = x / window.innerWidth * 2 - 1;
                self.pointer.y = -( y / self.viewportHeight ) * 2 + 1
            }
            self.dragState.maybe = true;
            self.dragState.start.x = x;
            self.dragState.start.y = y
        } )
    }
    getViewportHeight () {
        return window.innerWidth <= 991 ? document.getElementById( "globe" ).clientHeight : window.innerHeight
    }
    resize () {
        this.viewportHeight = this.getViewportHeight();
        this.camera.aspect = window.innerWidth / this.viewportHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( window.innerWidth, this.viewportHeight );
        if ( this.lookingAt ) {
            var goalCamera = this.lookAtCamera( this.lookingAt );
            this.camera.position.copy( goalCamera.position );
            this.camera.rotation.copy( goalCamera.rotation )
        }
    }
    setMouse ( x, y ) {
        if ( this.markers && !this.isZoomedOut ) {
            this.pointer.x = x / window.innerWidth * 2 - 1;
            this.pointer.y = -( y / this.viewportHeight ) * 2 + 1;
            var id = this.markers.raycast( this.pointer, this.camera );
            if ( id ) {
                this.gotoCity( id )
            }
        }
    }
}