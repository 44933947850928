import EventEmitter from 'events';
import { TweenMax, TweenLite, Linear, Quint } from 'gsap';
import AudioController from './AudioController';

export default class Pages extends EventEmitter {
    constructor() {
        super();
        this.introComplete = false;
        this.pageOpen = false;
        this.firstHomeLoad = true;
        this.deeplink = false;
        this.$intro = document.querySelector( "#intro" );
        this.$introBtn = document.querySelector( "#intro-btn" );
        this.$globe = document.querySelector( "#globe" );
        this.$nav = document.querySelector( "#home-nav" );
        this.$mobileNav = document.querySelector( "#mobile-menu-btn" );
        this.$cities = document.querySelector( "#cities-wrapper" );
        this.$sliderTitle = document.querySelector( "#cities-slider" );
        this.$mobileCitiesBtn = document.querySelector( "#cities-btn" );
        this.$timeline = document.querySelector( "#timeline" );
        this.$timelineMobile = document.querySelector( "#timeline-mobile" );
        this.$timelineCanvas = document.querySelector( "#timelineCanvas" );
        this.$topCity = [];
        this.$topCity[0] = document.querySelector( "[data-city-1]" );
        this.$topCity[1] = document.querySelector( "[data-city-2]" );
        this.$topCity[2] = document.querySelector( "[data-city-3]" );
        this.$topCity[3] = document.querySelector( "[data-city-4]" );
        this.$topCity[4] = document.querySelector( "[data-city-5]" );
        this.$aboutBtn = document.querySelector( "#about-btn" );
        this.$aboutCloseBtn = document.querySelector( "#about-close" );
        this.$resourcesBtn = document.querySelector( "#resources-btn" );
        this.$resourcesCloseBtn = document.querySelector( "#resources-close" );
        this.$resourcesMobileBtn = document.querySelector( "#resources-btn-mobile" );
        this.$aboutMobileBtn = document.querySelector( "#about-btn-mobile" );
        this.$aboutPage = document.querySelector( "#about-page" );
        this.$resourcesPage = document.querySelector( "#resources-page" );
        this.$aboutTitle = document.querySelector( "#about-title" );
        this.$aboutBody = document.querySelector( "#about-body" );
        this.$aboutResourcesLink = document.querySelector( "#about-resources-link" );
        this.$aboutTrendsLink = document.querySelector( "#about-trends-link" );
        this.$resourcesTitle = document.querySelector( "#resources-title" );
        this.$resourcesBody = document.querySelector( "#resources-body" );
        this.aboutPage();
        //this.resourcesPage();
        this.currentAnimation;
        const self = this;
        window.onpopstate = function () {
            self.handleState( window.location.pathname )
        };
        window.handleState = this.handleState.bind( this );
        setTimeout( function () {
            self.handleState( window.location.pathname )
        }, 1 );
    }
    handleState ( state ) {
        const self = this;
        var path = state;
        var citySlug = void 0;
        if ( location.hash && location.hash !== "#" || location.hash !== "" ) {
            var parsedHash = location.hash.split( "#" );
            if ( parsedHash.length > 1 ) {
                citySlug = parsedHash[1];
                path = "/location"
            }
        }
        if ( this.currentPath === path ) {
            return
        }
        this.currentPath = path;
        //_settings.Settings.globe.interactionEnabled = true;
        switch ( path ) {
            /*case "/resources":
                this.deeplink = true;
                //_settings.Settings.globe.interactionEnabled = false;
                this.showResourcesPage();
                this.hideHome();
                this.hideAboutPageContent();
                break;*/
            case "/about":
                //_settings.Settings.globe.interactionEnabled = false;
                this.deeplink = true;
                this.showAboutPage();
                //this.hideResourcesPageContent();
                this.hideHome();
                break;
            case "/embed":
                this.hideAboutPageContent();
                //this.hideResourcesPageContent();
                this.hideHome();
                this.showEmbedPage();
                //_settings.Settings.isEmbedMode = true;
                break;
            case "/test":
                this.introComplete = true;
                this.showHome();
                setTimeout( function () {
                    _this2.$introBtn.click()
                }, 1e3 );
                break;
            case "/location":
                this.introComplete = true;
                this.showHome();
                setTimeout( function () {
                    _this2.$introBtn.click();
                    _this2.emit( "open-city", citySlug )
                }, 1e3 );
                break;
            case "/":
            default:
                //this.hideResourcesPageContent();
                this.hideAboutPageContent();
                if ( this.introComplete ) {
                    this.showHome()
                } else {
                    //console.log( 'home' )
                    self.$intro.style.opacity = 1;
                    self.$introBtn.addEventListener( "click", function () {
                        self.showHome();
                        if ( self.firstHomeLoad ) {
                            self.firstHomeLoad = false
                        }
                    } )
                    /*if ( this.deeplink ) {
                        this.$intro.classList.add( "deeplink-show" );
                        this.$introBtn.addEventListener( "click", function () {
                            self.showHome();
                            if ( self.firstHomeLoad ) {
                                //_analytics.Analytics.createEvent( "pageload", "Home", "/home", true );
                                self.firstHomeLoad = false
                            }
                        } )
                    }*/
                }
                break
        }
    }
    go ( path ) {
        window.history.pushState( {}, "Me Too Rising", path );
        this.handleState( path )
    }
    animationInterrupt ( newAnimationName ) {
        //TweenMax.kill();
        if ( this.currentAnimation ) {
            if ( this.currentAnimation === "home" ) {
                this.hideHome()
            }
        }
        this.currentAnimation = newAnimationName
    }
    hideHome () {
        this.$globe.classList.add( "blur" );
        TweenMax.to( this.$nav, .2, {
            opacity: 0,
            pointerEvents: "none",
            ease: Linear.noEase,
            delay: 0
        } );
        TweenMax.to( this.$mobileNav, .2, {
            opacity: 0,
            pointerEvents: "none",
            ease: Linear.noEase,
            delay: 0
        } );
        TweenMax.to( this.$cities, .2, {
            opacity: 0,
            y: "-45%",
            ease: Quint.EaseOut,
            delay: .1
        } );
        TweenMax.to( this.$mobileCitiesBtn, .2, {
            opacity: 0,
            y: 10,
            ease: Linear.easeNone,
            delay: .2
        } );
        TweenMax.to( this.$timeline, .2, {
            opacity: 0,
            ease: Linear.noEase,
            delay: .3
        } );
        TweenMax.to( this.$timelineMobile, .2, {
            opacity: 0,
            ease: Linear.noEase,
            delay: .3
        } );
        this.emit( "close-overlay" )
    }
    showHome () {
        const self = this;
        this.currentAnimation = "home";
        this.pageOpen = false;
        this.$globe.classList.remove( "blur" );
        TweenMax.to( this.$nav, .2, {
            opacity: 1,
            pointerEvents: "auto",
            ease: Linear.noEase,
            delay: 0
        } );
        TweenMax.to( this.$mobileNav, .2, {
            opacity: 1,
            pointerEvents: "auto",
            ease: Linear.noEase,
            delay: 0
        } );
        TweenMax.to( this.$cities, .4, {
            opacity: 1,
            y: "-50%",
            ease: Quint.EaseOut,
            delay: .1
        } );
        TweenMax.to( this.$mobileCitiesBtn, .4, {
            opacity: 1,
            y: 0,
            ease: Linear.easeNone,
            delay: .2
        } );
        /*TweenMax.to( this.$timeline, .2, {
            opacity: 1,
            ease: Linear.noEase,
            delay: .3
        } );*/
        TweenMax.to( this.$timelineMobile, .2, {
            opacity: 1,
            ease: Linear.noEase,
            delay: .3
        } );
        /*TweenMax.to( this.$timelineCanvas, .2, {
            opacity: 1,
            y: 0,
            ease: Linear.noEase,
            delay: .4
        } );*/
        TweenLite.to( this.$sliderTitle, .2, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: .1
        } );
        /*this.$topCity.forEach( function ( $city ) {
            TweenLite.to( $city, .2, {
                opacity: 1,
                y: 0,
                ease: Quint.EaseOut,
                delay: .1
            } )
        } );*/
        TweenMax.delayedCall( .6, function () {
            self.currentAnimation = null
        } )
    }
    showResourcesPage () {
        var _this4 = this;
        this.pageOpen = true;
        document.querySelector( "#intro" ).style.opacity = 0;
        this.$resourcesPage.style.pointerEvents = "auto";
        this.animationInterrupt( "resources" );
        this.hideHome();
        //_analytics.Analytics.createEvent( "pageload", "Resources", "/resources", true );
        this.$resourcesPage.classList.add( "active" );
        TweenMax.to( this.$resourcesPage, .8, {
            opacity: 1,
            ease: Quint.EaseOut,
            delay: .1
        } );
        TweenMax.to( this.$resourcesCloseBtn, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: .3
        } );
        TweenMax.to( this.$resourcesTitle, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: .5
        } );
        TweenMax.to( this.$resourcesBody, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: .7
        } );
        TweenMax.delayedCall( 1.2, function () {
            _this4.currentAnimation = null
        } );
        AudioController.overlayIn();
        if ( !this.externalResources ) {
            fetch( "/assets/data/resources.json" ).then( function ( response ) {
                return response.json()
            } ).then( function ( json ) {
                var data = {};
                var $parent = document.querySelector( "#additional-resources" );
                json.forEach( function ( item ) {
                    if ( !data[item.parent_title] ) {
                        data[item.parent_title] = {
                            title: item.parent_title,
                            items: []
                        }
                    }
                    data[item.parent_title].items.push( item )
                } );
                _this4.externalResources = data;
                var template = "";
                Object.keys( data ).forEach( function ( key ) {
                    template += '\n            <li class="resource-item">\n              <a class="resources-parent">' + data[key].title + "</a>\n              <ul>\n              " + data[key].items.map( function ( item ) {
                        return '\n                <li>\n                  <div>\n                  <a href="' + item.website + '" target="_blank">' + item.name + "</a>\n                  " + ( item.phone && item.phone !== "" ? "&nbsp;— " + item.phone : "" ) + "\n                  </div>\n                  <p>" + item.description + "</p>\n                </li>\n                "
                    } ).join( "" ) + "\n              </ul>\n            </li>"
                } );
                $parent.innerHTML = template;
                Array.from( document.querySelectorAll( ".resources-parent" ) ).forEach( function ( item ) {
                    item.addEventListener( "click", function () {
                        if ( item.parentElement.classList.contains( "visible" ) ) {
                            item.parentElement.classList.remove( "visible" )
                        } else {
                            item.parentElement.classList.add( "visible" )
                        }
                    } )
                } )
            } )
        }
    }
    hideResourcesPageContent () {
        var _this5 = this;
        this.$resourcesPage.style.pointerEvents = "none";
        TweenMax.to( this.$resourcesBody, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: 0
        } );
        TweenMax.to( this.$resourcesTitle, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: .1
        } );
        TweenMax.to( this.$resourcesCloseBtn, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: .2
        } );
        TweenMax.to( this.$resourcesPage, .8, {
            opacity: 0,
            ease: Quint.EaseOut,
            delay: 0
        } );
        TweenMax.delayedCall( .9, function () {
            _this5.$resourcesPage.classList.remove( "active" )
        } );
        AudioController.overlayOut()
    }
    /*resourcesPage () {
        var _this6 = this;
        /*this.$resourcesBtn.addEventListener( "click", function () {
            _this6.go( "/resources" )
        } );
        this.$resourcesMobileBtn.addEventListener( "click", function () {
            TweenMax.delayedCall( .25, function () {
                _this6.go( "/resources" )
            } )
        } );
        this.$resourcesPage.addEventListener( "mouseover", function ( e ) {
            e.stopPropagation()
        } );
        this.$resourcesPage.addEventListener( "mouseenter", function ( e ) {
            e.stopPropagation()
        } );
        this.$resourcesPage.addEventListener( "mousemove", function ( e ) {
            e.stopPropagation()
        } );
        this.$resourcesCloseBtn.addEventListener( "click", function () {
            _this6.go( "/" );
            //_analytics.Analytics.createEvent( "click", "Resources Close", "clickable_link, resources, close" );
            TweenMax.delayedCall( .4, function () {
                _this6.showHome()
            } )
        } )
    }*/
    showAboutPage () {
        var _this7 = this;
        this.pageOpen = true;
        document.querySelector( "#intro" ).style.opacity = 0;
        this.$aboutPage.style.pointerEvents = "auto";
        this.animationInterrupt( "about" );
        this.hideHome();
        //_analytics.Analytics.createEvent( "pageload", "About", "/about", true );
        this.$aboutPage.classList.add( "active" );
        TweenMax.to( this.$aboutPage, .8, {
            opacity: 1,
            ease: Quint.EaseOut,
            delay: .1
        } );
        TweenMax.to( this.$aboutCloseBtn, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: .3
        } );
        TweenMax.to( this.$aboutTitle, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: .5
        } );
        TweenMax.to( this.$aboutBody, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: .7
        } );
        TweenMax.delayedCall( 1.2, function () {
            _this7.currentAnimation = null
        } );
        AudioController.overlayIn();
        //document.querySelector( "#iframe-code" ).classList.remove( "visible" )
    }
    hideAboutPageContent () {
        var _this8 = this;
        this.$aboutPage.style.pointerEvents = "none";
        TweenMax.to( this.$aboutBody, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: 0
        } );
        TweenMax.to( this.$aboutTitle, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: .1
        } );
        TweenMax.to( this.$aboutCloseBtn, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: .2
        } );
        TweenMax.to( this.$aboutPage, .8, {
            opacity: 0,
            ease: Quint.EaseOut,
            delay: 0
        } );
        TweenMax.delayedCall( .9, function () {
            _this8.$aboutPage.classList.remove( "active" )
        } );
        AudioController.overlayOut()
    }
    aboutPage () {
        var _this9 = this;
        this.$aboutBtn.addEventListener( "click", function () {
            _this9.go( "/about" )
        } );
        this.$aboutMobileBtn.addEventListener( "click", function () {
            TweenMax.delayedCall( .25, function () {
                _this9.go( "/about" )
            } )
        } );
        this.$aboutCloseBtn.addEventListener( "click", function () {
            _this9.go( "/" );
            //_analytics.Analytics.createEvent( "click", "About Close", "clickable_link, about, close" )
        } );
        /*document.querySelector( "#copy-embed" ).addEventListener( "click", function () {
            document.querySelector( "#iframe-code textarea" ).select();
            document.execCommand( "copy" )
        } );
        document.querySelector( "#embed-link" ).addEventListener( "click", function () {
            var $embed = document.querySelector( "#iframe-code" );
            if ( $embed.classList.contains( "visible" ) ) {
                $embed.classList.remove( "visible" )
            } else {
                $embed.classList.add( "visible" );
                document.querySelector( "#iframe-code textarea" ).value = '<iframe width="560" height="315" src="https://metoorising.withgoogle.com/embed" frameborder="0" allowfullscreen></iframe>'
            }
        } );*/
        this.$aboutPage.addEventListener( "mouseover", function ( e ) {
            e.stopPropagation()
        } );
        this.$aboutPage.addEventListener( "mouseenter", function ( e ) {
            e.stopPropagation()
        } );
        this.$aboutPage.addEventListener( "mousemove", function ( e ) {
            e.stopPropagation()
        } );
        /*this.$aboutResourcesLink.addEventListener( "click", function () {
            //_analytics.Analytics.createEvent( "click", "Sexual Assualt Resources", "clickable_link, about, sexual_assault_resources" );
            _this9.hideAboutPageContent();
            TweenMax.delayedCall( .45, function () {
                _this9.go( "/resources" )
            } )
        } );*/
        /*this.$aboutTrendsLink.addEventListener( "click", function () {
            //_analytics.Analytics.createEvent( "click", "Google Trends", "clickable_link, about, sexual_assault_resources" )
        } )*/
    }
    hideEmbedElements () {
        var removeElement = function removeElement ( elementOrId ) {
            var element = void 0;
            if ( typeof elementOrId === "string" ) {
                element = document.getElementById( elementOrId )
            } else {
                element = elementOrId
            }
            element.style.zIndex = -1;
            element.style.visibility = "hidden";
            element.style.pointerEvents = "none"
        };
        removeElement( "cities-wrapper" );
        removeElement( this.$mobileNav );
        removeElement( this.$mobileCitiesBtn );
        removeElement( this.$timelineMobile )
    }
    showEmbedPage () {
        this.pageOpen = true;
        this.$globe.classList.remove( "blur" );
        document.querySelector( "body" ).classList.add( "embed" );
        document.querySelector( "#intro" ).style.opacity = 1;
        TweenLite.to( document.querySelectorAll( ".intro-subheader" ), .5, {
            opacity: 1
        } );
        TweenLite.to( document.querySelectorAll( ".intro-btn" ), .5, {
            opacity: 1
        } );
        this.hideEmbedElements();
        window.dispatchEvent( new Event( "resize" ) );
        this.$globe.classList.remove( "blur" )
    }
    hideEmbedPage () {
        document.querySelector( "body" ).classList.remove( "embed" );
        document.querySelector( "#intro" ).style.opacity = 0
    }
    resize () {
        if ( this.pageOpen ) {
            this.$globe.classList.add( "blur" );
            TweenLite.to( this.$nav, 0, {
                opacity: 0,
                pointerEvents: "none",
                ease: Linear.noEase,
                delay: 0
            } );
            TweenLite.to( this.$mobileNav, 0, {
                opacity: 0,
                ease: Linear.noEase,
                delay: 0
            } );
            TweenLite.to( this.$cities, 0, {
                opacity: 0,
                y: "-45%",
                ease: Quint.EaseOut,
                delay: 0
            } );
            TweenLite.to( this.$mobileCitiesBtn, 0, {
                opacity: 0,
                y: 10,
                ease: Linear.easeNone,
                delay: 0
            } );
            TweenLite.to( this.$timeline, 0, {
                opacity: 0,
                ease: Linear.noEase,
                delay: 0
            } );
            TweenLite.to( this.$timelineMobile, 0, {
                opacity: 0,
                ease: Linear.noEase,
                delay: 0
            } );
            TweenLite.to( this.$timelineCanvas, 0, {
                opacity: 0,
                y: 10,
                ease: Linear.noEase,
                delay: 0
            } )
        }
    }
    reorient () {
        var _this10 = this;
        TweenLite.set( [this.$resourcesBody, this.$aboutBody], {
            display: "none"
        } );
        setTimeout( function () {
            TweenLite.set( [_this10.$resourcesBody, _this10.$aboutBody], {
                display: "block"
            } )
        }, 100 )
    }
}