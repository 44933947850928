import EventEmitter from 'events';
import AudioController from './AudioController';

export default class Nav extends EventEmitter {
    constructor() {
        super();
        this.$navBtn = document.querySelector( "#mobile-menu-btn" );
        this.$navOverlay = document.querySelector( "#mobile-nav-overlay" );
        this.$resourcesBtn = document.querySelector( "#resources-btn-mobile" );
        this.$aboutBtn = document.querySelector( "#about-btn-mobile" );
        this.navOpen = false;
        this.$fbShare = document.querySelector( "#fb-share" );
        this.$twitterShare = document.querySelector( "#twitter-share" );
        this.$fbShareMobile = document.querySelector( "#fb-share-mobile" );
        this.$twitterShareMobile = document.querySelector( "#twitter-share-mobile" );
        this.$mobileCitiesBtn = document.querySelector( "#cities-btn" );
        this.navClick();
        this.btnClick();
        this.socialClick();
        this.citiesBtnClick();
        this.btnMouseOverSound();
        this.btnClickSound();
    }
    btnMouseOverSound () {
        if ( AudioController.isMobile() ) return;
        var overlayButtons = document.getElementsByClassName( "overlay-button" );
        for ( var i = 0; i < overlayButtons.length; i++ ) {
            overlayButtons[i].addEventListener( "mouseenter", function () {
                AudioController.onOver()
            } )
        }
        document.querySelector( "#about-close" ).addEventListener( "mouseenter", function () {
            AudioController.onOver()
        } );
        document.querySelector( "#about-btn" ).addEventListener( "mouseenter", function () {
            AudioController.onOver()
        } );
        document.querySelector( "#arrow-prev" ).addEventListener( "mouseenter", function () {
            AudioController.onOver()
        } );
        document.querySelector( "#arrow-next" ).addEventListener( "mouseenter", function () {
            AudioController.onOver()
        } );
        this.$fbShare.addEventListener( "mouseenter", function () {
            AudioController.onOver()
        } );
        this.$twitterShare.addEventListener( "mouseenter", function () {
            AudioController.onOver()
        } )
    }
    btnClickSound () {
        var overlayButtons = document.getElementsByClassName( "overlay-button" );
        for ( var i = 0; i < overlayButtons.length; i++ ) {
            overlayButtons[i].addEventListener( "click", function () {
                AudioController.onClick()
            } )
        }
        document.querySelector( "#about-close" ).addEventListener( "click", function () {
            AudioController.onClick()
        } );
        document.querySelector( "#about-btn" ).addEventListener( "click", function () {
            //_analytics.Analytics.createEvent( "click", "About", "clickable_link, nav, about" );
            AudioController.onClick()
        } );
        document.querySelector( "#arrow-prev" ).addEventListener( "click", function () {
            AudioController.onClick()
        } );
        document.querySelector( "#arrow-next" ).addEventListener( "click", function () {
            AudioController.onClick()
        } );
        this.$fbShare.addEventListener( "click", function () {
            AudioController.onClick();
            //_analytics.Analytics.createEvent( "click", "Facebook", "clickable_link, nav, facebook" )
        } );
        this.$twitterShare.addEventListener( "click", function () {
            AudioController.onClick();
            //_analytics.Analytics.createEvent( "click", "Twitter", "clickable_link, nav, twitter" )
        } )
    }
    citiesBtnClick () {
        this.$mobileCitiesBtn.addEventListener( "click", function () {
             AudioController.overlayIn()
         } )
    }
    navClick () {
        var _this2 = this;
        this.$navBtn.addEventListener( "click", function () {
            _this2.navOpen = !_this2.navOpen;
            if ( _this2.navOpen ) {
                _this2.$navBtn.classList.add( "active" );
                _this2.$navOverlay.classList.add( "active" );
                AudioController.overlayIn()
            } else {
                _this2.$navBtn.classList.remove( "active" );
                _this2.$navOverlay.classList.remove( "active" );
                AudioController.overlayOut()
            }
        } )
    }
    closeMenu () {
        this.navOpen = false;
        this.$navBtn.classList.remove( "active" );
        this.$navOverlay.classList.remove( "active" )
    }
    btnClick () {
        var _this3 = this;
        this.$resourcesBtn.addEventListener( "click", function () {
            _this3.closeMenu()
        } );
        this.$aboutBtn.addEventListener( "click", function () {
            _this3.closeMenu()
        } );
        this.$navOverlay.querySelector( ".mobile-menu-backdrop" ).addEventListener( "click", function () {
            _this3.closeMenu()
        } )
    }
    socialClick () {
        const self = this;
        this.$fbShare.addEventListener( "click", function () {
            self.share( "facebook" );
            //_analytics.Analytics.createEvent( "click", "Facebook", "clickable_link, nav, facebook" )
        } );
        this.$fbShareMobile.addEventListener( "click", function () {
            self.share( "facebook" );
            //_analytics.Analytics.createEvent( "click", "Facebook", "clickable_link, nav, facebook" )
        } );
        this.$twitterShare.addEventListener( "click", function () {
            self.share( "twitter" );
            //_analytics.Analytics.createEvent( "click", "Twitter", "clickable_link, nav, twitter" )
        } );
        this.$twitterShareMobile.addEventListener( "click", function () {
            self.share( "twitter" );
            //_analytics.Analytics.createEvent( "click", "Twitter", "clickable_link, nav, twitter" )
        } )
    }
    popup ( url, height, width ) {
        var wLeft = window.screenLeft ? window.screenLeft : window.screenX;
        var wTop = window.screenTop ? window.screenTop : window.screenY;
        var left = wLeft + window.innerWidth / 2 - width / 2;
        var top = wTop + window.innerHeight / 2 - height / 2;
        window.open( url, "_blank", "location=yes,height=" + height + ",width=" + width + ",top=" + top + ",left=" + left + ",scrollbars=yes,status=no" ).focus()
    }
    reorient () {
        var _this5 = this;
        this.$navOverlay.style.display = "none";
        setTimeout( function () {
            _this5.$navOverlay.style.display = "block"
        }, 100 )
    }
    share ( platform ) {
        var url = "https://love.donateattention.org";
        var text = encodeURIComponent( "Explore the global #SpreadTheLove movement over time by Donate Attention" );
        if ( platform === "twitter" ) {
            var twitURL = "https://twitter.com/intent/tweet?text=" + text + "&url=" + url;
            this.popup( twitURL, 253, 600 )
        } else if ( platform === "facebook" ) {
            var fbURL = "https://www.facebook.com/sharer.php?u=" + url;
            this.popup( fbURL, 570, 520 )
        }
    }
}