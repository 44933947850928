import { HELPERS } from './helpers';

var dates = [];
var nearCities = [];
var trendToggle = false;
var staggeredDistanceDates = [];

export default class DateService {
    getDate ( date ) {
        const self = this;
        return new Promise( ( resolve, reject ) => {
            if ( !date ) {
                reject()
            } else if ( dates[date] ) {
                resolve( dates[date] )
            } else {
                //console.log( date )
                trendToggle = !trendToggle;
                var path = "/cities-1.json";
                return fetch( path, {
                    credentials: "include"
                } ).then( function ( response ) {
                    return response.text()
                } ).then( function ( text ) {
                    try {
                        if ( text.startsWith( ")]}'," ) ) {
                            text = text.replace( ")]}',", "" );
                            var data = JSON.parse( text );
                            dates[date] = self.sortTrendsByDistance( data );
                            resolve( dates[date] )
                        }
                    } catch ( err ) {
                        reject()
                    }
                } )
            }
        } )
    }
    getStaggeredDistanceDate ( date, cities ) {
        if ( staggeredDistanceDates[date] ) {
            return staggeredDistanceDates[date]
        } else {
            var citiesArr = cities.slice();
            var newArr = [];
            newArr.push( citiesArr.shift() );
            while ( citiesArr.length ) {
                var city = newArr[newArr.length - 1];
                var farCityIndexes = [];
                for ( var i = 0; i < citiesArr.length; i++ ) {
                    var distance = HELPERS.distanceBetweenTwoCoordinations( {
                        lat: city.lat,
                        lng: city.lon
                    }, {
                        lat: citiesArr[i].lat,
                        lng: citiesArr[i].lon
                    } );
                    if ( distance > 3e6 && distance < 1e7 ) {
                        farCityIndexes.push( i )
                    }
                }
                var newCityIndex = farCityIndexes.length ? farCityIndexes[Math.floor( Math.random() * farCityIndexes.length )] : Math.floor( Math.random() * citiesArr.length );
                newArr.push( citiesArr.splice( newCityIndex, 1 )[0] )
            }
            staggeredDistanceDates[date] = newArr;
            return staggeredDistanceDates[date]
        }
    }
    sortTrendsByDistance ( trends ) {
        trends.forEach( function ( trend ) {
            trend.key = trend.city + trend.region + trend.country
        } );
        var trendsWithOutNeighbors = trends.slice();
        var firstItem = trendsWithOutNeighbors.shift();
        var processed = [];
        var getClosestTrend = function getClosestTrend ( trend ) {
            var minDist = Infinity;
            var minDistIndex = undefined;
            trendsWithOutNeighbors.forEach( function ( item, index ) {
                item.distance = HELPERS.distanceBetweenTwoCoordinations( {
                    lat: item.lat,
                    lng: item.lon
                }, {
                    lat: trend.lat,
                    lng: trend.lon
                } );
                if ( item.distance < minDist ) {
                    minDist = item.distance;
                    minDistIndex = index
                }
            } );
            if ( minDistIndex !== undefined ) {
                return trendsWithOutNeighbors.splice( minDistIndex, 1 )[0]
            } else {
                return false
            }
        };
        var runLoop = function runLoop ( firstTrend ) {
            var trend = getClosestTrend( firstTrend );
            if ( trend ) {
                processed.push( trend );
                runLoop( trend )
            }
        };
        runLoop( firstItem );
        firstItem.distance = processed.length ? processed[0].distance : 1;
        processed.unshift( firstItem );
        return processed
    }
    getNearestCities ( position, date ) {
        var slug = position.lat + "-" + position.lng + "-" + date;
        if ( nearCities[slug] ) {
            return nearCities[slug]
        }
        var pointsForDate = dates[date];
        if ( !pointsForDate ) {
            return null
        }
        var distances = [];
        pointsForDate.forEach( function ( cityPoint ) {
            distances.push( HELPERS.distanceBetweenTwoCoordinations( position, {
                lat: cityPoint.lat,
                lng: cityPoint.lon
            } ) )
        } );
        var nearest = [];
        var _loop = function _loop ( i ) {
            var min = Math.min.apply( Math, distances );
            var index = distances.findIndex( function ( item ) {
                return item === min
            } );
            distances[index] = Infinity;
            nearest.push( pointsForDate[index] )
        };
        for ( var i = 0; i < 5; i++ ) {
            _loop( i )
        }
        nearCities[slug] = nearest;
        return nearest
    }
}