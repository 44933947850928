import "./klang";

var klangConfig = "http://klangfiles.s3.amazonaws.com/uploads/projects/tDqjJ/config.js";
var loadLocally = true;

const audioController = {
        init: function init(isMobile) {
            var _this = this;
            return new Promise(function(resolve, reject) {
                if (isMobile) {
                    resolve()
                } else {
                    Klang.init(loadLocally ? "/audio/config.js" : klangConfig, function(success) {
                        if (success) {
                            resolve()
                        } else {
                            reject()
                        }
                    }, function(progress) {
                        Klang.trigger("loading", progress)
                    })
                }
            })
        },
        enableWebAudio: function enableWebAudio() {
            if (Klang.context) {
                Klang.context.resume()
            }
            Klang.initIOS()
        },
        startBackground: function startBackground() {
            Klang.trigger("background_start")
        },
        stopBackground: function stopBackground() {
            Klang.trigger("background_stop")
        },
        zoomIn: function zoomIn() {
            Klang.trigger("zoom_in")
        },
        zoomOut: function zoomOut() {
            Klang.trigger("zoom_out")
        },
        playOneShot: function playOneShot() {
            Klang.trigger("oneshot_over")
        },
        updateTimelineValue: function updateTimelineValue(value) {
            Klang.trigger("update_timeline_value", Math.max(0, Math.min(1, value)))
        },
        trigger: function trigger() {
            Klang.trigger.apply(Klang.default, arguments)
        },
        onClick: function onClick() {
            Klang.trigger("tap")
        },
        onOver: function onOver() {
            Klang.trigger("over")
        },
        overlayIn: function overlayIn() {
            Klang.trigger("overlay_in")
        },
        overlayOut: function overlayOut() {
            Klang.trigger("overlay_out")
        },
        isMobile: function isMobile() {
            return Klang.isMobile
        },
        soundOn: function soundOn() {
            Klang.trigger("sound_on")
        },
        soundOff: function soundOff() {
            Klang.trigger("sound_off")
        }
};

var _assign = Object.assign || function assign(target) {
    var to = Object(target);
    for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];
        if (nextSource != null) {
            for (var nextKey in nextSource) {
                if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                    to[nextKey] = nextSource[nextKey]
                }
            }
        }
    }
    return to
};
//_assign(audioController, klang.core.EventEmitter.prototype);

export default audioController;