import * as THREE from 'three';
import EventEmitter from 'events';
import { HELPERS, COLOR_SHADER } from './helpers';

const VERTEX = `
    #define M_PI 3.1415926535897932384626433832795;
    attribute vec3 vel;
    attribute vec4 curl;
    attribute float starttime;
    attribute float lifetime;
    attribute vec3 rotationMatrix1;
    attribute vec3 rotationMatrix2;
    attribute vec3 rotationMatrix3;
    uniform float time;
    uniform float opacity;
    uniform float size;
    varying float op;
    varying vec3 colorP;
    varying float glow;
    ${COLOR_SHADER}
    void main() {
        mat3 rm;
        rm[0] = rotationMatrix1;
        rm[1] = rotationMatrix2;
        rm[2] = rotationMatrix3;
        float timeElapsed = time - starttime;
        float life = timeElapsed / lifetime;
        float lifeLeft = 1.0 - life;
        float t = timeElapsed;
        // Simulate
        vec3 newPosition = vel * life + curl.xyz * life * lifeLeft;
        newPosition = rm * newPosition;
        vec4 mvPosition = modelViewMatrix * vec4( newPosition + position, 1.0 );
        glow = curl.w;
        // Hide if particle is behind the earth
        float zPos = dot(cameraPosition, cameraPosition) - mvPosition.z * mvPosition.z;
        op = smoothstep(0.25, 0.75, zPos);
        op *= smoothstep(0., 0.2, life);
        // Fade in at the beginning
        op *= (1.0 - smoothstep(0.7, 1.0, life));
        // Fade out at the end of life
        op *= opacity;
        // Color
        colorP = positionBasedColor(modelMatrix * vec4( newPosition + position, 1.0 ));
        gl_PointSize = step(0., timeElapsed) * op * step(0., lifeLeft) * size * ( 300.0 / -mvPosition.z );
        gl_Position = projectionMatrix * mvPosition + vec4(0, 0, 100, 0) * step(zPos, 0.25);
    }
`;
const FRAG = `
    uniform sampler2D particle_texture;
    varying vec3 colorP;
    varying float op;
    varying float glow;
    void main() {
        vec4 texC = texture2D( particle_texture, gl_PointCoord );
        gl_FragColor = vec4(colorP * op * (texC.r + texC.g * glow), 1.0);
    }
`;
const MIN_STRENGTH = 0;
const MAX_OPACITY = .9;
const SIZE_RATIO = .18 / 803;


function getParticleSize () {
    const dpi = HELPERS.DPR();
    return Math.max( .18, window.innerHeight * SIZE_RATIO ) * dpi
}
export default class ParticleSystem extends EventEmitter {
    constructor( pos ) {
        super();
        this.pos = pos || new THREE.Vector3;
        this.size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 9e4;
        this.count = 0;
        this.current = -1;
        this.spawners = [];
        const textureLoader = new THREE.TextureLoader();
        this.uniforms = {
            strength: {
                value: MIN_STRENGTH,
                type: "f"
            },
            particle_texture: {
                value: textureLoader.load( "/particle.jpeg" )
            },
            opacity: {
                value: MAX_OPACITY,
                type: "f"
            },
            time: {
                value: this.time,
                type: "f"
            },
            size: {
                value: getParticleSize(),
                type: "f"
            }
        };
        this.material = new THREE.ShaderMaterial( {
            uniforms: this.uniforms,
            vertexShader: VERTEX,
            fragmentShader: FRAG,
            blending: THREE.AdditiveBlending,
            depthTest: false,
            transparent: true
        } );
        const self = this;
        this.geometry = new THREE.BufferGeometry;
        this.geometry.setAttribute( "vel", new THREE.BufferAttribute( new Float32Array( this.size * 3 ), 3 ).setDynamic( true ) );
        this.geometry.setAttribute( "curl", new THREE.BufferAttribute( new Float32Array( this.size * 4 ), 4 ).setDynamic( true ) );
        this.geometry.setAttribute( "position", new THREE.BufferAttribute( new Float32Array( this.size * 3 ), 3 ).setDynamic( true ) );
        this.geometry.setAttribute( "rotationMatrix1", new THREE.BufferAttribute( new Float32Array( this.size * 3 ), 3 ).setDynamic( true ) );
        this.geometry.setAttribute( "rotationMatrix2", new THREE.BufferAttribute( new Float32Array( this.size * 3 ), 3 ).setDynamic( true ) );
        this.geometry.setAttribute( "rotationMatrix3", new THREE.BufferAttribute( new Float32Array( this.size * 3 ), 3 ).setDynamic( true ) );
        this.geometry.setAttribute( "starttime", new THREE.BufferAttribute( new Float32Array( this.size ), 1 ).setDynamic( true ) );
        this.geometry.setAttribute( "lifetime", new THREE.BufferAttribute( new Float32Array( this.size ), 1 ).setDynamic( true ) );
        this.mesh = new THREE.Points( this.geometry, this.material );
        this.mesh.position.copy( this.pos.clone() );
        window.addEventListener( "resize", function () {
            self.uniforms.size.value = getParticleSize()
        } );
    }
    clearSpawners ( quick ) {
        this.spawners = [];
        if ( quick ) {
            this.scheduleClear = true;
            for ( var i = 0; i < this.geometry.attributes.starttime.array.length; i++ ) {
                this.geometry.attributes.starttime.array[i] -= 1e3
            }
            this.geometry.attributes.starttime.needsUpdate = true
        }
    }
    addSpawner ( id, pos, rate ) {
        var distance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
        var area = arguments[4];
        var combined = arguments[5];
        var position = new THREE.Vector3( pos.x, pos.y, pos.z );
        var normal = position.clone().normalize();
        var quaternion = ( new THREE.Quaternion ).setFromUnitVectors( new THREE.Vector3( 0, 0, 1 ), normal );
        var rm = new THREE.Matrix4;
        rm.makeRotationFromQuaternion( quaternion );
        var rotationMatrix = new THREE.Matrix3;
        rotationMatrix.setFromMatrix4( rm );
        area = area || new THREE.Vector2( 1, 1 );
        this.spawners.push( {
            combined: !!combined,
            id: id,
            rate: rate / 1e3,
            _rate: rate / 1e3,
            position: position,
            rotationMatrix: rotationMatrix,
            accumulator: 0,
            distance: distance,
            area: area
        } )
    }
    increaseRate ( id, pos, rate ) {
        id = id + "hover";
        var spawner = this.spawners.find( function ( o ) {
            return o.id === id
        } );
        if ( !spawner ) {
            this.addSpawner( id, pos, rate * 5, 0 )
        }
    }
    decreaseRate ( id ) {
        id = id + "hover";
        var spawnerIndex = this.spawners.findIndex( function ( o ) {
            return o.id === id
        } );
        if ( spawnerIndex > -1 ) {
            this.spawners.splice( spawnerIndex, 1 )
        }
    }
    randomSignedPow ( pow ) {
        return ( Math.random() > .5 ? -1 : 1 ) * Math.pow( Math.random(), pow )
    }
    spawn ( spawner ) {
        this.current += 1;
        if ( this.current >= this.size )
            this.current = 0;
        var i = this.current;
        this.geometry.attributes.position.array[i * 3] = spawner.position.x + ( -1 + Math.random() * 2 ) * .0075 * spawner.area.x;
        this.geometry.attributes.position.array[i * 3 + 1] = spawner.position.y + ( -1 + Math.random() * 2 ) * .0075 * spawner.area.y;
        this.geometry.attributes.position.array[i * 3 + 2] = spawner.position.z;
        this.geometry.attributes.rotationMatrix1.array[i * 3] = spawner.rotationMatrix.elements[0];
        this.geometry.attributes.rotationMatrix1.array[i * 3 + 1] = spawner.rotationMatrix.elements[1];
        this.geometry.attributes.rotationMatrix1.array[i * 3 + 2] = spawner.rotationMatrix.elements[2];
        this.geometry.attributes.rotationMatrix2.array[i * 3] = spawner.rotationMatrix.elements[3];
        this.geometry.attributes.rotationMatrix2.array[i * 3 + 1] = spawner.rotationMatrix.elements[4];
        this.geometry.attributes.rotationMatrix2.array[i * 3 + 2] = spawner.rotationMatrix.elements[5];
        this.geometry.attributes.rotationMatrix3.array[i * 3] = spawner.rotationMatrix.elements[6];
        this.geometry.attributes.rotationMatrix3.array[i * 3 + 1] = spawner.rotationMatrix.elements[7];
        this.geometry.attributes.rotationMatrix3.array[i * 3 + 2] = spawner.rotationMatrix.elements[8];
        this.geometry.attributes.vel.array[i * 3] = this.randomSignedPow( 8 ) * .03;
        this.geometry.attributes.vel.array[i * 3 + 1] = this.randomSignedPow( 8 ) * .03;
        this.geometry.attributes.vel.array[i * 3 + 2] = .005 + Math.pow( Math.random(), 5 ) * .05;
        this.geometry.attributes.curl.array[i * 4] = ( -1 + Math.random() * 2 ) * .03;
        this.geometry.attributes.curl.array[i * 4 + 1] = ( -1 + Math.random() * 2 ) * .03;
        this.geometry.attributes.curl.array[i * 4 + 2] = 0;
        this.geometry.attributes.curl.array[i * 4 + 3] = spawner.distance;
        this.geometry.attributes.starttime.array[i] = this.time + Math.random() * 250;
        this.geometry.attributes.lifetime.array[i] = 1900
    }
    tick ( time ) {
        var delta = time - this.time;
        this.time = time;
        this.uniforms.time.value = this.time;
        var current = this.current;
        for ( var i = 0, n = this.spawners.length; i < n; i++ ) {
            this.spawners[i].accumulator += this.spawners[i].rate * delta;
            if ( this.spawners[i].accumulator >= 1 ) {
                var j = 0;
                for ( j = 0; j < this.spawners[i].accumulator; j++ ) {
                    this.spawn( this.spawners[i] )
                }
                this.spawners[i].accumulator -= j
            }
        }
        if ( this.current != current ) {
            let count = this.current - current;
            let positionAttr = this.geometry.attributes.position;
            let starttimeAttr = this.geometry.attributes.starttime;
            let lifetimeAttr = this.geometry.attributes.lifetime;
            let normalAttr1 = this.geometry.attributes.rotationMatrix1;
            let normalAttr2 = this.geometry.attributes.rotationMatrix2;
            let normalAttr3 = this.geometry.attributes.rotationMatrix3;
            let velAttr = this.geometry.attributes.vel;
            let curlAttr = this.geometry.attributes.curl;
            if ( count > 0 && !this.scheduleClear ) {
                this.scheduleClear = false;
                var offset = current + 1;
                positionAttr.updateRange.offset = offset * positionAttr.itemSize;
                starttimeAttr.updateRange.offset = offset * starttimeAttr.itemSize;
                lifetimeAttr.updateRange.offset = offset * lifetimeAttr.itemSize;
                normalAttr1.updateRange.offset = offset * normalAttr1.itemSize;
                normalAttr2.updateRange.offset = offset * normalAttr2.itemSize;
                normalAttr3.updateRange.offset = offset * normalAttr3.itemSize;
                velAttr.updateRange.offset = offset * velAttr.itemSize;
                curlAttr.updateRange.offset = offset * curlAttr.itemSize;
                positionAttr.updateRange.count = count * positionAttr.itemSize;
                starttimeAttr.updateRange.count = count * starttimeAttr.itemSize;
                lifetimeAttr.updateRange.count = count * lifetimeAttr.itemSize;
                normalAttr1.updateRange.count = count * normalAttr1.itemSize;
                normalAttr2.updateRange.count = count * normalAttr2.itemSize;
                normalAttr3.updateRange.count = count * normalAttr3.itemSize;
                velAttr.updateRange.count = count * velAttr.itemSize;
                curlAttr.updateRange.count = count * curlAttr.itemSize
            } else {
                positionAttr.updateRange.offset = 0;
                starttimeAttr.updateRange.offset = 0;
                lifetimeAttr.updateRange.offset = 0;
                normalAttr1.updateRange.offset = 0;
                normalAttr2.updateRange.offset = 0;
                normalAttr3.updateRange.offset = 0;
                velAttr.updateRange.offset = 0;
                curlAttr.updateRange.offset = 0;
                positionAttr.updateRange.count = -1;
                starttimeAttr.updateRange.count = -1;
                lifetimeAttr.updateRange.count = -1;
                normalAttr1.updateRange.count = -1;
                normalAttr2.updateRange.count = -1;
                normalAttr3.updateRange.count = -1;
                velAttr.updateRange.count = -1;
                curlAttr.updateRange.count = -1
            }
            positionAttr.needsUpdate = true;
            starttimeAttr.needsUpdate = true;
            lifetimeAttr.needsUpdate = true;
            normalAttr1.needsUpdate = true;
            normalAttr2.needsUpdate = true;
            normalAttr3.needsUpdate = true;
            velAttr.needsUpdate = true;
            curlAttr.needsUpdate = true
        }
    }
}