export const HELPERS = {
    mapRange: function mapRange ( value, low1, high1, low2, high2 ) {
        return low2 + ( high2 - low2 ) * ( value - low1 ) / ( high1 - low1 )
    },
    xyzFromLatLng: function xyzFromLatLng ( lat, lng, radius ) {
        var phi = ( 90 - lat ) * Math.PI / 180;
        var theta = ( 360 - lng ) * Math.PI / 180;
        return {
            x: radius * Math.sin( phi ) * Math.cos( theta ),
            y: radius * Math.cos( phi ),
            z: radius * Math.sin( phi ) * Math.sin( theta )
        }
    },
    distanceBetweenTwoCoordinations: function distanceBetweenTwoCoordinations ( point1, point2 ) {
        var R = 6371e3;
        var φ1 = toRadians( point1.lat );
        var φ2 = toRadians( point2.lat );
        var Δφ = toRadians( point2.lat - point1.lat );
        var Δλ = toRadians( point2.lng - point1.lng );
        var a = Math.sin( Δφ / 2 ) * Math.sin( Δφ / 2 ) + Math.cos( φ1 ) * Math.cos( φ2 ) * Math.sin( Δλ / 2 ) * Math.sin( Δλ / 2 );
        var c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
        var d = R * c;
        return d
    },
    throttle: function throttle ( fn ) {
        var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
        var timer = null;
        var throttledFn = function throttledFn () {
            for ( var _len = arguments.length, args = Array( _len ), _key = 0; _key < _len; _key++ ) {
                args[_key] = arguments[_key]
            }
            if ( !timer ) {
                timer = setTimeout( function () {
                    fn.apply( undefined, args );
                    timer = null
                }, time )
            }
        };
        throttledFn.cancel = function () {
            clearTimeout( timer );
            timer = null
        }
            ;
        return throttledFn
    },
    pointId: function pointId ( p ) {
        return p.city + p.region + p.country
    },
    cluster: function cluster ( points, distance ) {
        points = points.slice( 0 );
        var clusters = [];
        for ( var i = points.length - 1; i > -1; i-- ) {
            var p = points.splice( i, 1 )[0];
            var cluster = [p];
            for ( var j = i - 1; j > -1; j-- ) {
                var p1 = points[j];
                var isClose = true;
                for ( var k = 0; k < cluster.length; k++ ) {
                    if ( HELPERS.distanceBetweenTwoCoordinations( {
                        lat: cluster[k].lat,
                        lng: cluster[k].lon
                    }, {
                        lat: p1.lat,
                        lng: p1.lon
                    } ) > distance ) {
                        isClose = false;
                        break
                    }
                }
                if ( isClose ) {
                    cluster.push( points.splice( j, 1 )[0] );
                    i--
                }
            }
            clusters.push( cluster )
        }
        return clusters
    },
    parseDate: function parseDate ( input ) {
        var parts = input.split( "-" );
        return new Date( parts[0], parts[1] - 1, parts[2] )
    },
    DPR: function DPR () {
        return window.devicePixelRatio < 1.5 ? window.devicePixelRatio : 1.5
    },
    distance: function distance ( x1, y1, z1, x2, y2, z2 ) {
        var dx = x2 - x1;
        var dy = y2 - y1;
        var dz = z2 - z1;
        return Math.sqrt( dx * dx + dy * dy + dz * dz )
    },
    getTodaysDate: function getTodaysDate () {
        var d = new Date;
        var month = "" + ( d.getMonth() + 1 );
        var day = "" + d.getDate();
        var year = d.getFullYear();
        if ( month.length < 2 )
            month = "0" + month;
        if ( day.length < 2 )
            day = "0" + day;
        return [year, month, day].join( "-" )
    }
};
var toRadians = function toRadians ( value ) {
    return value * Math.PI / 180
};
export const COLOR_SHADER = "\nvec3 positionBasedColor(vec4 position) {\n  float xmix = min(1., max(0., (position.x / 0.25) * 0.5 + 0.5));\n  float ymix = min(1., max(0., (position.y / 0.25) * 0.5 + 0.5));\n  vec3 color =\n    vec3(.900, .200, .200) * (1. - ymix) * 0.5 +\n    vec3(.900, .200, .200) * xmix +\n    vec3(.900, .200, .200) * (1. - xmix);\n  return color;\n}\n"
