import EventEmitter from 'events';
import Siema from 'siema';

export default class Timeline extends EventEmitter {
    constructor( isMobile ) {
        super();
        var dpr = window.devicePixelRatio || 1;
        this.todaysDate = this.setTodaysDate();
        this.ready = false;
        this.points = [];
        this.colorWhite = "#ffffff";
        this.colorGray = "#353535";
        this.colorPeach = "#fcdcbf";
        this.arcX = 0;
        this.arxY = 0;
        this.mouseX = 0;
        this.mouseY = 0;
        this.isDragging = false;
        this.markerInit = false;
        this.maxWidth = 0;
        this.date = "";
        this.activePointIndex = 0;
        this.markerPointIndex = 0;
        //this.canvasMouseEvents();
        this.isMobile = isMobile;
        this.mobilePerPage = window.innerWidth > 768 ? 12 : 5;
        this.$timelineMobile = document.querySelector( "#timeline-mobile" );
        this.timelineMobileItems;
        this.timelineTouch = {
            startTime: 0,
            lastTime: 0,
            endTime: 0,
            startX: 0,
            lastX: 0
        };
    }
    setTodaysDate () {
        var d = new Date;
        var month = "" + ( d.getMonth() + 1 );
        var day = "" + d.getDate();
        var year = d.getFullYear();
        if ( month.length < 2 ) month = "0" + month;
        if ( day.length < 2 ) day = "0" + day;
        return [year, month, day].join( "-" )
    }
    getData () {
        const self = this;
        const path = "/timeline.json";
        return fetch( path, {
            credentials: "include"
        } ).then( function ( response ) {
            return response.text()
        } ).then( function ( text ) {
            try {
                if ( text.startsWith( ")]}'," ) ) {
                    text = text.replace( ")]}',", "" );
                    var data = JSON.parse( text );
                    data.sort( function ( a, b ) {
                        if ( a.date < b.date ) return -1;
                        if ( a.date > b.date ) return 1;
                        return 0
                    } );
                    var values = [];
                    data.forEach( function ( date ) {
                        self.points.push( date );
                        values.push( date.value )
                    } );
                    var sortedValues = values.sort( function ( a, b ) {
                        return a - b
                    } );
                    self.min = Math.log( sortedValues[0] );
                    self.max = Math.log( sortedValues[values.length - 1] );
                    var lastPoint = self.points[self.points.length - 1];
                    var todaysDate = self.todaysDate;
                    if ( lastPoint.date !== todaysDate && !self.isMobile ) {
                        self.points.push( {
                            date: lastPoint.date,
                            value: lastPoint.value,
                            isDuplicate: true
                        } )
                    }
                    self.lastDate = self.points[self.points.length - 1];
                    //self.determineMultiplier();
                    self.activePointIndex = self.points.length - 1;
                    //self.updateSoundScape( lastPoint.value );
                    self.initMobile();
                    self.ready = true;
                    self.emit( "date-update", self.lastDate, self.lastDate )
                    self.emit( "intro-complete" );
                }
            } catch ( err ) { }
        } )
    }
    resize () {
        this.siema.resizeHandler();
    }
    formatDate ( item ) {
        var dateArray = item ? item.split( "-" ) : null;
        return dateArray ? dateArray[1] + "." + dateArray[2] + "." + dateArray[0].substring( 2, 4 ) : "TODAY"
    }
    updateMobileSelected () {
        this.timelineMobileItems.forEach( function ( item ) {
            if ( item && item.classList ) {
                item.classList.remove( "left" );
                item.classList.remove( "right" );
                item.classList.remove( "active" )
            }
        } );
        //var curSlide = this.siema.currentSlide + ( this.mobilePerPage - 1 ) / 2;
        const curSlide = this.siema.currentSlide + 9;
        this.siema.innerElements[curSlide].classList.add( "active" );
        /*if ( curSlide > 2 ) {
            this.siema.innerElements[curSlide - 2].classList.add( "left" )
        }
        if ( curSlide > 3 ) {
            this.siema.innerElements[curSlide - 3].classList.add( "left" )
        }
        if ( curSlide < this.siema.innerElements.length - 3 ) {
            this.siema.innerElements[curSlide + 2].classList.add( "right" )
        }
        if ( curSlide < this.siema.innerElements.length - 4 ) {
            this.siema.innerElements[curSlide + 3].classList.add( "right" )
        }*/
        /*if ( this.mobilePerPage === 7 ) {
            if ( curSlide > 4 ) {
                this.siema.innerElements[curSlide - 4].classList.add( "left" )
            }
            if ( curSlide < this.siema.innerElements.length - 5 ) {
                this.siema.innerElements[curSlide + 4].classList.add( "right" )
            }
        }*/
        var newDatePoint = this.points[this.siema.currentSlide];
        this.emit( "date-update", newDatePoint, this.lastDate );
        if ( newDatePoint && newDatePoint.date ) {
            //_analytics.Analytics.createEvent("clickable_link", "home", "timeline_" + newDatePoint.date, true)
        }
    }
    addItemsToMobileTimeline = () => {
        const self = this;

        const newElement = document.createElement( "div" );
        const newElement2 = document.createElement( "div" );
        const newElement3 = document.createElement( "div" );
        const newElement4 = document.createElement( "div" );
        const newElement5 = document.createElement( "div" );
        const newElement6 = document.createElement( "div" );
        newElement.setAttribute( "class", "date" );
        newElement2.setAttribute( "class", "date" );
        newElement3.setAttribute( "class", "date" );
        newElement4.setAttribute( "class", "date" );
        newElement5.setAttribute( "class", "date" );
        newElement6.setAttribute( "class", "date" );

        this.$timelineMobile.appendChild( newElement );
        this.$timelineMobile.appendChild( newElement2 );

        this.points.forEach(( date ) => {
            const pointElement = document.createElement( "div" );
            pointElement.setAttribute( "class", "date" );
            pointElement.innerHTML = date.isDuplicate ? "TODAY" : self.formatDate( date.date );
            self.$timelineMobile.appendChild( pointElement )
        } );

        this.$timelineMobile.appendChild( newElement4 );
        this.$timelineMobile.appendChild( newElement3 );

        this.timelineMobileItems = Array.from( document.querySelectorAll( ".date" ) )
    }
    initMobile () {
        const self = this;
        this.addItemsToMobileTimeline();
        this.siema = new Siema( {
            selector: "#timeline-mobile",
            duration: 200,
            easing: "ease-out",
            perPage: this.mobilePerPage,
            startIndex: this.points.length - ( this.mobilePerPage === 7 ? 0 : 1 ),
            draggable: true,
            multipleDrag: true,
            threshold: 20,
            loop: false,
            rtl: false,
            onChange: function onChange () {
                self.updateMobileSelected()
            }
        } );
        //( this.mobilePerPage === 7 ? 3 : 2 )
        this.siema.innerElements[this.siema.currentSlide + 9].classList.add( "active" );
        //this.siema.innerElements[this.siema.currentSlide + 1].classList.add( "left" );
        //this.siema.innerElements[this.siema.currentSlide].classList.add( "left" );
        /*if ( this.mobilePerPage === 7 ) {
            this.siema.innerElements[this.siema.currentSlide + 2].classList.add( "left" )
        }*/
        this.$timelineMobile.addEventListener( "touchstart", function ( e ) {
            self.timelineTouch.startTime = e.timeStamp;
            self.timelineTouch.startX = e.touches[0].pageX
        } );
        this.$timelineMobile.addEventListener( "touchmove", function ( e ) {
            self.timelineTouch.lastTime = e.timeStamp;
            self.timelineTouch.lastX = e.touches[0].pageX
        } );
        this.$timelineMobile.addEventListener( "touchend", function ( e ) {
            self.timelineTouch.endTime = e.timeStamp;
            if ( self.timelineTouch.endTime - self.timelineTouch.lastTime < 100 && self.timelineTouch.endTime - self.timelineTouch.startTime < 1e3 ) {
                var speed = ( self.timelineTouch.lastX - self.timelineTouch.startX ) / ( self.timelineTouch.endTime - self.timelineTouch.startTime );
                var absSpeed = Math.abs( speed );
                if ( absSpeed > .8 ) {
                    if ( speed > 0 ) {
                        self.siema.prev( Math.floor( absSpeed * 8 ) )
                    } else {
                        self.siema.next( Math.floor( absSpeed * 8 ) )
                    }
                }
            }
        } )
    }
}