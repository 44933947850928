import * as THREE from 'three';
import { TweenLite, Quad } from 'gsap';
import { COLOR_SHADER } from './helpers'

var FADE_DUR = 1.8;
var SIZE = .21;
var VERTEX = "\nuniform float opacity;\n\nvarying vec3 color;\nvarying vec2 v_uv;\n\n" + COLOR_SHADER + "\n\nvoid main() {\n  v_uv = uv;\n  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 ); // 3D\n  // vec4 mvPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0); // Billboard\n  // mvPosition.xy += position.xy;\n  color = positionBasedColor(modelMatrix * vec4( position, 1.0 ));\n  gl_Position = projectionMatrix * (mvPosition + vec4(0, 0, 100, 0) * step(opacity, 0.01));\n}";
var FRAG = `
    uniform sampler2D glow_texture;
    uniform float opacity;
    varying vec3 color;
    varying vec2 v_uv;
    void main() {
        vec4 tex = texture2D(glow_texture, v_uv);
        gl_FragColor = vec4(color, tex.r * opacity);
    }
`;

export default class Glows {
    constructor( size, group ) {
        const textureLoader = new THREE.TextureLoader();
        this.uniforms = {
            glow_texture: {
                value: textureLoader.load( "/glow.jpeg" )
            },
            opacity: {
                value: 0,
                type: "f"
            }
        };
        this.group = group;
        this.size = size;
        this.meshes = [];
        for ( var i = 0; i < this.size; i++ ) {
            this.meshes[i] = this.makeGlowMesh();
            this.group.add( this.meshes[i] );
            this.meshes[i].position.z = 100
        }
    }
    makeGlowMesh () {
        var MATERIAL = new THREE.ShaderMaterial( {
            uniforms: this.uniforms,
            vertexShader: VERTEX,
            fragmentShader: FRAG,
            blending: THREE.AdditiveBlending,
            depthTest: false,
            transparent: true
        } );
        var geometry = new THREE.PlaneGeometry( SIZE, SIZE, 1 );
        return new THREE.Mesh( geometry, MATERIAL )
    }
    update ( clusters ) {
        const self = this;
        TweenLite.killTweensOf( this.uniforms.opacity );
        if ( this.uniforms.opacity.value > 0 ) {
            TweenLite.to( this.uniforms.opacity, .5, {
                value: 0,
                ease: Quad.easeInOut,
                onComplete: function onComplete () {
                    return self.updatePositionsAndFadeIn( clusters )
                }
            } )
        } else {
            this.updatePositionsAndFadeIn( clusters )
        }
    }
    updatePositionsAndFadeIn ( clusters ) {
        for ( var i = 0; i < this.size; i++ ) {
            if ( clusters[i] ) {
                var pos = new THREE.Vector3( clusters[i].pos.x, clusters[i].pos.y, clusters[i].pos.z );
                pos.normalize().multiplyScalar( .5 );
                this.meshes[i].position.copy( new THREE.Vector3 );
                this.meshes[i].lookAt( pos );
                this.meshes[i].position.copy( pos )
            } else {
                this.meshes[i].position.z = 100
            }
        }
        TweenLite.to( this.uniforms.opacity, FADE_DUR, {
            value: 1,
            ease: Quad.easeInOut
        } )
    }
    clear () {
        TweenLite.to( this.uniforms.opacity, FADE_DUR, {
            value: 0,
            ease: Quad.easeInOut
        } )
    }
}