import EventEmitter from 'events';
import { TweenLite, TweenMax, Quint, Linear } from "gsap";
import { HELPERS } from './helpers';
import DataService from './DataService';
import CityResults from './CityResults';
import AudioController from './AudioController';

export default class Cities extends EventEmitter {
    constructor( args ) {
        super();
        this.isMobile = args.isTouch;
        this.isEmbed = args.isEmbed;
        this.todaysDate = HELPERS.getTodaysDate();
        this.currentDate = this.todaysDate;
        this.currentMonth;
        this.cityResults = new CityResults( this.isMobile );
        this.$home = document.querySelector( "#home" );
        this.$globe = document.querySelector( "#globe" );
        this.$nav = document.querySelector( "#home-nav" );
        this.$mobileNav = document.querySelector( "#mobile-menu-btn" );
        this.$timeline = document.querySelector( "#timeline" );
        this.$timelineCanvas = document.querySelector( "#timelineCanvas" );
        this.$timelineMobile = document.querySelector( "#timeline-mobile" );
        this.$mobileCitiesBtn = document.querySelector( "#cities-btn" );
        this.$fallbackMsg = document.querySelector( "#overlay-results-no-results" );
        this.$sliderTitle = document.querySelector( "#cities-slider" );
        this.$sliderBar = document.querySelector( "#slider" );
        this.$citiesWrapper = document.querySelector( "#cities-wrapper" );
        this.$topCities = document.querySelector( "#top-cities" );
        this.$nearCities = document.querySelector( "#near-cities" );
        this.$slider = document.querySelector( "#slider" );
        this.$topList = document.querySelector( "#cities-list-top" );
        this.$nearList = document.querySelector( "#cities-list-near" );
        this.slider();
        this.$topCity = [];
        this.$topCity[0] = document.querySelector( "[data-city-1]" );
        this.$topCity[1] = document.querySelector( "[data-city-2]" );
        this.$topCity[2] = document.querySelector( "[data-city-3]" );
        this.$topCity[3] = document.querySelector( "[data-city-4]" );
        this.$topCity[4] = document.querySelector( "[data-city-5]" );
        this.$nearCity = [];
        this.$nearCity[0] = document.querySelector( "[data-near-city-1]" );
        this.$nearCity[1] = document.querySelector( "[data-near-city-2]" );
        this.$nearCity[2] = document.querySelector( "[data-near-city-3]" );
        this.$nearCity[3] = document.querySelector( "[data-near-city-4]" );
        this.$nearCity[4] = document.querySelector( "[data-near-city-5]" );
        this.nearCities;
        this.$nearLoader = document.querySelector( "#near-cities-loader" );
        this.userLocation;
        this.$cityOverlay = document.querySelector( "#city-overlay" );
        this.$cityBackground = document.querySelector( "#city-overlay-background" );
        this.$cityOverlayClose = document.querySelector( "#city-overlay-close" );
        this.$cityName = document.querySelector( "#city-name" );
        this.$overlayCity = document.querySelector( "#overlay-city" );
        this.$searchResultsDate = Array.from( document.querySelectorAll( ".search-results-date" ) );
        this.$overlayCountry = document.querySelector( "#overlay-country" );
        this.$citySearchTitle = document.querySelector( "#overlay-results-title" );
        this.$overlayResults = document.getElementById( "overlay-results" );
        this.$buttonAll = document.querySelector( "[data-button-all]" );
        this.$buttonFollow = document.querySelector( "[data-button-follow]" );
        this.$buttonFollowCity = document.querySelector( "[data-button-follow-city]" );
        this.$prevArrow = document.querySelector( "#arrow-prev" );
        this.$nextArrow = document.querySelector( "#arrow-next" );
        this.$cityLegal = document.querySelector( "[data-city-legal]" );
        this.$cityFooter = document.querySelector( ".overlay-footerlinks" );
        this.cityOverlayData;
        this.activeCityIndex;
        this.staggeredData = null;
        this.$mobileCloseBtn = document.querySelector( "#cities-close-btn" );
        this.mobileInit = false;
        this.resize();
        this.cityOverlay();
        this.overlayChange();
        this.mobilePage();
        this.cityOverlayOpen = false;
        this.currentDate;
        this.currentAnimation;
    }
    setDate ( date, forceToday ) {
        if ( !!forceToday === true ) {
            this.currentDate = this.todaysDate
        } else {
            this.currentDate = date
        }
        if ( this.cityOverlayOpen ) {
            this.setOverlayData( this.cityOverlayOpen )
        }
    }
    setCityData ( data ) {
        var _this2 = this;
        const dateService = new DataService();
        this.cityOverlayData = data;
        if ( this.currentDate ) {
            this.staggeredData = dateService.getStaggeredDistanceDate( this.currentDate, this.cityOverlayData );
            if ( this.cityOverlayOpen ) {
                this.activeCityIndex = this.staggeredData.findIndex( function ( item ) {
                    return item.city === _this2.cityOverlayOpen.city && item.country === _this2.cityOverlayOpen.country && item.region === _this2.cityOverlayOpen.region
                } )
            }
        }
        this.topCities = this.cityOverlayData.slice();
        this.topCities.sort( function ( a, b ) {
            return parseFloat( a.value ) - parseFloat( b.value )
        } );
        this.topCities.reverse();
        var length = this.topCities.length <= 5 ? this.topCities.length : 5;
        for ( var i = 0; i < length; i++ ) {
            var key = this.topCities[i].city + this.topCities[i].region + this.topCities[i].country;
            this.$topCity[i].getElementsByTagName( "span" )[0].innerHTML = this.topCities[i].city;
            this.$topCity[i].setAttribute( "data-city", key )
        }
        if ( this.nearCities && this.userLocation ) {
            var newNearCities = dateService.getNearestCities( {
                lat: this.userLocation.lat,
                lng: this.userLocation.lng
            }, this.currentDate );
            this.nearCities = newNearCities ? newNearCities : this.nearCities;
            if ( this.nearCities ) {
                for ( var _i = 0; _i < this.nearCities.length; _i++ ) {
                    var _key = this.nearCities[_i].city + this.nearCities[_i].region + this.nearCities[_i].country;
                    this.$nearCity[_i].getElementsByTagName( "span" )[0].innerHTML = this.nearCities[_i].city;
                    this.$nearCity[_i].setAttribute( "data-city", _key )
                }
            }
        }
    }
    animateIn () {
        var _this3 = this;
        TweenLite.to( this.$home, 0, {
            opacity: 1,
            ease: Linear.easeNone,
            delay: 0
        } );
        this.animationInterrupt( "home" );
        TweenLite.to( this.$nav, .25, {
            opacity: 1,
            pointerEvents: "auto",
            ease: Linear.easeNone,
            delay: 0
        } );
        TweenLite.to( this.$mobileNav, .25, {
            opacity: 1,
            pointerEvents: "auto",
            ease: Linear.easeNone,
            delay: 0
        } );
        TweenLite.to( this.$timelineMobile, .25, {
            opacity: 1,
            pointerEvents: "auto",
            ease: Linear.easeNone,
            delay: 0
        } );
        TweenLite.to( this.$mobileCitiesBtn, .25, {
            opacity: 1,
            ease: Linear.easeNone,
            delay: 0
        } );
        this.$mobileCitiesBtn.style.pointerEvents = "auto";
        //this.$timeline.classList.remove( "no-pointers" );
        TweenLite.to( this.$sliderTitle, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: 5.4
        } );
        TweenLite.to( this.$sliderBar, .2, {
            opacity: 1,
            ease: Quint.EaseOut,
            delay: 5.4
        } );
        var delay = 5.6;
        this.$topCity.forEach( function ( $city ) {
            TweenLite.to( $city, .4, {
                opacity: 1,
                y: 0,
                ease: Quint.EaseOut,
                delay: delay
            } );
            delay += .2
        } );
        TweenMax.delayedCall( 2.15, function () {
            _this3.$prevArrow.classList.remove( "inactive" );
            _this3.$nextArrow.classList.remove( "inactive" );
            _this3.currentAnimation = null
        } )
    }
    getNearestCities () {
        var _this4 = this;
        if ( navigator.geolocation ) {
            navigator.geolocation.getCurrentPosition( function ( position ) {
                _this4.userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                _this4.nearCities = dateService.getNearestCities( {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }, _this4.currentDate );
                if ( _this4.nearCities ) {
                    for ( var i = 0; i < _this4.nearCities.length; i++ ) {
                        var key = _this4.nearCities[i].city + _this4.nearCities[i].region + _this4.nearCities[i].country;
                        _this4.$nearCity[i].getElementsByTagName( "span" )[0].innerHTML = _this4.nearCities[i].city;
                        _this4.$nearCity[i].setAttribute( "data-city", key )
                    }
                }
                //_analytics.Analytics.createEvent( "interaction", "Location Permission", "Nearby location permission" );
                _this4.$nearLoader.classList.remove( "active" );
                _this4.$nearList.style.height = "auto"
            }, function () { }, {
                maximumAge: 864e5
            } )
        } else {
            this.$nearLoader.classList.remove( "active" )
        }
    }
    slider () {
        var _this5 = this;
        this.$topCities.addEventListener( "click", function () {
            _this5.$topCities.classList.add( "active" );
            _this5.$nearCities.classList.remove( "active" );
            _this5.$slider.classList.remove( "right" );
            _this5.$topList.classList.add( "active" );
            _this5.$nearList.classList.remove( "active" );
            _this5.$nearLoader.classList.remove( "active" )
        } );
        this.$nearCities.addEventListener( "click", function () {
            if ( !_this5.nearCities ) {
                _this5.$nearList.style.height = _this5.$topList.offsetHeight + 12 + "px";
                _this5.$nearLoader.classList.add( "active" );
                _this5.getNearestCities()
            }
            _this5.$nearCities.classList.add( "active" );
            _this5.$topCities.classList.remove( "active" );
            _this5.$slider.classList.add( "right" );
            _this5.$nearList.classList.add( "active" );
            _this5.$topList.classList.remove( "active" );
            //_analytics.Analytics.createEvent( "click", "Nearby Cities", "clickable_link, home, nearby" )
        } )
    }
    animationInterrupt ( newAnimationName ) {
        if ( this.currentAnimation ) {
            //this.currentAnimation.kill();
            if ( this.currentAnimation === "home" ) {
                this.animateOutHome();
                this.animateOutHomeMobile()
            } else if ( this.currentAnimation === "overlay" ) {
                if ( newAnimationName === "overlay-close" ) {
                    this.animateOutOverlayCities();
                    this.animateOutCityOverlayExtras()
                }
            }
        }
        this.currentAnimation = newAnimationName
    }
    animateOutHome () {
        var delay = 0;
        TweenLite.to( this.$mobileCloseBtn, .25, {
            opacity: 0,
            ease: Quint.EaseOut,
            delay: 0
        } );
        this.$mobileCloseBtn.classList.add( "inactive" );
        this.$topCity.forEach( function ( $city ) {
            TweenLite.to( $city, .25, {
                opacity: 0,
                ease: Quint.EaseOut,
                delay: delay
            } );
            delay += .1
        } );
        this.$nearCity.forEach( function ( $city ) {
            TweenLite.to( $city, .25, {
                opacity: 0,
                ease: Quint.EaseOut,
                delay: delay
            } );
            delay += .1
        } );
        TweenLite.to( this.$sliderTitle, .25, {
            opacity: 0,
            ease: Quint.EaseOut,
            delay: .5
        } );
        TweenLite.to( this.$sliderBar, .25, {
            opacity: 0,
            ease: Quint.EaseOut,
            delay: .5
        } );
        TweenLite.to( this.$nav, .25, {
            opacity: 0,
            pointerEvents: "none",
            ease: Linear.easeNone,
            delay: .6
        } )
    }
    animateOutHomeMobile () {
        if ( this.mobileInit ) {
            this.$mobileCitiesBtn.style.pointerEvents = "none";
            TweenLite.to( this.$mobileCitiesBtn, .25, {
                opacity: 0,
                y: 10,
                ease: Linear.easeNone,
                delay: 0
            } );
            TweenLite.to( this.$mobileCloseBtn, .25, {
                opacity: 0,
                ease: Quint.EaseOut,
                delay: 0
            } );
            this.$mobileCloseBtn.classList.add( "inactive" );
            var delay = .1;
            this.$topCity.forEach( function ( $city ) {
                TweenLite.to( $city, .25, {
                    opacity: 0,
                    y: 10,
                    ease: Quint.EaseOut,
                    delay: delay
                } );
                delay += .1
            } );
            this.$nearCity.forEach( function ( $city ) {
                TweenLite.to( $city, .25, {
                    opacity: 0,
                    y: 10,
                    ease: Quint.EaseOut,
                    delay: delay
                } );
                delay += .1
            } );
            this.$globe.classList.remove( "blur" )
        }
    }
    animateOutOverlayCities () {
        TweenLite.to( this.$cityLegal, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: 0
        } );
        TweenLite.to( this.$cityFooter, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: 0
        } );
        TweenLite.to( this.$buttonAll, .25, {
            scale: 0,
            ease: Quint.EaseOut,
            delay: 0
        } );
        TweenLite.to( this.$buttonFollow, .25, {
            scale: 0,
            ease: Quint.EaseOut,
            delay: 0
        } );
        var topDelay = this.todaysDate === this.currentDate ? .4 : .1;
        var articles = Array.from( document.querySelectorAll( ".overlay-result" ) );
        articles.reverse();
        if ( articles.length > 0 ) {
            TweenMax.staggerTo( articles, .25, {
                opacity: 0,
                y: -10,
                ease: Quint.EaseOut
            }, .1 )
        }
        TweenLite.to( this.$citySearchTitle, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: topDelay
        } );
        TweenLite.to( this.$cityName, .25, {
            opacity: 0,
            y: 10,
            ease: Quint.EaseOut,
            delay: topDelay + .1
        } );
        TweenLite.to( document.querySelector( "#overlay-results-no-results" ), .25, {
            opacity: 0,
            ease: Quint.easeOut,
            delay: topDelay + .1
        } )
    }
    animateOutCityOverlayExtras () {
        TweenLite.to( this.$cityOverlayClose, .25, {
            opacity: 0,
            y: 0,
            ease: Linear.noEase,
            delay: 0
        } );
        TweenLite.to( this.$prevArrow, .25, {
            opacity: 0,
            ease: Linear.noEase,
            delay: .1
        } );
        TweenLite.to( this.$nextArrow, .25, {
            opacity: 0,
            ease: Linear.noEase,
            delay: .1
        } )
    }
    animateInOverlayCities () {
        TweenLite.to( this.$cityName, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: 0
        } );
        var bottomDelay = this.todaysDate === this.currentDate ? 1 : 0;
        TweenLite.to( this.$buttonAll, .4, {
            scale: 1,
            ease: Quint.EaseOut,
            delay: bottomDelay
        } );
        TweenLite.to( this.$buttonFollow, .4, {
            scale: 1,
            ease: Quint.EaseOut,
            delay: bottomDelay
        } );
        TweenLite.to( this.$cityFooter, .4, {
            opacity: 1,
            y: 0,
            ease: Quint.EaseOut,
            delay: bottomDelay + .1
        } )
    }
    overlayArrowEvent () {
        this.animationInterrupt( "arrows" );
        this.currentMonth = null;
        var newCityOverlay = this.staggeredData[this.activeCityIndex];
        if ( newCityOverlay ) {
            this.setOverlayData( this.staggeredData[this.activeCityIndex] );
            this.emit( "look-at-city", this.staggeredData[this.activeCityIndex] )
        }
        this.currentAnimation = null
    }
    overlayChange () {
        var _this6 = this;
        this.$prevArrow.addEventListener( "click", function () {
            if ( _this6.staggeredData ) {
                _this6.activeCityIndex = _this6.activeCityIndex === 0 ? _this6.staggeredData.length - 1 : _this6.activeCityIndex - 1;
                _this6.overlayArrowEvent()
            }
        } );
        this.$nextArrow.addEventListener( "click", function () {
            if ( _this6.staggeredData ) {
                _this6.activeCityIndex = _this6.activeCityIndex >= _this6.staggeredData.length - 1 ? 0 : _this6.activeCityIndex + 1;
                _this6.overlayArrowEvent()
            }
        } );
        this.$cityOverlay.addEventListener( "touchmove", function ( e ) {
            e.stopPropagation()
        } )
    }
    setOverlayData ( data ) {
        this.$cityLegal.querySelector( "span.location" ).innerText = data.city + ", " + data.country;
        this.$overlayCity.innerText = data.city + ",";
        this.$overlayCountry.innerText = data.country;
        var searchText = data.city + ", " + data.country + " ";
        searchText = encodeURI( searchText.replace( /\s/g, "+" ) );
        searchText += "%23MeToo+Movement";
        this.$buttonFollow.setAttribute( "href", "https://www.google.com/alerts?q=" + searchText );
        var searchUrl = "https://www.google.com/search?q=" + searchText;
        if ( this.todaysDate === this.currentDate ) {
            this.$overlayResults.classList.remove( "hide-articles" );
            this.$cityOverlay.classList.remove( "not-today" );
            this.$buttonAll.setAttribute( "href", searchUrl )
        } else {
            var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var newDateArr = this.currentDate.split( "-" );
            var newDate = new Date( newDateArr[0], newDateArr[1] - 1, 1 );
            var endOfMonth = new Date( newDateArr[0], newDateArr[1], 1 );
            endOfMonth.setMonth( newDate.getMonth() + 1 );
            endOfMonth.setDate( newDate.getDate() - 1 );
            endOfMonth.setYear( newDate.getFullYear() );
            var endMonthStr = endOfMonth.getMonth() + 1 + "%2F" + endOfMonth.getDate() + "%2F" + endOfMonth.getFullYear();
            var newDateStr = newDate.getMonth() + 1 + "%2F" + newDate.getDate() + "%2F" + newDate.getFullYear();
            searchUrl += "&source=lnt&tbs=cdr%3A1%2Ccd_min%3A" + newDateStr + "%2Ccd_max%3A" + endMonthStr + "&tbm=";
            this.$buttonAll.querySelector( ".all-text-date" ).innerText = " from " + months[newDate.getMonth()];
            this.$cityOverlay.classList.add( "not-today" );
            this.$buttonAll.setAttribute( "href", searchUrl )
        }
        this.$buttonFollowCity.innerText = data.city;
        this.getCityData( data )
    }
    cityOverlay () {
        var _this7 = this;
        this.$topList.addEventListener( "click", function ( $e ) {
            var $li = $e.target.parentElement;
            var key = $li.getAttribute( "data-city" );
            if ( key ) {
                var city = _this7.cityOverlayData.find( function ( item ) {
                    return item.key === key
                } );
                _this7.emit( "look-at-city", city );
                //_analytics.Analytics.createEvent( "click", "Top Cities - " + city.key.replace( " ", "" ), "clickable_link, home, top_cities_" + city.key.replace( " ", "" ) );
                AudioController.overlayOut()
            }
        } );
        this.$nearList.addEventListener( "click", function ( $e ) {
            var $li = $e.target.parentElement;
            var key = $li.getAttribute( "data-city" );
            var city = _this7.cityOverlayData.find( function ( item ) {
                return item.key === key
            } );
            _this7.emit( "look-at-city", city );
            //_analytics.Analytics.createEvent( "click", "Cities near you - " + city.key.replace( " ", "" ), "clickable_link, home, top_cities_" + city.key.replace( " ", "" ) );
            AudioController.overlayOut()
        } );
        this.$cityOverlayClose.addEventListener( "click", function () {
            _this7.closeOverlay()
        } )
    }
    openOverlayCheck ( $e ) {
        this.animateOutHomeMobile();
        this.openOverlay( $e )
    }
    getCityData ( city ) {
        var _this8 = this;
        clearTimeout( this.getCityDataDebouncer );
        this.getCityDataDebouncer = setTimeout( function () {
            _this8.cityResults.loadCity( city, _this8.currentDate )
        }, 200 )
    }
    openOverlay ( city ) {
        var _this9 = this;
        if ( !city ) return;
        TweenLite.to( this.$mobileNav, 0, {
            pointerEvents: "none",
            ease: Linear.easeNone,
            delay: 0
        } );
        this.$cityOverlay.scrollTop = 0;
        document.body.classList.add( "in-city" );
        this.animationInterrupt( "overlay" );
        this.$globe.classList.remove( "blur" );
        //_analytics.Analytics.createEvent( "pageload", city.key.replace( " " ), "/submit_success", true );
        this.activeCityIndex = this.staggeredData.findIndex( function ( item ) {
            return item.city === city.city && item.country === city.country && item.region === city.region
        } );
        this.setOverlayData( city );
        this.$cityOverlay.classList.add( "active" );
        this.$citiesWrapper.classList.add( "inactive" );
        this.$mobileCloseBtn.classList.add( "inactive" );
        this.animateOutHomeMobile();
        this.animateOutHome();
        if ( !this.cityOverlayOpen ) {
            TweenMax.delayedCall( .85, function () {
                TweenLite.to( _this9.$cityOverlayClose, .25, {
                    opacity: 1,
                    ease: Linear.noEase,
                    delay: 0
                } );
                _this9.animateInOverlayCities();
                TweenLite.to( _this9.$cityBackground, 2, {
                    opacity: 1,
                    ease: Linear.easeIn,
                    delay: 0
                } );
                TweenLite.to( _this9.$prevArrow, .25, {
                    opacity: 1,
                    ease: Linear.noEase,
                    delay: 1.5
                } );
                TweenLite.to( _this9.$nextArrow, .25, {
                    opacity: 1,
                    ease: Linear.noEase,
                    delay: 1.5
                } )
            } );
            if ( this.mobileInit ) {
                /*TweenLite.to( this.$timeline, .25, {
                    opacity: 0,
                    pointerEvents: "none",
                    ease: Linear.easeNone,
                    delay: .9
                } );*/
                //this.$timeline.classList.add( "no-pointers" );
                TweenLite.to( this.$timelineMobile, .25, {
                    opacity: 1,
                    pointerEvents: "auto",
                    ease: Linear.easeNone,
                    delay: 1.1
                } )
            }
            TweenMax.delayedCall( 2.6, function () {
                _this9.currentAnimation = null
            } )
        }
        this.cityOverlayOpen = city
    }
    animateOutOverlay () {
        var _this10 = this;
        this.cityOverlayOpen = false;
        this.cityResults.isLookingAtCity = false;
        this.currentMonth = null;
        this.$cityOverlay.style.pointerEvents = "none";
        document.querySelector( ".city-overlay-wrapper" ).style.pointerEvents = "none";
        document.body.classList.remove( "in-city" );
        this.activeCityIndex = null;
        TweenLite.to( this.$cityOverlayClose, .25, {
            opacity: 0,
            y: 0,
            ease: Linear.noEase,
            delay: 0
        } );
        TweenLite.to( this.$cityBackground, 1, {
            opacity: 0,
            ease: Linear.noEase,
            delay: 0
        } );
        TweenMax.delayedCall( .1, function () {
            _this10.animateOutOverlayCities();
            TweenLite.to( _this10.$prevArrow, .25, {
                opacity: 0,
                ease: Linear.noEase,
                delay: 0
            } );
            TweenLite.to( _this10.$nextArrow, .25, {
                opacity: 0,
                ease: Linear.noEase,
                delay: 0
            } )
        } )
    }
    closeOverlay () {
        var _this11 = this;
        this.animateOutOverlay();
        window.history.pushState( {}, "Title?", "/" );
        TweenMax.delayedCall( 1, function () {
            _this11.$cityOverlay.classList.remove( "active" );
            if ( !_this11.mobileInit ) {
                _this11.$citiesWrapper.classList.remove( "inactive" )
            }
        } );
        TweenLite.to( this.$nav, .25, {
            opacity: 1,
            pointerEvents: "auto",
            ease: Linear.easeNone,
            delay: .8
        } );
        TweenLite.to( this.$mobileNav, .25, {
            opacity: 1,
            pointerEvents: "auto",
            ease: Linear.easeNone,
            delay: .8
        } );
        if ( this.mobileInit ) {
            /*TweenLite.to( this.$timeline, .25, {
                opacity: 1,
                pointerEvents: "auto",
                ease: Linear.easeNone,
                delay: .9
            } );*/
            TweenLite.to( this.$mobileCitiesBtn, .25, {
                opacity: 1,
                y: 0,
                ease: Linear.easeNone,
                delay: 1
            } );
            this.$mobileCitiesBtn.style.pointerEvents = "auto";
            this.$mobileCloseBtn.classList.remove( "inactive" );
            ////
            if ( !this.isEmbed ) {
                TweenLite.to( this.$timelineMobile, .25, {
                    opacity: 1,
                    pointerEvents: "auto",
                    ease: Linear.easeNone,
                    delay: 1.1
                } )
            }
        } else {
            /*if ( this.isEmbed ) {
                TweenLite.to( this.$timeline, .25, {
                    opacity: 1,
                    pointerEvents: "auto",
                    ease: Linear.easeNone,
                    delay: .9
                } );
                //this.$timeline.classList.remove( "no-pointers" )
            }*/
            TweenLite.to( this.$sliderTitle, .25, {
                opacity: 1,
                ease: Quint.EaseOut,
                delay: .9
            } );
            TweenLite.to( this.$sliderBar, .25, {
                opacity: 1,
                ease: Quint.EaseOut,
                delay: .9
            } );
            var delay = 1;
            this.$topCity.forEach( function ( $city ) {
                TweenLite.to( $city, .25, {
                    opacity: 1,
                    y: 0,
                    ease: Quint.EaseOut,
                    delay: delay
                } );
                delay += .1
            } );
            this.$nearCity.forEach( function ( $city ) {
                TweenLite.to( $city, .25, {
                    opacity: 1,
                    y: 0,
                    ease: Quint.EaseOut,
                    delay: delay
                } );
                delay += .1
            } )
        }
        TweenMax.delayedCall( this.mobileInit ? 1.8 : 1.4, function () {
            _this11.currentAnimation = null
        } );
        if ( this.isEmbed ) {
            this.$globe.classList.remove( "blur" )
        }
        this.emit( "close-overlay" )
    }
    mobilePage () {
        var _this12 = this;
        this.$mobileCitiesBtn.addEventListener( "click", function () {
            _this12.animationInterrupt( "mobile-overlay" );
            //_settings.Settings.globe.interactionEnabled = false;
            if ( !_this12.mobileInit ) {
                _this12.$topCity.forEach( function ( $city ) {
                    TweenLite.to( $city, 0, {
                        opacity: 0,
                        y: 10
                    } )
                } );
                _this12.$nearCity.forEach( function ( $city ) {
                    TweenLite.to( $city, 0, {
                        opacity: 0,
                        y: 10
                    } )
                } );
                TweenLite.to( _this12.$citiesWrapper, 0, {
                    opacity: 0,
                    y: "-50%",
                    x: "-50%",
                    ease: Quint.EaseOut,
                    delay: 0
                } );
                _this12.mobileInit = true
            }
            _this12.$citiesWrapper.classList.add( "active" );
            _this12.$citiesWrapper.classList.remove( "inactive" );
            _this12.$mobileCloseBtn.classList.add( "active" );
            _this12.$mobileCloseBtn.classList.remove( "inactive" );
            _this12.$globe.classList.add( "blur" );
            TweenLite.to( _this12.$mobileCitiesBtn, .25, {
                opacity: 0,
                y: 10,
                ease: Linear.easeNone,
                delay: 0
            } );
            TweenLite.to( _this12.$timeline, .25, {
                opacity: 0,
                pointerEvents: "none",
                ease: Linear.easeNone,
                delay: .1
            } );
            TweenLite.to( _this12.$timelineMobile, .25, {
                opacity: 0,
                pointerEvents: "none",
                ease: Linear.easeNone,
                delay: .1
            } );
            TweenLite.to( _this12.$nav, .25, {
                opacity: 0,
                pointerEvents: "none",
                ease: Linear.easeNone,
                delay: .2
            } );
            TweenLite.to( _this12.$mobileNav, 0, {
                pointerEvents: "none",
                ease: Linear.easeNone,
                delay: 0
            } );
            TweenLite.to( _this12.$mobileNav, .25, {
                opacity: 0,
                ease: Linear.easeNone,
                delay: .2
            } );
            //_this12.$timeline.classList.add( "no-pointers" );
            TweenLite.to( _this12.$citiesWrapper, 0, {
                y: "-50%",
                x: "-50%",
                ease: Quint.EaseOut,
                delay: 0
            } );
            TweenLite.to( _this12.$citiesWrapper, .4, {
                opacity: 1,
                ease: Quint.EaseOut,
                delay: .4
            } );
            TweenLite.to( _this12.$mobileCloseBtn, .25, {
                opacity: 1,
                ease: Quint.EaseOut,
                delay: .5
            } );
            TweenLite.to( _this12.$sliderTitle, 0, {
                opacity: 1,
                y: 0,
                ease: Quint.EaseOut,
                delay: .6
            } );
            TweenLite.to( _this12.$sliderBar, 0, {
                opacity: 1,
                y: 0,
                ease: Quint.EaseOut,
                delay: .6
            } );
            var delay = .8;
            _this12.$topCity.forEach( function ( $city ) {
                TweenLite.to( $city, .4, {
                    opacity: 1,
                    y: 0,
                    ease: Quint.EaseOut,
                    delay: delay
                } );
                delay += .2
            } );
            delay = .8;
            _this12.$nearCity.forEach( function ( $city ) {
                TweenLite.to( $city, .4, {
                    opacity: 1,
                    y: 0,
                    ease: Quint.EaseOut,
                    delay: delay
                } );
                delay += .2
            } );
            TweenMax.delayedCall( 2.1, function () {
                _this12.currentAnimation = null
            } )
        } );
        this.$mobileCloseBtn.addEventListener( "click", function () {
            _this12.animationInterrupt( "mobile-overlay-close" );
            //_settings.Settings.globe.interactionEnabled = true;
            TweenLite.to( _this12.$mobileCloseBtn, .25, {
                opacity: 0,
                ease: Quint.EaseOut,
                delay: 0
            } );
            var delay = .1;
            _this12.$topCity.forEach( function ( $city ) {
                TweenLite.to( $city, .25, {
                    opacity: 0,
                    y: 10,
                    ease: Quint.EaseOut,
                    delay: delay
                } );
                delay += .1
            } );
            delay = .1;
            _this12.$nearCity.forEach( function ( $city ) {
                TweenLite.to( $city, .25, {
                    opacity: 0,
                    y: 10,
                    ease: Quint.EaseOut,
                    delay: delay
                } );
                delay += .1
            } );
            TweenLite.to( _this12.$citiesWrapper, .25, {
                opacity: 0,
                ease: Quint.EaseOut,
                delay: .6
            } );
            _this12.$globe.classList.remove( "blur" );
            TweenLite.to( _this12.$mobileNav, .25, {
                opacity: 1,
                pointerEvents: "auto",
                ease: Linear.easeNone,
                delay: .9
            } );
            TweenLite.to( _this12.$nav, .25, {
                opacity: 1,
                pointerEvents: "auto",
                ease: Linear.easeNone,
                delay: 1
            } );
            TweenLite.to( _this12.$timeline, .25, {
                opacity: 1,
                pointerEvents: "auto",
                ease: Linear.easeNone,
                delay: 1.1
            } );
            TweenLite.to( _this12.$timelineMobile, .25, {
                opacity: 1,
                pointerEvents: "auto",
                ease: Linear.easeNone,
                delay: 1.1
            } );
            TweenLite.to( _this12.$mobileCitiesBtn, .25, {
                opacity: 1,
                y: 0,
                ease: Linear.easeNone,
                delay: 1.2
            } );
            _this12.$mobileCitiesBtn.style.pointerEvents = "auto";
            _this12.$timeline.classList.remove( "no-pointers" );
            AudioController.overlayOut();
            TweenMax.delayedCall( 1.5, function () {
                _this12.$mobileCloseBtn.classList.remove( "active" );
                _this12.$citiesWrapper.classList.remove( "active" );
                _this12.currentAnimation = null
            } )
        } )
    }
    reorient () {
        var _this13 = this;
        TweenLite.set( [this.$cityLegal], {
            display: "none"
        } );
        setTimeout( function () {
            TweenLite.set( [_this13.$cityLegal], {
                display: "block"
            } )
        }, 100 )
    }
    resize () {
        if ( this.mobileInit && window.innerWidth > 991 ) {
            this.mobileInit = false;
            TweenLite.to( this.$mobileCloseBtn, 0, {
                opacity: 0,
                ease: Quint.EaseOut,
                delay: 0
            } );
            TweenLite.to( this.$sliderTitle, 0, {
                opacity: 1,
                ease: Quint.EaseOut,
                delay: 0
            } );
            TweenLite.to( this.$sliderBar, 0, {
                opacity: 1,
                ease: Quint.EaseOut,
                delay: 0
            } );
            this.$topCity.forEach( function ( $city ) {
                TweenLite.to( $city, 0, {
                    opacity: 1,
                    y: 0
                } )
            } );
            TweenLite.to( this.$citiesWrapper, 0, {
                opacity: 1,
                y: "-50%",
                x: "0%",
                ease: Quint.EaseOut,
                delay: 0
            } );
            this.$globe.classList.remove( "blur" );
            TweenLite.to( this.$mobileNav, 0, {
                opacity: 1,
                pointerEvents: "auto",
                ease: Linear.easeNone,
                delay: 0
            } );
            TweenLite.to( this.$nav, 0, {
                opacity: 1,
                pointerEvents: "auto",
                ease: Linear.easeNone,
                delay: 0
            } );
            /*TweenLite.to( this.$timeline, 0, {
                opacity: 1,
                pointerEvents: "auto",
                ease: Linear.easeNone,
                delay: 0
            } );*/
            //
            if ( this.isEmbed || this.isMobile ) {
                TweenLite.to( this.$timelineMobile, 0, {
                    opacity: 1,
                    pointerEvents: "auto",
                    ease: Linear.easeNone,
                    delay: 0
                } )
            }
            TweenLite.to( this.$mobileCitiesBtn, 0, {
                opacity: 1,
                y: 0,
                ease: Linear.easeNone,
                delay: 0
            } );
            this.$citiesWrapper.classList.remove( "active" );
            this.$mobileCloseBtn.classList.remove( "active" );
            if ( this.activeCityIndex || this.activeCityIndex === 0 ) {
                this.$topCity.forEach( function ( $city ) {
                    TweenLite.to( $city, 0, {
                        opacity: 0,
                        y: 10
                    } )
                } );
                TweenLite.to( this.$sliderTitle, 0, {
                    opacity: 0,
                    y: 10,
                    ease: Quint.EaseOut,
                    delay: 0
                } );
                TweenLite.to( this.$sliderBar, 0, {
                    opacity: 0,
                    y: 10,
                    ease: Quint.EaseOut,
                    delay: 0
                } );
                TweenLite.to( this.$nav, 0, {
                    opacity: 0,
                    pointerEvents: "none",
                    ease: Linear.easeNone,
                    delay: 0
                } )
            }
        } else if ( !this.mobileInit && window.innerWidth <= 991 ) {
            if ( this.activeCityIndex || this.activeCityIndex === 0 ) {
                TweenLite.to( this.$mobileNav, 0, {
                    opacity: 0,
                    pointerEvents: "none",
                    ease: Linear.easeNone,
                    delay: 0
                } );
                /*TweenLite.to( this.$timeline, 0, {
                    opacity: 0,
                    pointerEvents: "none",
                    ease: Linear.easeNone,
                    delay: 0
                } );*/
                TweenLite.to( this.$timelineMobile, 0, {
                    opacity: 0,
                    pointerEvents: "none",
                    ease: Linear.easeNone,
                    delay: 0
                } );
                TweenLite.to( this.$mobileCitiesBtn, 0, {
                    opacity: 0,
                    y: 0,
                    ease: Linear.easeNone,
                    delay: 0
                } );
                //this.$timeline.classList.add( "no-pointers" )
            } else {
                /*TweenLite.to( this.$timeline, 0, {
                    y: 0,
                    ease: Linear.easeNone
                } );*/
                this.$mobileCitiesBtn.style.pointerEvents = "auto"
            }
            this.mobileInit = true
        }
    }
}