import * as THREE from 'three';
import EventEmitter from 'events';
import { HELPERS } from './helpers';
import HoverGlow from './HoverGlow';


/*const MIN_SIZE = .27;
const VERTEX = `
    attribute float fade;
    attribute float nearCenter;
    attribute float start;
    attribute vec2 opacity;
    uniform float size;
    uniform float time;
    varying float op;
    void main() {
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        // Opacity
        float elapsed = time - start;
        op = opacity.x + fade * elapsed;
        op = clamp( op, opacity.x, opacity.y );
        // Hide the markers behind the earth
        float zPos = dot( cameraPosition, cameraPosition ) - mvPosition.z * mvPosition.z;
        op *= smoothstep( 0.25, 0.75, zPos );
        float _size = size * nearCenter;
        gl_PointSize = step( 0., op ) * _size * ( 300.0 / -mvPosition.z );
        gl_Position = projectionMatrix * mvPosition;
    }
`;
const FRAG = `
    uniform sampler2D textureM;
    varying float op;
    void main() {
        vec4 c = texture2D(textureM, gl_PointCoord);
        gl_FragColor = vec4(c.xyz, c.a * op);
    }
`;*/
var MIN_SIZE = .27;
var VERTEX = "\nattribute float fade;\nattribute float nearCenter;\nattribute float start;\nattribute vec2 opacity;\n\nuniform float size;\nuniform float time;\n\nvarying float op;\n\nvoid main() {\n  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );\n\n  // Opacity\n  float elapsed = time - start;\n  op = opacity.x + fade * elapsed;\n  op = clamp(op, opacity.x, opacity.y);\n\n  // Hide the markers behind the earth\n  float zPos = dot(cameraPosition, cameraPosition) - mvPosition.z * mvPosition.z;\n  op *= smoothstep(0.25, 0.75, zPos);\n\n  float _size = size * nearCenter;\n\n  gl_PointSize = step(0., op) * _size * ( 300.0 / -mvPosition.z );\n  gl_Position = projectionMatrix * mvPosition;\n}\n";
var FRAG = "\nuniform sampler2D marker_texture;\n\nvarying float op;\n\nvoid main() {\n  vec4 c = texture2D(marker_texture, gl_PointCoord);\n  gl_FragColor = vec4(c.xyz, c.a * op);\n}\n";


export default class Markers extends EventEmitter {
    constructor( isMobile ) {
        super();
        this.count = 600;
        this.points = [];
        this.indexes = {};
        this.ids = [];
        this.isMobile = isMobile;
        this.FADE_IN = this.isMobile ? 1 / 150 : 1 / 300;
        this.FADE_OUT = this.isMobile ? -1 / 500 : -1 / 300;
        var texture = ( new THREE.TextureLoader ).load( "/marker.png" );
        texture.flipY = false;
        this.uniforms = {
            marker_texture: {
                value: texture
            },
            size: {
                value: MIN_SIZE * HELPERS.DPR(),
                type: "f"
            },
            time: {
                value: 0,
                type: "f"
            }
        };
        this.material = new THREE.ShaderMaterial( {
            uniforms: this.uniforms,
            vertexShader: VERTEX,
            fragmentShader: FRAG,
            blending: THREE.NormalBlending,
            depthTest: false,
            transparent: true
        } );
        this.geometry = new THREE.BufferGeometry;
        this.geometry.setAttribute( "position", new THREE.BufferAttribute( new Float32Array( this.count * 3 ), 3 ).setDynamic( true ) );
        this.geometry.setAttribute( "opacity", new THREE.BufferAttribute( new Float32Array( this.count * 2 ), 2 ).setDynamic( true ) );
        this.geometry.setAttribute( "fade", new THREE.BufferAttribute( new Float32Array( this.count ), 1 ).setDynamic( true ) );
        this.geometry.setAttribute( "start", new THREE.BufferAttribute( new Float32Array( this.count ), 1 ).setDynamic( true ) );
        this.geometry.setAttribute( "nearCenter", new THREE.BufferAttribute( new Float32Array( this.count ).fill( 1 ), 1 ).setDynamic( true ) );
        this.mesh = new THREE.Points( this.geometry, this.material );
        this.raycaster = new THREE.Raycaster;
        this.setDefaultHitArea();
        this.hoverglow = new HoverGlow;
    }

    setDefaultHitArea () {
        this.raycaster.params.Points.threshold = .0175 * ( this.isMobile ? 2 : 1 )
    }
    decreaseHitArea () {
        this.raycaster.params.Points.threshold = .0035 * ( this.isMobile ? 2 : 1 )
    }
    intersect ( arr1, arr2 ) {
        return arr1.filter( function ( p1 ) {
            return -1 < arr2.findIndex( function ( p2 ) {
                return p1.pos.x == p2.pos.x && p1.pos.y == p2.pos.y && p1.pos.y == p2.pos.y
            } )
        } )
    }
    difference ( arr1, arr2 ) {
        return arr1.filter( function ( p1 ) {
            return -1 === arr2.findIndex( function ( p2 ) {
                return p1.pos.x == p2.pos.x && p1.pos.y == p2.pos.y && p1.pos.y == p2.pos.y
            } )
        } )
    }
    currentOpacity ( index, op, fade, start, time ) {
        var cur = op[index * 2] + fade[index] * ( time - start[index] );
        return Math.min( Math.max( cur, op[index * 2] ), op[index * 2 + 1] )
    }
    startTime ( index, cur, op, rate, time ) {
        return time - ( cur - op[index * 2] ) / rate
    }
    updatePoints ( points ) {
        var newPoints = [];
        var time = this.uniforms.time.value;
        var intersect = this.intersect( this.points, points );
        var diffOld = this.difference( this.points, points );
        var diffNew = this.difference( points, this.points );
        var pos = this.geometry.attributes.position.array;
        var op = this.geometry.attributes.opacity.array;
        var _op = new Float32Array( op.length );
        _op.set( op );
        var start = this.geometry.attributes.start.array;
        var _start = new Float32Array( this.count );
        _start.set( start );
        var fade = this.geometry.attributes.fade.array;
        var _fade = new Float32Array( this.count );
        _fade.set( fade );
        var offset = 0;
        op.fill( 0 );
        for ( var i = 0; i < intersect.length; i++ ) {
            var p = intersect[i];
            var j = i + offset;
            if ( j >= this.count )
                continue;
            var id = HELPERS.pointId( p );
            pos[j * 3] = p.pos.x;
            pos[j * 3 + 1] = p.pos.y;
            pos[j * 3 + 2] = p.pos.z;
            op[j * 2] = 0;
            op[j * 2 + 1] = 1;
            if ( _fade[p.index] <= 0 ) {
                var cur_op = this.currentOpacity( p.index, _op, _fade, _start, time );
                var start_time = this.startTime( p.index, cur_op, _op, this.FADE_IN, time );
                fade[j] = this.FADE_IN;
                start[j] = start_time
            }
            this.indexes[id] = j;
            this.ids[j] = id;
            p.index = j;
            newPoints.push( p )
        }
        offset += intersect.length;
        for ( var _i = 0; _i < diffNew.length; _i++ ) {
            var _p = diffNew[_i];
            var _j = _i + offset;
            if ( _j >= this.count )
                continue;
            var _id = HELPERS.pointId( _p );
            pos[_j * 3] = _p.pos.x;
            pos[_j * 3 + 1] = _p.pos.y;
            pos[_j * 3 + 2] = _p.pos.z;
            op[_j * 2] = 0;
            op[_j * 2 + 1] = 1;
            fade[_j] = this.FADE_IN;
            start[_j] = time;
            this.indexes[_id] = _j;
            this.ids[_j] = _id;
            _p.index = _j;
            newPoints.push( _p )
        }
        offset += diffNew.length;
        for ( var _i2 = 0; _i2 < diffOld.length; _i2++ ) {
            var _p2 = diffOld[_i2];
            var _j2 = _i2 + offset;
            if ( _j2 >= this.count )
                continue;
            var _id2 = HELPERS.pointId( _p2 );
            pos[_j2 * 3] = _p2.pos.x;
            pos[_j2 * 3 + 1] = _p2.pos.y;
            pos[_j2 * 3 + 2] = _p2.pos.z;
            op[_j2 * 2] = 0;
            op[_j2 * 2 + 1] = 1;
            var _cur_op = this.currentOpacity( _p2.index, _op, _fade, _start, time );
            if ( _cur_op <= 0 ) {
                offset--;
                continue
            } else if ( _fade[_p2.index] >= 0 ) {
                var _start_time = this.startTime( _p2.index, _cur_op, _op, this.FADE_OUT, time );
                fade[_j2] = this.FADE_OUT;
                start[_j2] = _start_time
            }
            this.indexes[_id2] = _j2;
            this.ids[_j2] = _id2;
            _p2.index = _j2;
            newPoints.push( _p2 )
        }
        this.geometry.attributes.position.needsUpdate = true;
        this.geometry.attributes.opacity.needsUpdate = true;
        this.geometry.attributes.fade.needsUpdate = true;
        this.geometry.attributes.start.needsUpdate = true;
        this.points = newPoints
    }
    highlight ( id ) {
        var index = this.indexes[id];
        if ( index !== undefined ) {
            this.highlighted = id;
            this.center = [this.geometry.attributes.position.array[index * 3], this.geometry.attributes.position.array[index * 3 + 1], this.geometry.attributes.position.array[index * 3 + 2]]
        }
        var near = this.geometry.attributes.nearCenter.array;
        for ( var i = 0; i < near.length; i++ ) {
            var d = HELPERS.distance( this.center[0], this.center[1], this.center[2], this.geometry.attributes.position.array[i * 3], this.geometry.attributes.position.array[i * 3 + 1], this.geometry.attributes.position.array[i * 3 + 2] );
            near[i] = 1 - Math.min( .1, d ) / .1
        }
        this.geometry.attributes.nearCenter.needsUpdate = true
    }
    unhighlight () {
        this.highlighted = false;
        this.geometry.attributes.nearCenter.array.fill( 1 );
        this.geometry.attributes.nearCenter.needsUpdate = true
    }
    select ( id ) {
        var index = this.indexes[id];
        if ( index === undefined )
            return;
        this.hoverglow.moveTo( this.geometry.attributes.position.array[index * 3], this.geometry.attributes.position.array[index * 3 + 1], this.geometry.attributes.position.array[index * 3 + 2] );
        this.hoverglow.show( !!this.highlighted )
    }
    deselect ( id ) {
        this.hoverglow.hide();
        var index = this.indexes[id];
        if ( index === undefined )
            return
    }
    getIdByIndex ( index ) {
        return this.ids[index]
    }
    getPointById ( id ) {
        var index = this.indexes[id];
        return this.points.find( function ( p ) {
            return index === p.index
        } )
    }
    tick ( time ) {
        this.uniforms.time.value = time
    }
    raycast ( pointer, camera ) {
        this.raycaster.setFromCamera( pointer, camera );
        var intersects = this.raycaster.intersectObject( this.mesh );
        var firstIndex = undefined;
        var max_distance = camera.position.length();
        for ( var i = 0; i < intersects.length; i++ ) {
            if ( this.geometry.attributes.fade.array[intersects[i].index] > 0 && this.geometry.attributes.nearCenter.array[intersects[i].index] > 0 && intersects[i].distance < max_distance ) {
                firstIndex = intersects[i].index;
                break
            }
        }
        if ( firstIndex !== undefined )
            return this.getIdByIndex( firstIndex );
        return undefined
    }
}